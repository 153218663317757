import { IOfferRowCalculationConcrete } from "../components/Offers/OfferRowCalculation/OfferRowCalculationConcretes/OfferRowCalculationConcrete";
import { IOfferRowCalculationHollowcore } from "../components/Offers/OfferRowCalculation/OfferRowCalculationHollowcores/OfferRowCalculationHollowcore";
import { IOfferRowCalculationInsulation } from "../components/Offers/OfferRowCalculation/OfferRowCalculationInsulations/OfferRowCalculationInsulations";
import { IOfferRowCalculationMesh } from "../components/Offers/OfferRowCalculation/OfferRowCalculationMeshes/OfferRowCalculationMeshes";
import { IOfferRowCalculationSteelPart } from "../components/Offers/OfferRowCalculation/OfferRowCalculationSteelParts/OfferRowCalculationSteelParts";
import { IOfferRowCalculationSteel } from "../components/Offers/OfferRowCalculation/OfferRowCalculationSteels/OfferRowCalculationSteels";
import { IOfferRowCalculationSteelTendon } from "../components/Offers/OfferRowCalculation/OfferRowCalculationSteels/OfferRowCalculationSteelTendon";
import { IOfferRowCalculationWork } from "../components/Offers/OfferRowCalculation/OfferRowCalculationWork/OfferRowCalculationWork";
import { IOption } from "../components/ui/Input/Input";

export enum EFactory {
  HOL = "HOL",
  JOE = "JOE",
  KUO = "KUO",
  LIE = "LIE",
  OKU = "OKU",
}

export const getFactoryAddress = (factory: EFactory) => {
  switch (factory) {
    case EFactory.HOL:
      return "Kukonkankaantie 20 15880 Hollola";
    case EFactory.JOE:
      return "Ivontie 1 80260 Joensuu";
    case EFactory.KUO:
      return "Tehdaskatu 43 70620 Kuopio";
    case EFactory.LIE:
      return "Tehdastie 12 81750 Pankakoski";
    case EFactory.OKU:
      return "Talkkitie 16 83500 Outokumpu";
  }
};

export enum EUnit {
  jm = "jm",
  brm2 = "brm2",
  nm2 = "nm2",
  m2 = "m2",
  m3 = "m3",
  ton = "ton",
  kpl = "kpl",
  era = "era",
};

export const UNIT_OPTIONS: IOption[] = [
  { value: EUnit.jm, label: "jm" },
  { value: EUnit.brm2, label: "brm²" },
  { value: EUnit.nm2, label: "nm²" },
  { value: EUnit.m2, label: "m²" },
  { value: EUnit.m3, label: "m³" },
  { value: EUnit.ton, label: "ton" },
  { value: EUnit.kpl, label: "kpl" },
  { value: EUnit.era, label: "erä" },
];

interface IOfferRow {
  hollowcoreSquareMeterPrice?: string;
  steelpartsByElement?: string;
  steelpartsTotal?: string;
  steelpartsBySquareMeter?: string;
  rowDeliveryTotal?: string;
  rowWeight?: string;
  id: string;
  factory?: EFactory;
  productGroupId: string;
  productTypeId: string;
  productGroupName: string;
  productTypeName: string;
  description: string;
  hidden: boolean;
  unitQuantity: string;
  count: string;
  unit: string;
  unitPrice: string;
  rowPrice: string;
  deleted: boolean;
  linealMeters: string;
  grossArea: string;
  cubicMeters: string;
  cubicMeterPrice: string;
  netArea: string;
  offerRowCalculationConcrete: IOfferRowCalculationConcrete;
  offerRowCalculationHollowcore: IOfferRowCalculationHollowcore;
  offerRowCalculationInsulation: IOfferRowCalculationInsulation;
  offerRowCalculationMesh: IOfferRowCalculationMesh;
  offerRowCalculationSteel: IOfferRowCalculationSteel;
  offerRowCalculationSteelTendon: IOfferRowCalculationSteelTendon;
  offerRowCalculationSteelPart: IOfferRowCalculationSteelPart;
  offerRowCalculationWork: IOfferRowCalculationWork;
  offerRowCalculationTotal: IOfferRowCalculationTotal;
  grossProfitDivisor?: string;
  grossProfit2Divisor?: string;
  name?: string;
  unitString?: string;
  swatchbook: number;
  additionalInformation: string;
  priceGuid?: string;
}

export interface IOfferRowCalculationTotalRequest {
  offerRowCalculationConcrete?: IOfferRowCalculationConcrete;
  offerRowCalculationHollowcore?: IOfferRowCalculationHollowcore;
  offerRowCalculationInsulation?: IOfferRowCalculationInsulation;
  offerRowCalculationMesh?: IOfferRowCalculationMesh;
  offerRowCalculationSteel?: IOfferRowCalculationSteel;
  offerRowCalculationSteelTendon?: IOfferRowCalculationSteelTendon;
  offerRowCalculationSteelPart?: IOfferRowCalculationSteelPart;
  offerRowCalculationWork: IOfferRowCalculationWork;
  grossProfitDivisor?: string;
  grossProfit2Divisor?: string;
  elementsInProductionLine?: string;
  productionLineLength?: string;
}

export interface IOfferRowCalculationTotal {
  deliveryLinealMeterPrice?: string;
  elementsInProductionLine?: string;
  // concreteTotalVolume?: string
  addedDifficultyHoursElement?: string
  concreteCubicMeterPrice?: string
  concreteLinealMeterPrice?: string
  concreteSquareMeterPrice?: string
  concreteUnitDelivery?: string
  deliveryCubicMeterPrice?: string
  deliverySquareMeterPrice?: string
  insulationCubicMeterPrice?: string
  insulationLinealMeterPrice?: string
  insulationSquareMeterPrice?: string
  meshCubicMeterPrice?: string
  meshCubicMeterWeight?: string
  meshLinealMeterPrice?: string
  meshSquareMeterPrice?: string
  meshSquareMeterWeight?: string
  linealMetersPriceWithGrossMargin?: string
  squareMeterPriceWithGrossMargin?: string
  cubicMeterPriceWithGrossMargin?: string
  steelCubicMeterPrice?: string
  steelCubicMeterWeight?: string
  steelLinealMeterPrice?: string
  steelPartCubicMeterPrice?: string
  steelPartCubicMeterPriceK1?: string
  steelPartCubicMeterPriceK2?: string
  steelPartLinealMeterPrice?: string
  steelPartSquareMeterPrice?: string
  steelPartSquareMeterPriceK1?: string
  steelPartSquareMeterPriceK2?: string
  steelSquareMeterPrice?: string
  steelSquareMeterWeight?: string
  steelTendonCubicMeterPrice?: string
  steelTendonKilogramsPerCubicMeter?: string
  steelTendonLinealMeterPrice?: string
  steelTendonSquareMeterPrice?: string
  workCubicMeterPrice?: string
  workSquareMeterPrice?: string
  workLinealMeterPrice?: string
  totalSquareMeterPrice?: string
  totalCubicMeterPrice?: string
  totalLinealMeterPrice?: string
  productionLineLength?: string;
  pricePerElement?: string;
  weightPerElement?: string;
  workEstimateForElement?: string;
}

export default IOfferRow;
