import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import { IOrganizationsSearch } from "./OrganizationsSearch";
import { storage } from "../../utils/localstorage-utils";
import IOrganization, { OrganizationType } from "../../shared/IOrganization";
import { useOrganizationService } from "../../services/organizations-service";

export const useOrganizationsSearch = (type: OrganizationType) => {
  const searchJson = useMemo(() => storage.getOrganizationsSearch(type), [type]);
  const initialSearch = useMemo(() => searchJson ? JSON.parse(searchJson ?? "") : null, [searchJson]);

  const [search, setSearch] = useState<IOrganizationsSearch | null>({ ...initialSearch, type });
  const { fetchOrganizations } = useOrganizationService();

  let queryKey = null;
  if (type === "CUSTOMER") {
    queryKey = QueryKey.customers;
  } else if (type === "ENGINEER") {
    queryKey = QueryKey.engineers;
  }

  const {
    data: organizations,
    isPending,
    isRefetching,
    isError,
    error,
    refetch,
  } = useQuery<IOrganization[], ErrorResponse>({
    queryKey: [queryKey],
    queryFn: ({ signal }) => fetchOrganizations({ signal, search }),
    staleTime: 5000,
  });

  return {
    organizations,
    isLoading: isPending || isRefetching,
    isError,
    error,
    search,
    setSearch,
    refetch,
  };
};
