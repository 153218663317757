import Container from '../components/ui/Container/Container';
import SteelPartsList from '../components/SteelParts/SteelPartsList';
import Button, { EButtonColor, EButtonSize } from '../components/ui/Button/Button';
import { faFile, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Route } from '../routes';
import { useSteelPartsService } from '../services/steelParts-service';
import { useConfirmModal } from '../components/ui/Modal/useConfirmModal';
import { useCallback, useState } from 'react';
import { queryClient } from '../App';
import { QueryKey } from '../services/query-keys';


const SteelPartsPage: React.FC = () => {
  const navigate = useNavigate();
  const { archivedDeleteSteelParts } = useSteelPartsService();
  const openConfirmModal = useConfirmModal();
  const [archivedDeleteLoading, setArchivedDeleteLoading] = useState(false);

  const archivedDeleteHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat poistaa arkistoidut?");
    if (isConfirm) {
      setArchivedDeleteLoading(true);
      try {
        await archivedDeleteSteelParts();
        queryClient.removeQueries({ queryKey: [QueryKey.steelParts] });
      } catch (error) {
        console.error('Error deleting:', error);
      } finally {
        setArchivedDeleteLoading(false);
      }
    }
  }, [openConfirmModal, archivedDeleteSteelParts]);

  return (
    <>
    <h1 style={{ margin: "1rem", display: "flex", gap: "1rem", alignItems: "center",}}>
      Teräsosat{" "}
      <Button
        size={EButtonSize.SMALL}
        onClick={() => navigate(Route.steelPart("add"))}
        icon={faPlus}
        style={{ fontSize: "initial" }}
      >
        Lisää uusi
      </Button>
      <Button
        size={EButtonSize.SMALL}
        onClick={() => navigate(Route.steelPartsImport)}
        icon={faFile}
        style={{ fontSize: "initial" }}
      >
        Päivitä tiedostosta
      </Button>
      <Button
        size={EButtonSize.SMALL}
        onClick={archivedDeleteHandler}
        style={{ fontSize: "initial" }}
        color={EButtonColor.DANGER}
        loading={archivedDeleteLoading}
      >
        Poista arkistoidut
      </Button>
    </h1>
    <Container>
      <SteelPartsList />
    </Container>
    </>
  )
}

export default SteelPartsPage;