import { useNavigate } from "react-router-dom";
import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import WireTypesList from "../components/WireTypes/WireTypesList";
import { Route } from "../routes";

const WireTypesPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.wireType("add"));
  }

  return (
    <>
      <PageHeading onAdd={addHandler}>Vaijerityypit</PageHeading>
      <Container>
        <WireTypesList />
      </Container>
    </>
  );
};

export default WireTypesPage;
