import { useCallback } from "react";
import IElement from "../../../shared/IElement";
import { useProvisionsEditModal } from "../../ProvisionsEditModal/useProvisionsEditModal";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import PageHeading from "../../ui/PageHeading/PageHeading";
import { EModalId, useModalContext } from "../../ui/Modal/modal-context";
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import Attachments from "../../Attachments/Attachments";
import { useElementsService } from "../../../services/elements-service";
import { useMutation } from "@tanstack/react-query";
import { ErrorResponse } from "../../../custom-fetch";
import { queryClient } from "../../../App";
import { QueryKey } from "../../../services/query-keys";

interface IProps {
  productionLineId: string;
  element: IElement;
  showNext: () => void;
  showPrevious: () => void;
  unselectHandler: () => void;
  updateActiveElement: (element: IElement) => void;
}

const ProductionLineActiveElement: React.FC<IProps> = ({ productionLineId, element, showNext, showPrevious, unselectHandler, updateActiveElement }) => {
  const { closeModal } = useModalContext();
  const openProvisionsModal = useProvisionsEditModal(element.id, element.provisions ?? []);
  const { updateElementMutate, isUpdatingElements } = useUpdateElements(productionLineId);

  const editProvisionsHandler = useCallback(async () => {
    const data = await openProvisionsModal();
    if (data) {
        try {
            const newElements = await updateElementMutate([{ ...element, provisions: data }]);
            if (newElements?.length > 0) {
                updateActiveElement(newElements[0]);
            }
        } catch (error) {
            console.error(error);
        }
    }
    closeModal(EModalId.PROVISIONS_EDIT);
  }, [openProvisionsModal, closeModal, updateElementMutate, element, updateActiveElement]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <PageHeading variant="h2" noHorizontalMargin>{element.name} Liitteet</PageHeading>
        <div style={{ display: "flex", gap: "0.25rem" }}>
          <Button onClick={(e) => { e.stopPropagation(); editProvisionsHandler(); }} size={EButtonSize.SMALL} icon={faEdit} title="Muokkaa varauksia" style={{ minWidth: "45px" }} loading={isUpdatingElements} />
          <Button onClick={showPrevious} size={EButtonSize.SMALL} color={EButtonColor.PRIMARY} icon={faChevronLeft} title="Edellinen" />
          <Button onClick={showNext} size={EButtonSize.SMALL} color={EButtonColor.PRIMARY} icon={faChevronRight} title="Seuraava" />
          <Button onClick={unselectHandler} size={EButtonSize.SMALL} color={EButtonColor.PRIMARY} icon={faChevronUp} title="Sulje" />
        </div>
      </div>
      <hr />
      <Attachments kind="Element" parentId={element.id} noScroll listDirection="column" successCallbackFn={(element) => updateActiveElement(element as IElement)} />
    </>
  );
};

const useUpdateElements = (productionLineId: string) => {
    const { updateElements } = useElementsService();

    const {
        mutateAsync: updateElementMutate,
        isPending: isUpdatingElements,
        isError: isUpdateElementsError,
        error: updateElementsError,
    } = useMutation<IElement[], ErrorResponse, IElement[]>({
        mutationFn: (data) => updateElements(data),
        onSuccess: (data) => {
            const element = data[0];
            const productionLineElements = queryClient.getQueryData<IElement[]>([QueryKey.elements, productionLineId]);
            queryClient.setQueryData([QueryKey.elements, productionLineId], [...productionLineElements ?? []].map(el => el.id === element.id ? { ...element } : el));
            const elements = queryClient.getQueryData<IElement[]>([QueryKey.elements]);
            queryClient.setQueryData([QueryKey.elements], [...elements ?? []].map(el => el.id === element.id ? { ...element } : el));
        },
    });

    return { updateElementMutate, isUpdatingElements, isUpdateElementsError, updateElementsError };
};

export default ProductionLineActiveElement;
