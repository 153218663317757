import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IWireType from '../shared/IWireType';

interface IGetWireType extends ISignal {
  id: string;
}

export const useWireTypeService = () => {
  const customFetch = useCustomFetch();

  const fetchWireTypes = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [wireType] = await customFetch<IWireType[]>(`/api/wire-types/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return wireType;
  }, [customFetch]);
  
  const getWireType = useCallback(async ({ signal, id }: IGetWireType) => {
    const [wireType] = await customFetch<IWireType>("/api/wire-types/get/" + id, { signal });
    return wireType;
  }, [customFetch]);
  
  const saveWireType = useCallback(async (data: IWireType) => {
    const [wireType] = await customFetch<IWireType>("/api/wire-types/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return wireType;
  }, [customFetch]);
  
  const updateWireType = useCallback(async (id: string, data: IWireType) => {
    const [wireType] = await customFetch<IWireType>("/api/wire-types/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return wireType;
  }, [customFetch]);
  
  const deleteWireType = useCallback(async (id: string) => {
    await customFetch<boolean>("/api/wire-types/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const archivedUpdateWireType = useCallback(async (id: string) => {
    const [wireType] = await customFetch<IWireType>("/api/wire-types/update-archived/" + id, {
      method: "PUT",
    });
    return wireType;
  }, [customFetch]);
  
  return { fetchWireTypes, getWireType, saveWireType, updateWireType, deleteWireType, archivedUpdateWireType };
}
