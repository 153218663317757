import { useQuery } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";
import ClaimEdit from "../components/Claims/ClaimEdit/ClaimEdit";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Container from "../components/ui/Container/Container";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { useClaimService } from "../services/claims-service";
import { QueryKey } from "../services/query-keys";
import IClaim from "../shared/IClaim";
import { useMemo } from "react";

type Params = {
  id: string;
};

const ClaimPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const projectId = useMemo(() => searchParams.get("projectId"), [searchParams]);
  const { id } = useParams<Params>();
  const { getClaim } = useClaimService();

  const {
    data: claim,
    isLoading,
    isError,
    error,
  } = useQuery<IClaim, ErrorResponse>({
    queryKey: [QueryKey.claims, id],
    queryFn: ({ signal }) => getClaim({ signal, id: id! }),
    enabled: id !== "add",
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Reklamaatio / poikkeama</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <ClaimEdit id={id ?? ""} claim={claim} projectId={projectId} />
        )}
      </Container>
    </>
  );
};

export default ClaimPage;
