import Button, { IButtonProps } from "./Button";

interface IProps extends IButtonProps {
  img: string;
  imgAlt: string;
}

const ImageButton: React.FC<IProps> = ({ img, imgAlt, ...props }) => {
  return (
    <Button
      {...props}
      style={{ display: "flex", alignItems: "center", gap: "1rem" }}
    >
      <img src={img} alt={imgAlt} />
      {props.children}
    </Button>
  );
};

export default ImageButton;
