import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyB10H1JsEN4qj5JHUFtj2TVbFddK_VWcbs",
  authDomain: "pielisenbetoni.firebaseapp.com",
  projectId: "pielisenbetoni",
  storageBucket: "pielisenbetoni.appspot.com",
  messagingSenderId: "576273961101",
  appId: "1:576273961101:web:9a47432b8ead9fb12f52c4"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

