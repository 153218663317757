import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route } from "../../routes";
import { formatDate } from "../../utils/date-utils";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import ElementStatusTable from "./Elements/ElementStatusTable";
import { useProjectsSearch } from "./useProjectsSearch";

const ProjectsList: React.FC = () => {
  const { isError, error, isLoading, projects } = useProjectsSearch();
  //const { fetchProjects } = useProjectsService();
  const [activeProjectId, setActiveProjectId] = useState<string | null>(null);

  const navigate = useNavigate();
  /*
  const {
    data: projects,
    isPending,
    isError,
    error,
  } = useQuery<IProject[], ErrorResponse>({
    queryKey: [QueryKey.projects],
    queryFn: fetchProjects,
    staleTime: 5000,
  });
  */

  const toggleHandler = useCallback((event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    if (id === activeProjectId) {
      setActiveProjectId(null);
      return;
    }
    setActiveProjectId(id);
  }, [activeProjectId])

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!projects || projects.length === 0) {
    return <p>Ei työmaita</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.project(id));
  };

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>Luontipvm</th>
            <th>Työmaan nro</th>
            <th>Työmaan nimi</th>
            <th>Kohteen nimi</th>
            <th>Asiakkaan nimi</th>
            <th>Myyjä</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <React.Fragment key={project.id}>
              <tr onClick={() => openHandler(project.id)} style={project.id === activeProjectId ? { background: "#ccc" } : {}}>
                <td>{formatDate(project.created)}</td>
                <td>
                  {project.projectNumber} / {project.offer?.offerNumber}
                </td>
                <td>{project.name}</td>
                <td>{project.offer?.targetName}</td>
                <td>{project.offer?.customer?.name}</td>
                <td>{project.offer?.seller?.name}</td>
                <td>
                  {activeProjectId === project.id ? (
                    <Button
                      onClick={(event) => toggleHandler(event, project.id)}
                      size={EButtonSize.SMALL}
                      icon={faChevronUp}
                      color={EButtonColor.DEFAULT}
                      title="Sulje"
                    />
                  ) : (
                    <Button
                      onClick={(event) => toggleHandler(event, project.id)}
                      size={EButtonSize.SMALL}
                      icon={faChevronDown}
                      color={EButtonColor.DEFAULT}
                      title="Avaa"
                    />
                  )}
                </td>
              </tr>
              {activeProjectId === project.id && (
                <tr style={project.id === activeProjectId ? { background: "#ccc" } : {}}>
                  <td colSpan={7}>
                    <ElementStatusTable projectId={activeProjectId} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ProjectsList;
