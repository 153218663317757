import { IOfferRowCalculationTotal } from "../../../../shared/IOfferRow";
import Table from "../../../ui/Table/Table";
import { IOfferRowCalculationInsulation } from "./OfferRowCalculationInsulations";

interface IProps {
  calculation: IOfferRowCalculationInsulation;
  total: IOfferRowCalculationTotal;
}

const InsulationsTotalTable: React.FC<IProps> = ({ calculation, total }) => {
  return (
    <Table removeGap>
      <thead>
        <tr>
          <th>yhteensä, €</th>
          <th>€ /m²</th>
          <th>€ /m³</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{calculation.price} €</td>
          <td>{total.insulationSquareMeterPrice} €</td>
          <td>{total.insulationCubicMeterPrice} €</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default InsulationsTotalTable;
