import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { queryClient } from "../../../App";
import { ErrorResponse } from "../../../custom-fetch";
import { useOfferService } from "../../../services/offers-service";
import { QueryKey } from "../../../services/query-keys";
import IOffer from "../../../shared/IOffer";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../../ui/Modal/modal-context";
import { useDialog } from "../../ui/Modal/useDialog";
import UpdateOfferPricesModal from "./UpdateOfferPricesModal";
import ErrorsAlert from "../../ErrorsAlert/ErrorsAlert";

const useUpdateOfferPricesModal = (id: string) => {
  const { addModal, closeModal } = useModalContext();
  const { updateOfferPrices } = useUpdateOfferPrices(id);

  const handler = (offer: IOffer): Promise<IOffer | null> => {
    const { summary } = offer;
    const { grossProfitDivisor, grossProfit2Divisor } = summary;
    return new Promise((resolve) => {
      addModal({
        id: EModalId.OFFER_UPDATE_PRICES,
        isOpen: true,
        size: EModalSize.SMALL,
        title: "Päivitä hinnat ja kate",
        backdropNotClose: true,
        content: (
          <UpdateOfferPricesModal
            onAccept={async (data) => {
              try {
                offer.summary.grossProfitDivisor = data.grossProfitDivisor;
                offer.summary.grossProfit2Divisor = data.grossProfit2Divisor;
                const newOffer = await updateOfferPrices(offer);
                closeModal(EModalId.OFFER_UPDATE_PRICES);
                resolve(newOffer);
              } catch (e) {
                console.error(e);
                closeModal(EModalId.OFFER_UPDATE_PRICES);
                resolve(null);
              }
            }}
            onCancel={() => {
              closeModal(EModalId.OFFER_UPDATE_PRICES);
              resolve(null);
            }}
            data={{ grossProfitDivisor, grossProfit2Divisor }}
          />
        ),
      });
    });
  };

  return handler;
};

const useUpdateOfferPrices = (id: string) => {
  const openDialog = useDialog();
  const { updateOfferPrices } = useOfferService();

  const mutationFn = useCallback(
    (offer: IOffer) => {
      return updateOfferPrices(offer);
    },
    [updateOfferPrices]
  );

  const {
    mutateAsync: mutateUpdateOfferPrices,
    isPending,
    isError,
    error,
  } = useMutation<IOffer, ErrorResponse, IOffer>({
    mutationFn,
    onSuccess: async (data) => {
      queryClient.setQueryData([QueryKey.offers, id], { ...data });
    },
    onError: (error) => {
      openDialog(
        "Virhe",
        <>
          <p>
            <FontAwesomeIcon icon={faTimesCircle} color="red" /> Hintojen
            päivitys epäonnistui.
          </p>
          {error?.messages && <ErrorsAlert errors={error.messages} />}
        </>
      );
    },
  });

  return {
    updateOfferPrices: mutateUpdateOfferPrices,
    isUpdatingPrices: isPending,
    isUpdatePricesError: isError,
    updatePricesError: error,
  };
};

export default useUpdateOfferPricesModal;
