import Container from "../components/ui/Container/Container";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import WaybillsList from "../components/Waybills/WaybillsList";
import WaybillsSearch from "../components/Waybills/WaybillsSearch";

const WaybillsPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.waybill("add"));
  }

  return (
    <>
      <PageHeading onAdd={addHandler}>Rahtikirjat</PageHeading>
      <Container>
        <WaybillsSearch />
      </Container>
      <Container>
        <WaybillsList />
      </Container>
    </>
  );
};

export default WaybillsPage;
