import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { CSSProperties, forwardRef, useImperativeHandle, useState } from "react";
import IInsulation from "../../../../shared/IInsulation";
import Button, { EButtonColor, EButtonSize } from "../../../ui/Button/Button";
import { EInputType, IInputField, IOption } from "../../../ui/Input/Input";
import { EInputUpdateAction } from "../../../ui/Input/input-context";
import { useInputs } from "../../../ui/Input/useInputs";
import { RefHandle } from "../../../../shared/RefHandle";

export interface IOfferRowCalculationInsulationRow {
  id: string;
  cubicPrice: string;
  squareMeters: string;
  thickness: string;
  volume: string;
  price: string;
  insulation: IInsulation;
}

export enum EInputs {
  squareMeters = "squareMeters",
  thickness = "thickness",
  cubicPrice = "cubicPrice",
  insulation = "insulation",
}

interface IProps {
  calculation: IOfferRowCalculationInsulationRow;
  onDelete: () => void;
  insulationOptions?: IOption[];
  insulationsLoading?: boolean;
  showControls: boolean;
}

const containerStyles: CSSProperties = { width: "100px" };

const OfferRowCalculationInsulation: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationInsulationRow>, IProps> = ({ calculation, onDelete, insulationOptions, insulationsLoading, showControls }, ref) => {
  const { createInput, submit } = useOfferRowCalculationInsulationInputs(calculation, showControls);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const newData = await submit();
      if (!calculation) return null;
      return { ...calculation, ...newData };
    },
  }), [calculation, submit]);

  return (
    <tr>
      <td>{createInput(EInputs.insulation, { options: insulationOptions, loading: insulationsLoading, containerStyles: { width: "300px" } })}</td>
      <td>{createInput(EInputs.squareMeters, { containerStyles })}</td>
      <td>{createInput(EInputs.thickness, { containerStyles })}</td>
      <td>{calculation?.volume}</td>
      <td>{createInput(EInputs.cubicPrice, { containerStyles })}</td>
      <td>{calculation?.price} €</td>
      <td>
        {showControls && (
          <Button
            onClick={onDelete}
            size={EButtonSize.SMALL}
            color={EButtonColor.DANGER}
            icon={faTrash}
          />
        )}
      </td>
    </tr>
  );
};

const useOfferRowCalculationInsulationInputs = (data?: IOfferRowCalculationInsulationRow, showControls?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.insulation]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
      isCreatable: true,
    },
    [EInputs.squareMeters]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.thickness]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.cubicPrice]: {
      type: EInputType.number,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.INSULATION, disabled: !showControls });

  return { createInput, submit };
};

export default forwardRef(OfferRowCalculationInsulation);
