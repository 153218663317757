import { useMutation } from "@tanstack/react-query";
import { useCallback, useRef } from "react";
import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { useAttachmentsService } from "../../services/attachments-service";
import { Kind } from "../../services/kind";
import { QueryKey } from "../../services/query-keys";
import IAttachment from "../../shared/IAttachment";
import Dropzone from "../Dropzone/Dropzone";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import { useProjectEditContext } from "../Projects/ProjectEdit/project-edit-context";
import AttachmentsList from "./AttachmentsList";
import IElement from "../../shared/IElement";

export type FlexDirection = "row" | "column";

export type AttachmentsReturnType = IElement;

interface IProps {
  kind: Kind;
  parentId: string;
  noScroll?: boolean;
  listDirection?: FlexDirection;
  iframeStyles?: React.CSSProperties;
  uploadDisabled?: boolean;
  successCallbackFn?: (returnType: AttachmentsReturnType) => void;
}

export interface IAttachmentsHandle {
  hasAttachments?: boolean;
}

const Attachments: React.FC<IProps> = ({ kind, parentId, noScroll, listDirection = "row", iframeStyles, uploadDisabled, successCallbackFn }) => {
  const { uploadMutate, isUploading, isUploadError, uploadError } = useUpload(kind, parentId, successCallbackFn);

  const uploadHandler = useCallback(
    async (files: File[]) => {
      if (!files || !files.length) return;
      uploadMutate(files[0]);
    },
    [uploadMutate]
  );

  const errorMessages = combineErrors({
    isError: isUploadError,
    error: uploadError,
  });

  const attachmentsListRef = useRef<IAttachmentsHandle>(null);

  return (
    <>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <div style={{ display: "flex", gap: "1rem", flexDirection: "column", margin: "1rem 0" }}>
        {!uploadDisabled && (
          <Dropzone
            onDrop={uploadHandler}
            canPaste={!isUploading}
            disabled={isUploading}
          />
        )}
        <AttachmentsList kind={kind} parentId={parentId} noScroll={noScroll} flexDirection={listDirection} isUploading={isUploading} ref={attachmentsListRef} iframeStyles={iframeStyles} uploadDisabled={uploadDisabled} successCallbackFn={successCallbackFn} />
      </div>
    </>
  );
};

const useUpload = (kind: string, parentId: string, successCallbackFn?: (element: IElement) => void) => {
  const { saveAttachment } = useAttachmentsService();
  const { setElements } = useProjectEditContext();

  const {
    mutate: uploadMutate,
    isPending: isUploading,
    isError: isUploadError,
    error: uploadError,
  } = useMutation<IAttachment, ErrorResponse, File>({
    mutationFn: (file) => saveAttachment(kind, parentId, file),
    onSuccess: (data) => {
      // queryClient.invalidateQueries({
      //   queryKey: [QueryKey.attachments, kind, parentId],
      // });
      const oldData = queryClient.getQueryData<IAttachment[]>([QueryKey.attachments, kind, parentId]) ?? [];
      queryClient.setQueryData([QueryKey.attachments, kind, parentId], [data, ...oldData]);
      queryClient.invalidateQueries({ queryKey: [QueryKey.latestAttachments, kind, parentId] });
      if (data.element) {
        // const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, data.element.projectId]) ?? [];
        // queryClient.setQueryData([QueryKey.elements, data.element.projectId], [...oldData ?? []].map(el => el.id === data.element?.id ? {...el, hasAttachments: data.element?.hasAttachments, isPlanned: data.element?.isPlanned } : el));
        setElements(elements => elements.map(el => el.id === data.element?.id ? {...el, hasAttachments: data.element?.hasAttachments, isPlanned: data.element?.isPlanned } : el));
        if (successCallbackFn) successCallbackFn(data.element);
      }
    },
  });

  return { uploadMutate, isUploading, isUploadError, uploadError };
};

export default Attachments;
