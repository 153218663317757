import Container from "../components/ui/Container/Container";
import Button, { EButtonColor, EButtonSize } from "../components/ui/Button/Button";
import { faFile, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import ConcreteTypesList from "../components/ConcreteTypes/ConcreteTypesList";
import { useConfirmModal } from "../components/ui/Modal/useConfirmModal";
import { useCallback, useState } from "react";
import { QueryKey } from "../services/query-keys";
import { useConcreteTypeService } from "../services/concreteTypes-service";
import { queryClient } from "../App";

const ConcreteTypesPage: React.FC = () => {
  const navigate = useNavigate();
  const { archivedDeleteConcreteTypes } = useConcreteTypeService();
  const openConfirmModal = useConfirmModal();
  const [archivedDeleteLoading, setArchivedDeleteLoading] = useState(false);

  const archivedDeleteHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat poistaa arkistoidut?");
    if (isConfirm) {
      setArchivedDeleteLoading(true);
      try {
        await archivedDeleteConcreteTypes();
        queryClient.removeQueries({ queryKey: [QueryKey.concreteTypes] });
      } catch (error) {
        console.error('Error deleting:', error);
      } finally {
        setArchivedDeleteLoading(false);
      }
    }
  }, [openConfirmModal, archivedDeleteConcreteTypes]);
  
  return (
    <>
      <h1
        style={{
          margin: "1rem",
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        Massat {" "}
        <Button
          size={EButtonSize.SMALL}
          onClick={() => navigate(Route.concreteType("add"))}
          icon={faPlus}
          style={{ fontSize: "initial" }}
        >
          Lisää uusi
        </Button>
        <Button
          size={EButtonSize.SMALL}
          onClick={() => navigate(Route.concreteTypesImport)}
          icon={faFile}
          style={{ fontSize: "initial" }}
        >
          Päivitä tiedostosta
        </Button>
        <Button
          size={EButtonSize.SMALL}
          onClick={archivedDeleteHandler}
          style={{ fontSize: "initial" }}
          color={EButtonColor.DANGER}
          loading={archivedDeleteLoading}
        >
          Poista arkistoidut
        </Button>
      </h1>
      <Container>
        <ConcreteTypesList />
      </Container>
    </>
  );
};

export default ConcreteTypesPage;
