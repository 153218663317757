import { CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useOfferTermOptions } from "../../../../hooks/useOfferTermOptions";
import { useOfferService } from "../../../../services/offers-service";
import { EOfferTermType } from "../../../../shared/IOfferTerm";
import { RefHandle } from "../../../../shared/RefHandle";
import { EInputType, IInputField, TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import { useInputs } from "../../../ui/Input/useInputs";
import Table from "../../../ui/Table/Table";
import { useOfferCalculationTabsContext } from "../offer-calculation-tabs-context";
import OfferCalculationTabInstallationRow, { EInputs as ERowInputs, IInstallationRow } from "./OfferCalculationTabInstallationRow";

export const createOfferInstallations = (): IInstallations => {
    return {installationRows: [], totalCost: "0", totalQuantity: "0", installed: false, installationText: "", installationPrice: "0", installationDeclaredPrice: "0", craneHourlyRate: "120", cranePriceTotal: "0"};
}


export enum EInputs {
  installationText = "installationText",
  installationDeclaredPrice = "installationDeclaredPrice",
  cranePriceTotal = "cranePriceTotal"
}

export interface IInstallations {
  installationRows: IInstallationRow[];
  totalCost: string;
  totalQuantity: string;
  installed: boolean;
  installationText: string;
  installationPrice: string;
  installationDeclaredPrice: string;
  craneHourlyRate: string;
  cranePriceTotal: string;
}

const containerStyles: CSSProperties = { width: "125px", marginBottom: "0", padding: "0"};

const OfferCalculationTabInstallations: React.ForwardRefRenderFunction<RefHandle<IInstallations>> = (_, ref) => {
  const { installations, setInstallations, setSummary, offerRows } = useOfferCalculationTabsContext();
  const { createInput, submit } = useInstallationsInputs(installations);
  const { calculateOfferInstallations, calculateOfferSummaryRows } = useOfferService();
  
  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.INSTALLATIONS) {
        const newInstallations = await calculateOfferInstallations({ ...installations, [inputName]: formatInputValue(value) });
        setInstallations(newInstallations);
        const newSummary = await calculateOfferSummaryRows(offerRows, newInstallations.installationDeclaredPrice);
        setSummary((summary) => ({ ...summary, ...newSummary }));
      }
      return Promise.resolve(true);
    },
    [setInstallations, calculateOfferInstallations, installations, calculateOfferSummaryRows, offerRows, setSummary]
  );
  
  const { offerTerms } = useOfferTermOptions(EOfferTermType.INSTALLATIONS_TERM, true);

  const updateInstallationRowHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.INSTALLATION_ROW) {
        const data = _ as IInstallationRow;
        let newInstallations = {...installations};
        const index = newInstallations.installationRows.findIndex((r) => r.id === data.id);
        if (inputName === ERowInputs.cost && !value) {
          value = "0";
        }
        if (index > -1) {
          const newData = {...data,
            [inputName]: formatInputValue(value),
          };
          newInstallations.installationRows[index] = newData;
          newInstallations = await calculateOfferInstallations(newInstallations);
          setInstallations(newInstallations);
          const newSummary = await calculateOfferSummaryRows(offerRows, newInstallations.installationDeclaredPrice);
          setSummary((summary) => ({ ...summary, ...newSummary }));
        }
      }
      return Promise.resolve(true);
    },
    [calculateOfferInstallations, installations, setInstallations, calculateOfferSummaryRows, offerRows, setSummary]
  );

  useEffect(() => {
    if (!installations.installationText && offerTerms && offerTerms[0]) {
      setInstallations({...installations, installationText: offerTerms[0].text})
    }
  }, [offerTerms, installations, setInstallations]);

  const installationRowsRef = useRef<RefHandle<IInstallationRow>[]>([]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const installationData = await submit();
      const installationRows = installationRowsRef.current;
      const newRows: IInstallationRow[] = [];
      for (let i = 0; i < installationRows.length; i++) {
        const row = installationRows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            newRows.push(rowData);
          }
        }
      }
      // TODO calculate installations
      return { ...installations, ...installationData, installationRows: [...newRows] };
    },
  }), [installations, submit]);

  return (
    <>
    <InputContextProvider initDone onAutoUpdate={updateHandler}>
      <Table>
          <thead>
            <tr>
              <th/>
              <th/>
              <th/>
              <th/>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Asennettavia kappaleita</td>
              <td >{installations.totalQuantity} kpl</td>
            </tr>
            <tr>
              <td>Laskettu hinta</td>
              <td >{installations.totalCost} €</td>
            </tr>
            <tr>
              <td>Nosturin hinta</td>
              <td>{createInput(EInputs.cranePriceTotal, { containerStyles })}</td>
            </tr>
            <tr>
              <td>Asennus yhteensä hinta</td>
              <td>{createInput(EInputs.installationDeclaredPrice, { containerStyles })}</td>
            </tr>
          </tbody>
        </Table>
      {createInput(EInputs.installationText)}
    </InputContextProvider>
    {installations?.installationRows?.length > 0 &&
    <Table>
      <thead>
        <tr>
          <th>tuoteryhmä</th>
          <th>kpl</th>
          <th>asennushinta</th>
          <th>yht</th>
          <th>nettohinta</th>
        </tr>
      </thead>
      <tbody>
        <InputContextProvider initDone onAutoUpdate={updateInstallationRowHandler}>
          {installations.installationRows.map((installationRow, i) => (
            <OfferCalculationTabInstallationRow key={installationRow.id} data={installationRow} ref={rowHandle => installationRowsRef.current[i] = rowHandle!} />
          ))}
        </InputContextProvider>
      </tbody>
    </Table>
    }
    </>
  );
};

const useInstallationsInputs = (data?: IInstallations) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.installationText]: {
      type: EInputType.textarea,
      value: "",
      label: "Teksti",
      rows: 4
    },
    [EInputs.installationDeclaredPrice]: {
      type: EInputType.number,
      value: "",
      post: "€"
    },
    [EInputs.cranePriceTotal]: {
      type: EInputType.number,
      value: "",
      post: "€",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.INSTALLATIONS });

  return { createInput, submit, inputs };
};

export default forwardRef(OfferCalculationTabInstallations);
