import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import classes from "./ToggleMenu.module.scss";

interface IProps {
  onToggle: () => void;
  className?: string;
}

const ToggleMenu: React.FC<IProps> = ({ onToggle, className }) => {
  const classNames = [classes.Container];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(" ")} onClick={onToggle}>
      <FontAwesomeIcon icon={faBars} className={classes.Icon} />
    </div>
  );
};

export default ToggleMenu;
