import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Container from "../components/ui/Container/Container";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useWireTypeService } from "../services/wireTypes-service";
import IWireType from "../shared/IWireType";
import WireTypeEdit from "../components/WireTypes/WireTypeEdit";

type Params = {
  id: string;
};

const WireTypePage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getWireType } = useWireTypeService();
  
  const {
    data: wireType,
    isLoading,
    isError,
    error,
  } = useQuery<IWireType, ErrorResponse>({
    queryKey: [QueryKey.wireTypes, id],
    queryFn: ({ signal }) => getWireType({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Vaijerityyppi</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <WireTypeEdit id={id!} wireType={wireType} />
        )}
      </Container>
    </>
  );
};

export default WireTypePage;
