import { IImport, useImportService } from "../../../../services/import-service";
import OfferRowsImportModal from "./OfferProductsImportModal";
import { EModalId, EModalSize, useModalContext } from "../../../ui/Modal/modal-context";
import { useMutation } from "@tanstack/react-query";
import OfferProductsImportResultModal from "./OfferProductsImportResultModal";
import { ErrorResponse } from "../../../../custom-fetch";

export const useOfferRowsImportModal = () => {
  const { addModal, closeModal } = useModalContext();
  const { importFile } = useImportService();

  const { mutateAsync } = useMutation<IImport, ErrorResponse, FormData>({
    mutationFn: (formData: FormData) => importFile("OfferRow", formData),
  });

  const handler = (): Promise<IImport | null> => {
    return new Promise((resolve) => {
      const handleImportAccept = async (formData: FormData) => {
        closeModal(EModalId.OFFER_ROW_IMPORT);
        try {
          const data = await mutateAsync(formData);
          showImportResultModal(data);
        } catch (error) {
          showImportResultModal(null, error as ErrorResponse);
        }
      };

      const handleImportCancel = () => {
        closeModal(EModalId.OFFER_ROW_IMPORT);
        resolve(null);
      };

      const showImportResultModal = (importResult: IImport | null, error?: ErrorResponse | null) => {
        addModal({
          id: EModalId.OFFER_ROW_IMPORT_RESULT,
          isOpen: true,
          size: EModalSize.SMALL,
          title: "Import Tulokset",
          backdropNotClose: true,
          onModalClose() {resolve(null)},
          content: (
            <OfferProductsImportResultModal
              importResult={importResult}
              error={error}
              onConfirm={() => {
                closeModal(EModalId.OFFER_ROW_IMPORT_RESULT);
                resolve(importResult);
              }}
              onCancel={() => {
                closeModal(EModalId.OFFER_ROW_IMPORT_RESULT);
                resolve(null);
              }}
            />
          ),
        });
      };

      addModal({
        id: EModalId.OFFER_ROW_IMPORT,
        isOpen: true,
        size: EModalSize.SMALL,
        title: "Import: csv/jcad tuoteriveiksi",
        backdropNotClose: true,
        onModalClose() {resolve(null)},
        content: (
          <OfferRowsImportModal
            onAccept={handleImportAccept}
            onCancel={handleImportCancel}
          />
        ),
      });
    });
  };

  return handler;
};