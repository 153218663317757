import { UseMutateFunction } from "@tanstack/react-query";
import { createContext, ReactNode, useContext } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import IOffer from "../../../shared/IOffer";
import IOfferRow, { EFactory } from "../../../shared/IOfferRow";
import IOfferTerm from "../../../shared/IOfferTerm";
import { RefHandle } from "../../../shared/RefHandle";
import { IOrganizationUserSelectHandle } from "../../OrganizationUserSelect/OrganizationUserSelect";
import { IOption } from "../../ui/Input/Input";
import { TCreateInput } from "../../ui/Input/useCreateInput";
import { IOfferCalculationSummary } from "../OfferCalculationTabs/OfferCalculationSummary/OfferCalculationSummary";
import { IInstallations } from "../OfferCalculationTabs/OfferCalculationTabInstallation/OfferCalculationTabInstallations";
import { IOfferCalculationKilometer } from "../OfferCalculationTabs/OfferCalculationTabKilometers/OfferCalculationTabKilometer";

interface IOfferEditContext {
  submitHandler: () => Promise<boolean>;
  isPending: boolean;
  isEdit: boolean;
  deleteMutate: UseMutateFunction<boolean, ErrorResponse, void, unknown>;
  isDeleting: boolean;
  updateOfferPricesHandler: () => Promise<void>;
  createOrderHandler?: () => void;
  copy?: boolean;
  offer?: IOffer;
  offerPrintModeHandler?: () => void;
  offerInstalmentTableModeHandler?: () => void;
  id?: string;
  errorMessages: string[];
  // onAutoUpdate: (inputName: string, value: TInputValue, action: string) => Promise<boolean>;
  createInput: TCreateInput;
  latest5OfferNumbers?: string;
  isLatest5Pending: boolean;
  sellerUserOptions?: IOption[];
  sellerUserOptionsLoading: boolean;
  showOrderFields: boolean;
  customerRef: React.RefObject<IOrganizationUserSelectHandle>;
  customerId?: string | null;
  engineerRef: React.RefObject<IOrganizationUserSelectHandle>;
  offerKilometers: IOfferCalculationKilometer[];
  setOfferKilometers: React.Dispatch<React.SetStateAction<IOfferCalculationKilometer[]>>;
  kilometersRef: React.RefObject<RefHandle<IOfferCalculationKilometer[]>>;
  openGoogleMapsHandler: (factory: EFactory) => void;
  summary: IOfferCalculationSummary;
  setSummary: React.Dispatch<React.SetStateAction<IOfferCalculationSummary>>;
  summaryRef: React.RefObject<RefHandle<IOfferCalculationSummary>>;
  installations: IInstallations;
  setInstallations: React.Dispatch<React.SetStateAction<IInstallations>>;
  installationsRef: React.RefObject<RefHandle<IInstallations>>;
  offerRows: IOfferRow[];
  setOfferRows: React.Dispatch<React.SetStateAction<IOfferRow[]>>;
  offerRowsRef: React.RefObject<RefHandle<IOfferRow[]>>;
  currentOfferRow: IOfferRow | null;
  setCurrentOfferRow: React.Dispatch<React.SetStateAction<IOfferRow | null>>;
  updateCurrentRow: (newRow: Partial<IOfferRow>) => Promise<void>;
  updateConditionalTerms: () => Promise<void>;
  currentDeliveryPriceForTon: string;
  offerTerms: IOfferTerm[];
  setOfferTerms: React.Dispatch<React.SetStateAction<IOfferTerm[]>>;
  planningNotes: string;
  setPlanningNotes: React.Dispatch<React.SetStateAction<string>>;
  overwriteDescription: boolean;
  setOverwriteDescription: React.Dispatch<React.SetStateAction<boolean>>;
}

const OfferEditContext = createContext<IOfferEditContext>({
  submitHandler: () => Promise.reject(),
  isPending: false,
  isEdit: false,
  deleteMutate: () => {},
  isDeleting: false,
  updateOfferPricesHandler: () => Promise.reject(),
  createOrderHandler: () => {},
  copy: false,
  offer: {} as IOffer,
  offerPrintModeHandler: () => {},
  offerInstalmentTableModeHandler: () => {},
  id: "add",
  errorMessages: [],
  // onAutoUpdate: () => Promise.reject(),
  createInput: () => <></>,
  latest5OfferNumbers: "",
  isLatest5Pending: false,
  sellerUserOptions: [],
  sellerUserOptionsLoading: false,
  showOrderFields: false,
  customerRef: { current: null },
  customerId: null,
  engineerRef: { current: null },
  offerKilometers: [],
  setOfferKilometers: () => {},
  kilometersRef: { current: null },
  openGoogleMapsHandler: () => {},
  summary: {} as IOfferCalculationSummary,
  setSummary: () => {},
  summaryRef: { current: null },
  installations: {} as IInstallations,
  setInstallations: () => {},
  installationsRef: { current: null },
  offerRows: [],
  setOfferRows: () => {},
  offerRowsRef: { current: null },
  currentOfferRow: null,
  setCurrentOfferRow: () => {},
  updateCurrentRow: () => Promise.reject(),
  updateConditionalTerms: () => Promise.reject(),
  currentDeliveryPriceForTon: "",
  offerTerms: [],
  setOfferTerms: () => {},
  planningNotes: "",
  setPlanningNotes: () => {},
  overwriteDescription: true,
  setOverwriteDescription: () => {},
});

export const useOfferEditContext = () => useContext(OfferEditContext);

const OfferEditContextProvider: React.FC<{
  value: IOfferEditContext;
  children: ReactNode;
}> = ({ value, children }) => (
  <OfferEditContext.Provider value={value}>
    {children}
  </OfferEditContext.Provider>
);

export default OfferEditContextProvider;
