import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import Alert from '../Alert/Alert';

import Backdrop from '../Backdrop/Backdrop';
import Spinner from '../Spinner/Spinner';
import classes from './Modal.module.scss';
import { EModalSize, IModal, useModalContext } from './modal-context';

interface IProps {
  modal: IModal;
}

const Modal: React.FC<IProps> = ({ modal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { closeModal } = useModalContext();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const containerClassNames = [classes.Container];

  if (isOpen) {
    containerClassNames.push(classes.ContainerOpen);
  }

  switch (modal.size) {
    case EModalSize.FULL:
      containerClassNames.push(classes.ContainerFull);
      break;
    case EModalSize.LARGE:
      containerClassNames.push(classes.ContainerLarge);
      break;
    case EModalSize.MEDIUM:
      containerClassNames.push(classes.ContainerMedium);
      break;
    case EModalSize.SMALL:
      containerClassNames.push(classes.ContainerSmall);
      break;
    case EModalSize.X_SMALL:
      containerClassNames.push(classes.ContainerXSmall);
      break;
    default:
  }

  useEffect(() => {
    if(modal.isOpen) {
      if(timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setTimeout(() => setIsOpen(true), 1);
    } else {
      setIsOpen(false);
      timeoutRef.current = setTimeout(() => closeModal(modal.id, false), 300);
    }

  }, [modal.isOpen, closeModal, modal.id])

  const closeModalHandler = () => {
    if (modal.disabled) return;
    closeModal(modal.id);
    if (modal.onModalClose) modal.onModalClose();
  };

  const contentClassNames = [classes.Content];
  if(modal.noScroll) {
    contentClassNames.push(classes.NoScroll);
  }

  return (
    <React.Fragment>
      <Backdrop onClick={modal.backdropNotClose ? () => {} : closeModalHandler} isOpen={isOpen} />
      <div className={containerClassNames.join(" ")} onClick={modal.backdropNotClose ? () => {} : closeModalHandler}>
        <div
          className={contentClassNames.join(' ')}
          style={{ overflow: modal.overflow }}
          onClick={(event) => event.stopPropagation()}
        >
          <header className={classes.Header}>
            <h2 className={classes.Title}>{modal.title}</h2>
            {!modal.disabled && (
              <span className={classes.CloseModal} onClick={closeModalHandler}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            )}
          </header>
          {modal.error && <Alert>{modal.error}</Alert>}
          {modal.loading ? <Spinner /> : modal.content}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
