import { IOfferRowCalculationTotal } from "../../../../shared/IOfferRow";
import Table from "../../../ui/Table/Table";
import { useOfferRowCalculationContext } from "../offer-row-calculation-context";

interface IProps {
  total: IOfferRowCalculationTotal;
}

const SteelPartsTotalTable: React.FC<IProps> = ({ total }) => {
  const { isHollowcore } = useOfferRowCalculationContext();

  return isHollowcore ? null : (
    <Table removeGap>
      <thead>
        <tr>
          <th></th>
          <th>€ /m²</th>
          <th>€ /m³</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Kaikki</td>
          <td>{total.steelPartSquareMeterPrice} € /m²</td>
          <td>{total.steelPartCubicMeterPrice} € /m³</td>
        </tr>
        <tr>
          <td>Kate2</td>
          <td>{total.steelPartSquareMeterPriceK2} € /m²</td>
          <td>{total.steelPartCubicMeterPriceK2} € /m³</td>
          {/* <td>{"TODO"} € /m³</td> */}
        </tr>
      </tbody>
    </Table>
  );
};

export default SteelPartsTotalTable;
