import { CSSProperties, forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { useSteelOptions } from "../../../../hooks/useSteelOptions";
import { EInputType, IInputField, TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../../ui/Input/input-context";
import { useInputs } from "../../../ui/Input/useInputs";
import Table from "../../../ui/Table/Table";
import { useOfferRowCalculationContext } from "../offer-row-calculation-context";
import SteelTendonTotalTable from "./SteelTendonTotalTable";
import IOfferRow from "../../../../shared/IOfferRow";
import { useOfferService } from "../../../../services/offers-service";
import { formatInputValue } from "../../../ui/Input/input-utils";
import { RefHandle } from "../../../../shared/RefHandle";
import { useOfferProductsContext } from "../../OfferProducts/offer-products-context";

export interface IOfferRowCalculationSteelTendon {
  steelId: string;
  steelWeight: string;
  steelPrice: string;
  tendonCount: string;
  tendonWaste: string;
  productionLineLength: string;
  elementsInProductionLine: string;
  price?: string;
  weight?: string;
  totalTendonSteelLength?: string;
//   squareMeterWeight?: string;
//   cubicMeterWeight?: string;
//   cubicMeterPrice?: string;
  weightPerElement?: string;
  pricePerElement?: string;
}

enum EInputs {
  steelId = "steelId",
  steelWeight = "steelWeight",
  steelPrice = "steelPrice",
  tendonCount = "tendonCount",
  tendonWaste = "tendonWaste",
  productionLineLength = "productionLineLength",
  elementsInProductionLine = "elementsInProductionLine",
}

const containerStyles: CSSProperties = { width: "125px" };

const OfferRowCalculationSteelTendon: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationSteelTendon>> = (_, ref) => {
  const { offerRow, updateOfferRow } = useOfferRowCalculationContext();
  const { offerRowCalculationSteelTendon: calculation, offerRowCalculationTotal: total } = offerRow;
  const { loading, options } = useSteelOptions();

  const { showControls } = useOfferProductsContext();
  const { createInput, submit } = useTendonInputs(calculation, showControls);
  const { updateHandler } = useHandlers(updateOfferRow);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const newData = await submit();
      if (!calculation) return null;
      return { ...calculation, ...newData };
    },
  }), [calculation, submit]);

  return (
    <>
      <SteelTendonTotalTable calculation={calculation} total={total} />
      <Table removeGap>
        <thead>
          <tr>
            <th>Punos</th>
            <th>Kg/m</th>
            <th>€/kg</th>
            <th>kpl</th>
            <th>Hukka m</th>
            <th>Linjapituus</th>
            <th>Kpl/Linja</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <InputContextProvider initDone onAutoUpdate={updateHandler}>
              <td>{createInput(EInputs.steelId, { options, loading, containerStyles:{width: "80px"}} ) }</td>
              <td>{createInput(EInputs.steelWeight, {containerStyles: containerStyles})}</td>
              <td>{createInput(EInputs.steelPrice, {containerStyles: containerStyles})}</td>
              <td>{createInput(EInputs.tendonCount, {containerStyles: containerStyles})}</td>
              <td>{createInput(EInputs.tendonWaste, {containerStyles: containerStyles})}</td>
              <td>{createInput(EInputs.productionLineLength, {containerStyles: containerStyles})}</td>
              <td>{createInput(EInputs.elementsInProductionLine, {containerStyles: containerStyles})}</td>
            </InputContextProvider>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

const useTendonInputs = (data?: IOfferRowCalculationSteelTendon, showControls?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.steelId]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.steelWeight]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.steelPrice]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.tendonCount]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.tendonWaste]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.productionLineLength]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.elementsInProductionLine]: {
      type: EInputType.number,
      value: "",
      disallowDecimals: true,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.STEEL_TENDON, disabled: !showControls });

  return { createInput, submit };
};

const useHandlers = (updateOfferRow: (newRow: Partial<IOfferRow>) => void) => {
  const { calculateOfferSteelTendon } = useOfferService();
  const { steels } = useSteelOptions();

  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.STEEL_TENDON) {
        const data = _ as IOfferRowCalculationSteelTendon;
        if (inputName === EInputs.steelId) {
          const selectedSteel = steels?.find((s) => s.id === value);
          data.steelWeight = selectedSteel?.weight ?? "0";
          data.steelPrice = selectedSteel?.price ?? "0";
        }
        const offerRowCalculationSteelTendon = await calculateOfferSteelTendon({
          ...data,
          [inputName]: formatInputValue(value),
        });
        updateOfferRow({ offerRowCalculationSteelTendon });
      }
      return Promise.resolve(true);
    },
    [calculateOfferSteelTendon, steels, updateOfferRow]
  );

  return { updateHandler };
};

export default forwardRef(OfferRowCalculationSteelTendon);
