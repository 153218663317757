import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import OrganizationEdit from "../components/Organizations/OrganizationEdit";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Container from "../components/ui/Container/Container";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { useOrganizationService } from "../services/organizations-service";
import { QueryKey } from "../services/query-keys";
import IOrganization from "../shared/IOrganization";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import UsersList from "../components/Users/UsersList";
import { Route } from "../routes";

type Params = {
  id: string;
};

const EngineerPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getOrganization } = useOrganizationService();
  const navigate = useNavigate();

  const {
    data: organization,
    isLoading,
    isError,
    error,
  } = useQuery<IOrganization, ErrorResponse>({
    queryKey: [QueryKey.engineers, id],
    queryFn: ({ signal }) => getOrganization({ signal, id: id! }),
    enabled: id !== "add",
  });

  const addHandler = () => {
    navigate(Route.user(`add?organizationId=${id}`));
  };

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Suunnittelija</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <OrganizationEdit id={id!} organization={organization} type="ENGINEER" />
        )}
      </Container>
      {organization?.id && (
        <>
          <PageHeading onAdd={addHandler}>Yhteyshenkilöt</PageHeading>
          <Container>
            <UsersList organizationId={organization.id} />
          </Container>
        </>
      )}
    </>
  );
};

export default EngineerPage;
