import { ReactNode, createContext, useContext } from "react";
import IOfferRow from "../../../shared/IOfferRow";
import { IOfferCalculationSummary } from "../OfferCalculationTabs/OfferCalculationSummary/OfferCalculationSummary";
import { IInstallations, createOfferInstallations } from "../OfferCalculationTabs/OfferCalculationTabInstallation/OfferCalculationTabInstallations";
import { IOfferCalculationKilometer } from "../OfferCalculationTabs/OfferCalculationTabKilometers/OfferCalculationTabKilometer";

interface IOfferProductsContext {
  offerRows: IOfferRow[];
  setOfferRows: React.Dispatch<React.SetStateAction<IOfferRow[]>>;
  currentOfferRow: IOfferRow | null;
  setCurrentOfferRow: React.Dispatch<React.SetStateAction<IOfferRow | null>>;
  updateCurrentRow: (newRow: Partial<IOfferRow>) => Promise<void>;
  updateConditionalTerms: () => Promise<void>;
  currentDeliveryPriceForTon: string;
  summary: IOfferCalculationSummary | null;
  setSummary: React.Dispatch<React.SetStateAction<IOfferCalculationSummary>>;
  installations: IInstallations;
  setInstallations: React.Dispatch<React.SetStateAction<IInstallations>>;
  offerKilometers: IOfferCalculationKilometer[];
  overwriteDescription: boolean;
  setOverwriteDescription: React.Dispatch<React.SetStateAction<boolean>>;
  showControls: boolean;
}

const OfferProductsContext = createContext<IOfferProductsContext>({
  offerRows: [],
  setOfferRows: () => {},
  currentOfferRow: null,
  setCurrentOfferRow: () => {},
  updateCurrentRow: () => Promise.reject(),
  updateConditionalTerms: () => Promise.reject(),
  currentDeliveryPriceForTon: "",
  summary: null,
  setSummary: () => {},
  installations: createOfferInstallations(),
  setInstallations: () => {},
  offerKilometers: [],
  overwriteDescription: true,
  setOverwriteDescription: () => {},
  showControls: true,
});

export const useOfferProductsContext = () => useContext(OfferProductsContext);

const OfferProductsContextProvider: React.FC<{
  value: IOfferProductsContext;
  children: ReactNode;
}> = ({ value, children }) => (
  <OfferProductsContext.Provider value={value}>
    {children}
  </OfferProductsContext.Provider>
);

export default OfferProductsContextProvider;
