import React, { CSSProperties } from "react";

import classes from "./Logo.module.scss";

import imgLogo from '../../assets/logo.png';

interface IProps {
  style?: CSSProperties;
  size?: "small" | "medium" | "large";
}

const Logo: React.FC<IProps> = ({ style, size = "small" }) => {
  const classNames = [classes.Logo];

  switch (size) {
    case "small":
      classNames.push(classes.Small)
      break;
    case "medium":
      classNames.push(classes.Medium)
      break;
    case "large":
      classNames.push(classes.Large)
      break;
  }

  return (
    <div className={classNames.join(' ')}>
      <img src={imgLogo} alt="Pielisen Betoni" style={style} />
    </div>
  );
};

export default Logo;
