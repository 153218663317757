import { useMemo, useRef, useState } from "react";
import IElement from "../../shared/IElement";
import IProductType from "../../shared/IProductType";
import { RefHandle } from "../../shared/RefHandle";
import Button, { EButtonColor } from "../ui/Button/Button";
import { IOption } from "../ui/Input/Input";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";
import Select from "../ui/Select/Select";
import Table from "../ui/Table/Table";
import ElementCreateEdit, { ElementsCreateDTO } from "./ElementCreateEdit";
import RadioButton from "../ui/RadioButton/RadioButton";

interface IProps {
  onAccept: (data: ElementsCreateDTO) => Promise<void>;
  onCancel: () => void;
  elements?: IElement[];
  phaseOptions?: IOption[];
  productTypesOptions?: IOption[];
  productTypesLoading: boolean;
  productTypes?: IProductType[];
}

export enum ECreateType {
  count = "count",
  copy = "copy",
}

const createTypeOptions = [
  { value: ECreateType.count, label: "Juoksuta"},
  { value: ECreateType.copy, label: "Kpl"},
];

const ElementsCreateModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
  elements = [],
  phaseOptions = [],
  productTypesOptions = [],
  productTypesLoading,
  productTypes = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedElement, setSelectedElement] = useState<IElement | undefined>(undefined);
  const [createType, setCreateType] = useState<ECreateType>(ECreateType.count);

  const elementRef = useRef<RefHandle<ElementsCreateDTO>>(null);

  const submitHandler = async () => {
    setLoading(true);
    const data = await elementRef.current?.getData();
    if (data) {
      const selectedProductType = productTypes?.find(productType => productType.id === data.productType);
      data.productType = selectedProductType ?? undefined;
      const selectedPhase = phaseOptions.find(phase => phase.value === data.phaseId);
      data.phaseId = selectedPhase?.value ?? undefined;
      data.phaseName = selectedPhase?.label ?? undefined;
      data.createType = createType;
      await onAccept(data);
    }
    setLoading(false);
  };

  const elementOptions = useMemo(
    () =>
      [...elements].map((element) => ({
        value: element.id,
        label: element.name,
      })),
    [elements]
  );

  return (
    <>
      <ModalBody
        style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <div style={{ width: "fit-content" }}>
            <label>Kopioi elementistä:</label>
            <Select
              name="selectElementId"
              value={selectedElement?.id as string}
              onChange={(value) => setSelectedElement(elements.find(element => element.id === value as string) ?? undefined)}
              options={elementOptions}
              isClearable
              hideControls
              placeholder=""
              autoFocus
            />
          </div>
          <div>
            <label>Tyyppi</label>
            <RadioButton 
              onChange={(value) => setCreateType(value as ECreateType)}
              options={createTypeOptions}
              value={createType}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <div>
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "125px" }}>Tunnus {createType === ECreateType.count && "alku"}</th>
                  <th style={{ width: "125px" }}>{createType === ECreateType.count ? "Tunnus loppu" : "Lukumäärä"}</th>
                  <th style={{ width: "80px" }}>Kerros / lohko</th>
                  <th style={{ width: "125px" }}>Tyyppi</th>
                  <th style={{ width: "80px" }}>P</th>
                  <th style={{ width: "80px" }}>O</th>
                  <th style={{ width: "125px" }}>Pituus</th>
                  <th style={{ width: "125px" }}>Halkaisija</th>
                  <th style={{ width: "125px" }}>Korkeus</th>
                  <th style={{ width: "150px" }}>Karkea kuorma Vko</th>
                  <th>→</th>
                  <th style={{ width: "100px" }}>Toim</th>
                  <th style={{ width: "80px" }}>Tehdas</th>
                </tr>
              </thead>
              <tbody>
                <ElementCreateEdit
                  element={selectedElement}
                  phaseOptions={phaseOptions}
                  productTypesOptions={productTypesOptions}
                  productTypesLoading={productTypesLoading}
                  ref={elementRef}
                />
              </tbody>
            </Table>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={submitHandler}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Tallenna
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

export default ElementsCreateModal;
