import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useUserService } from "../services/users-service";
import IUser, { UserRole } from "../shared/IUser";

interface IProps {
  role?: UserRole;
  organizationId?: string;
}

export const useUserOptions = ({ role, organizationId }: IProps) => {
  const { fetchUsers } = useUserService();

  const {
    data: users,
    isPending,
    isError,
  } = useQuery<IUser[], ErrorResponse>({
    queryKey: [QueryKey.users, organizationId || role],
    queryFn: ({ signal }) => fetchUsers({ signal, roles: role ? [role] : [], organizationId }),
    staleTime: 5000,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : users?.map((o) => ({ value: o.id, label: o.name } as IOption)),
    [users, isError]
  );

  return { options, loading: isPending, users };
};
