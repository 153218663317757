import { useNavigate } from 'react-router-dom';
import OrganizationsList from '../components/Organizations/OrganizationsList';
import Container from '../components/ui/Container/Container';
import PageHeading from '../components/ui/PageHeading/PageHeading';
import { Route } from '../routes';
import OrganizationsSearch from '../components/Organizations/OrganizationsSearch';


const CustomersPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.customer("add"));
  }

  return (
    <>
    <PageHeading onAdd={addHandler}>Asiakkaat</PageHeading>
    <Container>
      <OrganizationsSearch type="CUSTOMER" />
    </Container>
    <Container>
      <OrganizationsList type="CUSTOMER" />
    </Container>
    </>
  )
}

export default CustomersPage;