import { useQuery } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { QueryKey } from "../services/query-keys";
import IUser from "../shared/IUser";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../custom-fetch";
import Spinner from "../components/ui/Spinner/Spinner";
import Container from "../components/ui/Container/Container";
import UserEdit from "../components/Users/UserEdit";
import { useUserService } from "../services/users-service";
import { useMemo } from "react";

type Params = {
  id: string;
};

const UserPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getUser } = useUserService();
  const [searchParams] = useSearchParams();

  const organizationId = useMemo(
    () => searchParams.get("organizationId"),
    [searchParams]
  );
  
  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useQuery<IUser, ErrorResponse>({
    queryKey: [QueryKey.users, id],
    queryFn: ({ signal }) => getUser({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Käyttäjä</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <UserEdit id={id!} user={user} organizationId={organizationId ?? undefined} />
        )}
      </Container>
    </>
  );
};

export default UserPage;
