import IOfferRow from "../../../../shared/IOfferRow";
import Table from "../../../ui/Table/Table";

interface IProps {
  offerRow: IOfferRow;
}

const ConcreteTotalTable: React.FC<IProps> = ({ offerRow }) => {
  return (
    <Table style={{maxWidth: "800px" }}>
      <tbody>
        <tr>
          {/* <td>Kulj Per Tn</td>
          <td>{currentDeliveryPriceForTon} €</td> */}
          <td>Yhteensä</td>
          <td>{offerRow.offerRowCalculationConcrete.weightTons} ton</td>
          <td>Rahtaus</td>
          <td>{offerRow.offerRowCalculationTotal.concreteUnitDelivery} € /kpl</td>
          <td>Hinta</td>
          <td>{offerRow.offerRowCalculationTotal.concreteSquareMeterPrice} € /m²</td>
          <td>{offerRow.offerRowCalculationTotal.concreteCubicMeterPrice} € /m³</td>
        </tr>
        <tr>
          <td>Yhteensä</td>
          <td>{offerRow.count} kpl</td>
          {/* <td>Rahtaus/m²</td>
          <td>{"TODO"} €</td> */}
          <td>Massa</td>
          <td>{offerRow.offerRowCalculationConcrete.price} € /kpl</td>
          <td>Yht / m³</td>
          <td colSpan={2}>{offerRow.cubicMeters} m³</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ConcreteTotalTable;
