import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useId, useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import IElement from "../../shared/IElement";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import Table from "../ui/Table/Table";

interface IProps {
  elements: IElement[];
  showTotalRow?: boolean;
  setElements: React.Dispatch<React.SetStateAction<IElement[]>>;
  onDelete?: (element: IElement) => void;
  lineLength: string;
  onClick: (element: IElement) => void;
}

export const calculateTotalLength = (elements: IElement[]) => {
  let lengthTotal = 0;
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    lengthTotal += +(element.length ?? 0);
  }
  return lengthTotal;
};

const ElementsDragAndDropTable: React.FC<IProps> = ({ elements, showTotalRow, setElements, onDelete, lineLength, onClick }) => {
  const { dragEndHandler, dragUpdateHandler, dragStartHandler, items, getItemStyle, getListStyle } = useDragAndDrop(elements, setElements);

  const lengthTotal = useMemo(
    () => (showTotalRow ? calculateTotalLength(elements) : 0),
    [showTotalRow, elements]
  );

  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: "80px" }}>#</th>
          <th style={{ width: "125px" }}>Tunnus</th>
          <th style={{ width: "125px" }}>Pituus</th>
          <th style={{ width: "125px" }}>Vaijerityyppi</th>
          <th style={{ width: "125px" }}>Varaukset</th>
          <th style={{ width: "125px" }}>Paino</th>
          <th style={{ width: "100px" }}>Vko</th>
          <th style={{ width: "100px" }}>Toim</th>
          <th style={{ width: "125px" }}></th>
        </tr>
      </thead>
      <DragDropContext onDragUpdate={dragUpdateHandler} onDragEnd={dragEndHandler} onDragStart={dragStartHandler}>
        <Droppable droppableId={useId()}>
          {(provided, snapshot) => (
            <tbody
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((element, index) => (
                <Draggable
                  key={element.id}
                  draggableId={element.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      onClick={(event) => {
                        event.stopPropagation();
                        onClick(element);
                      }}
                    >
                      <td style={{ width: "80px" }}>{element.position || index + 1}</td>
                      <td style={{ width: "125px" }}>{element.name}</td>
                      <td style={{ width: "125px" }}>{element.length}</td>
                      <td style={{ width: "125px" }}>{element.typeName}</td>
                      <td style={{ width: "125px" }}>
                        {element.provisionAmountShort}
                        {element.provisionAmountShort && !element.isPlanned && !element.hasAttachments && <span title="Varausten liitteet puuttuu" style={{ alignSelf: "center", fontWeight: "bold" }}>!</span>}
                      </td>
                      <td style={{ width: "125px" }}>{element.weightTons}</td>
                      <td style={{ width: "100px" }}>{element.productionWeek}</td>
                      <td style={{ width: "100px" }}>{element.deliveryWeek}</td>
                      <td style={{ width: "125px" }}><Button onClick={(event) => { event.stopPropagation(); onDelete && onDelete(element) }} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" disabled={!onDelete} /></td>
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
      <tfoot>
        {showTotalRow && (
          <tr>
            <td>Yhteensä</td>
            <td>Jäljellä {+(lineLength ?? 0)-lengthTotal}</td>
            <td style={lengthTotal > +(lineLength ?? 0) ? { color: "red" } : {}}>{lengthTotal} / {lineLength}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        )}
      </tfoot>
    </Table>
  );
};

export default ElementsDragAndDropTable;
