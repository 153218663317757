import { useCallback } from "react";
import { useCustomFetch } from "../custom-fetch";
import IInsulation from "../shared/IInsulation";
import ISteelPart from "../shared/ISteelPart";
import IConcreteType from "../shared/IConcreteType";
import IMesh from "../shared/IMesh";
import IOfferRow from "../shared/IOfferRow";
import ITransportationCost from "../shared/ITransportationCost";

type ImportType = ISteelPart | IInsulation | IConcreteType | IMesh | IOfferRow | ITransportationCost;

type ImportKind = "SteelPart" | "Insulation" | "ConcreteType" | "Mesh" | "OfferRow" | "TransportationCost";

export interface IImport {
  newItems: ImportType[];
  updatedItems: ImportType[];
  upToDateItems: ImportType[];
  failedCSVRows: string[];
}

export const useImportService = () => {
  const customFetch = useCustomFetch();

  const importFile = useCallback(
    async (kind: ImportKind, data: FormData) => {
      const [importDTO] = await customFetch<IImport>("/api/import/" + kind, {
        method: "POST",
        body: data,
        multipart: true,
      });
      return importDTO;
    },
    [customFetch]
  );

  return { importFile };
};
