import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../custom-fetch";
import Spinner from "../components/ui/Spinner/Spinner";
import Container from "../components/ui/Container/Container";
import { useSteelService } from "../services/steels-service";
import ISteel from "../shared/ISteel";
import SteelEdit from "../components/Steels/SteelEdit";

type Params = {
  id: string;
};

const SteelPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getSteel } = useSteelService();
  
  const {
    data: steel,
    isLoading,
    isError,
    error,
  } = useQuery<ISteel, ErrorResponse>({
    queryKey: [QueryKey.steels, id],
    queryFn: ({ signal }) => getSteel({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Teräs</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <SteelEdit id={id!} steel={steel} />
        )}
      </Container>
    </>
  );
};

export default SteelPage;
