import IProductGroup from "./IProductGroup";
import IProductType from "./IProductType";

interface IOfferTerm {
	id?: string;
	name?: string;
	text: string;
	type: EOfferTermType;
	factory?: string;
	productGroup?: IProductGroup;
	productType?: IProductType;
	defaultValue?: boolean;
	archived?: boolean;
	updatedString?: string;
	factoryString?: string;
	typeString?: string;

	//OfferTabOfferTerms select value
	offerTermId?: string;
	//OfferTabOfferTerms embedded entity id
	idString?: string;
}

export enum EOfferTermType {
  DELIVERY_TERM = "DELIVERY_TERM",
  CONDITIONAL = "CONDITIONAL",
  PAYMENT_TERM = "PAYMENT_TERM",
  INVOICING = "INVOICING",
  ENGINEER_TERM = "ENGINEER_TERM",
  INSTALLATIONS_TERM = "INSTALLATIONS_TERM",
  FREIGHT_TERM = "FREIGHT_TERM",
  PLANNING_DELIVERY = "PLANNING_DELIVERY",
  COMMON = "COMMON",
  FREE_TEXT = "FREE_TEXT",
}

export default IOfferTerm;