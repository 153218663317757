import { useNavigate } from 'react-router-dom';
import OrganizationsList from '../components/Organizations/OrganizationsList';
import Container from '../components/ui/Container/Container';
import PageHeading from '../components/ui/PageHeading/PageHeading';
import { Route } from '../routes';
import OrganizationsSearch from '../components/Organizations/OrganizationsSearch';


const EngineersPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.engineer("add"));
  }

  return (
    <>
    <PageHeading onAdd={addHandler}>Suunnittelijat</PageHeading>
    <Container>
      <OrganizationsSearch type="ENGINEER" />
    </Container>
    <Container>
      <OrganizationsList type="ENGINEER" />
    </Container>
    </>
  )
}

export default EngineersPage;