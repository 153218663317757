import { useAsyncError, useNavigate, useRouteError } from "react-router-dom";
import Container from "../components/ui/Container/Container";
import Button from "../components/ui/Button/Button";
import { useEffect } from "react";
import { useCustomFetch } from "../custom-fetch";
import { Route } from "../routes";

const ErrorPage: React.FC = () => {
  const error = useRouteError() as Error;
  const asyncError = useAsyncError() as Error;

  console.log("error", error);
  console.log("error.cause", error?.cause);
  console.log("asyncError", asyncError);
  console.log("asyncError.cause", asyncError?.cause);

  const customFetch = useCustomFetch();

  useEffect(() => {
    if (error) {
      customFetch<boolean>("/api/error/log", {
        method: "PUT",
        body: JSON.stringify({
          name: error.name,
          message: error.message,
          stack: error.stack,
        }),
      });
    }
    if (asyncError) {
      customFetch<boolean>("/api/error/log", {
        method: "PUT",
        body: JSON.stringify({
          name: asyncError.name,
          message: asyncError.message,
          stack: asyncError.stack,
        }),
      });
    }
  }, [customFetch, error, asyncError]);

  const navigate = useNavigate();

  return (
    <Container>
      <p>
        Selaimessasi on tapahtunut odottamaton virhe. Emme näe näitä palvelimen
        lokitiedoissa. Vianetsintää auttaa, jos lähetät viestinä kuvauksen mistä
        virhe ilmeni, ylläolevan teknisen virheviestin kanssa ja lähetät
        tukeemme: support@posp.fi
      </p>
      {error && <ErrorMessage error={error} />}
      {asyncError && <ErrorMessage error={asyncError} />}
      <Button onClick={() => navigate(Route.home)} style={{ marginTop: "1rem" }}>
        Palaa etusivulle
      </Button>
    </Container>
  );
};

const ErrorMessage: React.FC<{ error: Error; cause?: boolean }> = ({
  error,
  cause,
}) => (
  <>
    {cause ? <h2>Caused by: {error.name}</h2> : <h1>{error.name}</h1>}
    <div>
      <p style={{ fontWeight: "bold" }}>{error.message}</p>
      <p>{error.stack}</p>
    </div>
    {error.cause && <ErrorMessage error={error.cause as Error} cause />}
  </>
);

export default ErrorPage;
