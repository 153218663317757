import React from "react";
import { IImport } from "../../../../services/import-service";
import Button, { EButtonColor } from "../../../ui/Button/Button";
import Container from "../../../ui/Container/Container";
import ErrorsAlert from "../../../ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../../../../custom-fetch";

interface IProps {
  importResult: IImport | null;
  error?: ErrorResponse | null;
  onConfirm: () => void;
  onCancel: () => void;
}

const OfferProductsImportResultModal: React.FC<IProps> = ({
  importResult,
  error,
  onConfirm,
  onCancel,
}) => {
  return (
    <Container>
      {error && (
          <ErrorsAlert errors={error.messages} />
      )}
      {importResult && importResult.newItems.length > 0 && (
        <h2>Onnistuneet: {importResult.newItems.length}</h2>
      )}
      {importResult && importResult.failedCSVRows.length > 0 && (
        <>
          <h2>Epäonnistuneet</h2>
          {importResult.failedCSVRows.map((failedRow, index) => (
            <p key={index}>{failedRow}</p>
          ))}
        </>
      )}
      <Button onClick={onConfirm} style={{ marginRight: "0.5rem" }}>
        Vahvista
      </Button>
      <Button onClick={onCancel} color={EButtonColor.DANGER}>
        Peruuta
      </Button>
    </Container>
  );
};

export default OfferProductsImportResultModal;