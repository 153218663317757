import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IOrganization from "../shared/IOrganization";
import { IOrganizationsSearch } from '../components/Organizations/OrganizationsSearch';

interface IFetchOrganizations extends ISignal {
  search?: IOrganizationsSearch | null;
}

interface IGetOrganization extends ISignal {
  id: string;
}

export const useOrganizationService = () => {
  const customFetch = useCustomFetch();

  const fetchOrganizations = useCallback(async ({ signal, search }: IFetchOrganizations) => {
    const [organizations] = await customFetch<IOrganization[]>("/api/organizations/list", {
      signal,
      method: "POST",
      body: JSON.stringify(search),
    });
    return organizations;
  }, [customFetch]);
  
  const getOrganization = useCallback(async ({ signal, id }: IGetOrganization) => {
    const [organization] = await customFetch<IOrganization>("/api/organizations/get/" + id, { signal });
    return organization;
  }, [customFetch]);
  
  const saveOrganization = useCallback(async (data: IOrganization) => {
    const [organization] = await customFetch<IOrganization>("/api/organizations/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return organization;
  }, [customFetch]);
  
  const updateOrganization = useCallback(async (id: string, data: IOrganization) => {
    const [organization] = await customFetch<IOrganization>("/api/organizations/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return organization;
  }, [customFetch]);
  
  const deleteOrganization = useCallback(async (id: string) => {
    await customFetch<IOrganization>("/api/organizations/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchOrganizations, getOrganization, saveOrganization, updateOrganization, deleteOrganization };
}




