import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Navigate, Route as RouterRoute, Routes, useLocation, useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";
import { useNavigationBlockerContext } from "../../../context/navigation-blocker-context";
import { ErrorResponse } from "../../../custom-fetch";
import { useOfferTermOptions } from "../../../hooks/useOfferTermOptions";
import { useUserOptions } from "../../../hooks/useUserOptions";
import { Route } from "../../../routes";
import { useInstalmentTableService } from "../../../services/instalmentTable-service";
import { useOfferTermService } from "../../../services/offerTerms-service";
import { useOfferService } from "../../../services/offers-service";
import { QueryKey } from "../../../services/query-keys";
import IInstalmentTable from "../../../shared/IInstalmentTable";
import IOffer, { STATUS_OPTIONS } from "../../../shared/IOffer";
import IOfferRow, {
  EFactory,
  getFactoryAddress,
  IOfferRowCalculationTotal,
} from "../../../shared/IOfferRow";
import IOfferTerm, { EOfferTermType } from "../../../shared/IOfferTerm";
import { HOLLOWCORE_PRODUCT_GROUP_NAMES } from "../../../shared/IProductGroup";
import { MUNCIPALITY_OPTIONS } from "../../../shared/MunicipalityOptions";
import { RefHandle } from "../../../shared/RefHandle";
import { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import Order from "../../Order/Order";
import { IOrganizationUserSelectHandle } from "../../OrganizationUserSelect/OrganizationUserSelect";
import Print from "../../Print/Print";
import {
  ECommonValue,
  EInputType,
  IInputField
} from "../../ui/Input/Input";
import { EInputUpdateAction } from "../../ui/Input/input-context";
import { getInputData } from "../../ui/Input/input-utils";
import { useInputs } from "../../ui/Input/useInputs";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../../ui/Modal/modal-context";
import { useDialog } from "../../ui/Modal/useDialog";
import {
  createSummary,
  IOfferCalculationSummary,
} from "../OfferCalculationTabs/OfferCalculationSummary/OfferCalculationSummary";
import {
  createOfferInstallations,
  IInstallations,
} from "../OfferCalculationTabs/OfferCalculationTabInstallation/OfferCalculationTabInstallations";
import { IOfferCalculationKilometer } from "../OfferCalculationTabs/OfferCalculationTabKilometers/OfferCalculationTabKilometer";
import { createKilometerRows } from "../OfferCalculationTabs/OfferCalculationTabKilometers/OfferCalculationTabKilometers";
import OfferEdit from "../OfferEdit/OfferEdit";
import OfferEditContextProvider from "../OfferEdit/offer-edit-context";
import OfferInstalmentTable from "../OfferInstalmentTable/OfferInstalmentTable";
import { createOfferRow, DEFAULT_FACTORY_LINE_LENGTH } from "../OfferProducts/OfferProducts";
import { createOfferTerm } from "../OfferTextTabs/OfferTextTabOfferTerms/OfferTextTabOfferTerms";
import useUpdateOfferPricesModal from "../UpdateOfferPricesModal/useUpdateOfferPricesModal";

enum ETab {
  // OFFER_EDIT,
  edit = "edit",
  copy = "copy",
  // CREATE_ORDER,
  order = "order",
  // OFFER_PRINT,
  printOffer = "print/offer",
  // ORDER_PRINT,
  printOrder = "print/order",
  // OFFER_INSTALMENT_TABLE,
  instalment = "instalment",
  // INSTALMENT_TABLE_PRINT
  printInstalment = "print/instalment",
}

// const createTabs = (isEdit: boolean): ITab[] => {
//   return [
//     { path: ETab.edit, title: "Muokkaa", visible: true },
//     { path: ETab.order, title: "Tilaus", visible: isEdit },
//     { path: ETab.printOffer, title: "Tulosta tarjous", visible: isEdit },
//     { path: ETab.printOrder, title: "Tulosta tilaus", visible: isEdit },
//     { path: ETab.instalment, title: "Maksuerätaulukko", visible: isEdit },
//     { path: ETab.printInstalment, title: "Tulosta maksuerätaulukko", visible: isEdit },
//   ];
// };

interface IProps {
  id: string;
  offer?: IOffer;
  copy?: boolean;
  customerId?: string | null;
}

export enum EInputs {
  offerNumber = "offerNumber",
  seller = "seller",
  offerDate = "offerDate",
  expireInDays = "expireInDays",
  status = "status",
  sellerNotes = "sellerNotes",
  targetName = "targetName",
  targetName2 = "targetName2",
  deliveryStreetAddress = "deliveryStreetAddress",
  deliveryZip = "deliveryZip",
  deliveryCity = "deliveryCity",
  customerReferenceNumber = "customerReferenceNumber",
  estimatedDeliveryWeeks = "estimatedDeliveryWeeks",
  deliveryNotes = "deliveryNotes",
  deliveryLocation = "deliveryLocation",
  terms = "terms",
  archived = "archived",
}

interface IGoogleMapsProps {
  deliveryStreetAddress: string;
  deliveryZip: string;
  deliveryCity: string;
}

const DEFAULT_OFFER_DATE = format(new Date(), "yyyy-MM-dd");

const Offer: React.FC<IProps> = ({
  id,
  offer,
  // copy,
  customerId,
}) => {
  // const [offerView, setOfferView] = useState(EOfferView.OFFER_EDIT);
  const navigate = useNavigate();
  const location = useLocation();
  const isCopy = useMemo(() => location.pathname.includes("/copy"), [location.pathname]);
  const isEdit = id !== "add" && !isCopy;

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id);
  const { createInput, submit, inputs } = useOfferInputs(offer);
  const { latest5OfferNumbers, isLatest5Pending, isLatest5Error, latest5Error } = useLatest5OfferNumbers();

  const { options: sellerUserOptions, loading: sellerUserOptionsLoading, users: sellers } = useUserOptions({ role: "SELLER" });

  const customerRef = useRef<IOrganizationUserSelectHandle>(null);
  const engineerRef = useRef<IOrganizationUserSelectHandle>(null);
  const orderRef = useRef<RefHandle<Partial<IOffer>>>(null);

  const [planningNotes, setPlanningNotes] = useState<string>(offer?.planningNotes ?? "");

  const kilometersRef = useRef<RefHandle<IOfferCalculationKilometer[]>>(null);
  const [offerKilometers, setOfferKilometers] = useState<IOfferCalculationKilometer[]>(offer?.kilometerCalculations ?? createKilometerRows());
  
  const installationsRef = useRef<RefHandle<IInstallations>>(null);
  const [installations, setInstallations] = useState<IInstallations>(offer?.installations ?? createOfferInstallations());
  
  const summaryRef = useRef<RefHandle<IOfferCalculationSummary>>(null);
  const [summary, setSummary] = useState<IOfferCalculationSummary>(offer?.summary ?? createSummary());

  const offerRowsRef = useRef<RefHandle<IOfferRow[]>>(null);
  const [offerRows, setOfferRows] = useState<IOfferRow[]>(offer?.rows ?? [createOfferRow(summary.grossProfitDivisor, summary.grossProfit2Divisor)]);
  const [currentOfferRow, setCurrentOfferRow] = useState<IOfferRow | null>(null);

  const [overwriteDescription, setOverwriteDescription] = useState(true);

  const { offerTerms: fetchedOfferTerms } = useOfferTermOptions();
  const [offerTerms, setOfferTerms] = useState<IOfferTerm[]>(offer?.terms ?? []);
  useEffect(() => {
    if (fetchedOfferTerms && offerTerms.length === 0) {
      setOfferTerms([
          createOfferTerm(EOfferTermType.PAYMENT_TERM, "Maksuehto", fetchedOfferTerms),
          createOfferTerm(EOfferTermType.DELIVERY_TERM, "Toimitusehto", fetchedOfferTerms),
          createOfferTerm(EOfferTermType.INVOICING, "Maksuerät", fetchedOfferTerms),
        ]
      )
    }
  }, [fetchedOfferTerms, offerTerms.length]);

  const { calculateOfferTotal, calculateOfferRow } = useOfferService();
  const { getConditionalTermsForOfferRows } = useOfferTermService();
  const { addModal } = useModalContext();

  const updateCurrentRow = useCallback(
    async (newRow: Partial<IOfferRow>) => {
      if (!currentOfferRow) return;
      let offerRowCalculationTotal: IOfferRowCalculationTotal | null = null;
      const index = offerRows.findIndex((r) => r.id === currentOfferRow.id);
      if (index > -1) {
        // PB-168 L Ontelot / L OntLisät && (newRow.hollowcore || newRow.steelpart) -> update row
        if (HOLLOWCORE_PRODUCT_GROUP_NAMES.includes(offerRows[index].productGroupName) && (newRow.offerRowCalculationHollowcore || newRow.offerRowCalculationSteelPart)) {
          console.log("hollowcore / steelpart changed -> update whole row: update", newRow);
          const newRows = [...offerRows];
          newRows[index] = await calculateOfferRow({
            ...newRows[index],
            ...newRow,
          });
          setOfferRows(newRows);
          setCurrentOfferRow(newRows[index]);
          return;
        }

        let elementsInProductionLine = "";
        let productionLineLength = offerRows[index].offerRowCalculationTotal?.productionLineLength ?? DEFAULT_FACTORY_LINE_LENGTH;
        if (newRow.offerRowCalculationSteelTendon) {
          elementsInProductionLine = newRow.offerRowCalculationSteelTendon.elementsInProductionLine;
          productionLineLength = newRow.offerRowCalculationSteelTendon.productionLineLength;
          // console.log("tendon", elementsInProductionLine, productionLineLength);
        }
        if (newRow.offerRowCalculationWork) {
          elementsInProductionLine = newRow.offerRowCalculationWork.elementsInProductionLine;
          productionLineLength = newRow.offerRowCalculationWork?.productionLineLength ?? "0";
          // console.log("work", elementsInProductionLine, productionLineLength);
        }

        offerRowCalculationTotal = await calculateOfferTotal({
          grossProfitDivisor: offerRows[index].grossProfitDivisor,
          grossProfit2Divisor: offerRows[index].grossProfit2Divisor,
          elementsInProductionLine,
          productionLineLength,
          offerRowCalculationConcrete: {
            ...offerRows[index].offerRowCalculationConcrete,
            ...newRow.offerRowCalculationConcrete,
          },
          offerRowCalculationHollowcore: {
            ...offerRows[index].offerRowCalculationHollowcore,
            ...newRow.offerRowCalculationHollowcore,
          },
          offerRowCalculationInsulation: {
            ...offerRows[index].offerRowCalculationInsulation,
            ...newRow.offerRowCalculationInsulation,
          },
          offerRowCalculationMesh: {
            ...offerRows[index].offerRowCalculationMesh,
            ...newRow.offerRowCalculationMesh,
          },
          offerRowCalculationSteel: {
            ...offerRows[index].offerRowCalculationSteel,
            ...newRow.offerRowCalculationSteel,
          },
          offerRowCalculationSteelTendon: {
            ...offerRows[index].offerRowCalculationSteelTendon,
            ...newRow.offerRowCalculationSteelTendon,
            elementsInProductionLine,
            productionLineLength,
          },
          offerRowCalculationSteelPart: {
            ...offerRows[index].offerRowCalculationSteelPart,
            ...newRow.offerRowCalculationSteelPart,
          },
          offerRowCalculationWork: {
            ...offerRows[index].offerRowCalculationWork,
            ...newRow.offerRowCalculationWork,
            elementsInProductionLine,
            productionLineLength,
          },
        });
      }
      setOfferRows((rows) => {
        const index = rows.findIndex((r) => r.id === currentOfferRow.id);
        if (index > -1) {
          const newTotal = offerRowCalculationTotal ?? rows[index].offerRowCalculationTotal;
          rows[index] = {
            ...rows[index],
            ...newRow,
            offerRowCalculationTotal: newTotal,
            offerRowCalculationSteelTendon: { 
              ...rows[index].offerRowCalculationSteelTendon,
              ...newRow.offerRowCalculationSteelTendon,
              elementsInProductionLine: newTotal?.elementsInProductionLine!,
              productionLineLength: newTotal?.productionLineLength!,
              pricePerElement: newTotal?.pricePerElement,
              weightPerElement: newTotal?.weightPerElement,
            },
            offerRowCalculationWork: { 
              ...rows[index].offerRowCalculationWork,
              ...newRow.offerRowCalculationWork,
              elementsInProductionLine: newTotal?.elementsInProductionLine!,
              productionLineLength: newTotal?.productionLineLength!,
              workEstimateForElement: newTotal?.workEstimateForElement!,
              addedDifficultyHoursElement: newTotal?.addedDifficultyHoursElement!,
            },
          };
        }
        setCurrentOfferRow(rows[index]);
        return rows;
      });
    },
    [currentOfferRow, offerRows, calculateOfferTotal, calculateOfferRow]
  );

  const { showBlocker, showNavigationBlockerModal, setIsDirty } = useNavigationBlockerContext();

  const getOfferData = useCallback(async (): Promise<IOffer | null> => {
    const data = await submit();
    const orderData = await orderRef.current?.getData();
    
    const updateOffer: Partial<IOffer> = {};

    const customerData = await customerRef.current?.getData();
    if (customerData) {
      updateOffer.customer = customerData?.organization || undefined;
      updateOffer.customerContactPerson = customerData?.user || undefined;
    }
    const engineerData = await engineerRef.current?.getData();
    if (engineerData) {
      updateOffer.engineer = engineerData?.organization || undefined;
      updateOffer.engineerContactPerson = engineerData?.user || undefined;
    }
    const summaryData = await summaryRef.current?.getData();
    if (summaryData) {
      updateOffer.summary = { ...summaryData };
    }
    const kilometersData = await kilometersRef.current?.getData();
    if (kilometersData) {
      updateOffer.kilometerCalculations = [...kilometersData];
    }
    const installationsData = await installationsRef.current?.getData();
    if (installationsData) {
      updateOffer.installations = {...installationsData};
    }
    const offerRowData = await offerRowsRef.current?.getData();
    if (offerRowData) {
      updateOffer.rows = [...offerRowData];
    }

    console.log("updateOffer", updateOffer);
    if (data) {
      return {
        ...offer,
        ...data,
        seller:
          (sellers?.find((s) => s.id === (data.seller.id ?? data.seller)) ??
            data.seller) ||
          undefined,
        status: data.status || undefined,
        terms: offerTerms,
        planningNotes: planningNotes,
        rows: offerRows,
        installations: {
          ...installations,
          installationRows: installations.installationRows ?? [],
        },
        summary,
        kilometerCalculations: offerKilometers,
        ...orderData,
        ...updateOffer,
      };
    }
    return null;
  }, [offer, submit, sellers, offerTerms, planningNotes, offerRows, installations, offerKilometers, summary]);

  const submitHandler = useCallback(async () => {
    const data = await getOfferData();
    if (data) {
      try {
        await saveOrUpdate(data);
        setIsDirty(false);
        return true;
      } catch (e) {
        console.error(e);
      }
    }
    return false;
  }, [getOfferData, saveOrUpdate, setIsDirty]);

  const updateConditionalTerms = async () => {
    const nonConditionalTerms: IOfferTerm[] = [];
    const existingConditionalTerms: IOfferTerm[] = [];
    offerTerms.forEach((offerTerm) => {
      if (offerTerm.type === EOfferTermType.CONDITIONAL) {
        existingConditionalTerms.push(offerTerm);
      } else {
        nonConditionalTerms.push(offerTerm);
      }
    });
    const conditionalTerms = await getConditionalTermsForOfferRows({offerRows: offerRows, existingConditionalTerms: existingConditionalTerms});
    setOfferTerms([...nonConditionalTerms, ...conditionalTerms]);
  };

  const openGoogleMapsHandler = useCallback(
    (factory: EFactory) => {
      const data = getInputData<IGoogleMapsProps>(inputs);
      const destinationAddress = `${data?.deliveryStreetAddress ?? ""} ${
        data?.deliveryZip ?? ""
      } ${data?.deliveryCity ?? ""}`.trim();
      if (!destinationAddress.length) {
        addModal({
          id: EModalId.DEFAULT,
          isOpen: true,
          size: EModalSize.SMALL,
          title: "Hae kilometrit",
          content: <p>Asiakkaan osoite puuttuu.</p>,
        });
        return;
      }
      const url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=${encodeURIComponent(
        getFactoryAddress(factory)
      )}&destination=${encodeURIComponent(destinationAddress)}`;
      window.open(url, "_blank");
    },
    [inputs, addModal]
  );

  const openUpdateOfferPricesModal = useUpdateOfferPricesModal(id);
  const openDialog = useDialog();

  const updateOfferPricesHandler = useCallback(async () => {
    const data = await getOfferData();
    if (data) {
      const newOffer = await openUpdateOfferPricesModal(data);
      if (newOffer) {
        setInstallations({...newOffer.installations});
        setOfferKilometers([...newOffer.kilometerCalculations]);
        setSummary({...newOffer.summary});
        setOfferRows([...newOffer.rows]);
        setCurrentOfferRow(
          (currentRow) =>
            newOffer.rows.find((row) => row.id === currentRow?.id) ?? null
        );
        openDialog(
          "Päivitä hinnat ja kate",
          <p>
            <FontAwesomeIcon icon={faCheckCircle} color="green" /> Hintojen
            päivitys onnistui.
          </p>
        );
      }
    }
  }, [getOfferData, openUpdateOfferPricesModal, openDialog]);

  const errorMessages = combineErrors(
    { isError, error },
    { isError: isDeletingError, error: deletingError },
    { isError: isLatest5Error, error: latest5Error }
  );

  const showOrderFields = useMemo(() => !!offer?.projectNumber, [offer?.projectNumber]);

  const currentDeliveryPriceForTon = useMemo(() =>
      offerKilometers?.length > 0 && currentOfferRow?.factory
        ? offerKilometers.find((k) => k.factory === currentOfferRow.factory)
            ?.offerFreightPrice ?? ""
        : "",
    [currentOfferRow?.factory, offerKilometers]
  );

  useEffect(() => {
    if (showBlocker) {
      showNavigationBlockerModal(submitHandler);
    }
  }, [showBlocker, showNavigationBlockerModal, submitHandler]);

  const setOfferViewHandler = useCallback(async (view: ETab) => {
    if (isEdit) {
        await submitHandler();
    }
    // setOfferView(view);
    // console.log(view);
    navigate(`${Route.offer(id)}/${view}`)
  }, [isEdit, navigate, id, submitHandler]);

  // const [instalmentTable, setInstalmentTable] = useState<IInstalmentTable>();
  const { instalmentTable, /*TODO: unused?? isInstalmentTablePending,*/ isInstalmentTableError, instalmentTableError } = useGetInstalmentTable(id);

  const setInstalmentTablePrintModeHandler = () => {
    // setOfferView(EOfferView.INSTALMENT_TABLE_PRINT);
    navigate(`${Route.offer(id)}/print/instalment`)
    // setInstalmentTable(instalmentTable);
  }

  const deleteHandler = useCallback(async () => {
    try {
      await deleteMutate();
      setIsDirty(false);
    } catch (e) {
      console.error(e);
    }
  }, [deleteMutate, setIsDirty]);

  return (
    <form onBlur={() => setIsDirty(true)}>
      {/* <Tabs tabs={createTabs(isEdit)} /> */}
      <Routes>
        <RouterRoute index element={<Navigate to={`/offers/${id}/edit`} replace />} />
        <RouterRoute path={ETab.edit} element={
          <OfferEditContextProvider
          value={{ submitHandler, isPending, isEdit, deleteMutate: deleteHandler, isDeleting, updateOfferPricesHandler,
            createOrderHandler: isEdit && offer ? () => setOfferViewHandler(ETab.order) : undefined,
            copy: false, offer,
            offerPrintModeHandler: isEdit && offer ? () => setOfferViewHandler(ETab.printOffer) : undefined,
            offerInstalmentTableModeHandler: isEdit && offer ? () => setOfferViewHandler(ETab.instalment) : undefined,
            id, errorMessages, createInput, latest5OfferNumbers, isLatest5Pending, sellerUserOptions, sellerUserOptionsLoading, showOrderFields, customerRef, customerId, engineerRef, offerKilometers, setOfferKilometers, kilometersRef, openGoogleMapsHandler, summary, setSummary, summaryRef, installations, setInstallations, installationsRef, offerRows, setOfferRows, offerRowsRef, currentOfferRow, setCurrentOfferRow, updateCurrentRow, updateConditionalTerms, currentDeliveryPriceForTon, offerTerms, setOfferTerms, planningNotes, setPlanningNotes,
            overwriteDescription, setOverwriteDescription,
          }}>
            <OfferEdit />
          </OfferEditContextProvider>
        } />
        <RouterRoute path={ETab.copy} element={
          <OfferEditContextProvider
          value={{ submitHandler, isPending, isEdit, deleteMutate: deleteHandler, isDeleting, updateOfferPricesHandler,
            createOrderHandler: isEdit && offer ? () => setOfferViewHandler(ETab.order) : undefined,
            copy: true, offer,
            offerPrintModeHandler: isEdit && offer ? () => setOfferViewHandler(ETab.printOffer) : undefined,
            offerInstalmentTableModeHandler: isEdit && offer ? () => setOfferViewHandler(ETab.instalment) : undefined,
            id, errorMessages, createInput, latest5OfferNumbers, isLatest5Pending, sellerUserOptions, sellerUserOptionsLoading, showOrderFields, customerRef, customerId, engineerRef, offerKilometers, setOfferKilometers, kilometersRef, openGoogleMapsHandler, summary, setSummary, summaryRef, installations, setInstallations, installationsRef, offerRows, setOfferRows, offerRowsRef, currentOfferRow, setCurrentOfferRow, updateCurrentRow, updateConditionalTerms, currentDeliveryPriceForTon, offerTerms, setOfferTerms, planningNotes, setPlanningNotes,
            overwriteDescription, setOverwriteDescription,
          }}>
            <OfferEdit />
          </OfferEditContextProvider>
        } />
        <RouterRoute path={ETab.order} element={
          <Order
            offer={offer!}
            engineerRef={engineerRef}
            onReturn={() => setOfferViewHandler(ETab.edit)}
            orderPrintHandler={() => setOfferViewHandler(ETab.printOrder)}
            ref={orderRef}
            setIsDirty={setIsDirty}
          />
        } />
        <RouterRoute path={ETab.instalment} element={
          <OfferInstalmentTable
            offer={offer!}
            onReturn={() => setOfferViewHandler(ETab.edit)}
            instalmentTablePrintModeHandler={setInstalmentTablePrintModeHandler}
            instalmentTable={instalmentTable}
            isInstalmentTableError={isInstalmentTableError}
            instalmentTableError={instalmentTableError}
          />
        } />
        <RouterRoute path={ETab.printOffer} element={<Print offer={offer!} onReturn={() => setOfferViewHandler(ETab.edit)} />} />
        <RouterRoute path={ETab.printOrder} element={<Print offer={offer!} onReturn={() => setOfferViewHandler(ETab.order)} order={true} />} />
        <RouterRoute path={ETab.printInstalment} element={<Print offer={offer!} instalmentTable={instalmentTable} onReturn={() => setOfferViewHandler(ETab.instalment)} order={true} />} />
      </Routes>
      {/* {offerView === EOfferView.OFFER_PRINT ? (
        <Print offer={offer!} onReturn={() => setOfferViewHandler(EOfferView.OFFER_EDIT)} />
      ) : offerView === EOfferView.ORDER_PRINT ? (
        <Print offer={offer!} onReturn={() => setOfferViewHandler(EOfferView.CREATE_ORDER)} order={true} />
      ) : offerView === EOfferView.INSTALMENT_TABLE_PRINT ? (
        <Print offer={offer!} instalmentTable={instalmentTable} onReturn={() => setOfferView(EOfferView.OFFER_INSTALMENT_TABLE)} order={true} />
      ) : offerView === EOfferView.OFFER_EDIT ? (
        <OfferEditContextProvider
          value={{ submitHandler, isPending, isEdit, deleteMutate: deleteHandler, isDeleting, updateOfferPricesHandler,
            createOrderHandler: isEdit && offer ? () => setOfferViewHandler(EOfferView.CREATE_ORDER) : undefined,
            copy, offer,
            offerPrintModeHandler: isEdit && offer ? () => setOfferViewHandler(EOfferView.OFFER_PRINT) : undefined,
            offerInstalmentTableModeHandler: isEdit && offer ? () => setOfferViewHandler(EOfferView.OFFER_INSTALMENT_TABLE) : undefined,
            id, errorMessages, createInput, latest5OfferNumbers, isLatest5Pending, sellerUserOptions, sellerUserOptionsLoading, showOrderFields, customerRef, customerId, engineerRef, offerKilometers, setOfferKilometers, kilometersRef, openGoogleMapsHandler, summary, setSummary, summaryRef, installations, setInstallations, installationsRef, offerRows, setOfferRows, offerRowsRef, currentOfferRow, setCurrentOfferRow, updateCurrentRow, updateConditionalTerms, currentDeliveryPriceForTon, offerTerms, setOfferTerms, planningNotes, setPlanningNotes,
            overwriteDescription, setOverwriteDescription,
          }}
        >
          <OfferEdit />
        </OfferEditContextProvider>
      ) : offerView === EOfferView.CREATE_ORDER ? (
        <Order
          offer={offer!}
          engineerRef={engineerRef}
          onReturn={() => setOfferViewHandler(EOfferView.OFFER_EDIT)}
          orderPrintHandler={() => setOfferViewHandler(EOfferView.ORDER_PRINT)}
          ref={orderRef}
          setIsDirty={setIsDirty}
        />
      ) : offerView === EOfferView.OFFER_INSTALMENT_TABLE ? (
        <OfferInstalmentTable
          offer={offer!}
          onReturn={() => setOfferViewHandler(EOfferView.OFFER_EDIT)}
          instalmentTablePrintModeHandler={setInstalmentTablePrintModeHandler}
        />
      ) : null} */}
    </form>
  );
};

const useOfferInputs = (data?: IOffer) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.offerNumber]: {
      type: EInputType.text,
      placeholder: "Tarjouksen numero",
      value: "",
      maxLength: 7,
      validation: {
        required: true,
      },
    },
    [EInputs.seller]: {
      type: EInputType.reactSelect,
      placeholder: "Myyjä",
      options: [],
      value: "",
    },
    [EInputs.offerDate]: {
      type: EInputType.date,
      placeholder: "Pvm",
      value: DEFAULT_OFFER_DATE,
    },
    [EInputs.expireInDays]: {
      type: EInputType.number,
      placeholder: "Voimassa",
      value: "14",
    },
    [EInputs.status]: {
      type: EInputType.reactSelect,
      placeholder: "Tila",
      options: STATUS_OPTIONS,
      value: "",
    },
    [EInputs.sellerNotes]: {
      type: EInputType.textarea,
      placeholder: "Sisäinen myynnin Huom",
      rows: 4,
      value: "",
    },
    [EInputs.targetName]: {
      type: EInputType.textarea,
      placeholder: "Kohteen nimi",
      value: "",
      rows: 2,
      maxLength: 30,
    },
    [EInputs.targetName2]: {
      type: EInputType.text,
      placeholder: "Kohteen nimi",
      value: "",
      maxLength: 30,
    },
    [EInputs.deliveryStreetAddress]: {
      type: EInputType.text,
      placeholder: "Katuosoite",
      value: "",
      maxLength: 30,
    },
    [EInputs.deliveryZip]: {
      type: EInputType.text,
      placeholder: "Postinumero",
      value: "",
      maxLength: 7,
    },
    [EInputs.deliveryCity]: {
      type: EInputType.text,
      placeholder: "Kaupunki",
      value: "",
      maxLength: 15,
    },
    [EInputs.customerReferenceNumber]: {
      type: EInputType.text,
      placeholder: "Asiakkaan viite",
      value: "",
      maxLength: 50,
    },
    [EInputs.estimatedDeliveryWeeks]: {
      type: EInputType.number,
      placeholder: "ArvTVko",
      value: "",
    },
    [EInputs.deliveryNotes]: {
      type: EInputType.text,
      placeholder: "Toimitusaika",
      value: "",
    },
    [EInputs.deliveryLocation]: {
      type: EInputType.reactSelect,
      placeholder: "Toimituskunta",
      options: MUNCIPALITY_OPTIONS,
      value: "",
    },
    [EInputs.terms]: { type: EInputType.text, label: "terms", value: "" },
    [EInputs.archived]: {
      type: EInputType.checkbox,
      label: "Arkistoitu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  // const { updateOffer } = useOfferActions();
  // const onAutoUpdate = useCallback(
  //   (inputName: string, value: TInputValue, action: string) => {
  //     // console.log("autoUpdate", inputName, value, action);
  //     if (action === EInputUpdateAction.OFFER) {
  //       const offer: Partial<IOffer> = {
  //         id: data?.id,
  //         [inputName]: formatInputValue(value),
  //       };
  //       dispatch(updateOffer(offer, inputName));
  //       // console.log("update done");
  //     }
  //     return Promise.resolve(true);
  //   },
  //   [data?.id, updateOffer]
  // );

  const { createInput, submit } = useInputs({
    data,
    inputs,
    setInputs,
    updateAction: EInputUpdateAction.OFFER,
  });

  return { createInput, submit, inputs };
};

const useSaveOrUpdate = (id: string, isEdit: boolean) => {
  const { updateOffer, saveOffer } = useOfferService();
  const navigate = useNavigate();

  const mutationFn = (data: IOffer) => {
    return isEdit ? updateOffer(id, data) : saveOffer(data);
  };

  const {
    mutateAsync: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IOffer, ErrorResponse, IOffer>({
    mutationFn,
    onSuccess: (data) => {
      // queryClient.removeQueries({ queryKey: [QueryKey.offers, id] });
      queryClient.setQueryData([QueryKey.offers, data.id], { ...data });
      // queryClient.invalidateQueries({ queryKey: [QueryKey.offers] });
      if (isEdit) {
        //navigate(Route.offer(id));
      } else {
        navigate(Route.offer(data.id));
      }
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string) => {
  const { deleteOffer } = useOfferService();
  const navigate = useNavigate();

  const {
    mutateAsync: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteOffer(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.offers],
        refetchType: "none",
      });
      navigate(Route.home);
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

const useLatest5OfferNumbers = () => {
  const { getLatest5OfferNumbers } = useOfferService();

  const {
    data: latest5OfferNumbers,
    isPending: isLatest5Pending,
    isError: isLatest5Error,
    error: latest5Error,
  } = useQuery<string, ErrorResponse>({
    queryFn: getLatest5OfferNumbers,
    queryKey: [QueryKey.latest5OfferNumbers],
  });

  return {
    latest5OfferNumbers,
    isLatest5Pending,
    isLatest5Error,
    latest5Error,
  };
};

const useGetInstalmentTable = (id?: string) => {
  const { getInstalmentTableByOfferId } = useInstalmentTableService();

  const {
    data: instalmentTable,
    isPending: isInstalmentTablePending,
    isError: isInstalmentTableError,
    error: instalmentTableError,
  } = useQuery<IInstalmentTable, ErrorResponse>({
    retry: false,
    queryKey: [QueryKey.instalmentTables, id],
    queryFn: ({ signal }) => getInstalmentTableByOfferId({ signal, id: id! }),
    enabled: !!id && id !== "add",
  });

  return { instalmentTable, isInstalmentTablePending, isInstalmentTableError, instalmentTableError };
}

export default Offer;
