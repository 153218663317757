import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IConcreteType from '../shared/IConcreteType';

interface IGetConcreteType extends ISignal {
  id: string;
}

export const useConcreteTypeService = () => {
  const customFetch = useCustomFetch();

  const fetchConcreteTypes = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [concreteTypes] = await customFetch<IConcreteType[]>(`/api/concrete-types/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return concreteTypes;
  }, [customFetch]);
  
  const getConcreteType = useCallback(async ({ signal, id }: IGetConcreteType) => {
    const [concreteType] = await customFetch<IConcreteType>("/api/concrete-types/get/" + id, { signal });
    return concreteType;
  }, [customFetch]);
  
  const saveConcreteType = useCallback(async (data: IConcreteType) => {
    const [concreteType] = await customFetch<IConcreteType>("/api/concrete-types/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return concreteType;
  }, [customFetch]);
  
  const updateConcreteType = useCallback(async (id: string, data: IConcreteType) => {
    const [concreteType] = await customFetch<IConcreteType>("/api/concrete-types/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return concreteType;
  }, [customFetch]);
  
  const deleteConcreteType = useCallback(async (id: string) => {
    await customFetch<IConcreteType>("/api/concrete-types/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const archivedUpdateConcreteType = useCallback(async (id: string) => {
    const [concreteType] = await customFetch<IConcreteType>("/api/concrete-types/update-archived/" + id, {
      method: "PUT",
    });
    return concreteType;
  }, [customFetch]);
  
  const archivedDeleteConcreteTypes = useCallback(async () => {
    await customFetch<IConcreteType>("/api/concrete-types/delete-archived", {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchConcreteTypes, getConcreteType, saveConcreteType, updateConcreteType, deleteConcreteType, archivedUpdateConcreteType, archivedDeleteConcreteTypes};
}