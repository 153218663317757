import Table from "../../../ui/Table/Table";
import { useOfferRowCalculationContext } from "../offer-row-calculation-context";

const OfferRowCalculationTotal: React.FC = () => {
  const { isWall } = useOfferRowCalculationContext();
  return isWall ? <WallTable /> : <DefaultTable />;
};

const NET_PRICE_TEXT = "Kaikki hinnat ovat nettohintoja";

const WallTable: React.FC = () => {
  const { offerRow } = useOfferRowCalculationContext();
  const { offerRowCalculationTotal: total } = offerRow;
  return (
    <Table removeGap>
      <thead>
        <tr>
          <th></th>
          <th>€ / m²</th>
          <th>€ / m³</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Betoni</td>
          <td>{total.concreteSquareMeterPrice}</td>
          <td>{total.concreteCubicMeterPrice}</td>
        </tr>
        <tr>
          <td>Teräsosat</td>
          <td>{total.steelPartSquareMeterPrice}</td>
          <td>{total.steelPartCubicMeterPrice}</td>
        </tr>
        {/* <tr>
          <td>Teräsosat K2</td>
          <td>{total.steelPartSquareMeterPriceK2}</td>
          <td>{total.steelPartCubicMeterPriceK2}</td>
        </tr> */}
        <tr>
          <td>Teräkset</td>
          <td>{total.steelSquareMeterPrice}</td>
          <td>{total.steelCubicMeterPrice}</td>
        </tr>
        <tr>
          <td>Punokset</td>
          <td>{total.steelTendonSquareMeterPrice}</td>
          <td>{total.steelTendonCubicMeterPrice}</td>
        </tr>
        <tr>
          <td>Verkot</td>
          <td>{total.meshSquareMeterPrice}</td>
          <td>{total.meshCubicMeterPrice}</td>
        </tr>
        <tr>
          <td>Eristeet</td>
          <td>{total.insulationSquareMeterPrice}</td>
          <td>{total.insulationCubicMeterPrice}</td>
        </tr>
        <tr>
          <td>Työ</td>
          <td>{total.workSquareMeterPrice}</td>
          <td>{total.workCubicMeterPrice}</td>
        </tr>
        <tr>
          <td>Kuljetus</td>
          <td>{total.deliverySquareMeterPrice}</td>
          <td>{total.deliveryCubicMeterPrice}</td>
        </tr>
        <tr style={{ fontWeight: "bold", borderTop: "2px solid black" }}>
          <td>Yhteensä</td>
          <td>{total.totalSquareMeterPrice}</td>
          <td>{total.totalCubicMeterPrice}</td>
        </tr>
        <tr>
          <td colSpan={3} style={{ fontStyle: "italic" }}>{NET_PRICE_TEXT}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const DefaultTable: React.FC = () => {
  const { offerRow } = useOfferRowCalculationContext();
  const { offerRowCalculationTotal: total } = offerRow;
  return (
    <Table removeGap>
      <thead>
        <tr>
          <th></th>
          <th>€ / m²</th>
          <th>€ / m³</th>
          <th>€ / jm</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Betoni</td>
          <td>{total.concreteSquareMeterPrice}</td>
          <td>{total.concreteCubicMeterPrice}</td>
          <td>{total.concreteLinealMeterPrice}</td>
        </tr>
        <tr>
          <td>Teräsosat</td>
          <td>{total.steelPartSquareMeterPrice}</td>
          <td>{total.steelPartCubicMeterPrice}</td>
          <td>{total.steelPartLinealMeterPrice}</td>
        </tr>
        {/* <tr>
          <td>Teräsosat K2</td>
          <td>{total.steelPartSquareMeterPriceK2}</td>
          <td>{total.steelPartCubicMeterPriceK2}</td>
          <td>{total.steelPartLinealMeterPrice}</td>
        </tr> */}
        <tr>
          <td>Teräkset</td>
          <td>{total.steelSquareMeterPrice}</td>
          <td>{total.steelCubicMeterPrice}</td>
          <td>{total.steelLinealMeterPrice}</td>
        </tr>
        <tr>
          <td>Punokset</td>
          <td>{total.steelTendonSquareMeterPrice}</td>
          <td>{total.steelTendonCubicMeterPrice}</td>
          <td>{total.steelTendonLinealMeterPrice}</td>
        </tr>
        <tr>
          <td>Verkot</td>
          <td>{total.meshSquareMeterPrice}</td>
          <td>{total.meshCubicMeterPrice}</td>
          <td>{total.meshLinealMeterPrice}</td>
        </tr>
        <tr>
          <td>Eristeet</td>
          <td>{total.insulationSquareMeterPrice}</td>
          <td>{total.insulationCubicMeterPrice}</td>
          <td>{total.insulationLinealMeterPrice}</td>
        </tr>
        <tr>
          <td>Työ</td>
          <td>{total.workSquareMeterPrice}</td>
          <td>{total.workCubicMeterPrice}</td>
          <td>{total.workLinealMeterPrice}</td>
        </tr>
        <tr>
          <td>Kuljetus</td>
          <td>{total.deliverySquareMeterPrice}</td>
          <td>{total.deliveryCubicMeterPrice}</td>
          <td>{total.deliveryLinealMeterPrice}</td>
        </tr>
        <tr style={{ fontWeight: "bold", borderTop: "2px solid black" }}>
          <td>Yhteensä</td>
          <td>{total.totalSquareMeterPrice}</td>
          <td>{total.totalCubicMeterPrice}</td>
          <td>{total.totalLinealMeterPrice}</td>
        </tr>
        <tr>
          <td colSpan={3} style={{ fontStyle: "italic" }}>{NET_PRICE_TEXT}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default OfferRowCalculationTotal;
