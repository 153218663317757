import QRCode from 'react-qr-code';
import { adminUiUrl } from '../../config';
import { EProvisionType } from '../../shared/IProvision';
import IWaybillDTO from '../../shared/IWaybillDTO';
import { formatDate } from '../../utils/date-utils';
import Table from '../ui/Table/Table';
import classes from './WaybillPrint.module.scss';
import WaybillPrintHeader from './WaybillPrintHeader';
import Button, { EButtonSize } from '../ui/Button/Button';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useConfirmModal } from '../ui/Modal/useConfirmModal';
import IWaybill from '../../shared/IWaybill';
import { usePublicQRCodesService } from '../../services/publicQRCodes-service';
import { useCallback, useState } from 'react';
import { queryClient } from '../../App';
import { QueryKey } from '../../services/query-keys';
import { EInputType, IInputField } from '../ui/Input/Input';
import { useInputs } from '../ui/Input/useInputs';

interface IProps {
  waybill: IWaybillDTO;
  fromQR?: boolean
}
  
enum EInputs {
  arriveTime = "arriveTime",
  departTime = "departTime",
  loadEndTime = "loadEndTime",
  unloadStartTime = "unloadStartTime",
}

const WaybillPrint: React.FC<IProps> = ({ waybill, fromQR }) => {
    const { elementDtos: elements } = waybill;
    const { updateTimeWaybill } = usePublicQRCodesService();
    const openConfirmModal = useConfirmModal();
    const { createInput, inputs } = useWaybillInputs(waybill);

    const arriveTime = inputs[EInputs.arriveTime].value as string;
    const departTime = inputs[EInputs.departTime].value as string;
    const loadEndTime = inputs[EInputs.loadEndTime].value as string;
    const unloadStartTime = inputs[EInputs.unloadStartTime].value as string;

    const updateTimeHandler = useCallback(
    async (text: string, paramWaybill: Partial<IWaybill>) => {
      const isConfirm = await openConfirmModal(
        "Oletko varma, että haluat päivittää "+text+"?"
      );
      if (waybill.uuid && isConfirm) {
        const updated = await updateTimeWaybill(waybill.uuid, paramWaybill);
        queryClient.setQueryData([QueryKey.publicWaybills, waybill.uuid], updated);
      }
    },
    [openConfirmModal, updateTimeWaybill, waybill.uuid]
  );

    const getLabel = (index: number) => {
        if (index % 3 === 1) {
            return (Math.floor(index / 3) + 1).toString();
        }
        const letterCode = index - Math.ceil(index / 3);
        return String.fromCharCode('A'.charCodeAt(0) + letterCode);
    };

    const getData = (slot: string) => {
        return waybill.waybillRows?.filter((row) => row.slot === slot).reverse();
    };

    let firstSixWeight = 0;
    let lastNineWeight = 0;
    let totalWeight = 0;
    let firstSixElementCount = 0;
    let lastNineElementCount = 0;
    let totalElementCount = 0;

    const firstSixItems: JSX.Element[] = [];
    const lastNineItems: JSX.Element[] = [];

    [...Array(15)].forEach((_, index) => {
        const label = getLabel(index);
        const data = getData(label);

        const totalWeightTons = data?.reduce((sum, row) => {
            const element = elements?.find(e => e.id === row.elementId);
            const weightTons = parseFloat(element?.weightTons ?? "0");
            return sum + weightTons;
        }, 0) ?? 0;

        const gridItem = (
            <div key={index} className={classes.gridItem}>
                {data && data.length > 0 ? (
                    <Table>
                        <thead>
                            <tr>
                                <th>{label}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: "0.8rem" }}>
                            {data.map((row) => {
                                const element = elements?.find((e) => e.id === row.elementId);
                                return (
                                    <tr key={row.id}>
                                        <td>{row.order}</td>
                                        <td>{element?.projectNumber}{element?.phaseName ? '/' + element?.phaseName : ""}</td>
                                        <td>{element?.name}</td>
                                        <td>{element?.weightTons} t, {element?.length}</td>
                                        <td>{element?.provisions?.some(provision => provision.type === EProvisionType.split) ? "H" : ""}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>{totalWeightTons.toFixed(2)} t</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </Table>
                ) : (
                    <div />
                )}
            </div>
        );

        if (index < 6) {
            firstSixItems.push(gridItem);
            firstSixWeight += totalWeightTons;
            firstSixElementCount += data?.length ?? 0;
        } else {
            lastNineItems.push(gridItem);
            lastNineWeight += totalWeightTons;
            lastNineElementCount += data?.length ?? 0;
        }
        totalWeight += totalWeightTons;
        totalElementCount += data?.length ?? 0;
    });

  return (
    <div className={classes.container}>
        <WaybillPrintHeader waybill={waybill} fromQR={fromQR} />
        <div className={classes.formRow}>
            <div className={classes.column}>
                {/* TODO: {project?.offer.targetName} */}
            </div>
            <div className={classes.column}>
                {/* TODO: {project?.offer.customer?.name} */}
            </div>
            <div className={classes.column}>
                {/* TODO: {project?.offer.customer?.number} */}
            </div>
            <div className={classes.column}>

            </div>
        </div>
        <div className={classes.formRow}>
            <div className={classes.column}>
                <label>Pvm / Aika:</label>
                <div className={classes.field}>{formatDate(waybill.deliveryDate)} / {waybill.deliveryTime}</div>
            </div>
            <div className={classes.column}>
                <label>Kuljetusliike:</label>
                <div className={classes.field}>{waybill.driverOrganization}</div>
            </div>
            <div className={classes.column}>
                <label>Auto / Kuski:</label>
                <div className={classes.field}>{waybill.car} / {waybill.driver}</div>
            </div>
            <div className={classes.column}>
                <label>Kilometrit / Nakki:</label>
                <div className={classes.field}>{waybill.kilometers} km {waybill.nakki ? "/ Nakki" : ""}</div>
            </div>
        </div>

        {(waybill.notes || waybill.additionalNotes) &&
        <div className={classes.formRow}>
            <div className={classes.doubleColumn}>
                <label>Huomautukset:</label>
                <div className={classes.field}>{waybill.notes}</div>
            </div>
            <div className={classes.doubleColumn}>
                <label>Lisä huomatukset:</label>
                <div className={classes.field}>{waybill.additionalNotes}</div>
            </div>
        </div>
        }

        <div className={classes.numberedGrid}>
            {firstSixItems}
        </div>

        <div className={classes.numberedGrid}>
            {lastNineItems}
        </div>        

        <div className={classes.footer}>
            <div className={classes.formContainer}>
                <div className={classes.printableForm}>
                    <div className={classes.formRow}>
                        <div className={classes.doubleColumn}>
                            <div className={classes.totalWeight}>
                                {firstSixElementCount > 0 && 
                                <span>Vetoauto: {firstSixWeight.toFixed(2)} t, {firstSixElementCount} kpl</span>
                                }
                            </div>

                            <div className={classes.totalWeight}>
                                {lastNineElementCount > 0 && 
                                <span>Perävaunu: {lastNineWeight.toFixed(2)} t, {lastNineElementCount} kpl</span>
                                }
                            </div>

                            <div className={classes.totalWeight}>
                                {firstSixElementCount > 0 && lastNineElementCount > 0 &&
                                <span>Kuorma yhteensä: {totalWeight.toFixed(2)} t, {totalElementCount} kpl</span>
                                }
                            </div>

                            <div className={classes.signing}>
                                <p>VASTAANOTETTU ja</p>
                                <p>Odotusaika</p>
                                <p>Hyväksytty:</p>
                            </div>
                        </div>
                        <div className={classes.column}>
                            <div className={classes.blankRow}>
                                <div className={classes.blankColumn}>
                                    {fromQR ?
                                        createInput(EInputs.loadEndTime)
                                    :
                                        <><label>Lastaus päättynyt:</label>
                                        <div className={classes.fieldBlank}>{waybill.loadEndTime}</div></>
                                    }
                                </div>
                                <div className={classes.Column}>
                                    {fromQR &&
                                    <Button size={EButtonSize.X_SMALL} icon={faPen} style={{marginTop: "1.5rem"}}
                                        onClick={()=>updateTimeHandler("Lastaus päättynyt", {
                                            arriveTime: waybill.arriveTime,
                                            departTime: waybill.departTime,
                                            loadEndTime: loadEndTime,
                                            unloadStartTime: waybill.unloadStartTime,
                                        })}
                                    />
                                    }
                                </div>
                            </div>
                            <div className={classes.blankRow}>
                                <div className={classes.blankColumn}>
                                    {fromQR ?
                                        createInput(EInputs.arriveTime)
                                    :
                                        <><label>Saapunut klo:</label>
                                        <div className={classes.fieldBlank}>{waybill.arriveTime}</div></>
                                    }
                                </div>
                                <div className={classes.Column}>
                                    {fromQR &&
                                    <Button size={EButtonSize.X_SMALL} icon={faPen} style={{marginTop: "1.5rem"}}
                                        onClick={()=>updateTimeHandler("Saapunut klo", {
                                            arriveTime: arriveTime,
                                            departTime: waybill.departTime,
                                            loadEndTime: waybill.loadEndTime,
                                            unloadStartTime: waybill.unloadStartTime,
                                        })}
                                    />
                                    }
                                </div>
                            </div>

                            <div className={classes.blankRow}>
                                <div className={classes.blankColumn}>
                                    {fromQR ?
                                        createInput(EInputs.unloadStartTime)
                                    :
                                        <><label>Purku alkanut:</label>
                                        <div className={classes.fieldBlank}>{waybill.unloadStartTime}</div></>
                                    }
                                </div>
                                <div className={classes.Column}>
                                    {fromQR &&
                                    <Button size={EButtonSize.X_SMALL} icon={faPen} style={{marginTop: "1.5rem"}}
                                        onClick={()=>updateTimeHandler("Purku alkanut", {
                                            arriveTime: waybill.arriveTime,
                                            departTime: waybill.departTime,
                                            loadEndTime: waybill.loadEndTime,
                                            unloadStartTime: unloadStartTime,
                                        })}
                                    />
                                    }
                                </div>
                            </div>

                            <div className={classes.blankRow}>
                                <div className={classes.blankColumn}>
                                    {fromQR ?
                                        createInput(EInputs.departTime)
                                    :
                                        <><label>Lähtenyt klo:</label>
                                        <div className={classes.fieldBlank}>{waybill.departTime}</div></>
                                    }
                                </div>
                                <div className={classes.Column}>
                                    {fromQR &&
                                    <Button size={EButtonSize.X_SMALL} icon={faPen} style={{marginTop: "1.5rem"}}
                                        onClick={()=>updateTimeHandler("Lähtenyt klo", {
                                            arriveTime: waybill.arriveTime,
                                            departTime: departTime,
                                            loadEndTime: waybill.loadEndTime,
                                            unloadStartTime: waybill.unloadStartTime,
                                        })}
                                    />
                                    }
                                </div>
                            </div>

                            <label>Odotus aika h:</label>
                            <div className={classes.fieldBlank}>{waybill.waitingTime}</div>
                        </div>
                        <div className={classes.column}>
                            <QRCode style={{height: "128px", width: "128px", marginBottom: "22px", marginLeft: "10px"}} value={`${adminUiUrl}/qr/waybill/${waybill.uuid}`}></QRCode><br/>
                            <label>Kilometrit:</label>
                            <div className={classes.fieldBlank}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

const useWaybillInputs = (data?: IWaybillDTO) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.arriveTime]: {
      type: EInputType.time,
      label: "Saapunut klo",
      value: "",
    },
    [EInputs.departTime]: {
      type: EInputType.time,
      label: "Lähtenyt klo",
      value: "",
    },
    [EInputs.loadEndTime]: {
      type: EInputType.time,
      label: "Lastaus päättynyt",
      value: "",
    },
    [EInputs.unloadStartTime]: {
      type: EInputType.time,
      label: "Purku alkanut",
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs, setInputs };
};

export default WaybillPrint;