import { useMutation } from "@tanstack/react-query";

import { useEffect, useState } from "react";
import { Accept } from "react-dropzone";
import { ErrorResponse } from "../../custom-fetch";
import { IImport, useImportService } from "../../services/import-service";
import IInsulation from "../../shared/IInsulation";
import Dropzone from "../Dropzone/Dropzone";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Container from "../ui/Container/Container";
import FormButtons from "../ui/FormButtons/FormButtons";
import InsulationsTable from "./InsulationsTable";

const InsulationsImport: React.FC = () => {
  const [file, setFile] = useState<File>();
  const [insulationsImport, setInsulationsImport] = useState<IImport>();

  const accept: Accept = { "text/csv": [] };

  const { importFile } = useImportService();

  const { mutate, isPending, isError, error, data } = useMutation<
    IImport,
    ErrorResponse,
    FormData
  >({
    mutationFn: (data: FormData) => importFile("Insulation", data),
  });

  const submitHandler = async () => {
    setInsulationsImport(undefined);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      mutate(formData);
    }
  };

  useEffect(() => {
    if (data) {
      setInsulationsImport(data);
    }
  }, [data]);

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  return (
    <>
      <Container>
        {file && <p>{file.name}</p>}
        <div style={{ width: "50%", paddingBottom: "1rem" }}>
          <Dropzone onDrop={(files) => setFile(files[0])} accept={accept} />
            <p>Tunnus;Kuutiohinta</p>
            <a
              href="https://storage.googleapis.com/pielisenbetoni.appspot.com/templates/eristeet_import_esimerkki.csv"
              target="_blank"
              rel="noreferrer"
            >
              Lataa esimerkkitiedosto tästä
            </a>
        </div>
        <FormButtons
          onSubmit={submitHandler}
          isLoading={isPending}
          submitDisabled={!file}
          submitText="Päivitä"
        />
      </Container>

      {insulationsImport && (
        <>
          {insulationsImport.failedCSVRows.length > 0 && (
            <Container>
              <h2>Epäonnistuneet</h2>
              {insulationsImport.failedCSVRows.map((failedRow, index) => (
                <p key={index}>{failedRow}</p>
              ))}
            </Container>
          )}
          {insulationsImport.newItems.length > 0 && (
            <Container>
              <h2>Uudet</h2>
              <InsulationsTable
                insulations={insulationsImport.newItems as IInsulation[]}
              />
            </Container>
          )}
          {insulationsImport.updatedItems.length > 0 && (
            <Container>
              <h2>Päivitetyt</h2>
              <InsulationsTable
                insulations={insulationsImport.updatedItems as IInsulation[]}
              />
            </Container>
          )}
          {insulationsImport.upToDateItems.length > 0 && (
            <Container>
              <h2>Valmiiksi ajan tasalla</h2>
              <InsulationsTable
                insulations={insulationsImport.upToDateItems as IInsulation[]}
              />
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default InsulationsImport;
