import IProject from "../../../shared/IProject";
import Attachments from "../../Attachments/Attachments";
import PageHeading from "../../ui/PageHeading/PageHeading";

interface IProps {
  project: IProject;
}

const ProjectAttachments: React.FC<IProps> = ({ project }) => {
  return (
    <>
      <PageHeading variant="h3" noHorizontalMargin>Työmaan liitteet</PageHeading>
      <Attachments kind="Project" parentId={project.id} noScroll />
      <hr />
      <PageHeading variant="h3" noHorizontalMargin>Tilauksen liitteet</PageHeading>
      <Attachments kind="Offer" parentId={project.offer.id} uploadDisabled noScroll />
    </>
  );
};

export default ProjectAttachments;
