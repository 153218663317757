import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../custom-fetch";
import Spinner from "../components/ui/Spinner/Spinner";
import Container from "../components/ui/Container/Container";
import { useSlabTypeService } from "../services/slabTypes-service";
import ISlabType from "../shared/ISlabType";
import SlabTypeEdit from "../components/SlabTypes/SlabTypeEdit";

type Params = {
  id: string;
};

const SlabTypePage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getSlabType } = useSlabTypeService();
  
  const {
    data: slabType,
    isLoading,
    isError,
    error,
  } = useQuery<ISlabType, ErrorResponse>({
    queryKey: [QueryKey.slabTypes, id],
    queryFn: ({ signal }) => getSlabType({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Laatta</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <SlabTypeEdit id={id!} slabType={slabType} />
        )}
      </Container>
    </>
  );
};

export default SlabTypePage;
