import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Container from "../components/ui/Container/Container";
import { EInputType, IInputField } from "../components/ui/Input/Input";
import { useInputs } from "../components/ui/Input/useInputs";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import Spinner from "../components/ui/Spinner/Spinner";
import WeeklyProductionLines from "../components/WeeklyProductionLines/WeeklyProductionLines";
import { ErrorResponse } from "../custom-fetch";
import { useProductionLinesService } from "../services/productionLines-service";
import { QueryKey } from "../services/query-keys";
import { FactoryOptions } from "../shared/FactoryOptions";
import IWeeklyProductionLine from "../shared/IWeeklyProductionLine";

enum EInputs {
  factory = "factory",
}

const WeeklyProductionLinesPage: React.FC = () => {
  const { fetchWeeklyProductionLines } = useProductionLinesService();
  const { createInput, inputs } = useWeeklyProductionLinesInputs();

  const factory = useMemo(
    () => inputs[EInputs.factory].value as string,
    [inputs]
  );

  const {
    data: weeklyProductionLines,
    isPending,
    isError,
    error,
  } = useQuery<IWeeklyProductionLine[], ErrorResponse>({
    queryKey: [QueryKey.weeklyProductionLines, factory],
    queryFn: ({ signal }) => fetchWeeklyProductionLines({ signal, factory }),
    staleTime: 5000,
    enabled: !!factory,
  });

  return (
    <>
      <PageHeading>Pedit viikolle</PageHeading>
      {isError && <ErrorsAlert errors={error.messages} />}
      <Container>
        <div>{createInput(EInputs.factory)}</div>
        <hr />
        {factory && isPending ? (
          <Spinner />
        ) : !weeklyProductionLines || weeklyProductionLines?.length === 0 ? (
          <p>Ei petejä</p>
        ) : (
          <WeeklyProductionLines
            weeklyProductionLines={weeklyProductionLines}
          />
        )}
      </Container>
    </>
  );
};

const useWeeklyProductionLinesInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      options: FactoryOptions,
      value: "HOL",
      placeholder: "",
      boldContent: true,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs };
};

export default WeeklyProductionLinesPage;
