import { useState } from "react";
import { EInputType, IInputField } from "../../../ui/Input/Input";
import Button, { EButtonColor, EButtonSize } from "../../../ui/Button/Button";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import IOfferTerm, { EOfferTermType } from "../../../../shared/IOfferTerm";
import { useOfferTermOptions } from "../../../../hooks/useOfferTermOptions";
import { EInputUpdateAction } from "../../../ui/Input/input-context";
import { useInputs } from "../../../ui/Input/useInputs";

export enum EInputs {
  type = "type",
  offerTermId = "offerTermId",
  text = "text",
}

interface IProps {
  offerTerm: IOfferTerm;
  onDelete: () => void;
}

const OfferTextTabOfferTerm: React.FC<IProps> = ({ offerTerm, onDelete,  }) => {
  const { createInput } = useOfferTermInputs(offerTerm);
  const { options, loading } = useOfferTermOptions(offerTerm.type);

  return (
    <tr>
      <td>
        {offerTerm.typeString}
      </td>
      <td>
        {offerTerm.type !== EOfferTermType.CONDITIONAL &&
          createInput(EInputs.offerTermId, {options: options, loading: loading})
        }
      </td>
      <td>
        {createInput(EInputs.text)}
      </td>
      <td>
        {[EOfferTermType.COMMON, EOfferTermType.FREE_TEXT, EOfferTermType.CONDITIONAL].includes(offerTerm.type) &&
        <Button
          onClick={onDelete}
          size={EButtonSize.SMALL}
          color={EButtonColor.DANGER}
          icon={faTrash}
        />
        }
      </td>
    </tr>
  );
};

const useOfferTermInputs = (data?: IOfferTerm) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.type]: {
      type: EInputType.reactSelect,
      value: "",
      menuPosition:"fixed",
    },
    [EInputs.offerTermId]: {
      type: EInputType.reactSelect,
      value: "",
      menuPosition:"fixed"
    },
    [EInputs.text]: {
      type: EInputType.textarea,
      value: "",
      rows: 4
    },
  });

  const { createInput } = useInputs({
    data,
    inputs,
    setInputs,
    updateAction: EInputUpdateAction.OFFER_TERM,
  });

  return { createInput };
};

export default OfferTextTabOfferTerm;
