import React from "react";

import classes from "./Backdrop.module.scss";

interface IProps {
  isOpen: boolean;
  onClick: () => void;
  className?: string;
}

const Backdrop: React.FC<IProps> = ({ isOpen, onClick, className }) => {
  let containerClasses = [classes.Backdrop];

  if (isOpen) {
    containerClasses.push(classes.Active);
  }

  if (className) {
    containerClasses.push(className);
  }

  return <div className={containerClasses.join(" ")} onClick={onClick} />;
};

export default Backdrop;
