import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IClaim from '../shared/IClaim';

interface IFetchClaims extends ISignal {
  projectId?: string;
}

interface IGetClaim extends ISignal {
  id: string;
}

export const useClaimService = () => {
  const customFetch = useCustomFetch();

  const fetchClaims = useCallback(async ({ signal, projectId }: IFetchClaims) => {
    let url = "/api/claims/list";
    if (projectId) {
      url += "/" + projectId;
    }
    const [claims] = await customFetch<IClaim[]>(url, { signal });
    return claims;
  }, [customFetch]);
  
  const getClaim = useCallback(async ({ signal, id }: IGetClaim) => {
    const [claim] = await customFetch<IClaim>("/api/claims/get/" + id, { signal });
    return claim;
  }, [customFetch]);
  
  const saveClaim = useCallback(async (data: IClaim) => {
    const [claim] = await customFetch<IClaim>("/api/claims/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return claim;
  }, [customFetch]);
  
  const updateClaim = useCallback(async (id: string, data: IClaim) => {
    const [claim] = await customFetch<IClaim>("/api/claims/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return claim;
  }, [customFetch]);
  
  const deleteClaim = useCallback(async (id: string) => {
    await customFetch<IClaim>("/api/claims/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchClaims, getClaim, saveClaim, updateClaim, deleteClaim };
}




