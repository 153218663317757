import { useCallback, useMemo, useState } from "react";
import IOffer from "../../../shared/IOffer";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { EInputType, IInputField, IOption } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import ModalBody from "../../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../../ui/Modal/ModalFooter/ModalFooter";

enum EInputs {
  id = "id",
  rowId = "rowId",
}

interface IProps {
  onAccept: (id: string, rowId: string) => void;
  onCancel: () => void;
  options?: IOption[];
  loading: boolean;
  offers?: IOffer[];
}

interface IOfferRowCopyModal {
  id: string;
  rowId: string;
}

const OfferRowCopyModal: React.FC<IProps> = ({ onAccept, onCancel, options, loading, offers }) => {
  const { createInput, submit, inputs } = useOfferRowCopyInputs();

  const id = useMemo(() => inputs[EInputs.id].value, [inputs]);
  const offerRows = useMemo(() => id && offers?.find(offer => offer.id === id)?.rows, [id, offers]);
  const rowOptions = useMemo(() => offerRows ? offerRows.map(row => ({ value: row.id, label: row.name })) : [], [offerRows]);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      onAccept(data.id, data.rowId);
    }
  }, [submit, onAccept]);

  return (
    <>
      <ModalBody>
        {createInput(EInputs.id, { options, loading })}
        {createInput(EInputs.rowId, { options: rowOptions })}
      </ModalBody>
      <ModalFooter>
        <Button onClick={submitHandler} style={{ marginRight: "0.5rem" }}>
          Vahvista
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

const useOfferRowCopyInputs = (data?: IOfferRowCopyModal) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.id]: {
      type: EInputType.reactSelect,
      label: "Valitse tarjous",
      value: "",
      menuPosition: "fixed",
      validation: { required: true },
    },
    [EInputs.rowId]: {
      type: EInputType.reactSelect,
      label: "Valitse rivi",
      value: "",
      menuPosition: "fixed",
      validation: { required: true },
    },
  });

  const { createInput, submit } = useInputs({
    data,
    inputs,
    setInputs,
  });

  return { createInput, submit, inputs };
};

export default OfferRowCopyModal;
