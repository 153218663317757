export const replaceForFileName = (string: string) => {
  let newString = string.toLowerCase();
  newString = newString.replace(/ä|å/g, 'a');
  newString = newString.replace('ö', 'o');
  newString = newString.replace(/\s/g, '-');
  newString = newString.replace(/[^a-z-]/g, "")
  return newString;
} 


interface IFirstNameLastName {
  firstName: string;
  lastName: string;
}

export const combineFirstNameLastName = (item: IFirstNameLastName) => {
  const { firstName = '', lastName = '' } = item;
  const list = [firstName, lastName];
  const name = list.join(' ').trim();
  if (name.length === 0) {
    return null;
  }
  return name;
}

export const numToRoman = (num: number) => {
  if (num === 0) return "0"
  const romanNumerals: [string, number][] = [
    ["X", 10],
    ["IX", 9],
    ["V", 5],
    ["IV", 4],
    ["I", 1]
  ];
  let result: string = "";
  for (const [symbol, value] of romanNumerals) {
    while (num >= value) {
      result += symbol;
      num -= value;
    }
  }
  return result;
}