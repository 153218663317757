import { useQuery } from "@tanstack/react-query";

import { useMemo } from "react";
import { ErrorResponse } from "../../custom-fetch";
import { useElementsService } from "../../services/elements-service";
import { QueryKey } from "../../services/query-keys";
import IElement from "../../shared/IElement";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { IElementsSearch } from "./ElementsSearch";
import ElementsTable from "./ElementsTable";

interface IProps {
  onClick?: (element: IElement) => void;
  selectedElements?: IElement[];
  search?: IElementsSearch | null;
  productionLineId?: string;
  typeName?: string;
  height?: string;
}

const ElementsList: React.FC<IProps> = ({
  onClick,
  selectedElements,
  search,
  productionLineId,
  typeName,
  height,
}) => {
  const { fetchElements } = useElementsService();

  const {
    data: elements,
    isPending,
    isError,
    error,
    // refetch,
    // isRefetching,
    // isRefetchError,
  } = useQuery<IElement[], ErrorResponse>({
    queryKey: [QueryKey.elements],
    queryFn: ({ signal }) => fetchElements({ signal, search: { isPlanned: true } }),
    staleTime: 5000,
  });

  // useEffect(() => {
  //   if (search) {
  //   //   console.log("refetch", search);
  //     refetch();
  //   }
  // }, [search, refetch]);

  // TODO: check
  const filteredElements = useMemo(() => {
    if (!elements) return [];
    let filtered = [...elements];
    // project exists -> filter project id = this or no project id
    if (productionLineId && productionLineId !== "add") {
      console.log("filter, productionLineId ->", productionLineId);
      filtered = filtered.filter(
        (element) => !element.productionLineId || element.productionLineId === productionLineId
      );
    } else {
      // project not exists -> filter no project id
      console.log("filter, productionLineId -> ''");
      filtered = filtered.filter((element) => !element.productionLineId);
    }
    // element selected -> filter element.productType
    if (typeName) {
      console.log("filter, typeName ->", typeName);
      filtered = filtered.filter((element) =>
        element.typeName?.includes(typeName)
      );
    }
    // element selected -> filter element.height
    if (height) {
      console.log("filter, height ->", height);
      filtered = filtered.filter((element) => element.height === height);
    }
    if (search?.factory) {
      console.log("filter, factory ->", search.factory);
      filtered = filtered.filter(
        (element) => element.factory === search.factory
      );
    }
    if (search?.projectId) {
      console.log("filter, projectId ->", search.projectId);
      filtered = filtered.filter(
        (element) => element.projectId === search.projectId
      );
    }
    // if (search?.productionLineId) {
    //   filtered = filtered.filter(element => element.productionLineId === search.productionLineId);
    // }
    if (search?.typeName) {
      console.log("filter, typeName ->", search.typeName);
      filtered = filtered.filter((element) =>
        element.typeName
          ?.toLowerCase()
          .includes(search?.typeName?.toLowerCase() ?? "")
      );
    }
    if (search?.tendonCountThick) {
      console.log("filter, tendonCountThick ->", search.tendonCountThick);
      filtered = filtered.filter(
        (element) => +(element.tendonCountThick ?? 0) === +(search.tendonCountThick ?? 0)
      );
    }
    if (search?.tendonCountThin) {
      console.log("filter, tendonCountThin ->", search.tendonCountThin);
      filtered = filtered.filter(
        (element) => +(element.tendonCountThin ?? 0) === +(search?.tendonCountThin ?? 0)
      );
    }
    return filtered;
  }, [elements, productionLineId, search, typeName, height]);

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!filteredElements || filteredElements.length === 0) {
    return <p>Ei elementtejä</p>;
  }

  return (
    <ElementsTable
      elements={filteredElements}
      onClick={onClick}
      selectedElements={selectedElements}
    />
  );
};

export default ElementsList;
