import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { useClaimService } from "../../services/claims-service";
import { QueryKey } from "../../services/query-keys";
import IClaim from "../../shared/IClaim";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { formatDate } from "../../utils/date-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  projectId?: string;
}

const ClaimsList: React.FC<IProps> = ({ projectId }) => {
  const { fetchClaims } = useClaimService();

  const navigate = useNavigate();
  const {
    data: claims,
    isPending,
    isError,
    error,
  } = useQuery<IClaim[], ErrorResponse>({
    queryKey: [QueryKey.claims, projectId ?? ""],
    queryFn: ({ signal }) => fetchClaims({ signal, projectId }),
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!claims || claims.length === 0) {
    return <p>Ei reklamaatioita</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.claim(id));
  };

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>Luontipvm</th>
            <th>Työmaan nro</th>
            <th>Työmaan nimi</th>
            <th>Asiakkaan nimi</th>
            <th>Vastaanottaja</th>
            <th>Virheen kuvaus</th>
            <th>Hoidettu</th>
            <th>Korjauksen kuvaus</th>
          </tr>
        </thead>
        <tbody>
          {claims.map((claim) => (
            <tr key={claim.id} onClick={() => openHandler(claim.id)}>
              <td>{formatDate(claim.created)}</td>
              <td>{claim.projectNumber}</td>
              <td>{claim.projectName}</td>
              <td>{claim.customerName}</td>
              <td>{claim.createdBy?.name}</td>
              <td>{claim.descriptionDefect}</td>
              <td>{claim.resolved && <FontAwesomeIcon icon={faCheck} />}</td>
              <td>{claim.descriptionFix}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ClaimsList;
