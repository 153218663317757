import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Spinner from "../components/ui/Spinner/Spinner";
import WaybillPrintElements from "../components/Waybills/WaybillPrintElements";
import { ErrorResponse } from "../custom-fetch";
import { usePublicQRCodesService } from "../services/publicQRCodes-service";
import { QueryKey } from "../services/query-keys";
import IWaybillDTO from "../shared/IWaybillDTO";

type Params = {
  id: string;
};

const WaybillPrintPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getWaybillByUuid } = usePublicQRCodesService();
  
  const {
    data: waybill,
    isLoading,
    isError,
    error,
  } = useQuery<IWaybillDTO, ErrorResponse>({
    queryKey: [QueryKey.publicWaybills, id],
    queryFn: ({ signal }) => getWaybillByUuid({ signal, id: id! }),
  });
  return (
    <>
      {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : waybill && (
          <WaybillPrintElements waybill={waybill} />
        )}
    </>
  )
}
export default WaybillPrintPage;