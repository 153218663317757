import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import { QueryKey } from "../../../services/query-keys";
import { useWeeklyProductionRowsService } from "../../../services/weeklyProductionRows-service";
import { EFactory } from "../../../shared/IOfferRow";
import IWeeklyProductionRow from "../../../shared/IWeeklyProductionRow";
import ErrorsAlert from "../../ErrorsAlert/ErrorsAlert";
import PageHeading from "../../ui/PageHeading/PageHeading";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";
import WeeklyProductionRowsProjectsTable from "./WeeklyProductionRowsProjectsTable";

interface IProps {
  factory: EFactory;
}

const WeeklyProductionRowsWeeksTable: React.FC<IProps> = ({ factory }) => {
  const { weeklyProductionRows, isFetching, isFetchError, fetchError } = useFetchByWeek(factory);
  const [productionWeek, setProductionWeek] = useState<string | null>(null);

  if (isFetchError) {
    return <ErrorsAlert errors={fetchError!.messages} />;
  }

  if (isFetching) {
    return <Spinner />;
  }

  if (!weeklyProductionRows || weeklyProductionRows.length === 0) {
    return <p>Ei tuloksia</p>;
  }

  return (
    <>
      <PageHeading variant="h3">Suunniteltu karkea kuorma</PageHeading>
      <Table style={{maxWidth: "800px" }} hover>
        <thead>
          <tr>
            <th style={{ width: "80px" }}>tehdas</th>
            <th style={{ width: "80px" }}>vko</th>
            <th style={{ width: "80px", maxWidth: "80px" }}></th>
            <th style={{ width: "80px" }}></th>
            <th style={{ width: "80px" }}>a²</th>
            <th style={{ width: "80px" }}>jm</th>
            <th style={{ width: "80px" }}>kpl</th>
          </tr>
        </thead>
        <tbody>
          {weeklyProductionRows.map((row) => (
            <tr key={`weeklyProductionRows-${row.id}`} onClick={() => setProductionWeek(row.productionWeek)} style={{ cursor: "pointer" }}>
              <td>{row.factory}</td>
              <td>{row.productionWeek}</td>
              <td></td>
              <td></td>
              <td>{row.grossArea}</td>
              <td>{row.linealMeters}</td>
              <td>{row.count}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {productionWeek && <WeeklyProductionRowsProjectsTable factory={factory} productionWeek={productionWeek} />}
    </>
  );
};

const useFetchByWeek = (factory: string) => {
  const { fetchWeeklyProductionRowsByWeek } = useWeeklyProductionRowsService();
  const queryKey = [QueryKey.weeklyProductionRowsByWeek, factory];
  const {
    data: weeklyProductionRows,
    isPending: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useQuery<IWeeklyProductionRow[], ErrorResponse>({
    queryKey,
    queryFn: ({ signal }) => fetchWeeklyProductionRowsByWeek({ signal, projectId: "", jsonBody: { factory } }),
    staleTime: 5000,
  });

  return { weeklyProductionRows, isFetching, isFetchError, fetchError };
};

export default WeeklyProductionRowsWeeksTable;
