import React, { useEffect, useState } from 'react';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import ReactAsyncSelect from 'react-select/async';

import { IOption, TFetchOption } from '../Input/Input';
import { getSelectValue, getSelectValues, TMenuPosition } from '../Select/Select';

interface IProps {
  value: string | string[];
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  onChange: (value: string | string[]) => void;
  fetchOptions: TFetchOption;
  options?: IOption[];
  menuPosition?: TMenuPosition;
}

const AsyncSelect: React.FC<IProps> = ({ value, onChange, placeholder, multiple, disabled, fetchOptions, options, menuPosition }) => {
  const [selectValue, setSelectValue] = useState<IOption | IOption[] | undefined>();

  useEffect(() => {
    if (value && (value.length || typeof value === 'number') && options) {
      if (multiple) {
        const values = (value as string[]).map(value => value.toString());
        // setSelectValue(options.filter(option => values.includes(option.value.toString())));
        setSelectValue(getSelectValues(options, values));
      } else {
        // setSelectValue(options.find(option => option.value.toString() === value.toString()));
        setSelectValue(getSelectValue(options, value.toString()))
      }
    } else {
      setSelectValue(undefined);
    }

  }, [value, options, multiple]);
  
  const changeHandler = (value: SingleValue<IOption> | MultiValue<IOption>, actionMeta: ActionMeta<IOption>) => {
    if (multiple) {
      onChange((value as MultiValue<IOption> | undefined)?.map(value => value.value) || '')
    } else {
      onChange((value as SingleValue<IOption> | undefined)?.value || '');
    }
  }

  const promiseOptions = (input: string) => {
    if(input.length < 3) {
      return Promise.resolve([]);
    }
    return fetchOptions(input);
  }

  return (
    <ReactAsyncSelect
      defaultOptions={options}
      onChange={changeHandler}
      cacheOptions
      loadOptions={promiseOptions}
      placeholder={placeholder}
      isMulti={multiple}
      value={selectValue}
      isDisabled={disabled}
      menuPosition={menuPosition}
      noOptionsMessage={(input) => {
        const length = input.inputValue.length;
        if (length === 0) {
          return "Kirjoita hakuehto"
        } else if(length >= 1 && length <= 2) {
          return "Kirjoita vähintään 3 merkkiä."
        }
        return "Ei hakutuloksia...";
      }}
      isClearable
    />
  );
};

export default AsyncSelect;
