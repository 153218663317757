import Container from "../components/ui/Container/Container";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import OfferTermsList from "../components/OfferTerms/OfferTermsList";
import { useOfferTermService } from "../services/offerTerms-service";
import { useConfirmModal } from "../components/ui/Modal/useConfirmModal";
import { useCallback, useState } from "react";
import { QueryKey } from "../services/query-keys";
import { queryClient } from "../App";
import Button, { EButtonColor, EButtonSize } from "../components/ui/Button/Button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const OfferTermsPage: React.FC = () => {
  const navigate = useNavigate();
  const { archivedDeleteOfferTerms } = useOfferTermService();
  const openConfirmModal = useConfirmModal();
  const [archivedDeleteLoading, setArchivedDeleteLoading] = useState(false);

  const archivedDeleteHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat poistaa arkistoidut?");
    if (isConfirm) {
      setArchivedDeleteLoading(true);
      try {
        await archivedDeleteOfferTerms();
        queryClient.removeQueries({ queryKey: [QueryKey.offerTerms] });
      } catch (error) {
        console.error('Error deleting:', error);
      } finally {
        setArchivedDeleteLoading(false);
      }
    }
  }, [openConfirmModal, archivedDeleteOfferTerms]);

  const addHandler = () => {
    navigate(Route.offerTerm("add"));
  }

  return (
    <>
      <h1
        style={{
          margin: "1rem",
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        Sopimustekstit {" "}
        <Button
          size={EButtonSize.SMALL}
          onClick={addHandler}
          icon={faPlus}
          style={{ fontSize: "initial" }}
        >
          Lisää uusi
        </Button>
        <Button
          size={EButtonSize.SMALL}
          onClick={archivedDeleteHandler}
          style={{ fontSize: "initial" }}
          color={EButtonColor.DANGER}
          loading={archivedDeleteLoading}
        >
          Poista arkistoidut
        </Button>
      </h1>
      <Container>
        <OfferTermsList />
      </Container>
    </>
  );
};

export default OfferTermsPage;
