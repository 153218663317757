import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { useSlabTypeOptions } from "../../hooks/useSlabTypeOptions";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import { useWireTypeService } from "../../services/wireTypes-service";
import IWireType from "../../shared/IWireType";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import Attachments from "../Attachments/Attachments";

interface IProps {
  id: string;
  wireType?: IWireType;
}

enum EInputs {
  name = "name",
  tendonCountThick = "tendonCountThick",
  tendonCountThin = "tendonCountThin",
  slabType = "slabType",
  // productType = "productType",
  archived = "archived",
}

const WireTypeEdit: React.FC<IProps> = ({ id, wireType }) => {
  const isEdit = id !== "add";
  
  const navigate = useNavigate();
  const redirectHandler = () => {
    return navigate(Route.wireTypes);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit } = useWireTypeInputs(wireType);
  
  const { loading: slabTypesLoading, options: slabTypeOptions, slabTypes } = useSlabTypeOptions();
  // const { loading: productTypesLoading, options: productTypeOptions, productTypes } = useProductTypeOptions();

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      const selectedSlabType = slabTypes?.find((slabType) => slabType.id === data.slabType as any);
      // const selectedProductType = productTypes?.find((productType) => productType.id === data.productType as any);
      data.slabType = selectedSlabType ?? undefined;
      // data.productType = selectedProductType ?? undefined;
      saveOrUpdate(data);
    }
  };

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      {createInput(EInputs.name)}
      {createInput(EInputs.tendonCountThick)}
      {createInput(EInputs.tendonCountThin)}
      {createInput(EInputs.slabType, { options: slabTypeOptions, loading: slabTypesLoading })}
      {/* {createInput(EInputs.productType, { options: productTypeOptions, loading: productTypesLoading })} */}
      {/*createInput(EInputs.archived)*/}
      {isEdit && wireType?.id && <Attachments kind="WireType" parentId={wireType.id} noScroll />}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa vaijerityypin?"
      />
    </>
  );
};

const useWireTypeInputs = (data?: IWireType) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.name]: {
      type: EInputType.text,
      label: "Nimi",
      value: "",
      validation: {
        required: true,
      },
      autoFocus: true
    },
    [EInputs.tendonCountThick]: {
      type: EInputType.number,
      label: "Paksut vaijerit",
      value: "",
    },
    [EInputs.tendonCountThin]: {
      type: EInputType.number,
      label: "Ohuet vaijerit",
      value: "",
    },
    [EInputs.slabType]: {
      type: EInputType.reactSelect,
      label: "Laatta",
      value: "",
      validation: {
        required: true,
      }
    },
    // [EInputs.productType]: {
    //   type: EInputType.reactSelect,
    //   label: "Tuote",
    //   value: "",
    // },
    [EInputs.archived]: {
      type: EInputType.checkbox,
      label: "Arkistoitu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirectHandler: () => void) => {
  const { updateWireType, saveWireType } = useWireTypeService();

  const mutationFn = (data: IWireType) => {
    return isEdit ? updateWireType(id, data) : saveWireType(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IWireType, ErrorResponse, IWireType>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.wireTypes, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.wireTypes] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.wireTypeOptions] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteWireType } = useWireTypeService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteWireType(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.wireTypes],
        refetchType: "none",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.wireTypeOptions],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default WireTypeEdit;
