import React, { useEffect, useState } from 'react';
import ReactSelect, { ActionMeta, createFilter, MultiValue, SingleValue } from 'react-select';
import CreatableReactSelect from "react-select/creatable";
import { IOption, TOptions } from '../Input/Input';
import classes from "./Select.module.scss";

export type TMenuPosition = "fixed" | "absolute";

interface IProps {
  options?: TOptions;
  name: string;
  value: string | string[];
  placeholder?: string;
  multiple?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onChange: (value: string | string[]) => void;
  menuPosition?: TMenuPosition;
  hideControls?: boolean;
  isClearable?: boolean;
  autoFocus?: boolean;
  isCreatable?: boolean;
}


export const getSelectValues = (options: TOptions, values: string[]) => {
  let sValues: IOption[] = [];
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if ("value" in option) {
      if (values.includes(option.value.toString())) {
        sValues.push(option);
      }
    } else if ("options" in option) {
      sValues = [...sValues, ...getSelectValues(option.options, values)];
    }
  }
  return sValues;
}

export const getSelectValue = (options: TOptions, value: string): IOption | undefined => {
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if ("value" in option) {
      if (option.value.toString() === value.toString()) {
       return option;
      }
    } else if ("options" in option) {
      const newOption = getSelectValue(option.options, value);
      if (newOption) {
        return newOption;
      }
    }
  }
  return undefined;
}

const Select: React.FC<IProps> = ({ options, name, placeholder, multiple, loading, disabled, value, onChange, menuPosition = 'absolute', hideControls, isClearable, autoFocus, isCreatable }) => {
  const [selectValue, setSelectValue] = useState<IOption | IOption[] | undefined>();

  useEffect(() => {
    if (value && (value.length || typeof value === 'number') && options) {
      if (multiple) {
        const values = (value as string[]).map(value => value.toString());
        // setSelectValue(options.filter(option => values.includes(option.value.toString())));
        setSelectValue(getSelectValues(options, values));
      } else {
        // setSelectValue(options.find(option => option.value.toString() === value.toString()));
        setSelectValue(getSelectValue(options, value.toString()))
      }
    } else {
      setSelectValue(undefined);
    }

  }, [value, options, multiple]);

  const changeHandler = (value: SingleValue<IOption> | MultiValue<IOption>, actionMeta: ActionMeta<IOption>) => {
    if (multiple) {
      onChange((value as MultiValue<IOption> | undefined)?.map(value => value.value) || '')
    } else {
      onChange((value as SingleValue<IOption> | undefined)?.value || '');
    }
  }

  const filterOptionByLabel = createFilter({ matchFrom: 'any', stringify: option => `${option.label}` });

  return isCreatable ? (
    <CreatableReactSelect
      key={`${name}_${selectValue}`}
      name={name}
      onChange={changeHandler}
      isLoading={loading}
      value={selectValue}
      placeholder={placeholder}
      options={options}
      isDisabled={disabled}
      isSearchable
      isClearable={isClearable}
      autoFocus={autoFocus}
      isMulti={multiple}
      menuPosition={menuPosition}
      components={hideControls ? { DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null } : {}}
      formatCreateLabel={(inputValue) => `Lisää "${inputValue}"`}
      filterOption={filterOptionByLabel}
      className={classes.Select}
      createOptionPosition="first"
    />
  ) : (
    <ReactSelect
      key={`${name}_${selectValue}`}
      name={name}
      onChange={changeHandler}
      isLoading={loading}
      value={selectValue}
      placeholder={placeholder}
      options={options}
      isDisabled={disabled}
      isSearchable
      isClearable={isClearable}
      autoFocus={autoFocus}
      isMulti={multiple}
      menuPosition={menuPosition}
      components={hideControls ? { DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null } : {}}
      filterOption={filterOptionByLabel}
      className={classes.Select}
    />
  );
};

export default Select;
