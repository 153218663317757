import { CSSProperties, forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { useOfferService } from "../../../../services/offers-service";
import { EInputType, IInputField, TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import { useInputs } from "../../../ui/Input/useInputs";
import Table from "../../../ui/Table/Table";
import { useOfferRowCalculationContext } from "../offer-row-calculation-context";
import WorkTotalTable from "./WorkTotalTable";
import classes from "./OfferRowCalculationWork.module.scss";
import { RefHandle } from "../../../../shared/RefHandle";
import { useOfferProductsContext } from "../../OfferProducts/offer-products-context";

export interface IOfferRowCalculationWork {
  price: string;
  workHoursElement: string;
  hourlyRate: string;
  factoryFee: string;
  workers: string;
  hoursDay: string;
  days: string;
  // hoursLine: string;
  // hoursPcsLine: string;
  productionLineLength: string;
  elementsInProductionLine: string;
  addedDifficultyHoursSquare: string;
  addedDifficultyHoursElement: string;
  workHoursTotalForProductionLine: string;
  workEstimateForElement: string;
}

enum EInputs {
  workHoursElement = "workHoursElement",
  hourlyRate = "hourlyRate",
  factoryFee = "factoryFee",
  workers = "workers",
  hoursDay = "hoursDay",
  days = "days",
  // hoursPcsLine = "hoursPcsLine",
  productionLineLength = "productionLineLength",
  elementsInProductionLine = "elementsInProductionLine",
  addedDifficultyHoursSquare = "addedDifficultyHoursSquare",
  addedDifficultyHoursElement = "addedDifficultyHoursElement",
  workHoursTotalForProductionLine = "workHoursTotalForProductionLine",
  workEstimateForElement = "workEstimateForElement"
}

const containerStyles: CSSProperties = { width: "110px" };

const OfferRowCalculationWork: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationWork>> = (_, ref) => {
  const { offerRow, updateOfferRow } = useOfferRowCalculationContext();
  const { offerRowCalculationWork: work, offerRowCalculationTotal: total } = offerRow;
  const { calculateOfferWork } = useOfferService();

  const { showControls } = useOfferProductsContext();
  const { createInput, submit } = useWorkInputs(work, showControls);

  const onAutoUpdate = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.WORK) {
        const data = _ as IOfferRowCalculationWork;
        const offerRowCalculationWork = await calculateOfferWork({
          ...data,
          [inputName]: formatInputValue(value),
        });
        updateOfferRow({ offerRowCalculationWork });
      }
      return Promise.resolve(true);
    },
    [calculateOfferWork, updateOfferRow]
  );

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const newData = await submit();
      if (!work) return null;
      return { ...work, ...newData };
    },
  }), [work, submit]);

  return (
    <>
      <WorkTotalTable offerRow={offerRow} />
      <InputContextProvider initDone onAutoUpdate={onAutoUpdate}>
        {/*
        <Table removeGap>
          <tbody>
            <tr >
              <td style={{ width: "120px" }}>Työtunteja / kpl</td>
              <td style={{ width: "300px" }}>{createInput(EInputs.workHoursElement, { containerStyles })}</td>
            </tr>
            <tr>
              <td>Tuntihinta</td>
              <td>{createInput(EInputs.hourlyRate, { containerStyles })}</td>
            </tr>
            <tr>
              <td>Tehtaan Ka työkustannus</td>
              <td>{createInput(EInputs.factoryFee, { containerStyles })}</td>
            </tr>
          </tbody>
        </Table>
        <hr />
        <Table removeGap>
          <tbody>
            <tr className={classes.NoBorder}>
              <td>Tekijöitä</td>
              <td>{createInput(EInputs.workers, { containerStyles })}</td>
              <td>Tunteja/pv</td>
              <td>{createInput(EInputs.hoursDay, { containerStyles })}</td>
              <td>Päiviä</td>
              <td>{createInput(EInputs.days, { containerStyles })}</td>
              <td>h/linja</td>
              <td>{createInput(EInputs.workHoursTotalForProductionLine, { containerStyles })}</td>
              <td>h/kpl/linja</td>
              <td>{createInput(EInputs.workEstimateForElement, { containerStyles })}</td>
            </tr>
            <tr>
              <td>Linjanpituus</td>
              <td colSpan={9}>{createInput(EInputs.lineLength, { containerStyles })}</td>
            </tr>
            <tr>
              <td>Kpl/Linja</td>
              <td colSpan={9}>{createInput(EInputs.pcsLine, { containerStyles })}</td>
            </tr>
            <tr>
              <td>h/m2(vaativuus)</td>
              <td>
                {createInput(EInputs.addedDifficultyHoursSquare, {
                  containerStyles,
                })}
              </td>
              <td>h/kpl</td>
              <td colSpan={7}>{offerRowCalculationTotal?.addedDifficultyHoursElement}</td>
            </tr>
          </tbody>
        </Table>
      */}
        <div>
          <Table removeGap>
            <thead>
              <tr>
                <th>Työtunteja / kpl</th>
                <th>Tuntihinta</th>
                <th>Tehtaan Ka<br />työkustannus</th>
                <th>yht</th>
              </tr>
            </thead>
            <tbody>
              <tr className={classes.NoBorder}>
                <td>{createInput(EInputs.workHoursElement, { containerStyles })}</td>
                <td>{createInput(EInputs.hourlyRate, { containerStyles })}</td>
                <td>{work.factoryFee} € /h </td>
                <td>{work.price} €</td>

              </tr>
            </tbody>
          </Table>
          <hr />
          <Table removeGap>
            <thead>
              <tr>
                <th>Tekijöitä</th>
                <th>Tunteja/pv</th>
                <th>Päiviä</th>
                <th>h/linja</th>
                <th>h/kpl/linja</th>
                <th>Linjanpituus</th>
                <th>Kpl/Linja</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{createInput(EInputs.workers, { containerStyles })}</td>
                <td>{createInput(EInputs.hoursDay, { containerStyles })}</td>
                <td>{createInput(EInputs.days, { containerStyles })}</td>
                <td>{work.workHoursTotalForProductionLine}</td>
                <td>{work.workEstimateForElement}</td>
                <td>{createInput(EInputs.productionLineLength, { containerStyles })}</td>
                <td>{createInput(EInputs.elementsInProductionLine, { containerStyles })}</td>
              </tr>
            </tbody>
          </Table>
          <hr />
          <Table removeGap>
            <thead>
              <tr>
                <th>h/m2(vaativuuslisä)</th>
                <th>h/kpl</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{createInput(EInputs.addedDifficultyHoursSquare, { containerStyles })}</td>
                <td>+ {total?.addedDifficultyHoursElement} h/kpl</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </InputContextProvider>
    </>
  );
};

const useWorkInputs = (data?: IOfferRowCalculationWork, showControls?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.days]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.factoryFee]: {
      type: EInputType.number,
      value: "",
      disabled: true
    },
    [EInputs.hourlyRate]: {
      type: EInputType.number,
      value: "",
      post: "€ /h"
    },
    [EInputs.hoursDay]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.workHoursTotalForProductionLine]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.workHoursElement]: {
      type: EInputType.number,
      value: "",
      post: "h"
    },
    [EInputs.workEstimateForElement]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.addedDifficultyHoursSquare]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.productionLineLength]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.elementsInProductionLine]: {
      type: EInputType.number,
      value: "",
      disallowDecimals: true,
    },
    [EInputs.addedDifficultyHoursElement]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.workers]: {
      type: EInputType.number,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.WORK, disabled: !showControls });

  return { createInput, submit, inputs };
};

export default forwardRef(OfferRowCalculationWork);