import { useEffect, useState } from "react";
import Button from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs,
} from "../ui/Input/input-utils";
import { useCreateInput } from "../ui/Input/useCreateInput";
import { useWaybillsSearch } from "./useWaybillsSearch";
import { storage } from "../../utils/localstorage-utils";
import { FactoryOptions } from "../../shared/FactoryOptions";
import { useProjectOptions } from "../../hooks/useProjectOptions";

export interface IWaybillsSearch {
  deliveryDate?: string;
  waybillId?: string;
  projectId?: string;
  factory?: string;
}

enum EInputs {
  deliveryDate = "deliveryDate",
  waybillId = "waybillId",
  projectId = "projectId",
  factory = "factory",
}

const WaybillsSearch: React.FC = () => {
  const { search, setSearch, isLoading, refetch } = useWaybillsSearch();
  const { createInput, submit } = useInputs(search);
  const { options: projectOptions, loading: projectsLoading } = useProjectOptions();

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      setSearch(data);
    }
  };

  useEffect(() => {
    if (!isLoading && search) {
      refetch();
      storage.saveWaybillsSearch(search);
    }
    // eslint-disable-next-line
  }, [search, refetch]);

  return (
    <>
      <div style={{ width: "80%"}}>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
          {createInput(EInputs.deliveryDate, {containerStyles: { width: "340px", marginRight: "1rem" }})}
          {createInput(EInputs.waybillId, {containerStyles: { width: "340px", marginRight: "1rem" }})}
          {createInput(EInputs.projectId, {options: projectOptions, loading: projectsLoading, containerStyles: { width: "340px", marginRight: "1rem" }})} 
          {createInput(EInputs.factory, {containerStyles: { width: "340px", marginRight: "1rem" }})} 
        </div>
      </div>

      <Button loading={isLoading} onClick={submitHandler}>
        Hae
      </Button>
    </>
  );
};

const useInputs = (search?: IWaybillsSearch | null) => {
  const [showValidation, setShowValidation] = useState(false);
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.deliveryDate]: {
      type: EInputType.date,
      label: "Pvm",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.waybillId]: {
      type: EInputType.number,
      label: "Numero",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.projectId]: {
      type: EInputType.reactSelect,
      label: "Työmaa",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.factory]: {
      type: EInputType.reactSelect,
      label: "Tehdas",
      options: FactoryOptions,
      value: "",
      placeholder: "",
      boldContent: true
    },
  });

  useEffect(() => {
    if (search) {
      initForm(setInputs, search);
    }
  }, [search]);

  const createInput = useCreateInput(inputs, setInputs, { showValidation });

  const submit = async () => {
    const isValid = await validateInputs(setInputs);
    if (isValid) {
      return getInputData<IWaybillsSearch>(inputs);
    }
    setShowValidation(true);
    return null;
  };

  return { createInput, submit };
};

export default WaybillsSearch;
