import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../custom-fetch";
import { useProductionLinesService } from "../../services/productionLines-service";
import { QueryKey } from "../../services/query-keys";
import IProductionLine from "../../shared/IProductionLine";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import ProductionLinesTable from "./ProductionLinesTable";

const ProductionLinesList: React.FC = () => {
  const { fetchProductionLines } = useProductionLinesService();

  const {
    data: productionLines,
    isPending,
    isError,
    error,
  } = useQuery<IProductionLine[], ErrorResponse>({
    queryKey: [QueryKey.productionLines],
    queryFn: fetchProductionLines,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!productionLines || productionLines.length === 0) {
    return <p>Ei petejä</p>;
  }

  return (
    <>
      <ProductionLinesTable productionLines={productionLines} />
    </>
  );
};

export default ProductionLinesList;
