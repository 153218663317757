import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { useOfferService } from "../../../../services/offers-service";
import { EFactory } from "../../../../shared/IOfferRow";
import { RefHandle } from "../../../../shared/RefHandle";
import { TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import Table from "../../../ui/Table/Table";
import { useOfferCalculationTabsContext } from "../offer-calculation-tabs-context";
import OfferCalculationTabKilometer, { IOfferCalculationKilometer } from "./OfferCalculationTabKilometer";

export const createKilometerRows = (): IOfferCalculationKilometer[] => {
  const freightKilometers = "";
  const freightToCustomer = "";
  const offerFreightPrice = "";
  const percentageDifference = "";
  return [
    { factory: EFactory.HOL, freightKilometers, freightToCustomer, offerFreightPrice, percentageDifference },
    { factory: EFactory.JOE, freightKilometers, freightToCustomer, offerFreightPrice, percentageDifference },
    // { factory: EFactory.KUO, freightKilometers, freightToCustomer, offerFreightPrice, percentageDifference },
    { factory: EFactory.LIE, freightKilometers, freightToCustomer, offerFreightPrice, percentageDifference },
    { factory: EFactory.OKU, freightKilometers, freightToCustomer, offerFreightPrice, percentageDifference },
  ];
};

const OfferCalculationTabKilometers: React.ForwardRefRenderFunction<RefHandle<IOfferCalculationKilometer[]>> = (_, ref) => {
  const { offerKilometers, setOfferKilometers } = useOfferCalculationTabsContext();
  const { calculateOfferKilometer, calculateOfferKilometers } = useOfferService();

  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.OFFER_KILOMETERS) {
        const data = _ as IOfferCalculationKilometer;
        const index = offerKilometers.findIndex((r) => r.factory === data.factory);
        if (index > -1) {
          const newData = await calculateOfferKilometer({
            ...data,
            [inputName]: formatInputValue(value),
          });
          offerKilometers[index] = newData;
          setOfferKilometers([ ...offerKilometers ]);
        }
      }
      return Promise.resolve(true);
    },
    [offerKilometers, setOfferKilometers, calculateOfferKilometer]
  );

  const kilometerRowsRef = useRef<RefHandle<IOfferCalculationKilometer>[]>([]);
  
  useImperativeHandle(ref, () => ({
    getData: async () => {
      const kilometerRows = kilometerRowsRef.current;
      const newRows: IOfferCalculationKilometer[] = [];
      for (let i = 0; i < kilometerRows.length; i++) {
        const row = kilometerRows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            newRows.push(rowData);
          }
        }
      }
      return await calculateOfferKilometers([...newRows]);
    },
  }), [calculateOfferKilometers]);

  return (
    <Table>
      <thead>
        <tr>
          <th>Tehdas</th>
          <th>Rahti km kohteeseen [km]</th>
          <th>hinnaston rahti kohteeseen [€/ton]</th>
          <th>Tarjouksen rahtihinta [€/ton]</th>
          <th>% ero</th>
        </tr>
      </thead>
      <tbody>
        <InputContextProvider initDone onAutoUpdate={updateHandler}>
          {offerKilometers.map((data, i) => (
            <OfferCalculationTabKilometer key={data.factory} data={data} ref={rowHandle => kilometerRowsRef.current[i] = rowHandle!} />
          ))}
        </InputContextProvider>
      </tbody>
    </Table>
  );
};

export default forwardRef(OfferCalculationTabKilometers);
