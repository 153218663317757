import ProjectsList from "../components/Projects/ProjectsList";
import ProjectsSearch from "../components/Projects/ProjectsSearch";
import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";

const ProjectsPage: React.FC = () => {
  return (
    <>
      <PageHeading>Työmaat</PageHeading>
      <Container>
        <ProjectsSearch />
      </Container>
      <Container>
        <ProjectsList />
      </Container>
    </>
  );
};

export default ProjectsPage;
