import { useState } from "react";
import IOrganization, { GROUP_OPTIONS } from "../../shared/IOrganization";
import Button, { EButtonColor } from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";

enum EInputs {
  number = "number",
  name = "name",
  businessId = "businessId",
  streetAddress = "streetAddress",
  zip = "zip",
  city = "city",
  group = "group",
}

interface IProps {
  onAccept: (data: IOrganization) => Promise<void>;
  onCancel: () => void;
  organization?: IOrganization;
}

const OrganizationEditModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
  organization,
}) => {
  const { createInput, submit } = useOrganizationInputs(organization);
  const [loading, setLoading] = useState(false);

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      setLoading(true);
      await onAccept(data);
      setLoading(false);
    }
  };

  return (
    <>
      <ModalBody>
        {createInput(EInputs.number)}
        {createInput(EInputs.name)}
        {createInput(EInputs.businessId)}
        {createInput(EInputs.streetAddress)}
        {createInput(EInputs.zip)}
        {createInput(EInputs.city)}
        {createInput(EInputs.group)}
      </ModalBody>
      <ModalFooter>
        <Button onClick={submitHandler} style={{ marginRight: "0.5rem" }} loading={loading}>
          Tallenna
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

const useOrganizationInputs = (data?: IOrganization) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.number]: {
      type: EInputType.number,
      label: "Numero",
      value: "",
      autoFocus: true,
    },
    [EInputs.name]: {
      type: EInputType.text,
      label: "Nimi",
      value: "",
    },
    [EInputs.businessId]: {
      type: EInputType.text,
      label: "Y-Tunnus",
      value: "",
    },
    [EInputs.streetAddress]: {
      type: EInputType.text,
      label: "Katuosoite",
      value: "",
    },
    [EInputs.zip]: {
      type: EInputType.text,
      label: "Postinumero",
      value: "",
    },
    [EInputs.city]: {
      type: EInputType.text,
      label: "Kaupunki",
      value: "",
    },
    [EInputs.group]: {
      type: EInputType.reactSelect,
      label: "Ryhmä",
      value: "",
      options: GROUP_OPTIONS,
      menuPosition: "fixed",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

export default OrganizationEditModal;
