import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IProductType from '../shared/IProductType';

interface IGetProductType extends ISignal {
  id: string;
}

export const useProductTypeService = () => {
  const customFetch = useCustomFetch();

  const fetchProductTypes = useCallback(async ({ signal }: ISignal, archived?: boolean, productGroupId?: string) => {
    let url = '/api/product-types/list';
    const params = new URLSearchParams();
    if (archived !== undefined) {
      params.append('archived', String(archived));
    }
    if (productGroupId) {
      params.append('productGroupId', productGroupId);
    }
    if (params.toString()) {
      url += `?${params.toString()}`;
    }
    const [productTypes] = await customFetch<IProductType[]>(url, { signal });
    return productTypes;
}, [customFetch]);
  
  const getProductType = useCallback(async ({ signal, id }: IGetProductType) => {
    const [productType] = await customFetch<IProductType>("/api/product-types/get/" + id, { signal });
    return productType;
  }, [customFetch]);
  
  const saveProductType = useCallback(async (data: IProductType) => {
    const [productType] = await customFetch<IProductType>("/api/product-types/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return productType;
  }, [customFetch]);
  
  const updateProductType = useCallback(async (id: string, data: IProductType) => {
    const [productType] = await customFetch<IProductType>("/api/product-types/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return productType;
  }, [customFetch]);
  
  const deleteProductType = useCallback(async (id: string) => {
    await customFetch<IProductType>("/api/product-types/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const archivedUpdateProductType = useCallback(async (id: string) => {
    const [productType] = await customFetch<IProductType>("/api/product-types/update-archived/" + id, {
      method: "PUT",
    });
    return productType;
  }, [customFetch]);

  const fetchSlabProductTypes = useCallback(async ({ signal }: ISignal) => {
    let url = '/api/product-types/list-slabs';
    const [productTypes] = await customFetch<IProductType[]>(url, { signal });
    return productTypes;
  }, [customFetch]);
  
  return { fetchProductTypes, getProductType, saveProductType, updateProductType, deleteProductType, archivedUpdateProductType, fetchSlabProductTypes };
}