import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IOfferAction from '../../shared/store/IOfferAction';
import IOfferState from '../../shared/store/IOfferState';

type ReducerState = IOfferState;
type ReducerAction = IOfferAction;

const initialState: ReducerState = {
  offer: null,
  loading: false,
  error: null,
};

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    updateOfferStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateOfferSuccess(state, { payload: { offer }}: PayloadAction<ReducerAction>) {
      state.offer = offer || null;
      state.error = null;
      state.loading = false;
    },
    updateOfferFail(state, { payload: { error }}: PayloadAction<ReducerAction>) {
      state.error = error;
      state.offer = null;
      state.loading = false;
    }
  }
});

export const offerActions = offerSlice.actions;

export default offerSlice.reducer;
