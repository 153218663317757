import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useSlabTypeService } from "../services/slabTypes-service";
import ISlabType from "../shared/ISlabType";

export const useSlabTypeOptions = () => {
  const { fetchSlabTypes } = useSlabTypeService();

  const {
    data: slabTypes,
    isPending,
    isError,
  } = useQuery<ISlabType[], ErrorResponse>({
    queryKey: [QueryKey.slabTypeOptions],
    queryFn: ({ signal }) => fetchSlabTypes({ signal }, false),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : slabTypes?.map(
            (s) => ({ value: s.id, label: s.typeName } as IOption)
          ),
    [slabTypes, isError]
  );

  return { options, loading: isPending, slabTypes };
};
