import { faChevronUp, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';

import classes from './Accordion.module.scss';
import IconBackground from '../IconBackground/IconBackground';

export declare class ResizeObserver {
  constructor (callback: VoidFunction);
  observe(target: Element): void;
  unobserve(target: Element): void;
  disconnect(): void;
}

interface IProps {
  text: ReactNode;
  children: ReactNode;
  bordered?: boolean
  style?: CSSProperties;
  withContentPadding?: boolean;
  isOpen?: boolean;
  className?: string;
  hasError?: boolean;
  color?: EAccordionColor;
}

export enum EAccordionColor {
  PRIMARY,
  SECONDARY,
}

const Accordion: React.FC<IProps> = ({ text, children, bordered, style = {}, withContentPadding, isOpen: isOpenParam, className, hasError, color = EAccordionColor.PRIMARY }) => {
  const [isOpen, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const openHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(isOpen => !isOpen);
  }


  const scrollHeight = contentRef.current?.scrollHeight;
  useEffect(() => {
    if (scrollHeight) {
      setMaxHeight(scrollHeight);
    }

  }, [scrollHeight]);

  const element = contentRef.current;
  useEffect(() => {
    if (element) {
      const resizeObserver = new ResizeObserver(() => {
        setMaxHeight(element.scrollHeight);
      });
      resizeObserver.observe(element);
      return () => {
        resizeObserver.unobserve(element);
        resizeObserver.disconnect();
      }
    }
  }, [element])

  const iconClassNames = [classes.Icon];

  if (isOpen) {
    iconClassNames.push(classes.IconOpen);
  }

  const classNames = [classes.Animate];
  if (bordered) {
    classNames.push(classes.Bordered);
  }

  switch (color) {
    case EAccordionColor.PRIMARY:
      classNames.push(classes.ColorPrimary);
      break;
    case EAccordionColor.SECONDARY:
      classNames.push(classes.ColorSecondary);
      break;
  }

  if (className) {
    classNames.push(className);
  }

  useEffect(() => {
    if (typeof isOpenParam !== 'undefined') {
      setOpen(isOpenParam)
    }
  }, [isOpenParam]);


  const toggleClassNames = [classes.Toggle];


  
  return (
    <div className={classNames.join(' ')} style={style}>
      <div className={toggleClassNames.join(' ')} onClick={openHandler}><h3 style={{margin:"0", lineHeight: "1.2"}}>{text}{hasError && <IconBackground style={{ marginLeft: '.5rem' }}><FontAwesomeIcon className={classes.Error} icon={faExclamationCircle} /></IconBackground>}</h3> <FontAwesomeIcon className={iconClassNames.join(' ')} icon={faChevronUp} /></div>
      <div style={{maxHeight: isOpen ? maxHeight : 0}} className={classes.Content} ref={contentRef}>
        {withContentPadding ? <div style={{ padding: '1rem' }}>{children}</div> : children}
      </div>
    </div>
  );
}

export default Accordion;