import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IUser, { UserRole } from "../shared/IUser";

interface IFetchUsers extends ISignal {
  roles: UserRole[];
  organizationId?: string;
  email?: string;
}

interface IGetUser extends ISignal {
  id: string;
}

export const useUserService = () => {
  const customFetch = useCustomFetch();

  const fetchUsers = useCallback(async ({ signal, roles, organizationId, email }: IFetchUsers) => {
  if (roles.length === 0 && !organizationId) return [];
    let url = '/api/users/list';
  if (organizationId) {
    url += `/${organizationId}`;
  }
  const params = new URLSearchParams();
  if (roles.length > 0) {
    params.append('roles', roles.join(','));
  }
  if (email) {
    params.append('email', email);
  }
  if (params.toString()) {
    url += `?${params.toString()}`;
  }
  const [users] = await customFetch<IUser[]>(url, { signal });
  return users;
}, [customFetch]);
  
  const getUser = useCallback(async ({ signal, id }: IGetUser) => {
    const [user] = await customFetch<IUser>("/api/users/get/" + id, { signal });
    return user;
  }, [customFetch]);
  
  const saveUser = useCallback(async (data: IUser) => {
    const [user] = await customFetch<IUser>("/api/users/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return user;
  }, [customFetch]);
  
  const updateUser = useCallback(async (id: string, data: IUser) => {
    const [user] = await customFetch<IUser>("/api/users/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return user;
  }, [customFetch]);
  
  const deleteUser = useCallback(async (id: string) => {
    await customFetch<IUser>("/api/users/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchUsers, getUser, saveUser, updateUser, deleteUser };
}




