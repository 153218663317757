import { IOfferRowCalculationTotal } from "../../../../shared/IOfferRow";
import Table from "../../../ui/Table/Table";
import { IOfferRowCalculationMesh } from "./OfferRowCalculationMeshes";

interface IProps {
  calculation: IOfferRowCalculationMesh;
  total: IOfferRowCalculationTotal;
}

const MeshesTotalTable: React.FC<IProps> = ({ calculation, total }) => {
  return (
    <Table removeGap>
      <thead>
        <tr>
          <th>yhteensä, kg</th>
          <th>yhteensä, €</th>
          <th>kg /m²</th>
          <th>kg /m³</th>
          <th>€ /m²</th>
          <th>€ /m³</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{calculation.weight}</td>
          <td>{calculation.price} €</td>
          <td>{total.meshSquareMeterWeight}</td>
          <td>{total.meshCubicMeterWeight}</td>
          <td>{total.meshSquareMeterPrice} €</td>
          <td>{total.meshCubicMeterPrice} €</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default MeshesTotalTable;
