import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { useProductTypeService } from "../services/productTypes-service";
import { QueryKey } from "../services/query-keys";
import IProductType from "../shared/IProductType";

export const useProductTypeOptions = (filterByProductGroupId?: boolean, productGroupId?: string) => {
  const { fetchProductTypes } = useProductTypeService();
  const enabled = useMemo(() => filterByProductGroupId ? !!productGroupId : true, [filterByProductGroupId, productGroupId]);

  const {
    data: productTypes,
    isPending,
    isError,
  } = useQuery<IProductType[], ErrorResponse>({
    queryKey: [QueryKey.productTypeOptions, productGroupId],
    queryFn: ({ signal }) => fetchProductTypes({ signal }, false, productGroupId),
    staleTime: Infinity,
    enabled,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : productTypes?.map((p) => ({ value: p.id, label: p.name } as IOption)),
    [productTypes, isError]
  );

  return { options, loading: enabled && isPending, productTypes };
};
