import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import ITransportationCost from "../../shared/ITransportationCost";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";
import { useTransportationCostService } from "../../services/transportationCosts-service";
import TransportationCostsTable from "./TransportationCostTable";

const TransportationCostsList: React.FC = () => {
  const { fetchTransportationCosts } = useTransportationCostService();
  const [filterValue, setFilterValue] = useState<string>('');

  const {
    data: transportationCosts,
    isPending,
    isError,
    error,
  } = useQuery<ITransportationCost[], ErrorResponse>({
    queryKey: [QueryKey.transportationCosts],
    queryFn: fetchTransportationCosts,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!transportationCosts || transportationCosts.length === 0) {
    return <p>Ei kuljetushintoja</p>;
  }

  const filteredTransportationCosts = transportationCosts.filter(
    (transportationCost) =>
      transportationCost.factoriesString?.toLowerCase().includes(filterValue)
  );

  return (
    <>
      <Input
        inputName="transportationCostsFilter"
        type={EInputType.text}
        placeholder="Suodata tehtaalla"
        value={filterValue}
        onChange={(value) => setFilterValue(value.toString().toLowerCase())}
        autoFocus={true}
      />
      <TransportationCostsTable transportationCosts={filteredTransportationCosts} showUpdateArchived={true}/>
    </>
  );
};

export default TransportationCostsList;
