import InsulationsImport from "../components/Insulations/InsulationsImport";

const InsulationsImportPage: React.FC = () => {
  return (
    <>
      <h1 style={{ margin: "1rem" }}>Eristeet Import</h1>
      <InsulationsImport />
    </>
  );
};

export default InsulationsImportPage;
