import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClaimsList from "../components/Claims/ClaimsList";
import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import Select from "../components/ui/Select/Select";
import { useProjectOptions } from "../hooks/useProjectOptions";
import { Route } from "../routes";

const ClaimsPage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");
  const { options, loading } = useProjectOptions();

  const addHandler = useCallback(() => {
    navigate(Route.claim("add"));
  }, [navigate]);

  return (
    <>
      <PageHeading onAdd={addHandler}>Reklamaatiot / poikkeamat</PageHeading>
      <Container>
        <div style={{ marginBottom: "0.75rem", maxWidth: "1000px" }}>
          <Select
            name="projectIdSelect"
            value={selectedProjectId as string}
            onChange={(value) => setSelectedProjectId(value as string)}
            options={options}
            loading={loading}
            isClearable
            hideControls
            placeholder="Hae projektilla"
            autoFocus
          />
        </div>
        <ClaimsList projectId={selectedProjectId} />
      </Container>
    </>
  );
};

export default ClaimsPage;
