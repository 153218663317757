import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { useOrganizationService } from "../../services/organizations-service";
import { QueryKey } from "../../services/query-keys";
import IOrganization, { OrganizationType } from "../../shared/IOrganization";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import OrganizationModal from "./OrganizationEditModal";

export const useOrganizationEditModal = (type: OrganizationType, organization?: IOrganization) => {
  const { addModal, closeModal, updateModal } = useModalContext();

  const id = useMemo(() => organization?.id ?? "add", [organization?.id]);
  const isEdit = useMemo(() => id !== "add", [id]);

  const mutateHandler = useCallback(() => updateModal(EModalId.ORGANIZATION_EDIT, { error: null }), [updateModal]);
  const errorHandler = useCallback((error: ErrorResponse) => updateModal(EModalId.ORGANIZATION_EDIT, { error: error.messages.join(", ") }), [updateModal]);

  const closeModalHandler = useCallback(() => closeModal(EModalId.ORGANIZATION_EDIT), [closeModal]);
  const { saveOrUpdate } = useSaveOrUpdate(id, isEdit, type, mutateHandler, closeModalHandler, errorHandler);

  const handler = (): Promise<IOrganization | null> => {
    let title = "";
    if (type === "CUSTOMER") {
      title = isEdit ? "Muokkaa asiakasta" : "Lisää asiakas";
    } else if (type === "ENGINEER") {
      title = isEdit ? "Muokkaa suunnittelijaa" : "Lisää suunnittelija";
    }
    return new Promise((resolve) => {
      addModal({
        id: EModalId.ORGANIZATION_EDIT,
        isOpen: true,
        size: EModalSize.SMALL,
        title,
        backdropNotClose: true,
        content: (
          <OrganizationModal
            onAccept={async (data) => {
              if (organization?.id) {
                data.id = organization.id;
              }
              data.type = type;
              try {
                const organization = await saveOrUpdate(data);
                // console.log(organization);
                resolve(organization);
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              updateModal(EModalId.ORGANIZATION_EDIT, { loading: false, error: null });
              closeModalHandler();
              resolve(null);
            }}
            organization={organization}
          />
        ),
      });
    });
  };

  return handler;
};

const useSaveOrUpdate = (id: string, isEdit: boolean, type: OrganizationType, onMutate: () => void, onSuccess: () => void, onError: (error: ErrorResponse) => void) => {
  const { updateOrganization, saveOrganization } = useOrganizationService();

  const mutationFn = (data: IOrganization) => {
    return isEdit ? updateOrganization(id, data) : saveOrganization(data);
  };

  const {
    mutateAsync: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IOrganization, ErrorResponse, IOrganization>({
    mutationFn,
    onMutate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.organizations, type] });
      onSuccess();
    },
    onError,
  });

  return { saveOrUpdate, isPending, isError, error };
};
