import { useEffect, useState } from "react";
import { IInputField, IInputOptions } from "./Input";
import { getInputData, initForm, validateInputs } from "./input-utils";
import { useCreateInput } from "./useCreateInput";
import { EInputUpdateAction } from "./input-context";

interface IParams<T> {
  data: T;
  inputs: IInputField;
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>;
  options?: IInputOptions;
  updateAction?: EInputUpdateAction;
  initDone?: boolean;
  disabled?: boolean;
}

export function useInputs<T>({
  data,
  inputs,
  setInputs,
  options = {},
  updateAction,
  initDone,
  disabled,
}: IParams<T>) {
  const [showValidation, setShowValidation] = useState(false);

  useEffect(() => {
    if (!initDone && data) {
      initForm(setInputs, data);
    }
  }, [data, setInputs, initDone]);

  const createInput = useCreateInput(inputs, setInputs, {
    updateAction,
    showValidation,
    data: updateAction ? data ?? undefined : undefined,
    disabled,
    ...options,
  });

  const submit = async () => {
    const isValid = await validateInputs(setInputs);
    if (isValid) {
      return getInputData<T>(inputs);
    }
    setShowValidation(true);
    return null;
  };

  return { createInput, submit };
}
