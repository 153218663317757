import { useEffect } from 'react';

import Spinner from '../components/ui/Spinner/Spinner';
import { useAuthContext } from '../context/auth-context';

const LogoutPage: React.FC = () => {
  const { logout } = useAuthContext();
  
  useEffect(() => {
    logout();
  }, [logout]);
  
  return <Spinner />
};

export default LogoutPage;
