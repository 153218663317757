import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { v4 as uuid } from "uuid";
import { useSteelPartOptions } from "../../../../hooks/useSteelPartOptions";
import { useOfferService } from "../../../../services/offers-service";
import IOfferRow from "../../../../shared/IOfferRow";
import Button from "../../../ui/Button/Button";
import { TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import Table from "../../../ui/Table/Table";
import { useOfferRowCalculationContext } from "../offer-row-calculation-context";
import OfferRowCalculationSteelPart, {
  IOfferRowCalculationSteelPartRow,
} from "./OfferRowCalculationSteelPart";
import SteelPartsTotalTable from "./SteelPartsTotalTable";
import ISteelPart from "../../../../shared/ISteelPart";
import { RefHandle } from "../../../../shared/RefHandle";
import { useOfferProductsContext } from "../../OfferProducts/offer-products-context";

export interface IOfferRowCalculationSteelPart {
  price: string;
  weight: string;
  rows: IOfferRowCalculationSteelPartRow[];
}

export enum EInputs {
  pricePerUnit = "pricePerUnit",
  quantity = "quantity",
  price = "price",
  grossProfit2 = "grossProfit2",
  unitPriceList = "unitPriceList",
  netPrice = "netPrice",
  steelPart = "steelPart",
}

export const createSteelPartRow = (): IOfferRowCalculationSteelPartRow => {
  const id = uuid();
  return { id, pricePerUnit: "0", quantity: "0", price: "0", grossProfit2: true, unitPriceList: false, steelPart: { id: "", unitName: "" } as ISteelPart };
}

const OfferRowCalculationSteelParts: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationSteelPart>> = (_, ref) => {
  const { offerRow, updateOfferRow } = useOfferRowCalculationContext();
  const { offerRowCalculationSteelPart: steelPart } = offerRow;

  const { loading: steelPartsLoading, options, steelparts } = useSteelPartOptions();
  const { showControls } = useOfferProductsContext();
  
  const steelPartsMemo = useMemo(() => {
    const opt = [...(options ?? [])];
    const parts = [...steelparts ?? []];
    if(steelPart.rows){
      for (let i = 0; i < steelPart.rows.length; i++) {
        const row = steelPart.rows[i];
        if (row.steelPart) {
          const index = opt?.findIndex((part) => part.value === row.steelPart?.id);
          if (index === -1 && row.steelPart.unitName) {
            opt.push({ value: row.steelPart.id, label: row.steelPart.unitName });
            parts.push({ ...row.steelPart })
          }
        }
      }
  
    }
    return { opt, parts };
  }, [options, steelPart.rows, steelparts]);

  const { addHandler, deleteHandler, updateHandler } = useHandlers(steelPart, updateOfferRow, steelPartsMemo.parts);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (!showControls) return;
      if (e.repeat) return;
      if (e.ctrlKey && e.key === "+") {
        // console.log("add steel part row")
        e.preventDefault();
        addHandler();
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [addHandler, showControls]);

  const steelPartRowsRef = useRef<RefHandle<IOfferRowCalculationSteelPartRow>[]>([]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const steelPartRows = steelPartRowsRef.current;
      const newRows: IOfferRowCalculationSteelPartRow[] = [];
      for (let i = 0; i < steelPartRows.length; i++) {
        const row = steelPartRows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            const steelPartId = rowData.steelPart as any;
          if (steelPartId) {
            const selectedSteelPart = steelPartsMemo.parts?.find((s) => s.id === steelPartId);
            if (selectedSteelPart) {
              rowData.steelPart = selectedSteelPart as any;
            }
          } else {
            rowData.steelPart = {} as ISteelPart;
          }
          newRows.push(rowData);
          }
        }
      }
      return { ...steelPart, rows: [...newRows] };
    },
  }), [steelPart, steelPartsMemo.parts]);

  return (
    <>
      <SteelPartsTotalTable total={offerRow.offerRowCalculationTotal} />
      <Table removeGap>
        <thead>
          <tr>
            <th>Tuote</th>
            <th>Hinta</th>
            <th>Määrä</th>
            <th>Yhteensä</th>
            <th>Kate2</th>
            <th title="yksikköhintalista, tulosteella">YL</th>
            <th>Nettohinta</th>
            {/* <th>Sum</th>
          <th>Sumk2</th>
          <th>TLA_Numero</th>
          <th>TLA_Rivi</th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          <InputContextProvider initDone onAutoUpdate={updateHandler}>
            {steelPart.rows?.map((calculation, i) => (
              <OfferRowCalculationSteelPart
                key={calculation.id}
                calculation={calculation}
                onDelete={() => deleteHandler(calculation.id)}
                steelPartOptions={steelPartsMemo.opt}
                steelPartsLoading={steelPartsLoading}
                ref={rowHandle => steelPartRowsRef.current[i] = rowHandle!}
                showControls={showControls}
              />
            ))}
          </InputContextProvider>
          <tr>
            <td colSpan={2}></td>
            <td>Yhteensä</td>
            <td colSpan={6}>{steelPart.price} €</td>
          </tr>
          {showControls && (
            <tr>
              <td colSpan={9}>
                <Button onClick={addHandler}>Uusi</Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

const useHandlers = (calculation: IOfferRowCalculationSteelPart, updateOfferRow: (newRow: Partial<IOfferRow>) => void, steelparts?: ISteelPart[]) => {
  const { calculateOfferSteelPart } = useOfferService();
  
  const addHandler = useCallback(() => {
    const offerRowCalculationSteelPart = {
      ...calculation,
      rows: [
        ...calculation.rows,
        createSteelPartRow(),
      ],
    };
    updateOfferRow({ offerRowCalculationSteelPart });
  }, [calculation, updateOfferRow]);

  const deleteHandler = useCallback((id: string) => {
    const offerRowCalculationSteelPart = {
      ...calculation,
      rows: calculation.rows.filter((i) => i.id !== id),
    };
    updateOfferRow({ offerRowCalculationSteelPart });
  }, [calculation, updateOfferRow]);

  const updateHandler = useCallback(async (inputName: string, value: TInputValue, action: string, _: any) => {
    if (action === EInputUpdateAction.STEEL_PART) {
      const data = _ as IOfferRowCalculationSteelPartRow;
      if (inputName === EInputs.steelPart) {
        const selectedSteelPart = steelparts?.find((s) => s.id === value);
        if (selectedSteelPart) {
          value = selectedSteelPart as any;
          data.pricePerUnit = selectedSteelPart.netPrice ?? "0";
        } else {
          value = { id: "", unitName: value as string } as any;
        }
      }
      const index = calculation.rows.findIndex(r => r.id === data.id);
      if (index > -1) {
        calculation.rows[index] = {
          ...data,
          [inputName]: formatInputValue(value),
        };
      }
      const offerRowCalculationSteelPart = await calculateOfferSteelPart(calculation);
      updateOfferRow({ offerRowCalculationSteelPart });
    }
    return Promise.resolve(true);
  }, [calculateOfferSteelPart, calculation, steelparts, updateOfferRow]);

  return { addHandler, deleteHandler, updateHandler };
};

export default forwardRef(OfferRowCalculationSteelParts);
