import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useMemo, useState } from "react";
import IElementsCSVResponseDTO from "../../shared/IElementsCSVResponseDTO";
import IProductType from "../../shared/IProductType";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";
import { useConfirmModal } from "../ui/Modal/useConfirmModal";
import PageHeading from "../ui/PageHeading/PageHeading";
import Table from "../ui/Table/Table";

interface IProps {
  onAccept: (data: IElementsCSVResponseDTO) => Promise<IElementsCSVResponseDTO | null>;
  onCancel: () => void;
  onUpload: (file: File) => Promise<IElementsCSVResponseDTO | null>;
}

enum EInputs {
  file = "file",
}

const EliplanModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
  onUpload,
}) => {
  const openConfirmModal = useConfirmModal();

  const [loading, setLoading] = useState(false);
  const { createInput } = useEliplanInputs();
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [uploadResponse, setUploadResponse] = useState<IElementsCSVResponseDTO | null>(null);
  const [submitResponse, setSubmitResponse] = useState<IElementsCSVResponseDTO | null>(null);

  const allowSubmit = useMemo(() => !!uploadResponse?.newItems, [uploadResponse?.newItems]);

  const submitHandler = useCallback(async () => {
    if (!uploadResponse) return;
    setLoading(true);
    const resp = await onAccept(uploadResponse);
    setSubmitResponse(resp);
    setLoading(false);
  }, [uploadResponse, onAccept]);

  const uploadHandler = useCallback(async () => {
    if (!file) {
      setFileError("Tiedosto puuttuu!");
      return;
    }
    setUploadResponse(null);
    setLoading(true);
    const resp = await onUpload(file);
    setUploadResponse(resp);
    setFileError(null);
    setLoading(false);
  }, [file, onUpload]);

  const onDrop = useCallback((inputName: string, acceptedFiles: File[]) => {
    console.log(inputName, acceptedFiles);
    const file = acceptedFiles[0];
    if (file) {
      setFileError(null);
    }
    setFile(file);
  }, []);

  const deleteElementHandler = useCallback(async (event: React.MouseEvent, elementId: string) => {
    if (!uploadResponse) return;
    const isConfirm = event.shiftKey ? true : await openConfirmModal("Oletko varma, että haluat poistaa elementin?");
    if (isConfirm) {
      setUploadResponse(resp => {
        if (!resp) return null;
        const newResponse = {...resp};
        if (newResponse.newItems) {
          newResponse.newItems = newResponse.newItems.filter(element => element.elementId !== elementId);
        }
        return newResponse;
      });
    }
  }, [openConfirmModal, uploadResponse]);

  return (
    <>
      <ModalBody
        style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
      >
        {createInput(EInputs.file, { onDrop })}
        {fileError && <ErrorsAlert errors={[fileError]} />}
        {file && (
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <span>Valittu tiedosto: {file?.name}</span>
            <Button onClick={() => setFile(null)} size={EButtonSize.X_SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" />
          </div>
        )}
        {uploadResponse && (
          uploadResponse.error ? (
            <ErrorsAlert errors={uploadResponse.error.messages} />
          ) : uploadResponse.newItems ? (
            <>
              <Table>
                <caption>
                  <PageHeading variant="h3" noHorizontalMargin>Tunnistetut elementit</PageHeading>
                </caption>
                <thead>
                  <tr>
                    <th>Kerros / lohko</th>
                    <th>Tyyppi</th>
                    <th>Tunnus</th>
                    <th>Pituus</th>
                    <th>Leveys</th>
                    <th>Korkeus</th>
                    <th>m²</th>
                    <th>Paino ton</th>
                    <th>Varaukset</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {uploadResponse.newItems.map(element => (
                    <tr key={element.elementId}>
                      <td>{element.phaseName}</td>
                      <td>{(element.productType as IProductType)?.name}</td>
                      <td>{element.name}</td>
                      <td>{element.length}</td>
                      <td>{element.width}</td>
                      <td>{element.height}</td>
                      <td>{element.area}</td>
                      <td>{element.weightTons}</td>
                      <td>{element.provisionAmountLong}</td>
                      <td><Button onClick={(event) => deleteElementHandler(event, element.elementId)} size={EButtonSize.X_SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" /></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <span>Tunnistettuja elementtejä yhteensä: {uploadResponse.newItems.length} kpl</span>
            </>
          ) : null
        )}
        {uploadResponse?.failedCSVRows && uploadResponse.failedCSVRows.map(warning => <span key={warning}>{warning}</span>)}
        {submitResponse?.error && <ErrorsAlert errors={submitResponse.error.messages} />}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={uploadHandler}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Lue tiedosto
        </Button>
        {allowSubmit && (
          <Button
            onClick={submitHandler}
            style={{ marginRight: "0.5rem" }}
            loading={loading}
          >
            Tallenna
          </Button>
        )}
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalFooter>
    </>
  );
};

const useEliplanInputs = (data?: {}) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.file]: {
      type: EInputType.dropzone,
      label: "Tiedosto",
      value: "",
      canPaste: true,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

export default EliplanModal;
