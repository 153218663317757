import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { useMeshService } from "../services/mesh-service";
import { QueryKey } from "../services/query-keys";
import IMesh from "../shared/IMesh";

export const useMeshOptions = () => {
  const { fetchMeshes } = useMeshService();

  const {
    data: meshes,
    isPending,
    isError,
  } = useQuery<IMesh[], ErrorResponse>({
    queryKey: [QueryKey.meshOptions],
    queryFn: ({ signal }) => fetchMeshes({ signal }, false),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : meshes?.map(
            (m) => ({ value: m.id, label: `${m.unitName} (${m.type})` } as IOption)
          ),
    [meshes, isError]
  );

  return { options, loading: isPending, meshes };
};
