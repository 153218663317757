import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { useConcreteTypeService } from "../services/concreteTypes-service";
import { QueryKey } from "../services/query-keys";
import IConcreteType from "../shared/IConcreteType";

export const useConcreteTypeOptions = () => {
  const { fetchConcreteTypes } = useConcreteTypeService();

  const {
    data: concreteTypes,
    isPending,
    isError,
  } = useQuery<IConcreteType[], ErrorResponse>({
    queryKey: [QueryKey.concreteTypeOptions],
    queryFn: ({ signal }) => fetchConcreteTypes({ signal }, false),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : concreteTypes?.map(
            (c) => ({ value: c.id, label: c.unitName } as IOption)
          ),
    [concreteTypes, isError]
  );

  return { options, loading: isPending, concreteTypes };
};
