import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { useOfferTermService } from "../services/offerTerms-service";
import { QueryKey } from "../services/query-keys";
import IOfferTerm, { EOfferTermType } from "../shared/IOfferTerm";

export const useOfferTermOptions = (type?: EOfferTermType, defaultValue?: boolean) => {
  const { fetchOfferTerms } = useOfferTermService();

  const {
    data: offerTerms,
    isPending,
    isError,
  } = useQuery<IOfferTerm[], ErrorResponse>({
    queryKey: [QueryKey.offerTerms, type, defaultValue],
    queryFn: ({ signal }) => fetchOfferTerms({ signal }, false, type, defaultValue),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : offerTerms?.map(
            (o) => ({ value: o.id, label: o.name } as IOption)
          ),
    [offerTerms, isError]
  );

  return { options, loading: isPending, offerTerms };
};
