import { ErrorResponse } from "../custom-fetch";


export const getErrorMessages = (error: unknown): string[] => {
  if (error instanceof ErrorResponse) {
    return error.messages;
  } else if (error instanceof Error) {
    return [error.message];
  } 
  console.log("uknown error", error);
  return ["Tuntematon virhe."];
}