import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { useInsulationService } from "../services/insulations-service";
import { QueryKey } from "../services/query-keys";
import IInsulation from "../shared/IInsulation";

export const useInsulationOptions = () => {
  const { fetchInsulations } = useInsulationService();

  const {
    data: insulations,
    isPending,
    isError,
  } = useQuery<IInsulation[], ErrorResponse>({
    queryKey: [QueryKey.insulationOptions],
    queryFn: ({ signal }) => fetchInsulations({ signal }, false),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : insulations?.map(
            (i) => ({ value: i.id, label: i.unitName } as IOption)
          ),
    [insulations, isError]
  );

  return { options, loading: isPending, insulations };
};
