import { CSSProperties, forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { useOfferService } from "../../../../services/offers-service";
import { ECommonValue, EInputType, IInputField, TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import { useInputs } from "../../../ui/Input/useInputs";
import Table from "../../../ui/Table/Table";
import { useOfferCalculationTabsContext } from "../offer-calculation-tabs-context";
import { EFactory } from "../../../../shared/IOfferRow";
import { RefHandle } from "../../../../shared/RefHandle";

enum EInputs {
  calculatedAmount = "calculatedAmount",
  offerPrice = "offerPrice",
  offerPriceVAT = "offerPriceVAT",
  privatePerson = "privatePerson",
  installed = "installed",
  offerPriceInstalled = "offerPriceInstalled",
  offerPriceInstalledVAT = "offerPriceInstalledVAT",
  grossProfitDivisor = "grossProfitDivisor",
  grossProfit2Divisor = "grossProfit2Divisor",
}
export interface IOfferCalculationSummary {
  calculatedAmount: string;
	offerPrice: string;
	offerPriceVAT: string;
	privatePerson: false;
	installed: false;
	offerPriceInstalled: string;
	offerPriceInstalledVAT: string;
	rows: IOfferCalculationSummaryRow[];
  grossProfitDivisor: string;
  grossProfit2Divisor: string;
  factoryRows: IOfferCalculationSummaryRow[];
  totalQuantity?: string;
  percentageDifference?: string;
}

export interface IOfferCalculationSummaryRow {
  productGroupId: string;
  productGroupName: string;
  netArea: string;
  grossArea: string
  price: string;
  count: string;
  linealMeters: string;
  cubicMeters: string;
  squareMeters: string;
  factory: EFactory;
  percentage: string;
}

export const createSummary = (): IOfferCalculationSummary => {
  return { calculatedAmount: "", installed: false, privatePerson: false, offerPrice: "", offerPriceVAT: "", offerPriceInstalled: "", offerPriceInstalledVAT: "", rows: [], grossProfitDivisor: "0.3", grossProfit2Divisor: "0.2", factoryRows: [] };
};

const containerStyles: CSSProperties = { marginBottom: 0 };

const OfferCalculationTabSummary: React.ForwardRefRenderFunction<RefHandle<IOfferCalculationSummary>> = (_, ref) => {
  const { summary, setSummary, installations } = useOfferCalculationTabsContext();
  const { createInput, submit } = useSummaryInputs(summary);
  
  const { calculateOfferSummary } = useOfferService();

  const onAutoUpdate = useCallback(async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.OFFER_SUMMARY) {
        const data = _ as IOfferCalculationSummary;
        const calc = await calculateOfferSummary({ oldSummary: {...data}, newSummary: { [inputName]: formatInputValue(value) }, installationDeclaredPrice: installations.installationDeclaredPrice ?? "0" });
        setSummary(calc);
      }
      return Promise.resolve(true);
  }, [calculateOfferSummary, setSummary, installations?.installationDeclaredPrice]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const data = await submit();
      // console.log(data);
      if (!data) return null;
      const newSummary: Partial<IOfferCalculationSummary> = {};
      if (data.offerPrice !== summary.offerPrice) newSummary.offerPrice = data.offerPrice;
      if (data.offerPriceVAT !== summary.offerPriceVAT) newSummary.offerPriceVAT = data.offerPriceVAT;
      if (data.offerPriceInstalled !== summary.offerPriceInstalled) newSummary.offerPriceInstalled = data.offerPriceInstalled;
      if (data.offerPriceInstalledVAT !== summary.offerPriceInstalledVAT) newSummary.offerPriceInstalledVAT = data.offerPriceInstalledVAT;
      if (newSummary.offerPrice || newSummary.offerPriceVAT || newSummary.offerPriceInstalled || newSummary.offerPriceInstalledVAT) {
        return await calculateOfferSummary({ oldSummary: { ...summary, ...data }, newSummary, installationDeclaredPrice: installations.installationDeclaredPrice ?? "0" });
      }
      // console.log("getData", selectData, userData, selectedOrganization);
      return { ...summary, ...data };
    },
  }), [submit, calculateOfferSummary, installations?.installationDeclaredPrice, summary]);

  const priceDifference = (+(summary.offerPrice??"0")-+(summary.calculatedAmount??"0")).toFixed(2);

  const percentageStyle: CSSProperties =
    summary.percentageDifference && +summary.percentageDifference > 0
      ? { color: "green" }
      : summary.percentageDifference && +summary.percentageDifference < 0
      ? { color: "red" }
      : {};

  return (
    <InputContextProvider initDone onAutoUpdate={onAutoUpdate}>
      <Table>
        <tbody>
          <tr>
            <td colSpan={3}>Laskettu summa</td>
            <td>{summary.calculatedAmount} €</td>
          </tr>
          <tr>
            <td colSpan={3}>Prosentti ero</td>
            <td style={percentageStyle}>{summary.percentageDifference} %, {priceDifference} €</td>
          </tr>
          <tr>
            <td colSpan={3}>Yksityinen</td>
            <td>{createInput(EInputs.privatePerson, { containerStyles })}</td>
          </tr>
          <tr>
            <td colSpan={3}>Asennettuna</td>
            <td>{createInput(EInputs.installed, { containerStyles })}</td>
          </tr>
          {summary.installed && (
            <>
              <tr>
                <td colSpan={3}>Yhteensä asennettuna ALV 0%</td>
                <td>
                  {createInput(EInputs.offerPriceInstalled, {
                    containerStyles,
                  })}
                </td>
              </tr>
              {summary.privatePerson && (
                <tr>
                  <td colSpan={3}>Yhteensä asennettuna ALV 25,5%</td>
                  <td>
                    {createInput(EInputs.offerPriceInstalledVAT, {
                      containerStyles,
                    })}
                  </td>
                </tr>
              )}
            </>
          )}
          <tr>
            <td colSpan={3} style={{ fontWeight: "bold" }}>
              Tarjouksen hinta ALV 0%
            </td>
            <td>{createInput(EInputs.offerPrice, { containerStyles })}</td>
          </tr>
          {summary.privatePerson && (
            <tr>
              <td colSpan={3} style={{ fontWeight: "bold" }}>
                Tarjouksen hinta ALV 25,5%
              </td>
              <td>{createInput(EInputs.offerPriceVAT, { containerStyles })}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </InputContextProvider>
  );
};

const useSummaryInputs = (data?: IOfferCalculationSummary) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.calculatedAmount]: {
      type: EInputType.number,
      value: "",
      post: "€",
    },
    [EInputs.offerPrice]: {
      type: EInputType.number,
      value: "",
      post: "€",
    },
    [EInputs.offerPriceVAT]: {
      type: EInputType.number,
      value: "",
      post: "€",
    },
    [EInputs.privatePerson]: {
      type: EInputType.checkbox,
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.installed]: {
      type: EInputType.checkbox,
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.offerPriceInstalled]: {
      type: EInputType.number,
      value: "",
      post: "€",
    },
    [EInputs.offerPriceInstalledVAT]: {
      type: EInputType.number,
      value: "",
      post: "€",
    },
    [EInputs.grossProfitDivisor]: {
      type: EInputType.number,
      value: "",
      post: "%",
      disabled: true,
    },
    [EInputs.grossProfit2Divisor]: {
      type: EInputType.number,
      value: "",
      post: "%",
      disabled: true,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.OFFER_SUMMARY });

  return { createInput, submit };
};

export default forwardRef(OfferCalculationTabSummary);
