import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import { useUserService } from "../../services/users-service";
import IUser from "../../shared/IUser";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";

interface IProps {
  organizationId?: string;
}

const UsersList: React.FC<IProps> = ({ organizationId }) => {
  const { fetchUsers } = useUserService();

  const navigate = useNavigate();
  const {
    data: users,
    isPending,
    isError,
    error,
  } = useQuery<IUser[], ErrorResponse>({
    queryKey: [QueryKey.users, organizationId ?? ""],
    queryFn: ({ signal }) => fetchUsers({ signal, roles: ["ADMIN", "SELLER"], organizationId }),
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!users || users.length === 0) {
    return <p>Ei käyttäjiä</p>;
  }

  const openHandler = (id: string) => {
    navigate(Route.user(id));
  };

  return (
    <Table hover>
      <thead>
        <tr>
          <th>Nimi</th>
          <th>Sähköposti</th>
          <th>Puhelinnumero</th>
          <th>Rooli</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.id} onClick={() => openHandler(user.id)}>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>{user.phoneNumber}</td>
            <td>{user.role}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default UsersList;
