import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IProductGroup from '../shared/IProductGroup';

interface IGetProductGroup extends ISignal {
  id: string;
}

export const useProductGroupService = () => {
  const customFetch = useCustomFetch();

  const fetchProductGroups = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [productGroups] = await customFetch<IProductGroup[]>(`/api/product-groups/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return productGroups;
  }, [customFetch]);
  
  const getProductGroup = useCallback(async ({ signal, id }: IGetProductGroup) => {
    const [productGroup] = await customFetch<IProductGroup>("/api/product-groups/get/" + id, { signal });
    return productGroup;
  }, [customFetch]);
  
  const saveProductGroup = useCallback(async (data: IProductGroup) => {
    const [productGroup] = await customFetch<IProductGroup>("/api/product-groups/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return productGroup;
  }, [customFetch]);
  
  const updateProductGroup = useCallback(async (id: string, data: IProductGroup) => {
    const [productGroup] = await customFetch<IProductGroup>("/api/product-groups/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return productGroup;
  }, [customFetch]);
  
  const deleteProductGroup = useCallback(async (id: string) => {
    await customFetch<IProductGroup>("/api/product-groups/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const archivedUpdateProductGroup = useCallback(async (id: string) => {
    const [productGroup] = await customFetch<IProductGroup>("/api/product-groups/update-archived/" + id, {
      method: "PUT",
    });
    return productGroup;
  }, [customFetch]);
  
  return { fetchProductGroups, getProductGroup, saveProductGroup, updateProductGroup, deleteProductGroup, archivedUpdateProductGroup };
}