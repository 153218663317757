import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../custom-fetch";
import Spinner from "../components/ui/Spinner/Spinner";
import { useOfferService } from "../services/offers-service";
import IOffer from "../shared/IOffer";
import Print from "../components/Print/Print";

type Params = {
  id: string;
};

const OrderPrintPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getOffer } = useOfferService();
  
  const {
    data: offer,
    isLoading,
    isError,
    error,
  } = useQuery<IOffer, ErrorResponse>({
    queryKey: [QueryKey.offers, id],
    queryFn: ({ signal }) => getOffer({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      {isError ? (
        <ErrorsAlert errors={error.messages} />
      ) : isLoading ? (
        <Spinner />
      ) : offer && (
        <Print offer={offer} order={true}/>
      )}
    </>
  );
};

export default OrderPrintPage;
