import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import ISteel from "../../shared/ISteel";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";
import { useSteelService } from "../../services/steels-service";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../App";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Button, { EButtonSize } from "../ui/Button/Button";

const SteelsList: React.FC = () => {
  const { fetchSteels, archivedUpdateSteel } = useSteelService();
  const [filterValue, setFilterValue] = useState<string>('');
  const navigate = useNavigate();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateSteel(id);
      queryClient.setQueryData([QueryKey.steels], (steels: ISteel[]) => 
        steels.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  const openHandler = (id: string) => {
    navigate(Route.steel(id));
  };

  const {
    data: steels,
    isPending,
    isError,
    error,
  } = useQuery<ISteel[], ErrorResponse>({
    queryKey: [QueryKey.steels],
    queryFn: fetchSteels,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!steels || steels.length === 0) {
    return <p>Ei teräksiä</p>;
  }

  const filteredSteels = steels.filter(
    (steel) =>
      steel.diameter?.toString().includes(filterValue)
  );

  return (
    <>
      <Input
        inputName="steelsFilter"
        type={EInputType.text}
        placeholder="Suodata halkaisijalla"
        value={filterValue}
        onChange={(value) => setFilterValue(value.toString().toLowerCase())}
        autoFocus={true}
      />
      <Table hover>
        <thead>
          <tr>
            <th>Halkaisija</th>
            <th>Paino</th>
            <th>Hinta</th>
            <th>Päivitetty</th>
            <th>Arkistoitu</th>
          </tr>
        </thead>
        <tbody>
          {filteredSteels.map((steel) => (
            <tr
              key={steel.id}
              onClick={() => openHandler(steel.id)}
              style={{
                color: steel.archived ? "gray" : "inherit",
                fontStyle: steel.archived ? "italic" : "inherit",
              }}
            >
              <td>{steel.diameter}</td>
              <td>{steel.weight}</td>
              <td>{steel.price} €</td>
              <td>{steel.updatedString}</td>
              <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(steel.id)} onClick={(event)=>archivedUpdateHandler(steel.id, event)} icon={steel.archived ? faEyeSlash : faEye}></Button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default SteelsList;
