import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IAuthAction from '../../shared/store/IAuthAction';
import IAuthState from '../../shared/store/IAuthState';

type ReducerState = IAuthState;
type ReducerAction = IAuthAction;

const initialState: ReducerState = {
  user: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getCurrentUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    getCurrentUserSuccess(state, { payload: { user }}: PayloadAction<ReducerAction>) {
      state.user = user || null;
      state.error = null;
      state.loading = false;
    },
    getCurrentUserFail(state, { payload: { error }}: PayloadAction<ReducerAction>) {
      state.error = error;
      state.user = null;
      state.loading = false;
    }
  }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
