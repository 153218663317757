import { useMutation } from "@tanstack/react-query";

import { useEffect, useState } from "react";
import { Accept } from "react-dropzone";
import { ErrorResponse } from "../../custom-fetch";
import { IImport, useImportService } from "../../services/import-service";
import IConcreteType from "../../shared/IConcreteType";
import Dropzone from "../Dropzone/Dropzone";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Container from "../ui/Container/Container";
import FormButtons from "../ui/FormButtons/FormButtons";
import ConcreteTypesTable from "./ConcreteTypesTable";

const ConcreteTypesImport: React.FC = () => {
  const [file, setFile] = useState<File>();
  const [concreteTypesImport, setConcreteTypesImport] = useState<IImport>();

  const accept: Accept = { "text/csv": [] };

  const { importFile } = useImportService();

  const { mutate, isPending, isError, error, data } = useMutation<
    IImport,
    ErrorResponse,
    FormData
  >({
    mutationFn: (data: FormData) => importFile("ConcreteType", data),
  });

  const submitHandler = async () => {
    setConcreteTypesImport(undefined);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      mutate(formData);
    }
  };

  useEffect(() => {
    if (data) {
      setConcreteTypesImport(data);
    }
  }, [data]);

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  return (
    <>
      <Container>
        {file && <p>{file.name}</p>}
        <div style={{ width: "50%", paddingBottom: "1rem" }}>
          <Dropzone onDrop={(files) => setFile(files[0])} accept={accept} />
            <p>Massatyyppi;Paino;Puristuslujuus;Perushinta</p>
            <a
              href="https://storage.googleapis.com/pielisenbetoni.appspot.com/templates/massat_import_esimerkki.csv"
              target="_blank"
              rel="noreferrer"
            >
              Lataa esimerkkitiedosto tästä
            </a>
        </div>
        <FormButtons
          onSubmit={submitHandler}
          isLoading={isPending}
          submitDisabled={!file}
          submitText="Päivitä"
        />
      </Container>

      {concreteTypesImport && (
        <>
          {concreteTypesImport.failedCSVRows.length > 0 && (
            <Container>
              <h2>Epäonnistuneet</h2>
              {concreteTypesImport.failedCSVRows.map((failedRow, index) => (
                <p key={index}>{failedRow}</p>
              ))}
            </Container>
          )}
          {concreteTypesImport.newItems.length > 0 && (
            <Container>
              <h2>Uudet</h2>
              <ConcreteTypesTable
                concreteTypes={concreteTypesImport.newItems as IConcreteType[]}
              />
            </Container>
          )}
          {concreteTypesImport.updatedItems.length > 0 && (
            <Container>
              <h2>Päivitetyt</h2>
              <ConcreteTypesTable
                concreteTypes={concreteTypesImport.updatedItems as IConcreteType[]}
              />
            </Container>
          )}
          {concreteTypesImport.upToDateItems.length > 0 && (
            <Container>
              <h2>Valmiiksi ajan tasalla</h2>
              <ConcreteTypesTable
                concreteTypes={concreteTypesImport.upToDateItems as IConcreteType[]}
              />
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default ConcreteTypesImport;
