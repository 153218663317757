import { useCallback } from 'react';
import imgLogo from '../../assets/logo.png';
import IWaybillDTO from '../../shared/IWaybillDTO';
import OfferHeader from '../Offers/OfferHeader/OfferHeader';
import SquareFI from '../SquareLogos/SquareFI';
import SquareSFS from '../SquareLogos/SquareSFS';
import classes from './WaybillPrint.module.scss';
import { getFactoryAddress } from '../../shared/IOfferRow';
import Button, { EButtonSize } from '../ui/Button/Button';
import { faRoute } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  waybill: IWaybillDTO;
  logoSfs?: boolean;
  fromQR?: boolean;
}
const WaybillPrintHeader: React.FC<IProps> = ({ waybill, logoSfs, fromQR }) => {
  const { factoryDto: factory } = waybill;

  const openGoogleMapsHandler = useCallback(() => {
      if (!waybill.destination || !waybill.factory) {
        return;
      }
      const url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=${encodeURIComponent(
        getFactoryAddress(waybill.factory)
      )}&destination=${encodeURIComponent(waybill.destination)}`;
      window.open(url, "_blank");
    },
    [waybill.destination, waybill.factory]
  );

  return (
    <>
      {!fromQR &&
      <OfferHeader
        onSubmit={() => window.print()}
        submitText="Tulosta"
        isLoading={false}
        title="Tulosta"
      />
      }
      <div className={classes.formRow}>
        <div style={{width: "25%"}}>
          <div>
            <img src={imgLogo} alt="Pielisen Betoni" style={{ maxHeight: "3rem" }} />
          </div>
          <div>{factory?.streetAddress}</div>
          <div>{factory?.zip} {factory?.city}</div>
        </div>
        <div style={{width: "35%"}}>
          <div>Puh: {factory?.phoneNumber}</div>
          <div>Lähettämö: {factory?.phoneNumberShipping}</div>
          <div>Fax: {factory?.fax}</div>
          <div>Email: {factory?.email}</div>
          <div>Y-tunnus: 2872212-8 ALV rek.</div>
        </div>
        <div style={{width: "30%"}}>
          <div>Lähete: {waybill.waybillId}</div>
          <div>Työmaa: {waybill.projectNumber}</div>
          <div>{waybill.destination} {waybill.destination && waybill.factory && <Button onClick={openGoogleMapsHandler} icon={faRoute} size={EButtonSize.X_SMALL}/>}</div>
          <div>{waybill.orderNumber? 'Tilausnumero: ' + waybill.orderNumber : ''}</div>
        </div>
        <div style={{width: "10%"}}>
          <div style={{marginTop: "1rem"}}>{logoSfs ? <SquareSFS/> : <SquareFI/>}</div>
          <br/>
          <div>{factory?.factoryId}</div>
        </div>
      </div>
    </>
  )
}

export default WaybillPrintHeader;