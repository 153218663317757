import { useCallback, useState } from "react";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { useOfferEditContext } from "../OfferEdit/offer-edit-context";
import OfferCalculationTabSummary from "./OfferCalculationSummary/OfferCalculationSummary";
import OfferCalculationTabInstallations from "./OfferCalculationTabInstallation/OfferCalculationTabInstallations";

enum EOfferTab {
  summary,
  kilometers,
  installation,
}

interface IOfferTab {
  id: EOfferTab;
  name: string;
}

const offerTabs: IOfferTab[] = [
  { id: EOfferTab.summary, name: "Yhteenveto ja hinnoittelu" },
  { id: EOfferTab.installation, name: "Asennus" },
];

const OfferCalculationTabs: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<EOfferTab>(EOfferTab.summary);
  const { summaryRef, installationsRef } = useOfferEditContext();

  const getCurrentTab = useCallback((id: EOfferTab) => {
    switch (id) {
      case EOfferTab.summary:
        return <OfferCalculationTabSummary ref={summaryRef} />;
      case EOfferTab.installation:
        return <OfferCalculationTabInstallations ref={installationsRef} />;
    }
  }, [summaryRef, installationsRef]);

  return (
    <div style={{ borderLeft: "thin solid gray", borderRight: "thin solid gray", padding: "0 2rem", margin: "0 1rem"}}>
      <div style={{ display: "flex", paddingBottom: "1rem", alignItems: "center", whiteSpace: "nowrap" }}>
        {offerTabs.map((tab) => (
          <Button
            key={tab.id}
            onClick={() => setCurrentTab(tab.id)}
            color={EButtonColor.SECONDARY}
            size={EButtonSize.SMALL}
            style={
              tab.id === currentTab ? { background: "#f8f9fa", color: "black" } : {}
            }
          >
            {tab.name}
          </Button>
        ))}
      </div>
      {getCurrentTab(currentTab)}
    </div>
  );
};

export default OfferCalculationTabs;
