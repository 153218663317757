export const HOLLOWCORE_PRODUCT_GROUP_NAME = "L Ontelot";

export const HOLLOWCORE_PRODUCT_GROUP_NAMES = [
  HOLLOWCORE_PRODUCT_GROUP_NAME,
  "L OntLisät",
];

export const WALL_PRODUCT_GROUP_NAMES = [
  "Erikoissei",
  "S Seinä Li",
  "S Seinät",
  "V Välisei",
];

export const SHELL_PLATE_PRODUCT_GROUP_NAME = "L Kuoril";

interface IProductGroup {
	id: string;
	name: string;
	installationCost: string;
	archived: boolean;
}

export default IProductGroup;