import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import IOfferTerm from "../../shared/IOfferTerm";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import { useState } from "react";
import Input, { EInputType } from "../ui/Input/Input";
import { useOfferTermService } from "../../services/offerTerms-service";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../App";
import Button, { EButtonSize } from "../ui/Button/Button";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const OfferTermsList: React.FC = () => {
  const { fetchOfferTerms, archivedUpdateOfferTerm } = useOfferTermService();
  const [filterValue, setFilterValue] = useState<string>('');
  const navigate = useNavigate();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateOfferTerm(id);
      queryClient.setQueryData([QueryKey.offerTerms], (offerTerms: IOfferTerm[]) => 
        offerTerms.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  const openHandler = (id?: string) => {
    if (id) navigate(Route.offerTerm(id));
  };

  const {
    data: offerTerms,
    isPending,
    isError,
    error,
  } = useQuery<IOfferTerm[], ErrorResponse>({
    queryKey: [QueryKey.offerTerms],
    queryFn: fetchOfferTerms,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!offerTerms || offerTerms.length === 0) {
    return <p>Ei sopimustekstejä</p>;
  }

  const filteredOfferTerms = offerTerms.filter(
    (offerTerm) =>
      offerTerm.name?.toLowerCase().includes(filterValue) ||
      offerTerm.text?.toLowerCase().includes(filterValue)
  );

  return (
    <>
      <Input
        inputName="offerTermsFilter"
        type={EInputType.text}
        placeholder="Suodata nimellä tai tekstillä"
        value={filterValue}
        onChange={(value) => setFilterValue(value.toString().toLowerCase())}
        autoFocus={true}
      />
      <Table hover>
        <thead>
          <tr>
            <th>Tyyppi</th>
            <th>Nimi</th>
            <th>Teksti</th>
            <th>Tehdas</th>
            <th>Tuoteryhmä</th>
            <th>Tuote</th>
            <th>Oletus</th>
            <th>Arkistoitu</th>
          </tr>
        </thead>
        <tbody>
          {filteredOfferTerms.map((offerTerm) => (
            <tr
              key={offerTerm.id}
              onClick={() => openHandler(offerTerm.id)}
              style={{
                color: offerTerm.archived ? "gray" : "inherit",
                fontStyle: offerTerm.archived ? "italic" : "inherit",
              }}
            >
              <td>{offerTerm.typeString}</td>
              <td>{offerTerm.name}</td>
              <td>{offerTerm.text.substring(0,500)}</td>
              <td>{offerTerm.factoryString}</td>
              <td>{offerTerm.productGroup?.name}</td>
              <td>{offerTerm.productType?.name}</td>
              <td>{offerTerm.defaultValue && "oletus"}</td>
              <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(offerTerm.id!)} onClick={(event)=>archivedUpdateHandler(offerTerm.id!, event)} icon={offerTerm.archived ? faEyeSlash : faEye}></Button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default OfferTermsList;
