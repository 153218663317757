import { useNavigate } from "react-router-dom";
import { Route } from "../../routes";
import { OrganizationType } from "../../shared/IOrganization";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { useOrganizationsSearch } from "./useOrganizationsSearch";

interface IProps {
  type: OrganizationType;
}

const OrganizationsList: React.FC<IProps> = ({ type }) => {
  const { isError, error, isLoading, organizations } = useOrganizationsSearch(type);

  const navigate = useNavigate();

  if (isError && error) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!organizations || organizations.length === 0) {
    if (type === "CUSTOMER") {
      return <p>Ei asiakkaita</p>;
    }
    if (type === "ENGINEER") {
      return <p>Ei suunnittelijoita</p>;
    }
    return <></>
  }

  const openHandler = (id: string) => {
    if (type === "CUSTOMER") {
      navigate(Route.customer(id));
    } else if (type === "ENGINEER") {
      navigate(Route.engineer(id));
    }
  };

  return (
    <Table hover>
      <thead>
        <tr>
          <th>Numero</th>
          <th>Nimi</th>
          <th>Y-Tunnus</th>
          <th>Asiakasryhmä</th>
        </tr>
      </thead>
      <tbody>
        {organizations.map((organization) => (
          <tr key={organization.id} onClick={() => openHandler(organization.id)}>
            <td>{organization.number}</td>
            <td>{organization.name}</td>
            <td>{organization.businessId}</td>
            <td>{organization.groupString}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default OrganizationsList;
