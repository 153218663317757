import Container from "../components/ui/Container/Container";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import ProductGroupsList from "../components/ProductGroups/ProductGroupsList";

const ProductGroupsPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.productGroup("add"));
  }

  return (
    <>
      <PageHeading onAdd={addHandler}>Tuoteryhmät</PageHeading>
      <Container>
        <ProductGroupsList />
      </Container>
    </>
  );
};

export default ProductGroupsPage;
