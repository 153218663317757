import { AuthProvider, GoogleAuthProvider, OAuthProvider, signInWithPopup } from 'firebase/auth';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth } from '../firebase';
import { Route } from '../routes';
import { useAuthService } from '../services/auth-service';
import IUser from '../shared/IUser';
import { getErrorMessages } from '../utils/error-utils';
import { storage } from '../utils/localstorage-utils';

type AuthContextObj = {
  isAuth: boolean;
  isLoggingIn: boolean;
  user: IUser | null;
  errors: string[] | null;
  loginWithMicrosoft: () => void;
  loginWithGoogle: () => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextObj>({
  isAuth: false,
  user: null,
  isLoggingIn: false,
  errors: null,
  loginWithMicrosoft: () => {},
  loginWithGoogle: () => {},
  logout: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { getAuthUser, authenticate: serviceAutenticate} = useAuthService();

  const [user, setUser] = useState<IUser | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);

  const logout = useCallback(() => {
    storage.deleteAuthentication();
    navigate(Route.auth);
    setUser(null);
  }, [navigate]);


  const setupExpirationTimer = useCallback(() => {
    const expiration = storage.getExpiration();
    if (expiration) {
      const expDate = new Date(expiration);
      const now = new Date();
      setTimeout(() => {
        logout();
      }, expDate.getTime() - now.getTime());
    }
  }, [logout]);

  const tryAutoLogin = useCallback(async () => {
    const token = storage.getToken();
    if (token) {
      setIsLoggingIn(true);
      try {
        const user = await getAuthUser();
        setUser(user);
        setupExpirationTimer();
      } catch(e) {
        setErrors(getErrorMessages(e));
      } finally {
        setIsLoggingIn(false);
      }
    } else {
      setIsLoggingIn(false);
    }
  }, [getAuthUser, setupExpirationTimer]);

  useEffect(() => {
    (async () => {
      setIsLoggingIn(true);
      tryAutoLogin();
    })();
  }, [tryAutoLogin, serviceAutenticate]);

  const authenticate = useCallback(async (provider: AuthProvider) => {
      setIsLoggingIn(true);
      const result = await signInWithPopup(auth, provider);
      if (result) {
        const token = await result.user.getIdToken();
        try {
          await serviceAutenticate(token);
          tryAutoLogin();
        } catch (e) {
          setErrors(getErrorMessages(e));
          setIsLoggingIn(false);
        }
      }
    },
    [tryAutoLogin, serviceAutenticate]
  );


  const loginWithGoogle = useCallback(async () => {
    authenticate(new GoogleAuthProvider());
  }, [authenticate]);

  const loginWithMicrosoft = useCallback(async () => {
    const provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      tenant: "356ed8a0-a436-41e1-b8b0-66792c927142",
    });
    authenticate(provider);
  }, [authenticate]);

  return (
    <AuthContext.Provider
      value={{
        isAuth: user != null,
        isLoggingIn,
        user,
        errors,
        loginWithGoogle,
        loginWithMicrosoft,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
