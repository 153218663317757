import { useCallback, useState } from "react";
import Button, { EButtonColor } from "../ui/Button/Button";
import ModalBody from "../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../ui/Modal/ModalFooter/ModalFooter";

interface IProps {
  onAccept: () => Promise<void>;
  onNavigate: () => void;
  onCancel: () => void;
}

const NavigationBlockerModal: React.FC<IProps> = ({
  onAccept,
  onNavigate,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);

  const acceptHandler = useCallback(async () => {
    setLoading(true);
    await onAccept();
    setLoading(false);
  }, [onAccept]);

  return (
    <>
      <ModalBody>
        <p>Haluatko varmasti poistua, tallentamattomat muutokset menetetään?</p>
      </ModalBody>
      <ModalFooter>
        <Button onClick={acceptHandler} style={{ marginRight: "0.5rem" }} color={EButtonColor.SUCCESS} loading={loading}>
          Tallenna ja poistu
        </Button>
        <Button onClick={onNavigate} style={{ marginRight: "0.5rem" }} color={EButtonColor.DANGER} disabled={loading}>
          Poistu tallentamatta
        </Button>
        <Button onClick={onCancel} color={EButtonColor.SECONDARY}>
          Palaa
        </Button>
      </ModalFooter>
    </>
  );
};

export default NavigationBlockerModal;
