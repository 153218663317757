import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IFactory from '../shared/IFactory';

interface IGetFactory extends ISignal {
  id: string;
}

export const useFactoryService = () => {
  const customFetch = useCustomFetch();

  const fetchFactories = useCallback(async ({ signal }: ISignal) => {
    const [factory] = await customFetch<IFactory[]>(`/api/factories/list`, { signal });
    return factory;
  }, [customFetch]);
  
  const getFactory = useCallback(async ({ signal, id }: IGetFactory) => {
    const [factory] = await customFetch<IFactory>("/api/factories/get/" + id, { signal });
    return factory;
  }, [customFetch]);
  
  const saveFactory = useCallback(async (data: IFactory) => {
    const [factory] = await customFetch<IFactory>("/api/factories/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return factory;
  }, [customFetch]);
  
  const updateFactory = useCallback(async (id: string, data: IFactory) => {
    const [factory] = await customFetch<IFactory>("/api/factories/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return factory;
  }, [customFetch]);
  
  const deleteFactory = useCallback(async (id: string) => {
    await customFetch<IFactory>("/api/factories/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const getFactoryByFactoryId = useCallback(async ({ signal, id }: IGetFactory) => {
    const [factory] = await customFetch<IFactory>("/api/factories/get-factory/" + id, { signal });
    return factory;
  }, [customFetch]);
  
  return { fetchFactories, getFactory, saveFactory, updateFactory, deleteFactory, getFactoryByFactoryId};
}