import { useMutation } from "@tanstack/react-query";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { v4 as uuid } from "uuid";
import { queryClient } from "../../../App";
import { ErrorResponse } from "../../../custom-fetch";
import { IMassUpdateElements, useElementsService } from "../../../services/elements-service";
import { useProductionLinesService } from "../../../services/productionLines-service";
import { QueryKey } from "../../../services/query-keys";
import IElement from "../../../shared/IElement";
import { IProjectPhase } from "../../../shared/IProject";
import IWeeklyProductionRow from "../../../shared/IWeeklyProductionRow";
import { RefHandle } from "../../../shared/RefHandle";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import Button from "../../ui/Button/Button";
import { IOption, TInputValue } from "../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../ui/Input/input-context";
import { formatInputValue } from "../../ui/Input/input-utils";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import Table from "../../ui/Table/Table";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";
import Element, { EInputs } from "./Element";
import { useElementsCreateModal } from "../../ElementsCreateModal/useElementsCreateModal";
import { ElementsCreateDTO } from "../../ElementsCreateModal/ElementCreateEdit";
import InputGroup from "../../ui/InputGroup/InputGroup";
import { useAIModal } from "../../AIModal/useAIModal";
import { useEliplanModal } from "../../EliplanModal/useEliplanModal";
import { useSlabProductTypeOptions } from "../../../hooks/useSlabProductTypeOptions";
import Checkbox from "../../ui/Checkbox/Checkbox";
import { useElementsUpdateModal } from "../../ElementsUpdateModal/useElementsUpdateModal";

interface IProps {
  phase?: IProjectPhase;
  phaseOptions: IOption[];
}

const createElement = (projectId: string, phase?: IProjectPhase): IElement => {
  const elementId = uuid();
  return {
    id: "",
    elementId,
    length: "",
    width: "",
    height: "",
    weightTons: "",
    name: "",
    productType: undefined,
    productionStatus: "",
    measurements: "",
    provisions: [],
    finishing: "",
    projectId,
    phaseId: phase?.phaseId ?? undefined,
    phaseName: phase?.name ?? undefined,
    factory: undefined,
  };
};

const colSpan = 22; // amount of columns

const Elements: React.ForwardRefRenderFunction<RefHandle<IElement[]>, IProps> = ({ phase, phaseOptions }, ref) => {
  const { id: projectId, weeklyProductionRows, elements, setElements, setIsProjectDirty } = useProjectEditContext();

  const { saveMutate, isSaving, isSaveError, saveError } = useSave(projectId);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(projectId);
  const { copyMutate, isCopying, isCopyError, copyError } = useCopy(projectId);
  const { createWeeklyMutate, isCreatingWeekly, isCreateWeeklyError, createWeeklyError } = useCreateProjectElementsFromWeeklyProductionRows(projectId);
  const { createNewMutate, isCreatingNew, isCreateNewError, createNewError } = useCreateProjectElementsNew(projectId);
  const { createProductionLinesMutate, isCreatingProductionLine, isCreateProductionLineError, createProductionLineError } = useCreateProjectProductionLines(projectId);
  const { options: productTypesOptions, loading: productTypesLoading, slabProductTypes } = useSlabProductTypeOptions();
  const { massUpdateElementMutate, isUpdatingElements, isUpdateElementsError, updateElementsError } = useMassUpdateElements();

  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [copyingId, setCopyingId] = useState<string | null>(null);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const openConfirmModal = useConfirmModal();
  const openElementsCreateModal = useElementsCreateModal();
  const openElementsUpdateModal = useElementsUpdateModal();
  const openAIModal = useAIModal("Element", projectId);
  const openEliplanModal = useEliplanModal(projectId);

  const saveHandler = useCallback(async () => {
    const newElement = await saveMutate(createElement(projectId, phase));
    if (newElement) {
      setElements(elements => [...elements, newElement]);
    }
  }, [saveMutate, projectId, phase, setElements]);

  const createElementsFromWeeklyProductionRowsHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat luoda elementit?");
    if (isConfirm) {
      if (weeklyProductionRows.length > 0) {
        try {
          const newElements = await createWeeklyMutate(weeklyProductionRows);
          if (newElements) {
            setElements([...newElements]);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [openConfirmModal, weeklyProductionRows, createWeeklyMutate, setElements]);

  const deleteHandler = useCallback(async (event: React.MouseEvent, id: string) => {
    const isConfirm = event.shiftKey ? true : await openConfirmModal("Oletko varma, että haluat poistaa rivin?");
    if (isConfirm) {
      setDeletingId(id);
      const deleteSuccess = await deleteMutate(id);
      if (deleteSuccess) {
        setElements(elements => elements.filter(element => element.id !== id));
      }
      setDeletingId(null);
    }
  }, [deleteMutate, openConfirmModal, setElements]);

  const copyHandler = useCallback(async (element: IElement) => {
    setCopyingId(element.id);
    const newElement = await copyMutate(element);
    if (newElement) {
      setElements(elements => [...elements, newElement]);
    }
    setCopyingId(null);
  }, [copyMutate, setElements]);

  const updateHandler = useCallback(async (inputName: string, value: TInputValue, action: string, _: any) => {
    if (action === EInputUpdateAction.ELEMENTS) {
      const data = _ as IElement;
      console.log(data);
      const newElements = [...elements];
      const index = newElements.findIndex(r => r.id === data.id);
      if (index > -1) {
        if (inputName === EInputs.phaseId) {
          const selectedPhase = phaseOptions.find(phase => phase.value === value);
          data.phaseId = selectedPhase?.value ?? undefined;
          data.phaseName = selectedPhase?.label ?? undefined;
        } else if (inputName === EInputs.productType) {
          const selectedProductType = slabProductTypes?.find(productType => productType.id === value);
          data.productType = selectedProductType ?? undefined;
        } else {
          newElements[index] = {
            ...data,
            [inputName]: formatInputValue(value),
          };
        }
      }
      setElements(newElements);
      setIsProjectDirty(dirty => ({ ...dirty, elements: true }));
    }
    return Promise.resolve(true);
  }, [elements, phaseOptions, setElements, setIsProjectDirty, slabProductTypes]);

  const updateElementHandler = useCallback((newElement: IElement) => {
    setElements(elements => elements.map(element => element.id === newElement.id ? newElement : element))
  }, [setElements]);

  const elementRowsRef = useRef<RefHandle<IElement>[]>([]);

  const getData = useCallback(async () => {
    const rows = elementRowsRef.current;
    const newRows: IElement[] = [];
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (row) {
        const rowData = await row.getData();
        if (rowData) {
          newRows.push(rowData);
        }
      }
    }
    return newRows;
  }, []);

  const createProductionLineHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat luoda pedit?");
    if (isConfirm) {
      const data = await getData();
      if (data) {
        const newData = data.filter(item => item.isPlanned && !item.productionLineId);
        try {
          const productionLineElements = await createProductionLinesMutate(newData);
          if (productionLineElements) {
            setElements(elements => elements.map(element => productionLineElements.find(productionLineElement => productionLineElement.id === element.id) ?? element));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  }, [openConfirmModal, getData, createProductionLinesMutate, setElements]);
  
  const elementsCreateHandler = useCallback(async () => {
    const data = await openElementsCreateModal(elements, phaseOptions);
    if (data) {
      try {
        await createNewMutate(data);
      } catch (error) {
        console.error(error);
      }
    }
  }, [openElementsCreateModal, elements, phaseOptions, createNewMutate]);

  const elementsAiHandler = useCallback(async () => {
    const data = await openAIModal();
    if (data) {
      try {
        // await createNewMutate(data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    }
  }, [openAIModal]);

  const eliplanHandler = useCallback(async () => {
    const data = await openEliplanModal();
    if (data) {
      try {
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    }
  }, [openEliplanModal]);

  const elementsUpdateHandler = useCallback(async () => {
    const data = await openElementsUpdateModal(phaseOptions);
    if (data) {
      try {
        console.log(data);
        const updatedElements = await massUpdateElementMutate({ element: data, elementIds: selectedIds });
        if (updatedElements) {
          setElements(elements => elements.map(element => {
            const newElement = updatedElements.find(el => element.id === el.id);
            // 4774560524140544
            console.log(newElement, element.id);
            return newElement ? { ...element, ...newElement } : element;
          }));
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [massUpdateElementMutate, openElementsUpdateModal, phaseOptions, selectedIds, setElements]);

  useImperativeHandle(ref, () => ({ getData }), [getData]);

  const errorMessages = combineErrors(
    { isError: isSaveError, error: saveError },
    { isError: isDeletingError, error: deletingError },
    { isError: isCopyError, error: copyError },
    { isError: isCreateWeeklyError, error: createWeeklyError },
    { isError: isCreateNewError, error: createNewError },
    { isError: isCreateProductionLineError, error: createProductionLineError },
    { isError: isUpdateElementsError, error: updateElementsError },
  );

  const isAllSelected = useMemo(() => selectedIds.length === elements.length, [elements.length, selectedIds.length]);

  const unselectHandler = useCallback((id: string) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds => selectedIds.filter(selected => selected !== id));
    } else {
      setSelectedIds((selectedIds) => [...selectedIds, id]);
    }
  }, [selectedIds]);

  return (
    <>
      <Table>
        {elements && elements.length > 0 && (
          <thead>
            <tr>
              <th>
                <Checkbox name={`checkbox-all`} value={isAllSelected ? [""] : selectedIds} options={[{ value: "" }]} onChange={() => isAllSelected ? setSelectedIds([]) : setSelectedIds([...elements].map(el => el.id))} />
              </th>
              <th style={{ width: "80px" }}>Kerros / lohko</th>
              <th style={{ width: "125px" }}>Tyyppi</th>
              <th style={{ width: "125px" }}>Tunnus</th>
              <th style={{ width: "80px" }}>P</th>
              <th style={{ width: "80px" }}>O</th>
              <th style={{ width: "125px" }}>Pituus</th>
              <th style={{ width: "125px" }}>Halkaisija</th>
              <th style={{ width: "125px" }}>Korkeus</th>
              <th style={{ width: "80px" }}>Paino ton</th>
              <th style={{ width: "125px" }}>Varaukset</th>
              <th style={{ width: "125px" }}></th>
              <th style={{ width: "150px" }}>Karkea kuorma Vko</th>
              <th>→</th>
              <th style={{ width: "100px" }}>Toim</th>
              <th style={{ width: "80px" }}>Petinro</th>
              <th title="Pedin valu pvm" style={{ width: "80px" }}>Valu pvm</th>
              <th title="Pedin tehdas" style={{ width: "80px" }}>Tehdas</th>
              <th title="Varastopaikka" style={{ width: "100px" }}>Varasto</th>
              <th title="Rahtikirjan numero" style={{ width: "100px" }}>Rahtikirja</th>
              <th title="Toimituspäivä" style={{ width: "100px" }}>Toimitus</th>
              <th></th>
            </tr>
          </thead>
        )}
        <tbody>
          <InputContextProvider initDone onAutoUpdate={updateHandler}>
            {elements?.map((element, i) => (
              <Element
                key={element.id}
                element={element}
                ref={(rowHandle) => (elementRowsRef.current[i] = rowHandle!)}
                hidden={phase?.phaseId ? phase.phaseId !== element.phaseId : false}
                phaseOptions={phaseOptions}
                onDelete={(event) => deleteHandler(event, element.id)}
                isDeleting={isDeleting && deletingId === element.id}
                onCopy={() => copyHandler(element)}
                isCopying={isCopying && copyingId === element.id}
                onUpdate={updateElementHandler}
                isActive={activeId === element.id}
                onClick={() => setActiveId(element.id)}
                onRemoveFocus={() => setActiveId(null)}
                productTypesOptions={productTypesOptions}
                productTypesLoading={productTypesLoading}
                slabProductTypes={slabProductTypes}
                colSpan={colSpan}
                isSelected={selectedIds.includes(element.id)}
                onSelect={() => unselectHandler(element.id)}
              />
            ))}
          </InputContextProvider>
          {/* <tr>
            <td style={{ borderTop: 0, width: "1rem" }}></td>
            <td colSpan={9}>
              <ProjectTabs phaseOptions={phaseOptions} />
            </td>
          </tr> */}
          <tr>
            <td colSpan={colSpan}>
              <InputGroup>
                <Button onClick={saveHandler} loading={isSaving}>Lisää uusi elementti</Button>
                <Button onClick={elementsCreateHandler} loading={isCreatingNew}>Luo elementtejä</Button>
                {!elements?.length && <Button onClick={createElementsFromWeeklyProductionRowsHandler} loading={isCreatingWeekly}>Luo elementit tuotannon riveistä</Button>}
                {elements && elements.length > 0 && elements.some(element => element.isPlanned && !element.productionLineId) && <Button onClick={createProductionLineHandler} loading={isCreatingProductionLine}>Luo pedit elementeistä</Button>}
                <Button onClick={elementsAiHandler}>Kysy AI:lta</Button>
                <Button onClick={eliplanHandler}>Syötä Eliplan</Button>
                {selectedIds.length > 0 && <Button onClick={elementsUpdateHandler} loading={isUpdatingElements}>Muokkaa valittuja elementtejä</Button>}
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </Table>
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
    </>
  );
};

const useSave = (projectId: string) => {
  const { saveElement } = useElementsService();

  const {
    mutateAsync: saveMutate,
    isPending: isSaving,
    isError: isSaveError,
    error: saveError,
  } = useMutation<IElement, ErrorResponse, IElement>({
    mutationFn: (data) => saveElement(projectId, data),
    onSuccess: (data) => {
      const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      queryClient.setQueryData([QueryKey.elements, projectId], [
        ...oldData,
        data,
      ]);
    },
  });

  return { saveMutate, isSaving, isSaveError, saveError };
};

const useDelete = (projectId: string) => {
  const { deleteElement } = useElementsService();

  const {
    mutateAsync: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse, string>({
    mutationFn: (id) => deleteElement(id),
    // onSuccess: (_, id) => {
    //   const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
    //   queryClient.setQueryData([QueryKey.elements, projectId], oldData.filter(row => row.id !== id));
    // },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

const useCopy = (projectId: string) => {
  const { copyElement } = useElementsService();

  const {
    mutateAsync: copyMutate,
    isPending: isCopying,
    isError: isCopyError,
    error: copyError,
  } = useMutation<IElement, ErrorResponse, IElement>({
    mutationFn: (data) => copyElement(data),
    // onSuccess: (data) => {
    //   const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
    //   queryClient.setQueryData([QueryKey.elements, projectId], [
    //     ...oldData,
    //     data,
    //   ]);
    // },
  });

  return { copyMutate, isCopying, isCopyError, copyError };
};

const useCreateProjectElementsFromWeeklyProductionRows = (projectId: string) => {
  const { createProjectElementsFromWeeklyProductionRows } = useElementsService();

  const {
    mutateAsync: createWeeklyMutate,
    isPending: isCreatingWeekly,
    isError: isCreateWeeklyError,
    error: createWeeklyError,
  } = useMutation<IElement[], ErrorResponse, IWeeklyProductionRow[]>({
    mutationFn: (data) => createProjectElementsFromWeeklyProductionRows(projectId, data),
    onSuccess: (data) => {
      const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      queryClient.setQueryData([QueryKey.elements, projectId], [
        ...oldData,
        ...data,
      ]);
    },
  });

  return { createWeeklyMutate, isCreatingWeekly, isCreateWeeklyError, createWeeklyError };
};

const useCreateProjectElementsNew = (projectId: string) => {
  const { createProjectElementsNew } = useElementsService();

  const {
    mutateAsync: createNewMutate,
    isPending: isCreatingNew,
    isError: isCreateNewError,
    error: createNewError,
  } = useMutation<IElement[], ErrorResponse, ElementsCreateDTO>({
    mutationFn: (data) => createProjectElementsNew(projectId, data),
    onSuccess: (data) => {
      const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      queryClient.setQueryData([QueryKey.elements, projectId], [
        ...oldData,
        ...data,
      ]);
    },
  });

  return { createNewMutate, isCreatingNew, isCreateNewError, createNewError };
};

const useCreateProjectProductionLines = (projectId: string) => {
  const { createProductionLines } = useProductionLinesService();

  const {
    mutateAsync: createProductionLinesMutate,
    isPending: isCreatingProductionLine,
    isError: isCreateProductionLineError,
    error: createProductionLineError,
  } = useMutation<IElement[], ErrorResponse, IElement[]>({
    mutationFn: (data) => createProductionLines(projectId, data),
    onSuccess: () => {
      // const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      // queryClient.setQueryData([QueryKey.elements, projectId], [...oldData.map(element => ({...element, productionLineId }))]);
      // queryClient.invalidateQueries({ queryKey: [QueryKey.elements, projectId] });
      // queryClient.setQueryData([QueryKey.elements, projectId], elements);
    },
  });

  return { createProductionLinesMutate, isCreatingProductionLine, isCreateProductionLineError, createProductionLineError };
};

const useMassUpdateElements = () => {
  const { massUpdateElements } = useElementsService();

  const {
    mutateAsync: massUpdateElementMutate,
    isPending: isUpdatingElements,
    isError: isUpdateElementsError,
    error: updateElementsError,
  } = useMutation<IElement[], ErrorResponse, IMassUpdateElements>({
    mutationFn: (data) => massUpdateElements(data),
  });

  return { massUpdateElementMutate, isUpdatingElements, isUpdateElementsError, updateElementsError };
};

export default forwardRef(Elements);
