import { useNavigate } from 'react-router-dom';
import Container from '../components/ui/Container/Container';
import PageHeading from '../components/ui/PageHeading/PageHeading';
import UsersList from '../components/Users/UsersList';
import { Route } from '../routes';


const UsersPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.user("add"));
  }

  return (
    <>
    <PageHeading onAdd={addHandler}>Käyttäjät</PageHeading>
    <Container>
      <UsersList />
    </Container>
    </>
  )
}

export default UsersPage;