import { createContext, ReactNode, useContext } from "react";

interface INavigationBlockerContext {
  showNavigationBlockerModal: (onAccept: () => Promise<boolean>) => Promise<boolean>;
  showBlocker: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavigationBlockerContext = createContext<INavigationBlockerContext>({
  showNavigationBlockerModal: () => Promise.reject(),
  showBlocker: false,
  setIsDirty: () => {},
});

export const useNavigationBlockerContext = () =>
  useContext(NavigationBlockerContext);

const NavigationBlockerContextProvider: React.FC<{
  value: INavigationBlockerContext;
  children: ReactNode;
}> = ({ value, children }) => (
  <NavigationBlockerContext.Provider value={value}>
    {children}
  </NavigationBlockerContext.Provider>
);

export default NavigationBlockerContextProvider;
