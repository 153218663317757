import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { CSSProperties, forwardRef, useImperativeHandle, useState } from "react";
import ISteelPart from "../../../../shared/ISteelPart";
import { RefHandle } from "../../../../shared/RefHandle";
import Button, { EButtonColor, EButtonSize } from "../../../ui/Button/Button";
import { ECommonValue, EInputType, IInputField, IOption } from "../../../ui/Input/Input";
import { EInputUpdateAction } from "../../../ui/Input/input-context";
import { useInputs } from "../../../ui/Input/useInputs";
import { EInputs } from "./OfferRowCalculationSteelParts";

export interface IOfferRowCalculationSteelPartRow {
  id: string;
  pricePerUnit: string;
  quantity: string;
  price: string;
  grossProfit2: boolean;
  unitPriceList: boolean;
  steelPart: ISteelPart;
}

interface IProps {
  calculation: IOfferRowCalculationSteelPartRow;
  onDelete: () => void;
  steelPartOptions?: IOption[];
  steelPartsLoading?: boolean;
  showControls: boolean;
}

const containerStyles: CSSProperties = { width: "125px" };

const OfferRowCalculationSteelPart: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationSteelPartRow>, IProps> = ({ calculation, onDelete, steelPartOptions, steelPartsLoading, showControls }, ref) => {
  const { createInput, submit } = useSteelPartInputs(calculation, showControls);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const newData = await submit();
      if (!calculation) return null;
      return { ...calculation, ...newData };
    },
  }), [calculation, submit]);

  return (
    <tr>
      <td>{createInput(EInputs.steelPart, { options: steelPartOptions, loading: steelPartsLoading, containerStyles: { width: "420px" } })}</td>
      <td>{createInput(EInputs.pricePerUnit, { containerStyles })}</td>
      <td>{createInput(EInputs.quantity, { containerStyles })}</td>
      <td>{calculation?.price}</td>
      <td>{createInput(EInputs.grossProfit2)}</td>
      <td>{createInput(EInputs.unitPriceList)}</td>
      <td>{calculation?.steelPart?.netPrice}</td>
      {/* <td></td>
      <td></td>
      <td></td>
      <td></td> */}
      <td>
        {showControls && (
          <Button
            onClick={onDelete}
            size={EButtonSize.SMALL}
            color={EButtonColor.DANGER}
            icon={faTrash}
          />
        )}
      </td>
    </tr>
  );
};

const useSteelPartInputs = (data?: IOfferRowCalculationSteelPartRow, showControls?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.steelPart]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
      isCreatable: true,
    },
    [EInputs.pricePerUnit]: {
      type: EInputType.number,
      value: "",
      post: "€"
    },
    [EInputs.quantity]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.price]: {
      type: EInputType.static,
      value: "",
    },
    [EInputs.grossProfit2]: {
      type: EInputType.checkbox,
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.unitPriceList]: {
      type: EInputType.checkbox,
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
    [EInputs.netPrice]: {
      type: EInputType.static,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.STEEL_PART, disabled: !showControls });

  return { createInput, submit };
};

export default forwardRef(OfferRowCalculationSteelPart);
