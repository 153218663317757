import { IOfferRowCalculationTotal } from "../../../../shared/IOfferRow";
import Table from "../../../ui/Table/Table";
import { IOfferRowCalculationSteel } from "./OfferRowCalculationSteels";

interface IProps {
  calculation: IOfferRowCalculationSteel;
  total: IOfferRowCalculationTotal;
}

const SteelTotalTable: React.FC<IProps> = ({ calculation, total }) => {
  return (
    <Table removeGap>
      <thead>
        <tr>
          <th>yhteensä, kg</th>
          <th>yhteensä, €</th>
          <th>kg /m²</th>
          <th>kg /m³</th>
          <th>€ /m²</th>
          <th>€ /m³</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{calculation.weight} kg</td>
          <td>{calculation.price} €</td>
          <td>{total.steelSquareMeterWeight}</td>
          <td>{total.steelCubicMeterWeight}</td>
          <td>{total.steelSquareMeterPrice} €</td>
          <td>{total.steelCubicMeterPrice} €</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default SteelTotalTable;
