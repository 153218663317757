import { IOption } from "../components/ui/Input/Input";
import IUser from "./IUser";

export type OrganizationType = "CUSTOMER" | "ENGINEER";

export enum EGroup {
  G0 = "G0",
  G1 = "G1",
  G10 = "G10",
  G20 = "G20",
  G30 = "G30",
  G40 = "G40",
  G50 = "G50",
  G60 = "G60",
  G70 = "G70",
  G80 = "G80",
  G90 = "G90",
  G95 = "G95",
};

export const GROUP_OPTIONS: IOption[] = [
  { value: EGroup.G0, label: "Ei ryhmää" },
  { value: EGroup.G1, label: "Yritykset" },
  { value: EGroup.G10, label: "Isot rakennusliikkeet" },
  { value: EGroup.G20, label: "Keskisuuret rakennusliikkeet" },
  { value: EGroup.G30, label: "Sekalaiset Rakennusliikkeet" },
  { value: EGroup.G40, label: "Valtio, kunnat Teollisuuskylät" },
  { value: EGroup.G50, label: "Suoraan ostajat" },
  { value: EGroup.G60, label: "Betoniyritykset, elementtien välittäjät" },
  { value: EGroup.G70, label: "Rakennuttajat Konsultit" },
  { value: EGroup.G80, label: "Omat" },
  { value: EGroup.G90, label: "Yksityiset" },
  { value: EGroup.G95, label: "Asunto osakeyhtiöt" },
];

interface IOrganization {
  id: string;
  number: string;
  name: string;
  businessId: string;
  contactPersons: IUser[];
  type: OrganizationType;
  streetAddress: string;
  zip: string;
  city: string;
  group: EGroup;
  groupString: string;
}

export default IOrganization;
