import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import OrganizationEdit from "../components/Organizations/OrganizationEdit";
import Button, { EButtonSize } from "../components/ui/Button/Button";
import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import Spinner from "../components/ui/Spinner/Spinner";
import UsersList from "../components/Users/UsersList";
import { ErrorResponse } from "../custom-fetch";
import { Route } from "../routes";
import { useOrganizationService } from "../services/organizations-service";
import { QueryKey } from "../services/query-keys";
import IOrganization from "../shared/IOrganization";
import OffersListCustomer from "../components/Offers/OffersListCustomer";

type Params = {
  id: string;
};

const CustomerPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getOrganization } = useOrganizationService();
  const navigate = useNavigate();

  const {
    data: organization,
    isLoading,
    isError,
    error,
  } = useQuery<IOrganization, ErrorResponse>({
    queryKey: [QueryKey.customers, id],
    queryFn: ({ signal }) => getOrganization({ signal, id: id! }),
    enabled: id !== "add",
  });

  const addHandler = () => {
    navigate(Route.user(`add?organizationId=${id}`));
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <h1 style={{ margin: "1rem" }}>Asiakas</h1>
        {id !== "add" && (
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Button onClick={() => navigate(Route.offer(`add?customerId=${id}`))} size={EButtonSize.SMALL}>Tee tarjous</Button>
            {" "}
            <Button onClick={() => navigate(`/?customerId=${id}&customerName=${organization?.name}`)} size={EButtonSize.SMALL}>Listaa tarjoukset</Button>
        </div>
        )}
      </div>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <OrganizationEdit
            id={id!}
            organization={organization}
            type="CUSTOMER"
          />
        )}
      </Container>
      {organization?.id && (
        <>
          <PageHeading onAdd={addHandler}>Yhteyshenkilöt</PageHeading>
          <Container>
            <UsersList organizationId={organization.id} />
          </Container>
          <h1 style={{margin: "1rem"}}>Tarjoukset</h1>
          <Container>
            <OffersListCustomer organizationId={organization.id} />
          </Container>
        </>
      )}
    </>
  );
};

export default CustomerPage;
