import TransportationCostsImport from "../components/TransportationCosts/TransportationCostImport";

const TransportationCostsImportPage: React.FC = () => {
  return (
    <>
      <h1 style={{ margin: "1rem" }}>Kuljetushinnat Import</h1>
      <TransportationCostsImport />
    </>
  );
};

export default TransportationCostsImportPage;
