import { useQuery } from "@tanstack/react-query";

import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert from "../ErrorsAlert/ErrorsAlert";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import { useNavigate } from "react-router-dom";
import { useFactoryService } from "../../services/factory-service";
import IFactory from "../../shared/IFactory";

const FactoryList: React.FC = () => {
  const { fetchFactories } = useFactoryService();
  const navigate = useNavigate();

  const openHandler = (id: string) => {
    navigate(Route.factory(id));
  };

  const {
    data: factories,
    isPending,
    isError,
    error,
  } = useQuery<IFactory[], ErrorResponse>({
    queryKey: [QueryKey.factories],
    queryFn: fetchFactories,
    staleTime: 5000,
  });

  if (isError) {
    return <ErrorsAlert errors={error.messages} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  if (!factories || factories.length === 0) {
    return <p>Ei tehtaita</p>;
  }

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>Tunnus</th>
            <th>Nimi</th>
          </tr>
        </thead>
        <tbody>
          {factories.map((factory) => (
            <tr key={factory.id} onClick={() => openHandler(factory.id)}>
              <td>{factory.factoryId}</td>
              <td>{factory.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default FactoryList;
