import React, { useState } from "react";
import { Accept } from "react-dropzone";
import Dropzone from "../../../Dropzone/Dropzone";
import Button, { EButtonColor } from "../../../ui/Button/Button";
import ModalBody from "../../../ui/Modal/ModalBody/ModalBody";

interface IProps {
  onAccept: (formData: FormData) => void;
  onCancel: () => void;
}

const OfferRowsImportModal: React.FC<IProps> = ({ onAccept, onCancel }) => {
  const [file, setFile] = useState<File>();

  const accept: Accept = { "text/csv": [] };

  const submitHandler = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      onAccept(formData);
    }
  };

  return (
    <>
      <ModalBody>
        {file && <p>{file.name}</p>}
        <div style={{ paddingBottom: "1rem" }}>
          <Dropzone onDrop={(files) => setFile(files[0])} accept={accept} />
            <p>Selite;kpl;määrä;yksikkö</p>
            <a
              href="https://storage.googleapis.com/pielisenbetoni.appspot.com/templates/tuoterivi_import_esimerkki.csv"
              target="_blank"
              rel="noreferrer"
            >
              Lataa esimerkkitiedosto tästä
            </a>
        </div>
        <Button
          onClick={submitHandler}
          disabled={!file}
          style={{ marginRight: "0.5rem" }}
        >
          Vahvista
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          Peruuta
        </Button>
      </ModalBody>
    </>
  );
};

export default OfferRowsImportModal;