import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import { storage } from "../../utils/localstorage-utils";
import { IProjectsSearch } from "./ProjectsSearch";
import { useProjectsService } from "../../services/projects-service";
import IProject from "../../shared/IProject";

export const useProjectsSearch = (customerId?: string | null) => {
  const searchJson = useMemo(() => storage.getProjectsSearch(), []);
  const initialSearch = useMemo(() => searchJson ? JSON.parse(searchJson ?? "") : null, [searchJson]);

  const [search, setSearch] = useState<IProjectsSearch | null>({ ...initialSearch, customerId: customerId ?? initialSearch?.customerId });
  const { fetchProjects } = useProjectsService();

  const {
    data: projects,
    isPending,
    isRefetching,
    isError,
    error,
    refetch,
  } = useQuery<IProject[], ErrorResponse>({
    queryKey: [QueryKey.projects],
    queryFn: ({ signal }) => fetchProjects({ signal, search }),
    staleTime: 5000,
  });

  return {
    projects,
    isLoading: isPending || isRefetching,
    isError,
    error,
    search,
    setSearch,
    refetch,
  };
};
