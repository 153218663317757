import classes from './PrintCheckbox.module.scss';

interface IProps {
  checked: boolean;
}

const PrintCheckbox: React.FC<IProps> = ({ checked }) => {
  return (
    <label className={classes.checkboxContainer}>
      <input type="checkbox" readOnly style={{ pointerEvents: 'none' }} checked={checked}/>
      <span className={classes.checkmark}/>
    </label>
  );
};

export default PrintCheckbox