import {
  faFile,
  faFilePdf,
  faImage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@tanstack/react-query";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { useAttachmentsService } from "../../services/attachments-service";
import { Kind } from "../../services/kind";
import { QueryKey } from "../../services/query-keys";
import IAttachment from "../../shared/IAttachment";
import { formatDate } from "../../utils/date-utils";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import { useProjectEditContext } from "../Projects/ProjectEdit/project-edit-context";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import IFrame from "../ui/IFrame/IFrame";
import { useConfirmModal } from "../ui/Modal/useConfirmModal";
import Spinner from "../ui/Spinner/Spinner";
import Table from "../ui/Table/Table";
import { AttachmentsReturnType, FlexDirection, IAttachmentsHandle } from "./Attachments";

interface IProps {
  kind: Kind;
  parentId: string;
  noScroll?: boolean;
  flexDirection?: FlexDirection;
  isUploading?: boolean;
  iframeStyles?: React.CSSProperties;
  uploadDisabled?: boolean;
  successCallbackFn?: (returnType: AttachmentsReturnType) => void;
}

const AttachmentsList: React.ForwardRefRenderFunction<IAttachmentsHandle, IProps> = ({ kind, parentId, noScroll, flexDirection, isUploading, iframeStyles, uploadDisabled, successCallbackFn }, ref) => {
  const openConfirmModal = useConfirmModal();

  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [activeAttachment, setActiveAttachment] = useState<IAttachment | null>(null);
  const [blob, setBlob] = useState<Blob | null>(null);
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    return () => {
      setDeletingId(null);
      setActiveAttachment(null);
      setBlob(null);
      setInitDone(false);
    };
  }, [kind, parentId]);

  const { attachments, isFetching, isFetchError, fetchError } = useFetch(kind, parentId);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(kind, parentId, successCallbackFn);
  const { downloadMutate, isDownloading, isDownloadError, downloadError } = useDownload(kind);

  const deleteHandler = useCallback(
    async (event: React.MouseEvent, id: string) => {
      event.stopPropagation();
      const isConfirm = event.shiftKey ? true : await openConfirmModal("Oletko varma, että haluat poistaa liitteen?");
      if (isConfirm) {
        if (activeAttachment?.id === id) {
          setActiveAttachment(null);
          setBlob(null);
        }
        setDeletingId(id);
        await deleteMutate(id);
        setDeletingId(null);
      }
    },
    [openConfirmModal, activeAttachment?.id, deleteMutate]
  );

  const downloadHandler = useCallback(
    async (attachment: IAttachment) => {
      if (activeAttachment?.id === attachment.id) return;
      setActiveAttachment(attachment);
      const data = await downloadMutate(attachment.id);
      setBlob(data);
    },
    [activeAttachment?.id, downloadMutate]
  );

  useEffect(() => {
    if (!initDone && attachments && attachments.length > 0) {
        downloadHandler(attachments[0]);
        setInitDone(true);
    }
  }, [initDone, attachments, downloadHandler]);

  const errorMessages = combineErrors(
    { isError: isFetchError, error: fetchError },
    { isError: isDeletingError, error: deletingError },
    { isError: isDownloadError, error: downloadError }
  );

  const isRow = useMemo(() => flexDirection === "row", [flexDirection]);

  // const setActiveIndex = useCallback((value: number) => {
  //   if (!attachments) return;
  //   const currentIndex = activeId ? attachments?.findIndex(attachment => attachment.id === activeId) ?? -1 : -1
  //   const index = currentIndex + value;
  //   if (index < 0 || index === attachments.length) return;
  //   const attachment = attachments[currentIndex + value];
  //   if (attachment?.id) {
  //       downloadHandler(attachment.id);
  //   }
  // }, [activeId, attachments, downloadHandler]);

  useImperativeHandle(ref, () => ({
  //  hasAttachments: (attachments?.length ?? 0) > 0,
  }), []);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <>
      {isUploading && <Spinner center />}
      <div style={{ display: "flex", gap: "1rem", flexDirection }}>
        {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
        {uploadDisabled && !attachments?.length && <span>Ei liitteitä.</span>}
        {attachments && attachments.length > 0 && (
          <Table
            style={isRow ? { width: "initial" } : {}}
            containerStyles={isRow ? { minWidth: "420px", borderRight: "1px solid" } : {}}
          >
            <thead>
              <tr>
                <th>Nimi</th>
                <th>Pvm</th>
                <th>Toiminnot</th>
              </tr>
            </thead>
            <tbody>
              {attachments.map((attachment) => (
                <tr
                  key={attachment.id}
                  style={{
                    cursor: "pointer",
                    background: activeAttachment?.id === attachment.id ? "#ccc" : "#fff",
                  }}
                  onClick={() => !isDownloading && downloadHandler(attachment)}
                >
                  <td>
                    {getContentTypeIcon(attachment.contentType)}{" "}
                    {attachment.clientName}
                  </td>
                  <td>{formatDate(attachment.created)}</td>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      onClick={(event) => deleteHandler(event, attachment.id)}
                      size={EButtonSize.SMALL}
                      color={EButtonColor.DANGER}
                      icon={faTrash}
                      title="Poista"
                      loading={deletingId === attachment.id}
                      disabled={isDeleting}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {(blob || isDownloading) && (
          <div
            style={{ width: "100%", position: "sticky", top: "6rem", right: "0", height: noScroll ? "auto" : "0" }}
          >
            {isDownloading ? (
              <Spinner />
            ) : (
              <IFrame blob={blob!} style={{ border: 0, ...iframeStyles }} title={activeAttachment?.clientName} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

/**
 * Värilliset iconit vain niille, jotka pystytään preview:ssä näyttämään
 */
const getContentTypeIcon = (contentType: string) => {
  if (contentType.startsWith("image/")) {
    return <FontAwesomeIcon icon={faImage} color="blue" />;
  }
  switch (contentType) {
    case "application/pdf":
      return <FontAwesomeIcon icon={faFilePdf} color="red" />;
    default:
      return <FontAwesomeIcon icon={faFile} />;
  }
};

const useFetch = (kind: Kind, parentId: string) => {
  const { fetchAttachments } = useAttachmentsService();

  const {
    data: attachments,
    isPending: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useQuery<IAttachment[], ErrorResponse>({
    queryKey: [QueryKey.attachments, kind, parentId],
    queryFn: ({ signal }) => fetchAttachments({ signal, kind, parentId }),
    staleTime: 5000,
  });

  return { attachments, isFetching, isFetchError, fetchError };
};

const useDelete = (kind: Kind, parentId: string, successCallbackFn?: (returnType: AttachmentsReturnType) => void) => {
  const { deleteAttachment } = useAttachmentsService();
  const { setElements } = useProjectEditContext();

  const {
    mutateAsync: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<IAttachment, ErrorResponse, string>({
    mutationFn: (id) => deleteAttachment(kind, id),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.attachments, kind, parentId] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.latestAttachments, kind, parentId] });
      if (data.element) {
        // const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, data.element.projectId]) ?? [];
        // queryClient.setQueryData([QueryKey.elements, data.element.projectId], [...oldData ?? []].map(el => el.id === data.element?.id ? {...el, hasAttachments: data.element?.hasAttachments, isPlanned: data.element?.isPlanned } : el));
        setElements(elements => elements.map(el => el.id === data.element?.id ? {...el, hasAttachments: data.element?.hasAttachments, isPlanned: data.element?.isPlanned } : el));
        if (successCallbackFn) successCallbackFn(data.element);
      }
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

const useDownload = (kind: Kind) => {
  const { downloadAttachment } = useAttachmentsService();

  const {
    mutateAsync: downloadMutate,
    isPending: isDownloading,
    isError: isDownloadError,
    error: downloadError,
  } = useMutation<Blob, ErrorResponse, string>({
    mutationFn: (id) => downloadAttachment({ kind, id }),
  });

  return { downloadMutate, isDownloading, isDownloadError, downloadError };
};

export default forwardRef(AttachmentsList);
