import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../Logo/Logo";
import ToggleMenu from "../ToggleMenu/ToggleMenu";

import classes from "./MobileMenu.module.scss";
import Backdrop from "../../ui/Backdrop/Backdrop";
import { EMenuItem, IMenuItem } from "../useMenuItems";

interface IProps {
  menuItems: IMenuItem[];
  isOpen: boolean;
  onToggle: () => void;
}

const MobileMenu: React.FC<IProps> = ({ menuItems, isOpen, onToggle }) => {
  const [activeMenuItem, setActiveMenuItem] = useState<EMenuItem | null>(null);
  const classNames = [classes.Container];

  if (isOpen) {
    classNames.push(classes.Open);
  }

  useEffect(() => {
    const classList = window.document.body.classList;
    const className = "no-scroll";
    if (isOpen) {
      classList.add(className);
    } else {
      classList.remove(className);
    }
    return () => {
      classList.remove(className);
    };
  }, [isOpen]);

  const menuItemClickHandler = (menuItem: IMenuItem): void => {
    const { id, link, onClick } = menuItem;
    if (link) {
      window.location.href = link;
    } else if(onClick) {
      onClick();
    } else if (id) {
      setActiveMenuItem((activeMenuItem) => (activeMenuItem !== id ? id : null));
    }
  };

  const createSubMenu = (isOpen: boolean, menuItems?: IMenuItem[]) => {
    if (!menuItems || !menuItems.length) return null;
    const classNames = [classes.SubMenu];
    if (isOpen) {
      classNames.push(classes.Open);
    }
    return <li className={classNames.join(" ")}>{menuItems.map((menuItem) => renderMenuItem(menuItem, true))}</li>;
  };

  const renderMenuItem = (menuItem: IMenuItem, isSubMenuItem = false) => {
    const { id, text, menuItems, styles, to } = menuItem;
    const classNames = [isSubMenuItem ? classes.SubMenuItem : classes.MenuItem];
    const Tag = isSubMenuItem ? "div" : "li";
    const activeClassName = isSubMenuItem ? classes.SubMenuItemActive : classes.MenuItemActive;
    const isOpen = id === activeMenuItem;
    if (isOpen) {
      classNames.push(classes.Open);
    }

    const subMenu = createSubMenu(isOpen, menuItems);

    if (to) {
      return (
          <NavLink
            key={text}
            to={to}
            className={(nav) => nav.isActive ? [...classNames, activeClassName].join(' ') : classNames.join(' ')}
            style={styles}
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
          >
            {text}
          </NavLink>
      );
    } else {
      return (
        <React.Fragment key={text}>
          <Tag className={classNames.join(' ')} onClick={() => menuItemClickHandler(menuItem)} style={styles}>
            {text}
            {subMenu && <FontAwesomeIcon className={classes.Icon} icon={faChevronDown} /> }
          </Tag>
          {subMenu}
        </React.Fragment>
      );
    }
  };

  return (
    <>
      <Backdrop isOpen={isOpen} onClick={onToggle} />
      <nav className={classNames.join(" ")}>
        <header className={classes.Header}>
          <Logo />
          <ToggleMenu onToggle={onToggle} className={classes.Toggle} />
        </header>
        <ul className={classes.Items}>{menuItems.map((menuItem) => renderMenuItem(menuItem))}</ul>
      </nav>
    </>
  );
};

export default MobileMenu;
