import { useQuery } from "@tanstack/react-query";
import { getISOWeek } from "date-fns";
import { useMemo } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import { QueryKey } from "../../../services/query-keys";
import { useWeeklyProductionRowsService } from "../../../services/weeklyProductionRows-service";
import IOfferRow, { EFactory } from "../../../shared/IOfferRow";
import IProductType from "../../../shared/IProductType";
import IWeeklyProductionRow from "../../../shared/IWeeklyProductionRow";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import PageHeading from "../../ui/PageHeading/PageHeading";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";
import { HOLLOWCORE_PRODUCT_GROUP_NAME } from "../../../shared/IProductGroup";

interface IProps {
  factory?: EFactory;
  showProjects?: boolean;
}

const MAX_CAPACITY = 2100;

/**Check if sold amount equals planned amount*/
const checkIsPlanned = (projectId: string, offerRow: IOfferRow, rows: IWeeklyProductionRow[]) => {
  const { productTypeId, count, factory } = offerRow;
  let productionCount = 0;
  let productionCapacity = 0;
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    if (projectId === row.projectId && (row.productType as IProductType).id === productTypeId && row.factory === factory) {
      productionCount += +(row.count ?? 0);
      productionCapacity += +(row.grossArea ?? 0);
    }
  }
  // console.log("offerRow", name, "productTypeId", productTypeId, "count", count, "productionCount", productionCount);
  return { isPlanned: +count === productionCount, productionCount, isMaxCapacity: productionCapacity >= MAX_CAPACITY };
}

const WeeklyProductionRowsTotalTable: React.FC<IProps> = ({ factory, showProjects }) => {
  const { project } = useProjectEditContext();
  const { weeklyProductionRows, isFetching, isFetchError, fetchError } = useFetch(factory);

  const factories = useMemo(() => project.offer?.rows?.map(row => row.factory), [project.offer?.rows]);
  const filteredRows = useMemo(() => factories ? weeklyProductionRows?.filter(row => factories?.includes(row.factory)) : weeklyProductionRows, [factories, weeklyProductionRows]);

  if (isFetching) {
    return <Spinner />;
  }

  const errorMessages = combineErrors({ isError: isFetchError, error: fetchError });
  return (
    <>
      <PageHeading variant="h3">Suunnittelu viikottain (viikko {getISOWeek(new Date())})</PageHeading>
      {errorMessages?.length > 0 && <ErrorsAlert errors={errorMessages} />}
      {project.offer && (
        <Table>
          <thead>
            <tr>
              <th style={{ width: "200px" }}>Myyty / työmaa {project.projectNumber}</th>
              <th style={{ width: "80px" }}>TR2</th>
              <th style={{ width: "80px" }}>tehdas</th>
              <th style={{ width: "80px" }}>A</th>
              <th style={{ width: "80px" }}>kpl</th>
              <th style={{ width: "80px" }}>jm</th>
            </tr>
          </thead>
          <tbody>
            {[...project.offer?.rows ?? []].filter(row => (row?.productGroupName ?? "") === HOLLOWCORE_PRODUCT_GROUP_NAME).map((row) => {
              const { isPlanned, productionCount } = checkIsPlanned(project.id, row, filteredRows ?? []);
              return (
                <tr key={row.id} style={isPlanned ? {} : { background: "red" }}>
                  <td></td>
                  <td>{row.productTypeName}</td>
                  <td>{row.factory}</td>
                  <td>{row.netArea}</td>
                  <td>{productionCount} / {row.count}</td>
                  <td>{row.linealMeters}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <Table>
        <thead>
          <tr>
            <th style={{ width: "200px" }}>tuotantoviikko</th>
            <th style={{ width: "80px" }}>TR2</th>
            <th style={{ width: "80px" }}>tehdas</th>
            <th style={{ width: "80px" }}>A</th>
            <th style={{ width: "80px" }}>kpl</th>
            <th style={{ width: "80px" }}>jm</th>
          </tr>
        </thead>
        <tbody>
          {filteredRows?.map((row) => (
            <tr key={row.id}>
              <td>{row.productionWeek}</td>
              <td>{(row.productType as IProductType)?.name}</td>
              <td>{row.factory}</td>
              <td>{row.grossArea}</td>
              <td>{row.count}</td>
              <td>{row.linealMeters}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const useFetch = (factory?: string) => {
  const { fetchWeeklyProductionRows } = useWeeklyProductionRowsService();
  const queryKey = factory ? [QueryKey.weeklyProductionRows, factory] : [QueryKey.weeklyProductionRows];
  const {
    data: weeklyProductionRows,
    isPending: isFetching,
    isError: isFetchError,
    error: fetchError,
  } = useQuery<IWeeklyProductionRow[], ErrorResponse>({
    queryKey,
    queryFn: ({ signal }) => fetchWeeklyProductionRows({ signal, projectId: "", jsonBody: { factory } }),
    staleTime: 5000,
  });

  return { weeklyProductionRows, isFetching, isFetchError, fetchError };
};

export default WeeklyProductionRowsTotalTable;
