import { CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useSlabTypeOptions } from "../../../../hooks/useSlabTypeOptions";
import { useOfferService } from "../../../../services/offers-service";
import { EInputType, IInputField, TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import { useInputs } from "../../../ui/Input/useInputs";
import Table from "../../../ui/Table/Table";
import { useOfferProductsContext } from "../../OfferProducts/offer-products-context";
import { useOfferRowCalculationContext } from "../offer-row-calculation-context";
import { RefHandle } from "../../../../shared/RefHandle";

export interface IOfferRowCalculationHollowcore {
  slabTypeId: string;
  pricePerSquare: string;
  squareWeight: string;
  deliveryPriceForTon: string;
  // jcadAmount: string;
  // jcadSquares: string;
  deliveryPriceForSquare: string;
  totalPricePerSquare: string;
}

enum EInputs {
  slabTypeId = "slabTypeId",
  pricePerSquare = "pricePerSquare",
  squareWeight = "squareWeight",
}

const containerStyles: CSSProperties = { width: "155px" };

const OfferRowCalculationHollowcore: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationHollowcore>> = (_, ref) => {
  const { offerRow, updateOfferRow } = useOfferRowCalculationContext();
  const { currentDeliveryPriceForTon, showControls } = useOfferProductsContext();
  const { offerRowCalculationHollowcore: calculation } = offerRow;

  const { loading, options, slabTypes } = useSlabTypeOptions();
  const { calculateOfferHollowcore } = useOfferService();

  const { createInput, submit } = useHollowcoreInputs(calculation, showControls);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (currentDeliveryPriceForTon) {
        const data = { ...calculation };
        data.deliveryPriceForTon = currentDeliveryPriceForTon;
        const offerRowCalculationHollowcore = await calculateOfferHollowcore(data);
        updateOfferRow({ offerRowCalculationHollowcore });
      }
    }, 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [currentDeliveryPriceForTon]);

  const onAutoUpdate = useCallback( 
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.HOLLOWCORE) {
        const data = _ as IOfferRowCalculationHollowcore;
        data.deliveryPriceForTon = currentDeliveryPriceForTon || "0";
        if (inputName === EInputs.slabTypeId) {
          const selectedSteelPart = slabTypes?.find((s) => s.id === value);
          data.squareWeight = selectedSteelPart?.netWeight ?? "0";
          data.pricePerSquare = selectedSteelPart?.squarePrice ?? "0";
        }
        const offerRowCalculationHollowcore = await calculateOfferHollowcore({
          ...data,
          [inputName]: formatInputValue(value),
        });
        updateOfferRow({ offerRowCalculationHollowcore, unitPrice: offerRow.unitPrice ?? offerRowCalculationHollowcore.totalPricePerSquare });
      }
      return Promise.resolve(true);
    },
    [currentDeliveryPriceForTon, calculateOfferHollowcore, updateOfferRow, offerRow.unitPrice, slabTypes]
  );

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const newData = await submit();
      if (!calculation) return null;
      return { ...calculation, ...newData };
    },
  }), [calculation, submit]);

  return (
    <Table removeGap>
      <thead>
        <tr>
          <th>laattatyyppi</th>
          <th>hinta /m²</th>
          <th>paino /m²</th>
          <th>kuljetus /ton</th>
          {/* <th>Jcad_kpl</th>
        <th>Jcad_m2</th> */}
          <th>kulj /m²</th>
          <th>M2 hinta</th>
        </tr>
      </thead>
      <tbody>
        <InputContextProvider initDone onAutoUpdate={onAutoUpdate}>
          <tr>
            <td>
              {createInput(EInputs.slabTypeId, {
                options,
                loading,
                containerStyles,
              })}
            </td>
            <td>{createInput(EInputs.pricePerSquare, { containerStyles })}</td>
            <td>{createInput(EInputs.squareWeight, { containerStyles })}</td>
            <td>{currentDeliveryPriceForTon} €</td>
            {/* <td></td>
          <td></td> */}
            <td>{calculation.deliveryPriceForSquare} €</td>
            <td>{calculation.totalPricePerSquare} €</td>
          </tr>
        </InputContextProvider>
      </tbody>
    </Table>
  );
};

const useHollowcoreInputs = (data?: IOfferRowCalculationHollowcore, showControls?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.slabTypeId]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.pricePerSquare]: {
      type: EInputType.number,
      value: "",
      post: "€ /m²",
    },
    [EInputs.squareWeight]: {
      type: EInputType.number,
      value: "",
      post: "kg /m²",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.HOLLOWCORE, disabled: !showControls });

  return { createInput, submit, inputs };
};

export default forwardRef(OfferRowCalculationHollowcore);
