import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IInsulation from '../shared/IInsulation';

interface IGetInsulation extends ISignal {
  id: string;
}

export const useInsulationService = () => {
  const customFetch = useCustomFetch();

  const fetchInsulations = useCallback(async ({ signal }: ISignal, archived?: boolean) => {
    const [insulations] = await customFetch<IInsulation[]>(`/api/insulations/list${archived !== undefined ? `?archived=${archived}`:""}`, { signal });
    return insulations;
  }, [customFetch]);
  
  const getInsulation = useCallback(async ({ signal, id }: IGetInsulation) => {
    const [insulation] = await customFetch<IInsulation>("/api/insulations/get/" + id, { signal });
    return insulation;
  }, [customFetch]);
  
  const saveInsulation = useCallback(async (data: IInsulation) => {
    const [insulation] = await customFetch<IInsulation>("/api/insulations/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return insulation;
  }, [customFetch]);
  
  const updateInsulation = useCallback(async (id: string, data: IInsulation) => {
    const [insulation] = await customFetch<IInsulation>("/api/insulations/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return insulation;
  }, [customFetch]);
  
  const deleteInsulation = useCallback(async (id: string) => {
    await customFetch<IInsulation>("/api/insulations/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const archivedUpdateInsulation = useCallback(async (id: string) => {
    const [insulation] = await customFetch<IInsulation>("/api/insulations/update-archived/" + id, {
      method: "PUT",
    });
    return insulation;
  }, [customFetch]);
  
  const archivedDeleteInsulations = useCallback(async () => {
    await customFetch<IInsulation>("/api/insulations/delete-archived", {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchInsulations, getInsulation, saveInsulation, updateInsulation, deleteInsulation, archivedUpdateInsulation, archivedDeleteInsulations};
}