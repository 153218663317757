import { EInputType, IInputField } from "../../../ui/Input/Input";
import { EInputUpdateAction } from "../../../ui/Input/input-context";
import { useInputs } from "../../../ui/Input/useInputs";
import IProductGroup from "../../../../shared/IProductGroup";
import { forwardRef, useImperativeHandle, useState } from "react";
import { RefHandle } from "../../../../shared/RefHandle";

export interface IInstallationRow {
  id: string;
  offerRowId: string;
  productGroup: IProductGroup;
  cost: string;
  quantity: string;
  totalCost: string;
}

interface IProps {
  data: IInstallationRow;
}

export enum EInputs {
  cost = "cost",
}

const OfferCalculationTabInstallationRow: React.ForwardRefRenderFunction<RefHandle<IInstallationRow>, IProps> = ({ data }, ref) => {
  const { createInput, submit } = useInstallationRowInputs(data);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const newData = await submit();
      if (!data) return null;
      return { ...data, ...newData };
    },
  }), [data, submit]);

  return (
    <tr>
      <td>{data.productGroup.name}</td>
      <td>{data.quantity}</td>
      <td>{createInput(EInputs.cost, {containerStyles: {width: "145px"}})}</td>
      <td>{data.totalCost} €</td>
      <td>{data.productGroup.installationCost} €</td>
    </tr>
  );
};

const useInstallationRowInputs = (data?: IInstallationRow) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.cost]: {
      type: EInputType.number,
      value: "",
      post: "€ /kpl",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.INSTALLATION_ROW });

  return { createInput, submit };
};

export default forwardRef(OfferCalculationTabInstallationRow);
