import { IOfferRowCalculationTotal } from "../../../../shared/IOfferRow";
import Table from "../../../ui/Table/Table";
import { IOfferRowCalculationSteelTendon } from "./OfferRowCalculationSteelTendon";

interface IProps {
  calculation: IOfferRowCalculationSteelTendon;
  total: IOfferRowCalculationTotal;
}

const SteelTendonTotalTable: React.FC<IProps> = ({ calculation, total }) => {
  return (
    <Table removeGap>
      <thead>
        <tr>
          <th>yhteensä, kg</th>
          <th>yhteensä, €</th>
          <th>kg /m³</th>
          <th>kg /Peti</th>
          <th>€ /Peti</th>
          <th>€ /m²</th>
          <th>€ /m³</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{calculation.weightPerElement} kg</td>
          <td>{calculation.pricePerElement} €</td>
          <td>{total.steelTendonKilogramsPerCubicMeter}</td>
          <td>{calculation.weight}</td>
          <td>{calculation.price}</td>
          <td>{total.steelTendonSquareMeterPrice} €</td>
          <td>{total.steelTendonCubicMeterPrice} €</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default SteelTendonTotalTable;
