import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useSteelPartsService } from "../services/steelParts-service";
import ISteelpart from "../shared/ISteelPart";

export const useSteelPartOptions = () => {
  const { fetchSteelParts } = useSteelPartsService();

  const {
    data: steelparts,
    isPending,
    isError,
  } = useQuery<ISteelpart[], ErrorResponse>({
    queryKey: [QueryKey.steelPartOptions],
    queryFn: ({ signal }) => fetchSteelParts({ signal }, false),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : steelparts?.map(
            (s) => ({ value: s.id, label: s.unitName } as IOption)
          ),
    [steelparts, isError]
  );

  return { options, loading: isPending, steelparts };
};
