import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../../../custom-fetch";
import { useProjectsService } from "../../../services/projects-service";
import { QueryKey } from "../../../services/query-keys";
import IElementStatusDTO from "../../../shared/IElementStatusDTO";
import ErrorsAlert from "../../ErrorsAlert/ErrorsAlert";
import Spinner from "../../ui/Spinner/Spinner";
import Table from "../../ui/Table/Table";

interface IProps {
  projectId: string;
}

const ElementStatusTable: React.FC<IProps> = ({ projectId }) => {
  const {
    elements,
    isFetchingElements,
    isFetchElementsError,
    fetchElementsError,
  } = useFetchElementStatus(projectId);

  if (isFetchElementsError) {
    return <ErrorsAlert errors={fetchElementsError!.messages} />;
  }

  if (isFetchingElements) {
    return <Spinner />;
  }

  if (!elements || elements.length === 0) {
    return <p>Ei suunniteltuja elementtejä</p>;
  }

  return (
    <>
      <Table containerStyles={{ padding: "0 1rem" }}>
        <thead>
          <tr>
            <th>Tuote</th>
            <th title="määrä tilauksessa">Myyty (kpl / m²)</th>
            <th title="suunniteltuja elementtejä">Tekemättä (kpl / m²)</th>
            <th>Tehty (kpl / m²)</th>
            <th>Toimitettu (kpl / m²)</th>
          </tr>
        </thead>
        <tbody>
          {elements?.map((element) => (
            <tr key={element.productType.id}>
              <td>{element.productType.name}</td>
              <td>{element.sold}kpl / {element.soldArea}m²</td>
              <td>{element.notDone}kpl / {element.notDoneArea}m²</td>
              <td>{element.done}kpl / {element.doneArea}m²</td>
              <td>{element.delivered}kpl / {element.deliveredArea}m²</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const useFetchElementStatus = (projectId: string | null) => {
  const { fetchProjectElementStatus } = useProjectsService();

  const {
    data: elements,
    isPending: isFetchingElements,
    isError: isFetchElementsError,
    error: fetchElementsError,
  } = useQuery<IElementStatusDTO[], ErrorResponse>({
    queryKey: [QueryKey.elementsStatus, projectId],
    queryFn: ({ signal }) =>
        fetchProjectElementStatus({ signal, id: projectId! }),
    staleTime: 5000,
    enabled: !!projectId,
  });

  return {
    elements,
    isFetchingElements,
    isFetchElementsError,
    fetchElementsError,
  };
};

export default ElementStatusTable;
