import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../custom-fetch";
import Spinner from "../components/ui/Spinner/Spinner";
import Container from "../components/ui/Container/Container";
import SteelPartsEdit from "../components/SteelParts/SteelPartsEdit";
import { useSteelPartsService } from "../services/steelParts-service";
import ISteelParts from "../shared/ISteelPart";

type Params = {
  id: string;
};

const SteelPartPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getSteelPart } = useSteelPartsService();
  
  const {
    data: steelParts,
    isLoading,
    isError,
    error,
  } = useQuery<ISteelParts, ErrorResponse>({
    queryKey: [QueryKey.steelParts, id],
    queryFn: ({ signal }) => getSteelPart({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Teräsosat</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <SteelPartsEdit id={id!} steelParts={steelParts} />
        )}
      </Container>
    </>
  );
};

export default SteelPartPage;
