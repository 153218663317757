import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { forwardRef, useImperativeHandle, useState } from "react";
import IAdditionalInvoice from "../../../shared/IAdditionalInvoice";
import { RefHandle } from "../../../shared/RefHandle";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import { EInputUpdateAction } from "../../ui/Input/input-context";

enum EInputs {
  invoiceText = "invoiceText",
  invoiceSum = "invoiceSum",
  description = "description",
}

interface IProps {
  invoice: IAdditionalInvoice;
  onDelete: (event: React.MouseEvent) => void;
  isDeleting: boolean;
}

const AdditionalInvoice: React.ForwardRefRenderFunction<RefHandle<IAdditionalInvoice>, IProps> = ({ invoice, onDelete, isDeleting }, ref) => {
  const { createInput, submit } = useElementInputs(invoice);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const data = await submit();
      // console.log(data);
      if (!data) return null;
      return { ...invoice, ...data };
    },
  }));

  return (
    <>
      <tr>
        <td>{createInput(EInputs.invoiceText)}</td>
        <td>{createInput(EInputs.invoiceSum)}</td>
        <td>{createInput(EInputs.description)}</td>
        <td style={{ borderTop: 0, display: "flex", gap: "0.1rem" }}>
          <Button onClick={(e) => { e.stopPropagation(); onDelete(e); }} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" loading={isDeleting} />
        </td>
      </tr>
    </>
  );
};

const useElementInputs = (data?: IAdditionalInvoice) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.invoiceText]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.invoiceSum]: {
      type: EInputType.number,
      value: "",
      post: "€",
    },
    [EInputs.description]: {
      type: EInputType.textarea,
      value: "",
      rows: 4,
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.ADDITIONAL_INVOICES });

  return { createInput, submit };
};

export default forwardRef(AdditionalInvoice);
