import { useCallback } from "react";
import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import { useNavigate } from "react-router-dom";
import { Route } from "../routes";
import ProductionLinesList from "../components/ProductionLines/ProductionLinesList";

const ProductionLinesPage: React.FC = () => {
  const navigate = useNavigate();

  const addHandler = useCallback(() => {
    navigate(Route.productionLine("add"));
  }, [navigate]);

  return (
    <>
      <PageHeading onAdd={addHandler}>Pedit</PageHeading>
      <Container>
        <ProductionLinesList />
      </Container>
    </>
  );
};

export default ProductionLinesPage;
