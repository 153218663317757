import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import FactoryList from "../components/Factories/FactoryList";

const FactoriesPage: React.FC = () => {

  return (
    <>
      <PageHeading>Tehtaat</PageHeading>
      <Container>
        <FactoryList />
      </Container>
    </>
  );
};

export default FactoriesPage;
