import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useSteelService } from "../services/steels-service";
import ISteel from "../shared/ISteel";

export const useSteelOptions = () => {
  const { fetchSteels } = useSteelService();

  const {
    data: steels,
    isPending,
    isError,
  } = useQuery<ISteel[], ErrorResponse>({
    queryKey: [QueryKey.steelOptions],
    queryFn: ({ signal }) => fetchSteels({ signal }, false),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : steels?.map(
            (s) => ({ value: s.id, label: `${s.diameter || ""}`.replaceAll(".", ",") } as IOption)
          ),
    [steels, isError]
  );

  return { options, loading: isPending, steels };
};
