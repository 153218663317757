import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { ErrorResponse } from "../../custom-fetch";
import { useAIService } from "../../services/ai-service";
import { Kind } from "../../services/kind";
import IElementsCSVResponseDTO from "../../shared/IElementsCSVResponseDTO";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import AIModal, { IAIModalData } from "./AIModal";
import { useElementsService } from "../../services/elements-service";
import IElement from "../../shared/IElement";
import { queryClient } from "../../App";
import { QueryKey } from "../../services/query-keys";
import IProject, { IProjectPhase } from "../../shared/IProject";

export const useAIModal = (kind: Kind, parentId: string) => {
  const { addModal, closeModal, updateModal } = useModalContext();

  const closeModalHandler = useCallback(
    () => closeModal(EModalId.AI_UPLOAD),
    [closeModal]
  );

  const { uploadMutate, 
    //TODO isUploading, isUploadError, uploadError
   } = useAIUpload(kind, parentId);

   const { saveElementsMutate,
    //TODO isSavingElements, isSaveElementsError, saveElementsError
    } = useSaveElements(parentId);

  const handler = (): Promise<IElementsCSVResponseDTO | null> => {
    let title = "Kysy AI:lta";
    return new Promise((resolve) => {
      addModal({
        id: EModalId.AI_UPLOAD,
        isOpen: true,
        size: EModalSize.MEDIUM,
        title,
        backdropNotClose: true,
        content: (
          <AIModal
            onAccept={async (data) => {
              try {
                if (data.newItems) {
                  data.newItems = await saveElementsMutate(data.newItems);
                }
                resolve(data);
                closeModalHandler();
                return data;
              } catch (error) {
                console.error(error);
                return { responseText: "Virhe!", error: error as ErrorResponse };
              }
            }}
            onCancel={() => {
              updateModal(EModalId.AI_UPLOAD, {
                loading: false,
                error: null,
              });
              closeModalHandler();
              resolve(null);
            }}
            onUpload={async (data) => {
              try {
                return await uploadMutate(data);
              } catch (error) {
                console.error(error);
                return { responseText: "Virhe!", error: error as ErrorResponse };
              }
            }}
          />
        ),
      });
    });
  };

  return handler;
};

const useAIUpload = (kind: Kind, parentId: string) => {
  const { uploadFileToAI } = useAIService();
  
  const {
    mutateAsync: uploadMutate,
    isPending: isUploading,
    isError: isUploadError,
    error: uploadError,
  } = useMutation<IElementsCSVResponseDTO, ErrorResponse, IAIModalData>({
    mutationFn: ({ prePrompt, file }) => uploadFileToAI(kind, parentId, prePrompt, file),
  });

  return { uploadMutate, isUploading, isUploadError, uploadError };
};

const useSaveElements = (projectId: string) => {
  const { saveElements } = useElementsService();
  
  const {
    mutateAsync: saveElementsMutate,
    isPending: isSavingElements,
    isError: isSaveElementsError,
    error: saveElementsError,
  } = useMutation<IElement[], ErrorResponse, IElement[]>({
    mutationFn: (elements) => saveElements(projectId, elements),
    onSuccess: (data) => {
      const oldData = queryClient.getQueryData<IElement[]>([QueryKey.elements, projectId]) ?? [];
      queryClient.setQueryData([QueryKey.elements, projectId], [
        ...oldData,
        ...data,
      ]);

      const oldProjectData = queryClient.getQueryData<IProject>([QueryKey.projects, projectId]);
      let phases = [...data].filter(element => !!element.phaseId && !!element.phaseName).map(element => ({ phaseId: element.phaseId, name: element.phaseName }) as IProjectPhase) ?? [];
      phases = [...oldProjectData?.phases ?? [], ...phases].filter((value, index, array) => array.findIndex(el => el.phaseId === value.phaseId) === index);
      queryClient.setQueryData([QueryKey.projects, projectId], { ...oldProjectData, phases });
    },
  });

  return { saveElementsMutate, isSavingElements, isSaveElementsError, saveElementsError };
};
