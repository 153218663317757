import IElement from "../../shared/IElement";
import Table from "../ui/Table/Table";

interface IProps {
  elements: IElement[];
  onClick?: (element: IElement) => void;
  selectedElements?: IElement[];
}

const ElementsTable: React.FC<IProps> = ({
  elements,
  onClick,
  selectedElements,
}) => {
  return (
    <Table hover={!!onClick}>
      <thead>
        <tr>
          <th style={{ width: "200px" }}>Tehdas</th>
          <th style={{ width: "200px" }}>Työmaa</th>
          <th style={{ width: "80px" }}>Kerros / lohko</th>
          <th style={{ width: "80px" }}>Tunnus</th>
          <th style={{ width: "125px" }}>Vaijerityyppi</th>
          <th style={{ width: "125px" }}>Pituus</th>
          <th style={{ width: "125px" }}>Varaukset</th>
          <th style={{ width: "125px" }}>Paino</th>
          <th style={{ width: "100px" }}>Vko</th>
          <th style={{ width: "100px" }}>Toim</th>
        </tr>
      </thead>
      <tbody>
        {elements.map((element) => (
          <tr
            key={element.id}
            onClick={() => onClick && onClick(element)}
            style={
              selectedElements &&
              selectedElements.findIndex((el) => el.id === element.id) !== -1
                ? { background: "#f5f5f5" }
                : {}
            }
          >
            <td>{element.factory}</td>
            <td>{element.projectNumber}</td>
            <td>{element.phaseName}</td>
            <td>{element.name}</td>
            <td>{element.typeName}</td>
            <td>{element.length}</td>
            <td>{element.provisionAmountShort}</td>
            <td>{element.weightTons}</td>
            <td>{element.productionWeek}</td>
            <td>{element.deliveryWeek}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ElementsTable;
