import { useState } from "react";
import { IInstalmentRow } from "../../../shared/IInstalmentTable";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import { EInputUpdateAction } from "../../ui/Input/input-context";

interface IProps {
    row: IInstalmentRow;
}

export enum EInstalmentRowInputs {
    rowSum = "rowSum",
}

const OfferInstalmentTableRow: React.FC<IProps> = ({ row }) => {
    const { createInput } = useInstalmentRowInputs(row);
    return (
        <tr key={row.number} >
            <td>{row.description}</td>
            <td>{row.amount}</td>
            <td style={{width: "200px"}}>{createInput(EInstalmentRowInputs.rowSum, {containerStyles: {marginBottom: "0px"}})}</td>
        </tr>
    )
}

const useInstalmentRowInputs = (data?: IInstalmentRow, ) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInstalmentRowInputs.rowSum]: {
      type: EInputType.number,
      value: "",
      post: "€",
    },
  });

  const { createInput } = useInputs({
    data,
    inputs,
    setInputs,
    updateAction: EInputUpdateAction.INSTALMENT_ROW,
  });

  return { createInput };
}

export default OfferInstalmentTableRow;