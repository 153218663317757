import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../../../ui/Table/Table";
import { IOfferCalculationSummary } from "./OfferCalculationSummary";
import { faRepeat } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useState } from "react";

enum EView {
  product, factory,
}

interface IProps {
  summary: IOfferCalculationSummary;
}

const SummaryTable: React.FC<IProps> = ({ summary }) => {
  const [view, setView] = useState(EView.product);

  const toggleView = useCallback(() => {
    setView((view) => {
      if (view === EView.product) return EView.factory;
      return EView.product;
    });
  }, []);

  const showTable = summary?.rows?.length > 0 || summary?.factoryRows?.length > 0;
  return showTable ? (
    <Table>
      <thead>
        <tr>
          <th style={{ textDecoration: "underline", cursor: "pointer" }} onClick={toggleView}>
            <FontAwesomeIcon icon={faRepeat} />
            {" "}
            {view === EView.product && "Tuoteryhmä"}
            {view === EView.factory && "Tehdas"}
          </th>
          <th>Kappalemäärä</th>
          <th>jm</th>
          <th>m³</th>
          <th>m²</th>
          <th>nettom²</th>
          <th>brm²</th>
          <th>hinta</th>
          {view === EView.factory && <th>%-osuus</th>}
        </tr>
      </thead>
      <tbody>
        {view === EView.product ? summary?.rows?.map((row) => (
          <tr key={row.productGroupId}>
            <td>{row.productGroupName}</td>
            <td>{row.count}</td>
            <td>{row.linealMeters}</td>
            <td>{row.cubicMeters}</td>
            <td>{row.squareMeters}</td>
            <td>{row.netArea}</td>
            <td>{row.grossArea}</td>
            <td>{row.price}</td>
          </tr>
        )) : summary?.factoryRows?.map((row) => (
          <tr key={row.factory}>
            <td>{row.factory}</td>
            <td>{row.count}</td>
            <td>{row.linealMeters}</td>
            <td>{row.cubicMeters}</td>
            <td>{row.squareMeters}</td>
            <td>{row.netArea}</td>
            <td>{row.grossArea}</td>
            <td>{row.price}</td>
            <td>{row.percentage}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : null;
};

export default SummaryTable;
