import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { useProductTypeService } from "../services/productTypes-service";
import { QueryKey } from "../services/query-keys";
import IProductType from "../shared/IProductType";

export const useSlabProductTypeOptions = () => {
  const { fetchSlabProductTypes } = useProductTypeService();

  const {
    data: slabProductTypes,
    isPending,
    isError,
  } = useQuery<IProductType[], ErrorResponse>({
    queryKey: [QueryKey.slabProductTypeOptions],
    queryFn: ({ signal }) => fetchSlabProductTypes({ signal }),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : slabProductTypes?.map(
            (p) => ({ value: p.id, label: p.name } as IOption)
          ),
    [slabProductTypes, isError]
  );

  return { options, loading: isPending, slabProductTypes };
};
