import { ReactNode, createContext, useContext } from "react";
import IOfferRow from "../../../shared/IOfferRow";

interface IOfferRowCalculationContext {
  offerRow: IOfferRow;
  updateOfferRow: (newRow: Partial<IOfferRow>) => void;
  isHollowcore: boolean;
  isWall: boolean;
}

const OfferRowCalculationContext = createContext<IOfferRowCalculationContext>({
  offerRow: {} as IOfferRow,
  updateOfferRow: () => {},
  isHollowcore: false,
  isWall: false,
});

export const useOfferRowCalculationContext = () =>
  useContext(OfferRowCalculationContext);

const OfferRowCalculationContextProvider: React.FC<{
  value: IOfferRowCalculationContext;
  children: ReactNode;
}> = ({ value, children }) => (
  <OfferRowCalculationContext.Provider value={value}>
    {children}
  </OfferRowCalculationContext.Provider>
);

export default OfferRowCalculationContextProvider;
