import { faRoute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, forwardRef, useImperativeHandle, useState } from "react";
import { EFactory } from "../../../../shared/IOfferRow";
import { EInputType, IInputField } from "../../../ui/Input/Input";
import { EInputUpdateAction } from "../../../ui/Input/input-context";
import { useInputs } from "../../../ui/Input/useInputs";
import { useOfferCalculationTabsContext } from "../offer-calculation-tabs-context";
import { RefHandle } from "../../../../shared/RefHandle";

export interface IOfferCalculationKilometer {
  factory: EFactory;
  freightKilometers: string;
  freightToCustomer: string;
  offerFreightPrice: string;
  percentageDifference: string;
}

interface IProps {
  data: IOfferCalculationKilometer;
}

export enum EInputs {
  freightKilometers = "freightKilometers",
  offerFreightPrice = "offerFreightPrice",
}

const containerStyles: CSSProperties = { marginBottom: 0 };

const OfferCalculationTabKilometer: React.ForwardRefRenderFunction<RefHandle<IOfferCalculationKilometer>, IProps> = ({ data }, ref) => {
  const { createInput, submit } = useKilometerInputs(data);
  const { openGoogleMapsHandler } = useOfferCalculationTabsContext();

  const percentageStyle: CSSProperties =
    +data.percentageDifference > 0
      ? { color: "green" }
      : +data.percentageDifference < 0
      ? { color: "red" }
      : {};

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const newData = await submit();
      if (!data) return null;
      return { ...data, ...newData };
    },
  }), [data, submit]);

  return (
    <tr key={data.factory}>
      <td>{data.factory}</td>
      <td>
        {createInput(EInputs.freightKilometers, { postButtonText: <FontAwesomeIcon icon={faRoute} />, onPostButtonClick: () => openGoogleMapsHandler(data.factory), containerStyles })}
      </td>
      <td>{data.freightToCustomer}</td>
      <td>{createInput(EInputs.offerFreightPrice, { containerStyles })}</td>
      <td style={percentageStyle}>{data.percentageDifference}</td>
    </tr>
  );
};

const useKilometerInputs = (data?: IOfferCalculationKilometer) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.freightKilometers]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.offerFreightPrice]: {
      type: EInputType.number,
      value: "",
      post: "€",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.OFFER_KILOMETERS });

  return { createInput, submit };
};

export default forwardRef(OfferCalculationTabKilometer);
