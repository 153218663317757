import React, { useRef } from 'react';

import { IOption, TOptionsDirection } from '../Input/Input';
import classes from './Checkbox.module.scss';
import CheckboxOption from './CheckboxOption/CheckboxOption';

interface IProps {
  value: string[];
  onChange: (value: string[]) => void;
  options: IOption[] | undefined;
  name: string;
  disabled?: boolean;
  invalid?: boolean;
  optionsDirection?: TOptionsDirection;
}

const Checkbox: React.FC<IProps> = ({ value, onChange, options, name, disabled, invalid, optionsDirection}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const classNames = [classes.OptionContainer];
  if (optionsDirection === 'vertical') {
    classNames.push(classes.Vertical);
  }



  return (
    <div className={classNames.join(' ')} ref={containerRef}>
      {options &&
        options.map(option => (
          <CheckboxOption
            key={option.value}
            value={option.value}
            label={option.label}
            info={option.info}
            isActive={value.indexOf(option.value) !== -1}
            onClick={onChange}
            name={name}
            disabled={disabled}
            invalid={invalid}
            containerRef={containerRef}
          />
        ))}
    </div>
  );
};

export default Checkbox;
