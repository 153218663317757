import React, { CSSProperties, ReactNode } from 'react';
import classes from './Container.module.scss';

interface IProps {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  shadow?: boolean;
}

const Container:React.FC<IProps> = ({ children, style, className, shadow }) => {
  if (!children) return null;
  
  const classNames = [classes.Container];

  if (className) {
    classNames.push(className);
  }

  if (shadow) {
    classNames.push(classes.Shadow);
  }

  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  )
}

export default Container;