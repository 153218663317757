import { useCallback, useEffect, useState } from "react";
import Button from "../ui/Button/Button";
import { EInputType, IInputField, TFetchOption } from "../ui/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs,
} from "../ui/Input/input-utils";
import { useCreateInput } from "../ui/Input/useCreateInput";
import { useOrganizationsSearch } from "./useOrganizationsSearch";
import { storage } from "../../utils/localstorage-utils";
import { OrganizationType } from "../../shared/IOrganization";
import { useUserService } from "../../services/users-service";
import IUser from "../../shared/IUser";

export interface IOrganizationsSearch {
  type: OrganizationType;
  name?: string;
  businessId?: string;
  organizationId?: string;
  email?: string;
}

enum EInputs {
  name = "name",
  businessId = "businessId",
  organizationId = "organizationId",
}

interface IProps {
  type: OrganizationType;
}

const OrganizationsSearch: React.FC<IProps> = ({ type }) => {
  const { search, setSearch, isLoading, refetch } = useOrganizationsSearch(type);
  const { createInput, submit } = useInputs(search);
  const [users, setUsers] = useState<IUser[]>([])
  const { fetchUsers } = useUserService();

  const fetchOptions: TFetchOption = useCallback(async (input, signal) => {
    const fetchedUsers = await fetchUsers({ signal, roles: [type], email: input });
    setUsers(fetchedUsers);
    return fetchedUsers.map((user: IUser) => ({
      value: user.organizationId,
      label: user.email,
    }));
  }, [fetchUsers, type]);

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      const selectedUser = users?.find(u => u.organizationId === data.organizationId);
      data.email = selectedUser?.email
      data.type = type;
      setSearch(data);
    }
  };

  useEffect(() => {
    if (!isLoading && search) {
      refetch();
      storage.saveOrganizationsSearch(search);
    }
    // eslint-disable-next-line
  }, [search, refetch]);

  return (
    <>
      {createInput(EInputs.name)}
      {createInput(EInputs.businessId)}
      {createInput(EInputs.organizationId, {fetchOptions, 
        options: search?.organizationId && search?.email ? [{value: search.organizationId, label: search.email}] : []})}
      <Button loading={isLoading} onClick={submitHandler}>
        Hae
      </Button>
    </>
  );
};

const useInputs = (search?: IOrganizationsSearch | null) => {
  const [showValidation, setShowValidation] = useState(false);
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.name]: {
      type: EInputType.text,
      label: "Nimi",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.businessId]: {
      type: EInputType.text,
      label: "Y-tunnus",
      value: "",
      placeholder: "",
      boldContent: true
    },
    [EInputs.organizationId]: {
      type: EInputType.reactAsyncSelect,
      label: "Yhteyshenkilön email",
      value: "",
      placeholder: "",
      boldContent: true
    },
  });

  useEffect(() => {
    if (search) {
      initForm(setInputs, search);
    }
  }, [search]);

  const createInput = useCreateInput(inputs, setInputs, { showValidation });

  const submit = async () => {
    const isValid = await validateInputs(setInputs);
    if (isValid) {
      return getInputData<IOrganizationsSearch>(inputs);
    }
    setShowValidation(true);
    return null;
  };

  return { createInput, submit, inputs };
};

export default OrganizationsSearch;
