import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";


const SettingsPage: React.FC = () => {
  return (
    <>
      <PageHeading>Asetukset</PageHeading>
      <Container>
        Tehty tähän vain navigointia varten lisäsivu.
      </Container>
    </>
  )
}
export default SettingsPage;