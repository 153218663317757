import { CSSProperties, useEffect, useRef, useState } from "react";
import Spinner from "../Spinner/Spinner";

interface IProps {
  title?: string;
  src?: string;
  width?: string;
  style?: CSSProperties;
  blob?: Blob;
}

const DISABLED_TYPES = ["text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/plain"];
const AUTORESIZE_DISABLED_TYPES = ["application/pdf"];

const IFrame: React.FC<IProps> = ({
  title = "Lataa liite",
  src,
  width = "100%",
  style = {},
  blob,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState("0px");
  const [objectUrl, setObjectUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    setIsLoading(true);
    if (!isLoading && blob) {
    //   console.log("blob", blob);
      setObjectUrl(URL.createObjectURL(blob));
    }
    setIsLoading(false);
  }, [blob, isLoading]);
  
  useEffect(() => {
    if (objectUrl) {
      return () => {
        // console.log("revoke", objectUrl);
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [objectUrl]);

  if (isLoading) {
    return <Spinner />;
  }

  const isDisabled = DISABLED_TYPES.includes(blob?.type ?? "");
  if (isDisabled) {
    return <a href={src ? src : objectUrl ? objectUrl : undefined} target="_blank" rel="noreferrer">{title}</a>;
  }

  const onLoad = () => {
    if (!iframeRef.current?.contentWindow) return;
    setHeight(iframeRef.current.contentWindow.document.body.scrollHeight + "px");
  };

  const autoResize = !AUTORESIZE_DISABLED_TYPES.includes(blob?.type ?? "");

  if (blob?.type.startsWith("image"))
    return (
      <img
        alt={title}
        src={src ? src : objectUrl ? objectUrl : undefined}
        width={width}
        height={"auto"}
        style={{ border: 0, ...style }}
      />
    );
  return (
    <iframe
      title={title}
      src={src ? src : objectUrl ? objectUrl : undefined}
      width={width}
      height={autoResize ? height : "800px"}
      style={{ border: 0, ...style }}
      ref={iframeRef}
      onLoad={autoResize ? onLoad : undefined}
    />
  );
};

export default IFrame;
