import SteelPartsImport from '../components/SteelParts/SteelPartsImport';

const SteelPartsImportPage: React.FC = () => {
  return (
    <>
    <h1 style={{ margin: "1rem" }}>Teräsosat Import</h1>
    <SteelPartsImport />
    </>
  )
}

export default SteelPartsImportPage;