import { CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useConcreteTypeOptions } from "../../../../hooks/useConcreteTypeOptions";
import { useOfferService } from "../../../../services/offers-service";
import { EInputType, IInputField, TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import { useInputs } from "../../../ui/Input/useInputs";
import Table from "../../../ui/Table/Table";
import { useOfferRowCalculationContext } from "../offer-row-calculation-context";
import ConcreteTotalTable from "./ConcreteTotalTable";
import { useOfferProductsContext } from "../../OfferProducts/offer-products-context";
import { RefHandle } from "../../../../shared/RefHandle";

export interface IOfferRowCalculationConcrete {
  length1: string;
  length2: string;
  length3: string;
  width1: string;
  width2: string;
  width3: string;
  height1: string;
  height2: string;
  height3: string;
  concreteTypeId1: string;
  concreteTypeId2: string;
  concreteTypeId3: string;
  concreteWeightPerCubic1?: string;
  concreteWeightPerCubic2?: string;
  concreteWeightPerCubic3?: string;
  concretePricePerCubic1?: string;
  concretePricePerCubic2?: string;
  concretePricePerCubic3?: string;
  area1: string;
  area2: string;
  area3: string;
  volume1: string;
  volume2: string;
  volume3: string;
  price1: string;
  price2: string;
  price3: string;
  totalVolume: string;
  maxLength?: string;
  maxLengthInMeters?: string;
  maxArea?: string;
  crossSectionArea?: string;
  weight?: string;
  price?: string;
  circumference?: string;
  weightTons: string;
  deliveryPriceForTon?: string;
  deliveryPriceForSquare?: string;
}

enum EInputs {
  length1 = "length1",
  length2 = "length2",
  length3 = "length3",
  concreteTypeId1 = "concreteTypeId1",
  width1 = "width1",
  width2 = "width2",
  width3 = "width3",
  concreteTypeId2 = "concreteTypeId2",
  height1 = "height1",
  height2 = "height2",
  height3 = "height3",
  concreteTypeId3 = "concreteTypeId3",
  concretePricePerCubic1 = "concretePricePerCubic1",
  concretePricePerCubic2 = "concretePricePerCubic2",
  concretePricePerCubic3 = "concretePricePerCubic3",
}

const containerStyles: CSSProperties = { width: "185px" };

const OfferRowCalculationConcrete: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationConcrete>> = (_, ref) => {
  const { offerRow, updateOfferRow } = useOfferRowCalculationContext();
  const { offerRowCalculationConcrete: concrete, offerRowCalculationInsulation: insulation } = offerRow;
  const { currentDeliveryPriceForTon, showControls } = useOfferProductsContext();

  const { loading, options, concreteTypes } = useConcreteTypeOptions();
  const { calculateOfferConcrete, calculateOfferInsulation } = useOfferService();

  const { createInput, submit } = useConcreteInputs(concrete, showControls);
  
  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (currentDeliveryPriceForTon) {
        const data = { ...concrete };
        data.deliveryPriceForTon = currentDeliveryPriceForTon;
        const offerRowCalculationConcrete = await calculateOfferConcrete(data);
        updateOfferRow({ offerRowCalculationConcrete });
      }
    }, 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [currentDeliveryPriceForTon]);

  const onAutoUpdate = useCallback( 
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.CONCRETE) {
        const data = _ as IOfferRowCalculationConcrete;
        if ([EInputs.concreteTypeId1, EInputs.concreteTypeId2, EInputs.concreteTypeId3].includes(inputName as EInputs)) {
          const selectedConcrete = concreteTypes?.find((t) => t.id === value);
          if (inputName === EInputs.concreteTypeId1) {
            data.concreteWeightPerCubic1 = selectedConcrete?.weight ?? "0";
            data.concretePricePerCubic1 = selectedConcrete?.price ?? "0";
          } else if (inputName === EInputs.concreteTypeId2) {
            data.concreteWeightPerCubic2 = selectedConcrete?.weight ?? "0";
            data.concretePricePerCubic2 = selectedConcrete?.price ?? "0";
          } else if (inputName === EInputs.concreteTypeId3) {
            data.concreteWeightPerCubic3 = selectedConcrete?.weight ?? "0";
            data.concretePricePerCubic3 = selectedConcrete?.price ?? "0";
          }
        }
        const offerRowCalculationConcrete = await calculateOfferConcrete({
          ...data,
          [inputName]: formatInputValue(value),
        });
        
        let offerRowCalculationInsulation = {...insulation};
        if (offerRowCalculationConcrete.maxArea && insulation.rows?.length > 0) {
          let updateInsulation = false;
          let maxArea = Number(offerRowCalculationConcrete.maxArea).toFixed();
          for (let i = 0; i < insulation.rows.length; i++) {
            const row = insulation.rows[i];
            row.squareMeters = maxArea;
            updateInsulation = true;
          }

          if (updateInsulation) {
            offerRowCalculationInsulation = await calculateOfferInsulation(insulation);
          }
        }
        
        updateOfferRow({ offerRowCalculationConcrete, offerRowCalculationInsulation });
      }
      return Promise.resolve(true);
    },
    [concreteTypes, calculateOfferConcrete, updateOfferRow, insulation, calculateOfferInsulation]
  );

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const newData = await submit();
      if (!concrete) return null;
      return { ...concrete, ...newData };
    },
  }), [concrete, submit]);

  return (
    <>
      <ConcreteTotalTable offerRow={offerRow} />
      <Table removeGap>
        <thead>
          <tr>
            <th></th>
            <th>PIT</th>
            <th>LEV</th>
            <th>KORK/Paksuus</th>
            <th>A</th>
            <th>m³</th>
            <th>Hinta/m²</th>
            <th>Massatyyppi</th>
            <th>Paino</th>
            <th>Hinta € /m³</th>
          </tr>
        </thead>
        <tbody>
          <InputContextProvider initDone onAutoUpdate={onAutoUpdate}>
            <tr>
              <td>1.</td>
              <td>{createInput(EInputs.length1, { containerStyles })}</td>
              <td>{createInput(EInputs.width1, { containerStyles })}</td>
              <td>{createInput(EInputs.height1, { containerStyles })}</td>
              <td>{concrete.area1}</td>
              <td>{concrete.volume1}</td>
              <td>{concrete.price1}</td>
              <td>
                {createInput(EInputs.concreteTypeId1, {
                  options,
                  loading,
                  containerStyles,
                })}
              </td>
              <td>{concrete.concreteWeightPerCubic1}</td>
              <td>{createInput(EInputs.concretePricePerCubic1, { containerStyles })}</td>
            </tr>
            <tr>
            <td>2.</td>
              <td>{createInput(EInputs.length2, { containerStyles })}</td>
              <td>{createInput(EInputs.width2, { containerStyles })}</td>
              <td>{createInput(EInputs.height2, { containerStyles })}</td>
              <td>{concrete.area2}</td>
              <td>{concrete.volume2}</td>
              <td>{concrete.price2}</td>
              <td>
                {createInput(EInputs.concreteTypeId2, {
                  options,
                  loading,
                  containerStyles,
                })}
              </td>
              <td>{concrete.concreteWeightPerCubic2}</td>
              <td>{createInput(EInputs.concretePricePerCubic2, { containerStyles })}</td>
            </tr>
            <tr>
            <td>3.</td>
              <td>{createInput(EInputs.length3, { containerStyles })}</td>
              <td>{createInput(EInputs.width3, { containerStyles })}</td>
              <td>{createInput(EInputs.height3, { containerStyles })}</td>
              <td>{concrete.area3}</td>
              <td>{concrete.volume3}</td>
              <td>{concrete.price3}</td>
              <td>
                {createInput(EInputs.concreteTypeId3, {
                  options,
                  loading,
                  containerStyles,
                })}
              </td>
              <td>{concrete.concreteWeightPerCubic3}</td>
              <td>{createInput(EInputs.concretePricePerCubic3, { containerStyles })}</td>
            </tr>
          </InputContextProvider>
        </tbody>
      </Table>
    </>
  );
};

const useConcreteInputs = (data?: IOfferRowCalculationConcrete, showControls?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.length1]: {
      type: EInputType.number,
      value: "",
      post: "mm"
    },
    [EInputs.length2]: {
      type: EInputType.number,
      value: "",
      post: "mm"
    },
    [EInputs.length3]: {
      type: EInputType.number,
      value: "",
      post: "mm"
    },
    [EInputs.width1]: {
      type: EInputType.number,
      value: "",
      post: "mm"
    },
    [EInputs.width2]: {
      type: EInputType.number,
      value: "",
      post: "mm"
    },
    [EInputs.width3]: {
      type: EInputType.number,
      value: "",
      post: "mm"
    },
    [EInputs.height1]: {
      type: EInputType.number,
      value: "",
      post: "mm"
    },
    [EInputs.height2]: {
      type: EInputType.number,
      value: "",
      post: "mm"
    },
    [EInputs.height3]: {
      type: EInputType.number,
      value: "",
      post: "mm"
    },
    [EInputs.concreteTypeId1]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.concreteTypeId2]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.concreteTypeId3]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.concretePricePerCubic1]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.concretePricePerCubic2]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.concretePricePerCubic3]: {
      type: EInputType.number,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.CONCRETE, disabled: !showControls });

  return { createInput, submit, inputs };
};

export default forwardRef(OfferRowCalculationConcrete);
