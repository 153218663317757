import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { useOfferTermOptions } from "../../../../hooks/useOfferTermOptions";
import IOfferTerm, { EOfferTermType } from "../../../../shared/IOfferTerm";
import Button from "../../../ui/Button/Button";
import { TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, {
  EInputUpdateAction,
} from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import Table from "../../../ui/Table/Table";
import OfferTabOfferTerm, { EInputs } from "./OfferTextTabOfferTerm";

export const createOfferTerm = (type: EOfferTermType, typeString: string, offerTerms?: IOfferTerm[]): IOfferTerm => {
    const id = uuid();
    let text = ""
    if (offerTerms && (type === EOfferTermType.PAYMENT_TERM || type === EOfferTermType.DELIVERY_TERM || type === EOfferTermType.INVOICING)) {
      const defaultOfferTerm = offerTerms?.find((o) => o.type === type && o.defaultValue === true);
      if (defaultOfferTerm) text = defaultOfferTerm.text;
    }
    return {idString: id, type, typeString, text};
}

interface IProps {
  offerTerms: IOfferTerm[];
  setOfferTerms: React.Dispatch<React.SetStateAction<IOfferTerm[]>>;
}

const OfferTextTabOfferTerms: React.FC<IProps> = ({ offerTerms, setOfferTerms }) => {
  const { offerTerms: offerTermOptions } = useOfferTermOptions();
  const { addHandler, deleteHandler, updateHandler } = useHandlers(offerTerms, setOfferTerms, offerTermOptions ?? []);

  return (
    <> 
    <Table style={{maxWidth: "2000px"}}>
      <thead>
        <tr>
          <th style={{width: "200px"}}>Tyyppi</th>
          <th style={{width: "200px"}}>Nimi</th>
          <th>Teksti</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <InputContextProvider initDone onAutoUpdate={updateHandler}>
          {offerTerms.map((offerTerm) => (
            // offerTerm.type !== EOfferTermType.CONDITIONAL &&
            <OfferTabOfferTerm key={offerTerm.idString} offerTerm={offerTerm} onDelete={() => deleteHandler(offerTerm.idString)} />
          ))}
        </InputContextProvider>
        <tr>
          <td colSpan={18}>
            <Button onClick={addHandler}>Lisää sopimusteksti</Button>
          </td>
        </tr>
      </tbody>
    </Table>

    {/* <Table>
      <thead>
        <tr>
          <th>Tyyppi</th>
          <th>Nimi</th>
          <th>Tehdas</th>
          <th>Tuoteryhmä</th>
          <th>Tuote</th>
          <th>Teksti</th>
        </tr>
      </thead>
      <tbody>
        {offerTerms.map((offerTerm) => (
          offerTerm.type === EOfferTermType.CONDITIONAL &&
          <tr key={offerTerm.id ?? offerTerm.idString}>
            <td>Ehdollinen</td>
            <td>{offerTerm.name}</td>
            <td>{offerTerm.factoryString}</td>
            <td>{offerTerm.productGroup?.name}</td>
            <td>{offerTerm.productType?.name}</td>
            <td>{offerTerm.text}</td>
          </tr>
        ))}
      </tbody>
    </Table> */}
    </>
  );
};

const useHandlers = (
  offerTerms: IOfferTerm[],
  setOfferTerms: React.Dispatch<React.SetStateAction<IOfferTerm[]>>,
  offerTermOptions: IOfferTerm[],
) => {
  const addHandler = useCallback(() => {
    setOfferTerms((offerTerms) => [...offerTerms, createOfferTerm(EOfferTermType.COMMON, "Yleiset")]);
  }, [setOfferTerms]);

  const deleteHandler = useCallback((id?: string) => {
      setOfferTerms((offerTerms) => offerTerms.filter((o) => o.idString !== id));
    },
    [setOfferTerms]
  );

  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.OFFER_TERM) {
        const data = _ as IOfferTerm;
        if (inputName === EInputs.offerTermId) {
          const selectedOfferTerm = offerTermOptions?.find((o) => o.id === value);
          if (selectedOfferTerm) {
            data.text = selectedOfferTerm?.text ?? "";
          }
          data.name = selectedOfferTerm?.name ?? "";
        }
        const index = offerTerms.findIndex((o) => o.idString === data.idString);
        if (index > -1) {
          offerTerms[index] = {
            ...data,
            [inputName]: formatInputValue(value),
          };
        }
        setOfferTerms([...offerTerms]);
      }
      return Promise.resolve(true);
    },
    [offerTerms, setOfferTerms, offerTermOptions]
  );

  return { addHandler, deleteHandler, updateHandler };
};

export default OfferTextTabOfferTerms;
