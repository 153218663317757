import { faCheck, faMinus, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { queryClient } from "../../../App";
import { ErrorResponse } from "../../../custom-fetch";
import { useInstalmentTableService } from "../../../services/instalmentTable-service";
import { QueryKey } from "../../../services/query-keys";
import IInstalmentTable, { IInstalmentRow } from "../../../shared/IInstalmentTable";
import IOffer from "../../../shared/IOffer";
import ErrorsAlert from "../../ErrorsAlert/ErrorsAlert";
import Button, { EButtonColor } from "../../ui/Button/Button";
import Container from "../../ui/Container/Container";
import { EInputType, IInputField, IOption, TInputValue } from "../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../ui/Input/input-context";
import { formatInputValue } from "../../ui/Input/input-utils";
import { useInputs } from "../../ui/Input/useInputs";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import Table from "../../ui/Table/Table";
import OfferInstalmentTableRow, { EInstalmentRowInputs } from "./OfferInstalmentTableRow";

interface IProps {
  offer: IOffer;
  onReturn: () => void;
  instalmentTablePrintModeHandler: () => void;
  instalmentTable?: IInstalmentTable;
  isInstalmentTableError: boolean;
  instalmentTableError: ErrorResponse | null;
}

enum EInputs {
  productionPercentage = "productionPercentage",
  deliveryPercentage = "deliveryPercentage",
  finalPercentage = "finalPercentage",
  orderPercentage = "orderPercentage",
  unitTreshold = "unitTreshold"
}

const UnitTresholdOptions: IOption[]=[
    {value: "10", label: "10"},
    {value: "25", label: "25"},
    {value: "50", label: "50"},
    {value: "75", label: "75"},
    {value: "100", label: "100"},
    {value: "150", label: "150"},
    {value: "200", label: "200"},
]

interface IRowProps {
  left: ReactNode;
  right: ReactNode;
}
const Row: React.FC<IRowProps> = ({ left, right }) => {
  return (
    <div style={{ display: "flex"}}>
      <div style={{ width: "35%" }}>{left}</div>
      <div style={{ width: "65%" }}>{right}</div>
    </div>
  );
};

const containerStyles: CSSProperties = { width: "90%" };

const OfferInstalmentTable: React.FC<IProps> = ({ offer, onReturn, instalmentTablePrintModeHandler, instalmentTable, isInstalmentTableError, instalmentTableError }) => {
  const { saveOrUpdate, error: saveOrUpdateError, isError: saveOrUpdateIsError, isPending: saveOrUpdateIsPending } = useSaveOrUpdate(instalmentTable?.id);
  const { createInput, submit, inputs } = useInstalmentTableInputs(instalmentTable);
  const [instalmentRows, setInstalmentRows] = useState<IInstalmentRow[]>(instalmentTable?.instalmentRows ?? []);
  const { updateHandler } = useHandlers(instalmentRows, setInstalmentRows);
  const openConfirmModal = useConfirmModal();

  const elementsPrice = offer.summary.offerPrice ?? "0";
  const installationPrice = offer.summary.installed ? offer.installations.installationDeclaredPrice ?? "0" : "0";
  const totalPrice = +(offer.summary.installed ? offer.summary.offerPriceInstalled : elementsPrice);

  const totalPriceVAT = offer.summary.privatePerson 
    ? offer.summary.installed ? offer.summary.offerPriceInstalledVAT : offer.summary.offerPriceVAT
    : offer.summary.installed ? offer.summary.offerPriceInstalled : offer.summary.offerPrice;
    
  const [productionTotal, setProductionTotal] = useState<string>("0");
  const [deliveryTotal, setDeliveryTotal] = useState<string>("0");
  const [finalTotal, setFinalTotal] = useState<string>("0");
  const [orderTotal, setOrderTotal] = useState<string>("0");

  const productionPercentage = +(inputs[EInputs.productionPercentage].value as string);
  const deliveryPercentage = +(inputs[EInputs.deliveryPercentage].value as string);
  const finalPercentage = +(inputs[EInputs.finalPercentage].value as string);
  const orderPercentage = +(inputs[EInputs.orderPercentage].value as string);

  const totalPercentage100 = orderPercentage + productionPercentage + deliveryPercentage + finalPercentage === 100;

  const instalmentRowsTotal = useMemo(() => {
    return instalmentRows.reduce((total, instalmentRow) => total + +instalmentRow.rowSum, 0);
  }, [instalmentRows]);

  const instalmentRowsRemainder = instalmentRowsTotal - totalPrice;

  useEffect(() => {
    if (totalPrice) {
      setProductionTotal(productionPercentage >= 0 ? (productionPercentage / 100 * totalPrice).toFixed(2) : "0")
      setDeliveryTotal(deliveryPercentage >= 0 ? (deliveryPercentage / 100 * totalPrice).toFixed(2) : "0")
      setFinalTotal(finalPercentage >= 0 ? (finalPercentage / 100 * totalPrice).toFixed(2) : "0")
      setOrderTotal(orderPercentage >= 0 ? (orderPercentage / 100 * totalPrice).toFixed(2) : "0")
    }
  }, [deliveryPercentage, finalPercentage, orderPercentage, productionPercentage, setDeliveryTotal, setFinalTotal, setProductionTotal, totalPrice]);  

  useEffect(() => {
    if (instalmentTable?.instalmentRows) {
      setInstalmentRows(instalmentTable.instalmentRows);
    }
  }, [instalmentTable?.instalmentRows]);  

  const updateLastRowSum = () => {
    setInstalmentRows(prevRows => {
      if (prevRows.length === 0) return prevRows;
      const newRows = [...prevRows];
      const newSum = newRows[newRows.length - 1].rowSum - instalmentRowsRemainder;
      if (newSum < 0) window.alert("Viimeisen erän summa ei ole tarpeeksi tasausta varten");
      newRows[newRows.length - 1] = {...newRows[newRows.length - 1], rowSum: newSum < 0 ? 0 : +newSum.toFixed(2)};
      return newRows
    });
  };

  const submitHandler = useCallback(async (updateRows: boolean) => {
    const data = await submit();
    if (data && totalPercentage100) {
      data.offerId = offer.id
      data.offerNumber = offer.offerNumber
      data.totalPrice = totalPrice.toString()
      data.totalUnits = offer.summary.totalQuantity ?? "0"
      data.installed = offer.summary.installed
      if (updateRows) {
        data.instalmentRows = instalmentRows;
      }
      saveOrUpdate(data);
    }
  }, [instalmentRows, offer.id, offer.offerNumber, offer.summary.installed, offer.summary.totalQuantity, saveOrUpdate, submit, totalPercentage100, totalPrice]);

  const confirmNewRowsHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat luoda uudet erät?");
    if (isConfirm) {
      submitHandler(false);
    }
  }, [openConfirmModal, submitHandler]);

  return (
    <>
      <div style={{ display: "flex", padding: "1rem", position: "sticky", top: "0", left: "0", zIndex: "99", background: "#f3f3f3"}}>
        <h1 style={{ margin: "0", marginRight: "1rem"}}>Maksuerätaulukko</h1>
        <Button onClick={instalmentTablePrintModeHandler} icon={faPrint} color={EButtonColor.SECONDARY}
          disabled={!instalmentTable}>
          Tulosta
        </Button>
        <Button onClick={confirmNewRowsHandler} color={EButtonColor.PRIMARY} loading={saveOrUpdateIsPending} style={{marginLeft: "1rem", marginRight: "1rem"}}>
          Luo uudet erät
        </Button>
        <Button onClick={()=>submitHandler(true)} color={EButtonColor.SUCCESS} loading={saveOrUpdateIsPending}>
          Tallenna
        </Button>
        <Button onClick={updateLastRowSum} color={EButtonColor.PRIMARY} style={{marginLeft: "1rem", marginRight: "1rem"}}
          disabled={instalmentRows.length === 0 || instalmentRowsRemainder === 0} title="Tasaa viimeinen erä">
          Tasaus
        </Button>
        <Button onClick={onReturn} color={EButtonColor.SECONDARY}>Takaisin</Button>
      </div>
      <Container>
        {isInstalmentTableError && instalmentTableError && instalmentTableError.messages && <ErrorsAlert errors={instalmentTableError.messages} />}
        {saveOrUpdateIsError && saveOrUpdateError && saveOrUpdateError.messages && <ErrorsAlert errors={saveOrUpdateError.messages} />}
        <div style={{ display: "flex", margin: "1rem" }}>
          <div style={{ width: "35%"}}>
            <Row left={"Työnumero"} right={offer.projectNumber} />
            <Row left={"Tarjousnumero"} right={offer.offerNumber} />

            <div style={{marginTop: "2rem"}}>
              <Row left="Kohteen nimi" right={offer.targetName} />
              <Row left="Kohteen osoite" right={offer.deliveryStreetAddress} />
              <Row left="Postinumero / paikka" right={<span>{offer.deliveryZip} {offer.deliveryCity}</span>} />
            </div>

            <div style={{marginTop: "2rem"}}>
              <Row left="Tilaaja" right={offer.customer?.name} />
              <Row left="Tilaajan yhteyshenkilö" right={offer.customerContactPerson?.name} />
              <Row left="Puhelin" right={offer.customerContactPerson?.phoneNumber} />
              <Row left="Sähköposti" right={offer.customerContactPerson?.email} />
              <Row left="Y-tunnus" right={offer.customer?.businessId} />
              <Row left="Tilausnumero" right={offer.customerReferenceNumber} />
            </div>

            <div style={{marginTop: "2rem", marginBottom: "2rem"}}>
              <Row left="Elementit Alv 0%" right={elementsPrice +" €"} />
              <Row left="Asennus Alv 0%" right={installationPrice +" €"} />
              <Row left="Urakkasumma Alv 0%" right={totalPrice +" €"} />
              {offer.summary.privatePerson && <Row left="Urakkasumma Alv 25,5%" right={totalPriceVAT +" €"} />}
            </div>

            <Row left="Yksityinen" right={offer.summary.privatePerson ? <FontAwesomeIcon icon={faCheck} size="2x" color="green" /> : <FontAwesomeIcon icon={faMinus} size="2x" />} />

            <div style={{marginTop: "1rem"}}>
              <Row left="Asennettuna" right={offer.summary.installed ? <FontAwesomeIcon icon={faCheck} size="2x" color="green" /> : <FontAwesomeIcon icon={faMinus} size="2x" />} />
            </div>

            <div style={{marginTop: "2rem"}}>
              <Row left="Tilaushetkellä Alv 0%" right={
                <Row left={createInput(EInputs.orderPercentage, {containerStyles})} right={orderTotal+" €"} />} 
              />
              <Row left="Valmistuserittäin Alv 0%" right={
                <Row left={createInput(EInputs.productionPercentage, {containerStyles})} right={productionTotal+" €"} />} 
              />
              <Row left="Toimituserittäin Alv 0%" right={
                <Row left={createInput(EInputs.deliveryPercentage, {containerStyles})} right={deliveryTotal+" €"} />} 
              />
              <Row left="Loppuselvityksen Alv 0%" right={
                <Row left={createInput(EInputs.finalPercentage, {containerStyles})} right={finalTotal+" €"} />} 
              />
              {!totalPercentage100 &&
                <Row left="" right={<p style={{color: "#bb4c30"}}>Vaadittu yhteensä 100%</p>}/>
              }
            </div>

            <div style={{marginTop: "2rem", marginBottom: "2rem"}}>
              <div style={{marginBottom: "1rem"}}>
                <Row left="Tarjouksella tuotteita" right={offer.summary.totalQuantity+" kpl"} />
              </div>
              <Row left="Maksuerien väli" right={createInput(EInputs.unitTreshold, {containerStyles:{width:"45%"}})} />
              {/*<Row left="Oletus tehdas" right={"TODO"} />*/}
            </div>

            <div style={{marginTop: "2rem", marginBottom: "2rem"}}>
              <div style={{marginBottom: "1rem"}}>
                <Row left="Maksuerät yhteensä" right={<b style={{color: instalmentRowsRemainder < 0 ? "red" : "green"}}>{instalmentRowsTotal.toFixed(2)}</b>} />
              </div>
              <Row left="Urakkasumma erotus" right={<b style={{color: instalmentRowsRemainder < 0 ? "red" : "green"}}>{instalmentRowsRemainder.toFixed(2)}</b>} />
            </div>

          </div>
          <div style={{ width: "65%"}}>
            <div style={{ paddingLeft: "2rem"}}>
            <Table style={{ borderStyle: "solid"}}>
              <thead>
                <tr>
                  <th>Selite</th>
                  <th>Määrä</th>
                  <th>Veloitus</th>
                </tr>
              </thead>
              <tbody>
                <InputContextProvider initDone onAutoUpdate={updateHandler}>
                  {instalmentRows && instalmentRows.map((row) => (
                    <OfferInstalmentTableRow key={row.number} row={row} />
                  ))}
                </InputContextProvider>
              </tbody>
            </Table>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const useInstalmentTableInputs = (data?: IInstalmentTable, ) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.productionPercentage]: {
      type: EInputType.number,
      value: "",
      post: "%",
      validation: {
        required: true,
        minAmount: 0,
        maxAmount: 100,
      },
      disallowDecimals: true,
    },
    [EInputs.deliveryPercentage]: {
      type: EInputType.number,
      value: "",
      post: "%",
      validation: {
        required: true,
        minAmount: 0,
        maxAmount: 100,
      },
      disallowDecimals: true,
    },
    [EInputs.finalPercentage]: {
      type: EInputType.number,
      value: "",
      post: "%",
      validation: {
        required: true,
        minAmount: 0,
        maxAmount: 100,
      },
      disallowDecimals: true,
    },
    [EInputs.orderPercentage]: {
      type: EInputType.number,
      value: "",
      post: "%",
      validation: {
        required: true,
        minAmount: 0,
        maxAmount: 100,
      },
      disallowDecimals: true,
    },
    [EInputs.unitTreshold]: {
      type: EInputType.reactSelect,
      value: "",
      options: UnitTresholdOptions,
      menuPosition: "fixed",
      validation: {
        required: true
      }
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit, inputs };
};

const useSaveOrUpdate = (id?: string) => {
  const { updateInstalmentTable, saveInstalmentTable } = useInstalmentTableService();

  const mutationFn = (data: IInstalmentTable) => {
    return id ? updateInstalmentTable(id, data) : saveInstalmentTable(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IInstalmentTable, ErrorResponse, IInstalmentTable>({
    mutationFn,
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.instalmentTables, data.offerId], {...data});
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useHandlers = (
  instalmentRows: IInstalmentRow[],
  setInstalmentRows: React.Dispatch<React.SetStateAction<IInstalmentRow[]>>,
) => {
  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.INSTALMENT_ROW) {
        const data = _ as IInstalmentRow;

        if (inputName === EInstalmentRowInputs.rowSum) {
          if (+(value as string) < 0) value = "0"
        }

        const index = instalmentRows.findIndex((r) => r.number === data.number);
        if (index > -1) {
          instalmentRows[index] = {
            ...data,
            [inputName]: formatInputValue(value),
          };
        }
        setInstalmentRows([...instalmentRows]);
      }
      return Promise.resolve(true);
    },
    [instalmentRows, setInstalmentRows]
  );

  return { updateHandler };
}

export default OfferInstalmentTable;
