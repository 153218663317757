import { useCallback, useState } from "react";
import IOfferTerm from "../../../shared/IOfferTerm";
import Attachments from "../../Attachments/Attachments";
import Button, { EButtonColor, EButtonSize } from "../../ui/Button/Button";
import { ITab } from "../../ui/Tabs/Tabs";
import { useOfferEditContext } from "../OfferEdit/offer-edit-context";
import OfferTextTabOfferTerms from "./OfferTextTabOfferTerms/OfferTextTabOfferTerms";
import OfferTextTabPlanning from "./OfferTextTabPlanning/OfferTextTabPlanning";

enum EOfferTab {
  offerTerms = "offerTerms",
  planning = "planning",
  considerations = "considerations",
  attachments = "attachments",
}

const createOfferTabs = (isEdit: boolean): ITab[] => [
  { path: EOfferTab.planning, title: "Suunnittelu", visible: true },
  { path: EOfferTab.offerTerms, title: "Ehdot ja tekstit", visible: true },
  //{ id: EOfferTab.considerations, name: "Huomiot" },
  { path: EOfferTab.attachments, title: "Liitteet", visible: isEdit },
];

interface IProps {
  offerTerms: IOfferTerm[];
  setOfferTerms: React.Dispatch<React.SetStateAction<IOfferTerm[]>>;
  planningNotes: string;
  setPlanningNotes: React.Dispatch<React.SetStateAction<string>>;
  /*considerations: IConsiderations;
  setConsiderations: React.Dispatch<React.SetStateAction<IConsiderations>>;*/
  showOrderFields: boolean;
}

const OfferTextTabs: React.FC<IProps> = ({ offerTerms, setOfferTerms, planningNotes, setPlanningNotes, /*considerations, setConsiderations, showOrderFields*/ }) => {
  const [currentTab, setCurrentTab] = useState<EOfferTab>(EOfferTab.planning);
  const { id: offerId, isEdit } = useOfferEditContext();
  const offerTabs = createOfferTabs(isEdit);

  const getCurrentTab = useCallback((id: EOfferTab) => {
    switch (id) {
      case EOfferTab.offerTerms:
        return <OfferTextTabOfferTerms offerTerms={offerTerms} setOfferTerms={setOfferTerms}/>;
      case EOfferTab.planning:
        return <OfferTextTabPlanning planningNotes={planningNotes} setPlanningNotes={setPlanningNotes} />;
      /*case EOfferTab.considerations:
        return <OfferTextTabConsiderations considerations={considerations} setConsiderations={setConsiderations} showOrderFields={showOrderFields} />;*/
      case EOfferTab.attachments:
        return <Attachments kind="Offer" parentId={offerId!} noScroll />;
    }
  }, [offerTerms, setOfferTerms, planningNotes, setPlanningNotes, offerId]);

  return (
    <div style={{paddingTop: "1rem", borderTop: "thin solid gray", marginTop: "1rem"}}>
      <div style={{ display: "flex", paddingBottom: "1rem" }}>
        {offerTabs.map((tab) => !tab.visible ? null : (
          <Button
            key={tab.path}
            onClick={() => setCurrentTab(tab.path as EOfferTab)}
            color={EButtonColor.SECONDARY}
            size={EButtonSize.SMALL}
            style={
              tab.path === currentTab ? { background: "#f8f9fa", color: "black" } : {}
            }
          >
            {tab.title}
          </Button>
        ))}
      </div>
      {getCurrentTab(currentTab)}
    </div>
  );
};

export default OfferTextTabs;
