import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { v4 as uuid } from "uuid";
import { useInsulationOptions } from "../../../../hooks/useInsulationOptions";
import { useOfferService } from "../../../../services/offers-service";
import Button from "../../../ui/Button/Button";
import { TInputValue } from "../../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../../ui/Input/input-context";
import { formatInputValue } from "../../../ui/Input/input-utils";
import Table from "../../../ui/Table/Table";
import { useOfferRowCalculationContext } from "../offer-row-calculation-context";
import OfferRowCalculationInsulation, {
  EInputs,
  IOfferRowCalculationInsulationRow,
} from "./OfferRowCalculationInsulation";
import IOfferRow from "../../../../shared/IOfferRow";
import InsulationsTotalTable from "./InsulationsTotalTable";
import IInsulation from "../../../../shared/IInsulation";
import { RefHandle } from "../../../../shared/RefHandle";
import { useOfferProductsContext } from "../../OfferProducts/offer-products-context";

export interface IOfferRowCalculationInsulation {
  price: string;
  weight: string;
  rows: IOfferRowCalculationInsulationRow[];
}

export const createInsulationRow = (initialSquareMeters?: string): IOfferRowCalculationInsulationRow => {
  const id = uuid();
  return { id, cubicPrice: "0", squareMeters: initialSquareMeters || "0", thickness: "0", volume: "0", price: "0", insulation: { id: "", unitName: "" } as IInsulation };
}

const OfferRowCalculationInsulations: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationInsulation>> = (_, ref) => {
  const { offerRow, updateOfferRow } = useOfferRowCalculationContext();
  const { offerRowCalculationInsulation: insulation, offerRowCalculationConcrete: concrete } = offerRow;
  const { loading: insulationsLoading, options, insulations } = useInsulationOptions();

  const insulationsMemo = useMemo(() => {
    const opt = [...(options ?? [])];
    const parts = [...insulations ?? []];
    if(insulation.rows){
      for (let i = 0; i < insulation.rows.length; i++) {
        const row = insulation.rows[i];
        if (row.insulation) {
          const index = opt?.findIndex((part) => part.value === row.insulation?.id);
          if (index === -1 && row.insulation.unitName) {
            opt.push({ value: row.insulation.id, label: row.insulation.unitName });
            parts.push({ ...row.insulation })
          }
        }
      }
    }
    return { opt, parts };
  }, [options, insulation.rows, insulations]);

  const initialSquareMeters = useMemo(() => concrete.maxArea ? Number(concrete.maxArea).toFixed() : "0", [concrete.maxArea]);
  const { addHandler, deleteHandler, updateHandler } = useHandlers(insulation, updateOfferRow, insulationsMemo.parts, initialSquareMeters);

  const { showControls } = useOfferProductsContext();

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (!showControls) return;
      if (e.repeat) return;
      if (e.ctrlKey && e.key === "+") {
        // console.log("add insulation row");
        e.preventDefault();
        addHandler();
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [addHandler, showControls]);

  const insulationRowsRef = useRef<RefHandle<IOfferRowCalculationInsulationRow>[]>([]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const insulationRows = insulationRowsRef.current;
      const newRows: IOfferRowCalculationInsulationRow[] = [];
      for (let i = 0; i < insulationRows.length; i++) {
        const row = insulationRows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            const insulationId = rowData.insulation as any;
            if (insulationId) {
              const selectedInsulation = insulationsMemo.parts?.find((s) => s.id === insulationId);
              if (selectedInsulation) {
                rowData.insulation = selectedInsulation as any;
                rowData.cubicPrice = selectedInsulation.cubicPrice ?? "0";
              }
            } else {
              rowData.insulation = {} as IInsulation;
            }
            newRows.push(rowData);
          }
        }
      }
      return { ...insulation, rows: [...newRows] };
    },
  }), [insulation, insulationsMemo.parts]);

  return (
    <>
      <InsulationsTotalTable calculation={insulation} total={offerRow.offerRowCalculationTotal} />
      <Table removeGap>
        <thead>
          <tr>
            <th>eriste</th>
            <th>ala, m²</th>
            <th>paksuus, mm</th>
            <th>m³</th>
            <th>€ /m³</th>
            <th>€ yhteensä</th>
          </tr>
        </thead>
        <tbody>
          <InputContextProvider initDone onAutoUpdate={updateHandler}>
            {insulation.rows?.map((calculation, i) => (
              <OfferRowCalculationInsulation
                key={calculation.id}
                calculation={calculation}
                onDelete={() => deleteHandler(calculation.id)}
                insulationOptions={insulationsMemo.opt}
                insulationsLoading={insulationsLoading}
                ref={rowHandle => insulationRowsRef.current[i] = rowHandle!}
                showControls={showControls}
              />
            ))}
          </InputContextProvider>
          {showControls && (
            <tr>
              <td colSpan={7}>
                <Button onClick={addHandler}>Uusi</Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

const useHandlers = (calculation: IOfferRowCalculationInsulation, updateOfferRow: (newRow: Partial<IOfferRow>) => void, insulations?: IInsulation[], initialSquareMeters?: string) => {
  const { calculateOfferInsulation } = useOfferService();

  const addHandler = useCallback(() => {
    const offerRowCalculationInsulation = {
      ...calculation,
      rows: [
        ...calculation.rows,
        createInsulationRow(initialSquareMeters),
      ],
    };
    updateOfferRow({ offerRowCalculationInsulation });
  }, [calculation, updateOfferRow, initialSquareMeters]);

  const deleteHandler = useCallback((id: string) => {
    const offerRowCalculationInsulation = {
      ...calculation,
      rows: calculation.rows.filter((i) => i.id !== id),
    };
    updateOfferRow({ offerRowCalculationInsulation });
  }, [calculation, updateOfferRow]);

  const updateHandler = useCallback(async (inputName: string, value: TInputValue, action: string, _: any) => {
    if (action === EInputUpdateAction.INSULATION) {
      const data = _ as IOfferRowCalculationInsulationRow;
      if (inputName === EInputs.insulation) {
        const selectedInsulation = insulations?.find((s) => s.id === value);
        if (selectedInsulation) {
          value = selectedInsulation as any;
          data.cubicPrice = selectedInsulation.cubicPrice ?? "0";
        } else {
          value = { id: "", unitName: value as string } as any;
        }
      }
      const index = calculation.rows.findIndex(r => r.id === data.id);
      if (index > -1) {
        calculation.rows[index] = {
          ...data,
          [inputName]: formatInputValue(value),
        };
      }
      const offerRowCalculationInsulation = await calculateOfferInsulation(calculation);
      updateOfferRow({ offerRowCalculationInsulation });
    }
    return Promise.resolve(true);
  }, [calculateOfferInsulation, calculation, insulations, updateOfferRow]);

  return { addHandler, deleteHandler, updateHandler };
}

export default forwardRef(OfferRowCalculationInsulations);
