import React from "react";

import classes from "./Tabs.module.scss";
import { useNavigate, useLocation } from "react-router-dom";

export interface ITab {
  title: string;
  path: string;
  visible: boolean;
}

interface IProps {
  tabs: ITab[];
}

const Tabs: React.FC<IProps> = ({ tabs }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <ul className={classes.Tabs}>
      {tabs.map((tab) => {
        if (!tab.visible) return null;
        const classNames: string[] = [];
        if (location.pathname.includes(tab.path)) {
          classNames.push(classes.ActiveTab);
        }
        return (
          <li
            key={tab.path}
            className={classNames.join(" ")}
            onClick={() => navigate(tab.path)}
          >
            {tab.title}
          </li>
        );
      })}
    </ul>
  );
};

export default Tabs;
