import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { CSSProperties, forwardRef, useImperativeHandle, useState } from "react";
import { useSteelOptions } from "../../../../hooks/useSteelOptions";
import { RefHandle } from "../../../../shared/RefHandle";
import Button, { EButtonColor, EButtonSize } from "../../../ui/Button/Button";
import { EInputType, IInputField } from "../../../ui/Input/Input";
import { EInputUpdateAction } from "../../../ui/Input/input-context";
import { useInputs } from "../../../ui/Input/useInputs";

export interface IOfferRowCalculationSteelRow {
  id: string;
  unitName: string;
  steelId: string;
  weight: string;
  price: string;
  quantity: string;
  length: string;
  diameter: string;
  steelWeight: string;
  steelPrice: string;
}

export enum EInputs {
  unitName = "unitName",
  steelId = "steelId",
  quantity = "quantity",
  length = "length",
  steelPrice = "steelPrice",
}

interface IProps {
  calculation: IOfferRowCalculationSteelRow;
  onDelete: () => void;
  showControls: boolean;
}

const containerStyles: CSSProperties = { width: "125px" };

const OfferRowCalculationSteel: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationSteelRow>, IProps> = ({ calculation, onDelete, showControls }, ref) => {
  const { loading, options } = useSteelOptions();
  const { createInput, submit } = useOfferRowCalculationSteelInputs(calculation, showControls);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const newData = await submit();
      if (!calculation) return null;
      return { ...calculation, ...newData };
    },
  }), [calculation, submit]);

  return (
    <tr>
      <td>{createInput(EInputs.unitName, { containerStyles: { width: "250px" } })}</td>
      <td>{createInput(EInputs.steelId, { options, loading, containerStyles })}</td>
      <td>{createInput(EInputs.quantity, { containerStyles })}</td>
      <td>{createInput(EInputs.length, { containerStyles })}</td>
      <td>{createInput(EInputs.steelPrice, { containerStyles })}</td>
      <td>{calculation?.steelWeight}</td>
      <td>{calculation?.weight}</td>
      <td>{calculation?.price}</td>
      <td>
        {showControls && (
          <Button
            onClick={onDelete}
            size={EButtonSize.SMALL}
            color={EButtonColor.DANGER}
            icon={faTrash}
          />
        )}
      </td>
    </tr>
  );
};

const useOfferRowCalculationSteelInputs = (data?: IOfferRowCalculationSteelRow, showControls?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.unitName]: {
      type: EInputType.text,
      value: "",
    },
    [EInputs.steelId]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.quantity]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.length]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.steelPrice]: {
      type: EInputType.number,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.STEEL, disabled: !showControls });

  return { createInput, submit };
};

export default forwardRef(OfferRowCalculationSteel);
