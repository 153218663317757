import { faPlus, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useRef, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { queryClient } from "../App";
import ErrorsAlert, {
  combineErrors,
} from "../components/ErrorsAlert/ErrorsAlert";
import ProductionLineEdit, { IProductionLineHandle } from "../components/ProductionLines/ProductionLineEdit/ProductionLineEdit";
import Button, { EButtonColor } from "../components/ui/Button/Button";
import Container from "../components/ui/Container/Container";
import { useConfirmModal } from "../components/ui/Modal/useConfirmModal";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { Route } from "../routes";
import { useElementsService } from "../services/elements-service";
import { useProductionLinesService } from "../services/productionLines-service";
import { QueryKey } from "../services/query-keys";
import IElement from "../shared/IElement";
import IProductionLine from "../shared/IProductionLine";

const ProductionLinePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isEdit = useMemo(() => id !== "add", [id]);
  const navigate = useNavigate();
  const openConfirmModal = useConfirmModal();

  const { productionLine, isPending, isError, error } = useGetProductionLine(id!, isEdit);
  const { elements, isElementsPending, isElementsError, elementsError } = useFetchProductionLineElements(id!, isEdit, isPending);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDeleteProductionLine(id!, navigate);

  const productionLineEditRef = useRef<IProductionLineHandle>(null);
  const [loading, setLoading] = useState(false);

  const submitHandler = useCallback(async () => {
    setLoading(true);
    try {
      await productionLineEditRef.current?.submitHandler();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  const deleteHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat poistaa pedin?");
    if (isConfirm) {
      deleteMutate();
    }
  }, [deleteMutate, openConfirmModal]);

  const submitAndNavigateHandler = useCallback(async(route: Route) => {
    await submitHandler();
    navigate(route);
  }, [submitHandler, navigate]);

  const errorMessages = combineErrors(
    { isError, error },
    { isError: isElementsError, error: elementsError },
    { isError: isDeletingError, error: deletingError },
  );

  if (errorMessages.length > 0) {
    return <ErrorsAlert errors={errorMessages} />;
  }

  if (isEdit && (isPending || isElementsPending)) {
    return <Spinner />;
  }

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <PageHeading>Peti {productionLine?.productionLineNumber}</PageHeading>
        {productionLine?.id && (
          <Button onClick={() => submitAndNavigateHandler(Route.productionLineElements(productionLine.id!))} loading={loading} icon={faPrint} color={EButtonColor.SECONDARY}>Lappukuvat</Button>
        )}
        {productionLine?.id && (
          <Button onClick={() => submitAndNavigateHandler(Route.productionLinePrint(productionLine.id!))} loading={loading} icon={faPrint} color={EButtonColor.SECONDARY}>Tulosta</Button>
        )}
        {productionLine?.id && (
          <Button onClick={() => submitAndNavigateHandler(Route.productionLine("add"))} loading={loading} icon={faPlus}>Lisää uusi</Button>
        )}
        <Button onClick={submitHandler} loading={loading}>Tallenna</Button>
        <Button onClick={() => navigate(Route.productionLines)} color={EButtonColor.SECONDARY}>Palaa</Button>
        {isEdit && <Button onClick={deleteHandler} loading={isDeleting || loading} color={EButtonColor.DANGER}>Poista</Button>}
      </div>
      <Container>
        <ProductionLineEdit
          id={id!}
          isEdit={isEdit}
          productionLine={productionLine}
          productionLineElements={elements ?? []}
          ref={productionLineEditRef}
        />
      </Container>
    </>
  );
};

const useGetProductionLine = (id: string, isEdit: boolean) => {
  const { getProductionLine } = useProductionLinesService();

  const {
    data: productionLine,
    isPending,
    isError,
    error,
  } = useQuery<IProductionLine, ErrorResponse>({
    queryKey: [QueryKey.productionLines, id],
    queryFn: ({ signal }) => getProductionLine({ signal, id: id! }),
    enabled: isEdit,
  });

  return { productionLine, isPending, isError, error };
};

const useFetchProductionLineElements = (
  productionLineId: string,
  isEdit: boolean,
  isGetPending: boolean,
) => {
  const { fetchElements } = useElementsService();

  const {
    data: elements,
    isPending: isElementsPending,
    isError: isElementsError,
    error: elementsError,
  } = useQuery<IElement[], ErrorResponse>({
    queryKey: [QueryKey.elements, productionLineId],
    queryFn: ({ signal }) => fetchElements({ signal, search: { productionLineId } }),
    enabled: isEdit && !isGetPending,
  });

  return { elements, isElementsPending, isElementsError, elementsError };
};

const useDeleteProductionLine = (id: string, navigate: NavigateFunction) => {
  const { deleteProductionLine } = useProductionLinesService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteProductionLine(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.productionLines],
        refetchType: "none",
      });
      navigate(Route.productionLines);
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default ProductionLinePage;
