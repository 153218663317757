import Container from '../components/ui/Container/Container';
import Button, { EButtonColor, EButtonSize } from '../components/ui/Button/Button';
import { faFile, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Route } from '../routes';
import MeshList from '../components/Meshes/MeshList';
import { useMeshService } from '../services/mesh-service';
import { useConfirmModal } from '../components/ui/Modal/useConfirmModal';
import { useCallback, useState } from 'react';
import { queryClient } from '../App';
import { QueryKey } from '../services/query-keys';


const MeshesPage: React.FC = () => {
  const navigate = useNavigate();
  const { archivedDeleteMeshes } = useMeshService();
  const openConfirmModal = useConfirmModal();
  const [archivedDeleteLoading, setArchivedDeleteLoading] = useState(false);

  const archivedDeleteHandler = useCallback(async () => {
    const isConfirm = await openConfirmModal("Oletko varma, että haluat poistaa arkistoidut?");
    if (isConfirm) {
      setArchivedDeleteLoading(true);
      try {
        await archivedDeleteMeshes();
        queryClient.removeQueries({ queryKey: [QueryKey.meshes] });
      } catch (error) {
        console.error('Error deleting:', error);
      } finally {
        setArchivedDeleteLoading(false);
      }
    }
  }, [openConfirmModal, archivedDeleteMeshes]);

  return (
    <>
    <h1 style={{ margin: "1rem", display: "flex", gap: "1rem", alignItems: "center",}}>
      Verkot{" "}
      <Button
        size={EButtonSize.SMALL}
        onClick={() => navigate(Route.mesh("add"))}
        icon={faPlus}
        style={{ fontSize: "initial" }}
      >
        Lisää uusi
      </Button>
      <Button
        size={EButtonSize.SMALL}
        onClick={() => navigate(Route.meshesImport)}
        icon={faFile}
        style={{ fontSize: "initial" }}
      >
        Päivitä tiedostosta
      </Button>
      <Button
        size={EButtonSize.SMALL}
        onClick={archivedDeleteHandler}
        style={{ fontSize: "initial" }}
        color={EButtonColor.DANGER}
        loading={archivedDeleteLoading}
      >
        Poista arkistoidut
      </Button>
    </h1>
    <Container>
      <MeshList />
    </Container>
    </>
  )
}

export default MeshesPage;