import { ErrorResponse } from "../../custom-fetch";
import Alert from "../ui/Alert/Alert";

interface IProps {
  errors: string[];
}

const ErrorsAlert: React.FC<IProps> = ({ errors }) => {
  return (
    <Alert style={{ marginTop: 0, whiteSpace: "pre-wrap" }}>
      {errors.length > 1 ? (
        <ul style={{ margin: 0 }}>
          {errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      ) : (
        errors[0]
      )}
    </Alert>
  );
};


interface IError {
  isError: boolean;
  error: ErrorResponse | null;
}

export const combineErrors = (...errors: IError[]) => {
  let messages: string[] = [];
  errors.forEach(error => {
    if (error.isError && error.error && error.error.messages) {
      messages = [...messages, ...error.error.messages];
    }
  });
  return messages;
}

export default ErrorsAlert;
