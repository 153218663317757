import { useCallback } from "react";
import {
  DraggingStyle,
  DragUpdate,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import IWeeklyProductionLine from "../../shared/IWeeklyProductionLine";
import { formatDate } from "../../utils/date-utils";
import IProductionLine from "../../shared/IProductionLine";
import {
  addDays,
  isFriday,
  toDate,
  formatDate as formatDateFns,
} from "date-fns";

const DEBUG = false;

const grid = 8;
const activeColor = "#fff";
const inactiveColor = "#ccc";

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  //   padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: isDragging ? activeColor : inactiveColor,
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  // background: isDraggingOver ? activeColor : inactiveColor,
  background: inactiveColor,
  padding: grid,
  // width: 250,
});

const destructureDragData = (result: DragUpdate) => {
  const source = result.source.droppableId.split("/");
  const sourceProductionWeek = source[0];
  const sourceStartDate = source[1];
  const sourceIndex = result.source.index;

  if (DEBUG) {
    console.log(
      "source",
      source,
      "sourceProductionWeek",
      sourceProductionWeek,
      "sourceStartDate",
      sourceStartDate,
      "sourceIndex",
      sourceIndex
    );
  }

  const destination = result.destination!.droppableId.split("/");
  const destinationProductionWeek = destination[0];
  const destinationStartDate = destination[1];
  const destinationIndex = result.destination!.index;

  if (DEBUG) {
    console.log(
      "destination",
      destination,
      "destinationProductionWeek",
      destinationProductionWeek,
      "destinationStartDate",
      destinationStartDate,
      "destinationIndex",
      destinationIndex
    );
  }

  const productionWeekMatch =
    sourceProductionWeek === destinationProductionWeek;
  const startDateMatch = sourceStartDate === destinationStartDate;
  const indexMatch = sourceIndex === destinationIndex;

  if (DEBUG) {
    console.log(
      "productionWeekMatch",
      productionWeekMatch,
      "startDateMatch",
      startDateMatch,
      "indexMatch",
      indexMatch
    );
  }

  return {
    sourceProductionWeek,
    sourceStartDate,
    sourceIndex,
    destinationProductionWeek,
    destinationStartDate,
    destinationIndex,
    productionWeekMatch,
    startDateMatch,
    indexMatch,
  };
};

const findItem = (
  items: IWeeklyProductionLine[],
  productionWeek: string,
  startDate: string,
  sourceIndex: number
) => {
  return [...items]
    .find((item) => item.productionWeek === productionWeek)
    ?.days.find((day) => !day.startDate || day.startDate === startDate)
    ?.productionLines[sourceIndex];
};

const findItems = (
  items: IWeeklyProductionLine[],
  productionWeek: string,
  startDate: string
) => {
  return (
    [...items]
      .find((item) => item.productionWeek === productionWeek)
      ?.days.find((day) => !day.startDate || day.startDate === startDate)
      ?.productionLines ?? []
  );
};

export function useWeeklyProductionLinesDragAndDrop(
  items: IWeeklyProductionLine[],
  setItems: React.Dispatch<React.SetStateAction<IWeeklyProductionLine[]>>
) {
  const dragEndHandler = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        setItems((items) =>
          items.map((item) => ({
            ...item,
            days: item.days.map((day) => ({
              ...day,
              productionLines: day.productionLines.map(
                (productionLine, index) => ({
                  ...productionLine,
                  position: index + 1,
                })
              ),
            })),
          }))
        );
        return;
      }

      const {
        sourceProductionWeek,
        sourceStartDate,
        sourceIndex,
        destinationProductionWeek,
        destinationStartDate,
        destinationIndex,
        productionWeekMatch,
        startDateMatch,
        indexMatch,
      } = destructureDragData(result);

      if (productionWeekMatch && startDateMatch && indexMatch) return;

      const newItems = [...items];

      const source = findItems(newItems, sourceProductionWeek, sourceStartDate);

      let destination: IProductionLine[] = [];
      if (productionWeekMatch && startDateMatch) {
        if (DEBUG) {
          console.log("destination === source");
        }
        destination = source;
      } else {
        destination = findItems(
          newItems,
          destinationProductionWeek,
          destinationStartDate
        );
      }

      const [removed] = source.splice(sourceIndex, 1);
      if (destinationStartDate !== "undefined") {
        removed.productionWeek = destinationProductionWeek;
        removed.startDate = destinationStartDate;
        removed.startDateFin = formatDate(destinationStartDate);
        let endDate = toDate(destinationStartDate);
        if (isFriday(endDate)) {
          // pe -> ma
          endDate = addDays(endDate, 3);
        } else {
          endDate = addDays(endDate, 1);
        }
        removed.endDate = formatDateFns(endDate, "yyyy-MM-dd");
        removed.endDateFin = formatDateFns(endDate, "dd.MM.yyyy");
        if (DEBUG) {
          console.log(removed.endDate, removed.endDateFin);
        }
      } else {
        removed.startDate = undefined;
        removed.startDateFin = undefined;
        removed.endDate = undefined;
        removed.endDateFin = undefined;
        removed.productionWeek = undefined;
      }
      destination.splice(destinationIndex, 0, removed);

      const updatedItems = newItems.map((item) => ({
        ...item,
        days: item.days.map((day) => ({
          ...day,
          productionLines:
            day.startDate === destinationStartDate
              ? destination.map((productionLine, index) => ({
                  ...productionLine,
                  position: index + 1,
                }))
              : day.startDate === sourceStartDate
              ? source.map((productionLine, index) => ({
                  ...productionLine,
                  position: index + 1,
                }))
              : day.productionLines.map((productionLine, index) => ({
                  ...productionLine,
                  position: index + 1,
                })),
        })),
      }));

      setItems(updatedItems);
      if (productionWeekMatch && startDateMatch) {
        if (DEBUG) {
          console.log(
            "productionWeekMatch && startDateMatch, return destination"
          );
        }
        return findItems(
          updatedItems,
          destinationProductionWeek,
          destinationStartDate
        );
      }
      return [
        ...findItems(updatedItems, sourceProductionWeek, sourceStartDate),
        ...findItems(
          updatedItems,
          destinationProductionWeek,
          destinationStartDate
        ),
      ];
    },
    [items, setItems]
  );

  const dragUpdateHandler = useCallback(
    (result: DragUpdate) => {
      if (!result.destination) return;

      const {
        sourceProductionWeek,
        sourceStartDate,
        sourceIndex,
        // destinationProductionWeek,
        destinationStartDate,
        destinationIndex,
        // productionWeekMatch,
        // startDateMatch,
        // indexMatch,
      } = destructureDragData(result);

      // if (productionWeekMatch && startDateMatch && indexMatch) return;

      const newItems = [...items];
      // const dragged = newItems[result.source.index];
      const dragged = findItem(
        newItems,
        sourceProductionWeek,
        sourceStartDate,
        sourceIndex
      );
      if (!dragged) {
        console.error("dragged NOT FOUND");
        return;
      }

      if (
        dragged.startDate !== "undefined" &&
        destinationStartDate !== "undefined"
      ) {
        dragged.startDate = destinationStartDate;
        dragged.startDateFin = formatDate(destinationStartDate);
        let endDate = toDate(destinationStartDate);
        if (isFriday(endDate)) {
          // pe -> ma
          endDate = addDays(endDate, 3);
        } else {
          endDate = addDays(endDate, 1);
        }
        dragged.endDate = formatDateFns(endDate, "yyyy-MM-dd");
        dragged.endDateFin = formatDateFns(endDate, "dd.MM.yyyy");
        if (DEBUG) {
          console.log(dragged.endDate, dragged.endDateFin);
        }
      } else {
        dragged.startDate = undefined;
        dragged.startDateFin = undefined;
        dragged.endDate = undefined;
        dragged.endDateFin = undefined;
      }

      const previousDraggedIndex = dragged.position;
      dragged.position = destinationIndex + 1;
      const draggedIndexDifference =
        dragged.position - (previousDraggedIndex || 0);

      const updatedContent = newItems.map((item) => ({
        ...item,
        days: item.days.map((day) => ({
          ...day,
          productionLines: day.productionLines.map((productionLine, index) => {
            const sameDay = destinationStartDate === day.startDate;
            let position = productionLine.position;
            if (
              sameDay &&
              position === destinationIndex + 1 &&
              index !== sourceIndex
            ) {
              position -= draggedIndexDifference;
            }
            return {
              ...productionLine,
              position,
            };
          }),
        })),
      }));

      setItems(updatedContent);
    },
    [items, setItems]
  );

  return {
    dragEndHandler,
    dragUpdateHandler,
    items,
  };
}
