import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useWireTypeService } from "../services/wireTypes-service";
import IWireType from "../shared/IWireType";

export const useWireTypeOptions = () => {
  const { fetchWireTypes } = useWireTypeService();

  const {
    data: wireTypes,
    isPending,
    isError,
  } = useQuery<IWireType[], ErrorResponse>({
    queryKey: [QueryKey.wireTypeOptions],
    queryFn: ({ signal }) => fetchWireTypes({ signal }, false),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : wireTypes?.map((p) => ({ value: p.id, label: p.name } as IOption)),
    [wireTypes, isError]
  );

  return { options, loading: isPending, wireTypes };
};
