import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OffersList from "../components/Offers/OffersList";
import OffersSearch from "../components/Offers/OffersSearch";
import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";
import { Route } from "../routes";

const OffersPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const customerId = useMemo(() => searchParams.get("customerId"), [searchParams]);
  const customerName = useMemo(() => searchParams.get("customerName"), [searchParams]);
  const navigate = useNavigate();

  const addHandler = () => {
    navigate(Route.offer("add"));
  };

  return (
    <>
      <PageHeading onAdd={addHandler}>Tarjoukset</PageHeading>
      <Container>
        <OffersSearch customerId={customerId} customerName={customerName} />
      </Container>
      <Container>
        <OffersList />
      </Container>
    </>
  );
};

export default OffersPage;
