import { useCallback, useState } from "react";
import Button, { EButtonColor } from "../../ui/Button/Button";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useInputs } from "../../ui/Input/useInputs";
import ModalBody from "../../ui/Modal/ModalBody/ModalBody";
import ModalFooter from "../../ui/Modal/ModalFooter/ModalFooter";

interface IProps {
  onAccept: (data: IUpdateOfferPricesModalData) => Promise<void>;
  onCancel: () => void;
  data: IUpdateOfferPricesModalData;
}

interface IUpdateOfferPricesModalData {
  grossProfitDivisor: string;
  grossProfit2Divisor: string;
}

enum EInputs {
  grossProfitDivisor = "grossProfitDivisor",
  grossProfit2Divisor = "grossProfit2Divisor",
}

const UpdateOfferPricesModal: React.FC<IProps> = ({
  onAccept,
  onCancel,
  data,
}) => {
  const { createInput, submit } = usePricesInputs(data);
  const [loading, setLoading] = useState(false);

  const submitHandler = useCallback(async () => {
    const data = await submit();
    if (data) {
      setLoading(true);
      await onAccept(data);
    }
    setLoading(false);
  }, [onAccept, submit]);

  return (
    <>
      <ModalBody>
        <p>Päivitetäänkö hinnat ajantasaisiin hintoihin?</p>
        {createInput(EInputs.grossProfitDivisor)}
        {createInput(EInputs.grossProfit2Divisor)}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={submitHandler}
          style={{ marginRight: "0.5rem" }}
          loading={loading}
        >
          Kyllä
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER} disabled={loading}>
          Ei
        </Button>
      </ModalFooter>
    </>
  );
};

const usePricesInputs = (data?: IUpdateOfferPricesModalData) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.grossProfitDivisor]: {
      type: EInputType.number,
      label: "Kate jakaja",
      value: "",
      post: "%",
      min: "0",
      max: "1",
      validation: {
        required: true,
      },
    },
    [EInputs.grossProfit2Divisor]: {
      type: EInputType.number,
      label: "Kate2 jakaja",
      value: "",
      post: "%",
      min: "0",
      max: "1",
      validation: {
        required: true,
      },
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

export default UpdateOfferPricesModal;
