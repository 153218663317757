import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { useProductGroupService } from "../services/productGroups-service";
import { QueryKey } from "../services/query-keys";
import IProductGroup from "../shared/IProductGroup";

export const useProductGroupOptions = () => {
  const { fetchProductGroups } = useProductGroupService();

  const {
    data: productGroups,
    isPending,
    isError,
  } = useQuery<IProductGroup[], ErrorResponse>({
    queryKey: [QueryKey.productGroupOptions],
    queryFn: ({ signal }) => fetchProductGroups({ signal }, false),
    staleTime: Infinity,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : productGroups?.map(
            (p) => ({ value: p.id, label: p.name } as IOption)
          ),
    [productGroups, isError]
  );

  return { options, loading: isPending, productGroups };
};
