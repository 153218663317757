import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../custom-fetch";
import Spinner from "../components/ui/Spinner/Spinner";
import Container from "../components/ui/Container/Container";
import { useConcreteTypeService } from "../services/concreteTypes-service";
import IConcreteType from "../shared/IConcreteType";
import ConcreteTypeEdit from "../components/ConcreteTypes/ConcreteTypeEdit";

type Params = {
  id: string;
};

const ConcreteTypePage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getConcreteType } = useConcreteTypeService();
  
  const {
    data: concreteType,
    isLoading,
    isError,
    error,
  } = useQuery<IConcreteType, ErrorResponse>({
    queryKey: [QueryKey.concreteTypes, id],
    queryFn: ({ signal }) => getConcreteType({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Massatyyppi</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <ConcreteTypeEdit id={id!} concreteType={concreteType} />
        )}
      </Container>
    </>
  );
};

export default ConcreteTypePage;
