import ConcreteTypesImport from "../components/ConcreteTypes/ConcreteTypesImport";

const ConcreteTypesImportPage: React.FC = () => {
  return (
    <>
      <h1 style={{ margin: "1rem" }}>Massatyypit Import</h1>
      <ConcreteTypesImport />
    </>
  );
};

export default ConcreteTypesImportPage;
