import { useMutation } from "@tanstack/react-query";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { ErrorResponse } from "../../../custom-fetch";
import { useWeeklyProductionRowsService } from "../../../services/weeklyProductionRows-service";
import IProject, { IProjectPhase } from "../../../shared/IProject";
import IWeeklyProductionRow from "../../../shared/IWeeklyProductionRow";
import { RefHandle } from "../../../shared/RefHandle";
import ErrorsAlert, { combineErrors } from "../../ErrorsAlert/ErrorsAlert";
import Button from "../../ui/Button/Button";
import { IOption, TInputValue } from "../../ui/Input/Input";
import InputContextProvider, { EInputUpdateAction } from "../../ui/Input/input-context";
import { formatInputValue } from "../../ui/Input/input-utils";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import Table from "../../ui/Table/Table";
import { useProjectEditContext } from "../ProjectEdit/project-edit-context";
import WeeklyProductionRow, { EInputs } from "./WeeklyProductionRow";
import WeeklyProductionRowsTotalTable from "./WeeklyProductionRowsTotalTable";

interface IProps {
  selectedPhase?: IProjectPhase;
  phaseOptions: IOption[];
}

const createItem = (projectId: string, project: IProject, phase?: IProjectPhase): IWeeklyProductionRow => {
  return {
    id: "",
    factory: undefined,
    phaseId: phase?.phaseId ?? "",
    productType: {} as any,
    count: "",
    grossArea: "",
    linealMeters: "",
    cubicMeters: "",
    productionWeek: "",
    deliveryWeek: "",
    projectId,
    projectName: project.name,
    projectNumber: project.projectNumber,
    phaseName: phase?.name ?? "",
  };
};

const WeeklyProductionRows: React.ForwardRefRenderFunction<RefHandle<IWeeklyProductionRow[]>, IProps> = ({ selectedPhase, phaseOptions }, ref) => {
  const { id: projectId, project, weeklyProductionRows, setWeeklyProductionRows, setIsProjectDirty } = useProjectEditContext();
  const openConfirmModal = useConfirmModal();
  
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [copyingId, setCopyingId] = useState<string | null>(null);

  const { saveMutate, isSaving, isSaveError, saveError } = useSave(projectId);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete();
  const { copyMutate, isCopying, isCopyError, copyError } = useCopy();

  const saveHandler = useCallback(async () => {
    const newRow = await saveMutate(createItem(projectId, project, selectedPhase));
    if (newRow) {
      setWeeklyProductionRows(rows => [...rows, newRow]);
    }
  }, [saveMutate, projectId, project, selectedPhase, setWeeklyProductionRows]);

  const deleteHandler = useCallback(async (event: React.MouseEvent, id: string) => {
    const isConfirm = event.shiftKey ? true : await openConfirmModal("Oletko varma, että haluat poistaa rivin?");
    if (isConfirm) {
      setDeletingId(id);
      const deleteSuccess = await deleteMutate(id);
      if (deleteSuccess) {
        setWeeklyProductionRows(rows => rows.filter(row => row.id !== id));
      }
      setDeletingId(null);
    }
  }, [deleteMutate, openConfirmModal, setWeeklyProductionRows]);

  const copyHandler = useCallback(async (row: IWeeklyProductionRow) => {
    setCopyingId(row.id);
    const newRow = await copyMutate(row);
    if (newRow) {
      setWeeklyProductionRows(rows => [...rows, newRow]);
    }
    setCopyingId(null);
  }, [copyMutate, setWeeklyProductionRows]);

  const updateHandler = useCallback(async (inputName: string, value: TInputValue, action: string, _: any) => {
    if (action === EInputUpdateAction.WEEKLY_PRODUCTION_ROWS) {
      const data = _ as IWeeklyProductionRow;
      if (inputName === EInputs.phaseId) {
        const phase = phaseOptions.find(phase => phase.value === value);
        data.phaseName = phase?.label ?? "";
      }
      const newRows = [...weeklyProductionRows];
      const index = newRows.findIndex(r => r.id === data.id);
      if (index > -1) {
        newRows[index] = {
          ...data,
          [inputName]: formatInputValue(value),
        };
      }
      setWeeklyProductionRows(newRows);
      setIsProjectDirty(dirty => ({ ...dirty, weeklyProductionRows: true }));
    }
    return Promise.resolve(true);
  }, [phaseOptions, setIsProjectDirty, setWeeklyProductionRows, weeklyProductionRows]);

  const rowsRef = useRef<RefHandle<IWeeklyProductionRow>[]>([]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const rows = rowsRef.current;
      const newRows: IWeeklyProductionRow[] = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            if (rowData.phaseId) {
              const phase = phaseOptions.find(phase => phase.value === rowData.phaseId);
              rowData.phaseName = phase?.label ?? "";
              console.log("rowData", rowData);
            }
            newRows.push(rowData);
          }
        }
      }
      return newRows;
    },
  }));

  const errorMessages = combineErrors(
    { isError: isSaveError, error: saveError },
    { isError: isDeletingError, error: deletingError },
    { isError: isCopyError, error: copyError }
  );

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "200px" }}>Tehdas</th>
            <th style={{ width: "80px" }}>Kerros / lohko</th>
            <th style={{ width: "125px" }}>TR2</th>
            <th style={{ width: "100px" }}>kpl</th>
            <th style={{ width: "100px" }}>m²</th>
            <th style={{ width: "100px" }}>jm</th>
            <th style={{ width: "100px" }}>m³</th>
            <th style={{ width: "100px" }}>Vko</th>
            {/* (arvioitu tuotantovko) */}
            <th style={{ width: "100px" }}>Toim</th>
            {/* (arvioitu toimitusvko) */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          <InputContextProvider initDone onAutoUpdate={updateHandler}>
            {weeklyProductionRows?.map((row, i) => (
              <WeeklyProductionRow
                key={row.id}
                row={row}
                phaseOptions={phaseOptions}
                onDelete={(event) => deleteHandler(event, row.id)}
                isDeleting={isDeleting && deletingId === row.id}
                ref={(rowHandle) => (rowsRef.current[i] = rowHandle!)}
                onCopy={() => copyHandler(row)}
                isCopying={isCopying && copyingId === row.id}
                hidden={selectedPhase?.phaseId ? selectedPhase.phaseId !== row.phaseId : false}
              />
            ))}
          </InputContextProvider>
          <tr>
            <td colSpan={11}>
              <Button onClick={saveHandler} loading={isSaving}>
                Lisää uusi
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <WeeklyProductionRowsTotalTable />
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
    </div>
  );
};

const useSave = (projectId: string) => {
  const { saveWeeklyProductionRow } = useWeeklyProductionRowsService();

  const {
    mutateAsync: saveMutate,
    isPending: isSaving,
    isError: isSaveError,
    error: saveError,
  } = useMutation<IWeeklyProductionRow, ErrorResponse, IWeeklyProductionRow>({
    mutationFn: (data) => saveWeeklyProductionRow(projectId, data),
  });

  return { saveMutate, isSaving, isSaveError, saveError };
};

const useDelete = () => {
  const { deleteWeeklyProductionRow } = useWeeklyProductionRowsService();

  const {
    mutateAsync: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse, string>({
    mutationFn: (id) => deleteWeeklyProductionRow(id),
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

const useCopy = () => {
  const { copyWeeklyProductionRow } = useWeeklyProductionRowsService();

  const {
    mutateAsync: copyMutate,
    isPending: isCopying,
    isError: isCopyError,
    error: copyError,
  } = useMutation<IWeeklyProductionRow, ErrorResponse, IWeeklyProductionRow>({
    mutationFn: (data) => copyWeeklyProductionRow(data),
  });

  return { copyMutate, isCopying, isCopyError, copyError };
};

export default forwardRef(WeeklyProductionRows);
