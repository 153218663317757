import React from "react";
import { IOption } from "../Input/Input";

import classes from "./RadioButton.module.scss";
import RadioButtonOption from "./RadioButtonOption/RadioButtonOption";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  options: IOption[] | undefined;
  disabled?: boolean;
  invalid?: boolean;
  hideUnselected?: boolean;
}

const RadioButton: React.FC<IProps> = ({
  value,
  onChange,
  options,
  disabled,
  invalid,
  hideUnselected,
}) => {
  if(!options) return null;
  let length = options.length;
  if(hideUnselected && value) {
    options = options.filter(options => options.value === value);
    length = options.length;
  }

  return (
    <div className={classes.OptionContainer}>
      {options.map((option, i) => {
          return (
            <React.Fragment key={option.value}>
              <RadioButtonOption
                value={option.value}
                label={option.label}
                isActive={option.value === value}
                onClick={onChange}
                disabled={disabled}
                invalid={invalid}
              />
              {i < length - 1 && <span style={{ width: "1px" }}></span>}
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default RadioButton;
