import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IInstalmentTable from '../shared/IInstalmentTable';

interface IGetInstalmentTable extends ISignal {
  id: string;
}

export const useInstalmentTableService = () => {
  const customFetch = useCustomFetch();

  const fetchInstalmentTables = useCallback(async ({ signal }: ISignal) => {
    const [instalmentTable] = await customFetch<IInstalmentTable[]>(`/api/instalment-tables/list`, { signal });
    return instalmentTable;
  }, [customFetch]);
  
  const getInstalmentTable = useCallback(async ({ signal, id }: IGetInstalmentTable) => {
    const [instalmentTable] = await customFetch<IInstalmentTable>("/api/instalment-tables/get/" + id, { signal });
    return instalmentTable;
  }, [customFetch]);
  
  const saveInstalmentTable = useCallback(async (data: IInstalmentTable) => {
    const [instalmentTable] = await customFetch<IInstalmentTable>("/api/instalment-tables/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return instalmentTable;
  }, [customFetch]);
  
  const updateInstalmentTable = useCallback(async (id: string, data: IInstalmentTable) => {
    const [instalmentTable] = await customFetch<IInstalmentTable>("/api/instalment-tables/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return instalmentTable;
  }, [customFetch]);
  
  const deleteInstalmentTable = useCallback(async (id: string) => {
    await customFetch<IInstalmentTable>("/api/instalment-tables/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const getInstalmentTableByOfferId = useCallback(async ({ signal, id }: IGetInstalmentTable) => {
    const [instalmentTable] = await customFetch<IInstalmentTable>("/api/instalment-tables/get-offer/" + id, { signal });
    return instalmentTable;
  }, [customFetch]);
  
  return { fetchInstalmentTables, getInstalmentTable, saveInstalmentTable, updateInstalmentTable, deleteInstalmentTable, getInstalmentTableByOfferId};
}