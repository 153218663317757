import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { CSSProperties, forwardRef, useImperativeHandle, useState } from "react";
import { useMeshOptions } from "../../../../hooks/useMeshOptions";
import Button, { EButtonColor, EButtonSize } from "../../../ui/Button/Button";
import { EInputType, IInputField } from "../../../ui/Input/Input";
import { useInputs } from "../../../ui/Input/useInputs";
import { EInputUpdateAction } from "../../../ui/Input/input-context";
import { RefHandle } from "../../../../shared/RefHandle";

export interface IOfferRowCalculationMeshRow {
  id: string;
  squareMeters: string;
  meshId: string;
  price: string;
  weight: string;
  unitName: string;
  type: string;
  weightSquare: string;
  weightPrice: string;
}

export enum EInputs {
  squareMeters = "squareMeters",
  meshId = "meshId",
  weightPrice = "weightPrice",
}

interface IProps {
  calculation: IOfferRowCalculationMeshRow;
  onDelete: () => void;
  showControls: boolean;
}

const containerStyles: CSSProperties = { width: "85px" };

const OfferRowCalculationMesh: React.ForwardRefRenderFunction<RefHandle<IOfferRowCalculationMeshRow>, IProps> = ({ calculation, onDelete, showControls }, ref) => {
  const { loading, options } = useMeshOptions();
  const { createInput, submit } = useMeshInputs(calculation, showControls);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const newData = await submit();
      if (!calculation) return null;
      return { ...calculation, ...newData };
    },
  }), [calculation, submit]);

  return (
    <tr>
      <td>{createInput(EInputs.meshId, { options, loading, containerStyles: { width: "400px" } })}</td>
      <td>{createInput(EInputs.squareMeters, { containerStyles })}</td>
      {/* <td>{calculation?.type}</td> */}
      <td>{calculation?.weightSquare}</td>
      <td>{createInput(EInputs.weightPrice, { containerStyles })}</td>
      <td>{calculation?.price} €</td>
      {/* <td>{}</td> */}
      <td>
        {showControls && (
          <Button
            onClick={onDelete}
            size={EButtonSize.SMALL}
            color={EButtonColor.DANGER}
            icon={faTrash}
          />
        )}
      </td>
    </tr>
  );
};

const useMeshInputs = (data?: IOfferRowCalculationMeshRow, showControls?: boolean) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.squareMeters]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.meshId]: {
      type: EInputType.reactSelect,
      value: "",
      hideControls: true,
      menuPosition: "fixed",
      placeholder: "",
    },
    [EInputs.weightPrice]: {
      type: EInputType.number,
      value: "",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.MESH, disabled: !showControls });

  return { createInput, submit, inputs };
};

export default forwardRef(OfferRowCalculationMesh);
