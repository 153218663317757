import { useOfferOptions } from "../../../hooks/useOfferOptions";
import { useOfferService } from "../../../services/offers-service";
import IOfferRow from "../../../shared/IOfferRow";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../../ui/Modal/modal-context";
import OfferRowCopyModal from "./OfferRowCopyModal";

export const useOfferRowCopyModal = () => {
  const { addModal, closeModal } = useModalContext();
  const { copyOfferRow } = useOfferService();
  const { options, loading, offers } = useOfferOptions();

  const handler = (): Promise<IOfferRow | null> => {
    return new Promise((resolve) => {
      addModal({
        id: EModalId.OFFER_ROW_COPY,
        isOpen: true,
        size: EModalSize.SMALL,
        title: "Kopioi tarjoukselta",
        content: (
          <OfferRowCopyModal
            onAccept={async (id: string, rowId: string) => {
              closeModal(EModalId.OFFER_ROW_COPY);
              const row = await copyOfferRow(id, rowId);
              resolve(row);
            }}
            onCancel={() => {
              closeModal(EModalId.OFFER_ROW_COPY);
              resolve(null);
            }}
            options={options}
            loading={loading}
            offers={offers}
          />
        ),
      });
    });
  };

  return handler;
};
