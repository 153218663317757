import { IWaybillRow } from "../../shared/IWaybill";
import { EInputType, IInputField, IOption } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { EInputUpdateAction } from "../ui/Input/input-context";
import { useMemo, useState } from "react";
import Button, { EButtonColor, EButtonSize } from "../ui/Button/Button";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import IElement from "../../shared/IElement";

export enum EInputs {
  slot = "slot",
  order = "order",
  elementId = "elementId",
}

interface IProps {
  row: IWaybillRow;
  onDelete: () => void;
  elements?: IElement[];
  elementOptions?: IOption[];
  waybillElementIds: string[];
}

const SlotOptions: IOption[]=[
    {value:"A", label:"A"},
    {value:"B", label:"B"},
    {value:"C", label:"C"},
    {value:"D", label:"D"},
    {value:"E", label:"E"},
    {value:"F", label:"F"},
    {value:"G", label:"G"},
    {value:"H", label:"H"},
    {value:"I", label:"I"},
    {value:"J", label:"J"},
    {value:"1", label:"1"},
    {value:"2", label:"2"},
    {value:"3", label:"3"},
    {value:"4", label:"4"},
    {value:"5", label:"5"},
]

const WaybillRow: React.FC<IProps> = ({ row, onDelete, elements, elementOptions, waybillElementIds }) => {
  const { createInput/*, submit*/ } = useWaybillRowInputs(row);

  const element = useMemo(() => elements?.find((e) => e.id === row.elementId), [elements, row.elementId]);

  const filteredOptions = useMemo(() => {
    let options = [...elementOptions ?? []];
    if (waybillElementIds.length > 0) {
      options = options.filter(element => element.value === row.elementId || !waybillElementIds.includes(element.value));
    }
    return options;
  }, [elementOptions, row.elementId, waybillElementIds]);

  return (
    <>
      <tr>
        <td>{createInput(EInputs.slot)}</td>
        <td>{createInput(EInputs.order)}</td>
        <td>{createInput(EInputs.elementId, {options: filteredOptions})}</td>
        <td>{element?.weightTons} t</td>
        <td>{element?.length}</td>
        <td><Button onClick={onDelete} size={EButtonSize.SMALL} color={EButtonColor.DANGER} icon={faTrash} title="Poista" /></td>
      </tr>
    </>
  );
};

const useWaybillRowInputs = (data?: IWaybillRow) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.slot]: {
      type: EInputType.reactSelect,
      value: "",
      options: SlotOptions,
      menuPosition: "fixed",
    },
    [EInputs.order]: {
      type: EInputType.number,
      value: "",
    },
    [EInputs.elementId]: {
      type: EInputType.reactSelect,
      value: "",
      menuPosition: "fixed",
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs, updateAction: EInputUpdateAction.WAYBILL_ROW });

  return { createInput, submit };
};

export default WaybillRow;
