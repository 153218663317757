import { useEffect } from "react";
import { useOfferTermOptions } from "../../../../hooks/useOfferTermOptions";
import { EOfferTermType } from "../../../../shared/IOfferTerm";
import Input, { EInputType } from "../../../ui/Input/Input";

interface IProps {
  planningNotes: string;
  setPlanningNotes: React.Dispatch<React.SetStateAction<string>>;
}

const OfferTextTabPlanning: React.FC<IProps> = ({ planningNotes, setPlanningNotes }) => {
  const { offerTerms } = useOfferTermOptions(EOfferTermType.ENGINEER_TERM, true);

  useEffect(() => {
    if (!planningNotes && offerTerms && offerTerms[0]) {
      setPlanningNotes(offerTerms[0].text);
    }
  }, [offerTerms, planningNotes, setPlanningNotes]);

  return (
    <div style={{maxWidth: "2000px"}}>
      <Input
        value={planningNotes}
        type={EInputType.textarea}
        onChange={(value) => setPlanningNotes(value.toString())}
        inputName={"planningNotes"}
        label={"Suunnitteluohje"}
        rows={4}
      />
    </div>
  );
};

export default OfferTextTabPlanning;
