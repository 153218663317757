import { faLink, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { useFactories } from "../../../hooks/useFactories";
import { useProductGroupOptions } from "../../../hooks/useProductGroupOptions";
import { useSlabTypeOptions } from "../../../hooks/useSlabTypeOptions";
import { useOfferService } from "../../../services/offers-service";
import { useProductTypeService } from "../../../services/productTypes-service";
import IOfferRow, { EFactory, EUnit } from "../../../shared/IOfferRow";
import { HOLLOWCORE_PRODUCT_GROUP_NAME } from "../../../shared/IProductGroup";
import { RefHandle } from "../../../shared/RefHandle";
import Button from "../../ui/Button/Button";
import { TInputValue } from "../../ui/Input/Input";
import InputContextProvider, {
  EInputUpdateAction,
} from "../../ui/Input/input-context";
import { formatInputValue } from "../../ui/Input/input-utils";
import { useConfirmModal } from "../../ui/Modal/useConfirmModal";
import Table from "../../ui/Table/Table";
import { IOfferCalculationSummary } from "../OfferCalculationTabs/OfferCalculationSummary/OfferCalculationSummary";
import { IInstallations } from "../OfferCalculationTabs/OfferCalculationTabInstallation/OfferCalculationTabInstallations";
import { IOfferCalculationKilometer } from "../OfferCalculationTabs/OfferCalculationTabKilometers/OfferCalculationTabKilometer";
import { createInsulationRow } from "../OfferRowCalculation/OfferRowCalculationInsulations/OfferRowCalculationInsulations";
import { createMeshRow } from "../OfferRowCalculation/OfferRowCalculationMeshes/OfferRowCalculationMeshes";
import { createSteelPartRow } from "../OfferRowCalculation/OfferRowCalculationSteelParts/OfferRowCalculationSteelParts";
import { createSteelRow } from "../OfferRowCalculation/OfferRowCalculationSteels/OfferRowCalculationSteels";
import { useOfferRowCopyModal } from "../OfferRowCopyModal/useOfferRowCopyModal";
import OfferProduct, { EInputs } from "./OfferProduct";
import { useOfferRowsImportModal } from "./OfferProductsImport/useOfferProductsImportModal";
import { useOfferProductsContext } from "./offer-products-context";

export const DEFAULT_FACTORY_FEE = "30";
export const DEFAULT_FACTORY_LINE_LENGTH = "115";

export const createOfferRow = (grossProfitDivisor?: string, grossProfit2Divisor?: string): IOfferRow => {
    const id = uuid();
    return { id, factory: undefined, productGroupId: "", productTypeId: "", productGroupName: "", productTypeName: "", description: "", hidden: false, unitQuantity: "0", count: "0", unit: EUnit.jm, unitPrice: "0", rowPrice: "0", deleted: false, linealMeters: "0", grossArea: "0", cubicMeters: "0", cubicMeterPrice: "0", netArea: "0", grossProfitDivisor, grossProfit2Divisor, swatchbook: 0,
        offerRowCalculationHollowcore: { slabTypeId: "0", pricePerSquare: "0", squareWeight: "0", deliveryPriceForTon: "0",  deliveryPriceForSquare: "0", totalPricePerSquare: "0" },
        offerRowCalculationConcrete: { length1: "0", length2: "0", length3: "0", width1: "0", width2: "0", width3: "0", height1: "0", height2: "0", height3: "0", concreteTypeId1: "", concreteTypeId2: "", concreteTypeId3: "", concreteWeightPerCubic1: "0", concreteWeightPerCubic2: "0", concreteWeightPerCubic3: "0", concretePricePerCubic1: "0", concretePricePerCubic2: "0", concretePricePerCubic3: "0", area1: "0", area2: "0", area3: "0", volume1: "0", volume2: "0", volume3: "0", totalVolume: "0", price1: "0", price2: "0", price3: "0" , weightTons: "0"},
        offerRowCalculationSteelPart: { price: "", weight: "", rows: [ createSteelPartRow() ] },
        offerRowCalculationSteel: { price: "", weight: "", rows: [ createSteelRow() ] },
        offerRowCalculationSteelTendon: { steelId: "", steelWeight: "0", steelPrice: "0", tendonCount: "0", tendonWaste: "9", productionLineLength: DEFAULT_FACTORY_LINE_LENGTH, elementsInProductionLine: "0" },
        offerRowCalculationMesh: { price: "", weight: "", rows: [ createMeshRow() ] },
        offerRowCalculationInsulation: { price: "", weight: "", rows: [ createInsulationRow() ] },
        offerRowCalculationTotal: {},
        offerRowCalculationWork: { addedDifficultyHoursElement: "0", hourlyRate: "0", factoryFee: DEFAULT_FACTORY_FEE, workers: "0", hoursDay: "8", days: "0", productionLineLength: DEFAULT_FACTORY_LINE_LENGTH, elementsInProductionLine: "0", addedDifficultyHoursSquare: "0", workHoursElement: "0", price: "0", workHoursTotalForProductionLine: "0", workEstimateForElement: "0" },
        additionalInformation: "",
    };
}

const OfferProducts: React.ForwardRefRenderFunction<RefHandle<IOfferRow[]>> = (_, ref) => {
  const { offerRows, setOfferRows, currentOfferRow, setCurrentOfferRow, updateConditionalTerms, summary, setSummary, installations, setInstallations, offerKilometers, overwriteDescription, setOverwriteDescription, showControls } = useOfferProductsContext();
  const { addHandler, deleteHandler, updateHandler, copyHandler, copyFromOfferHandler } = useHandlers(offerRows, setOfferRows, setCurrentOfferRow, updateConditionalTerms, summary, setSummary, installations, setInstallations, offerKilometers, overwriteDescription, showControls);
  const importOfferRows = useOfferRowsImportModal();
  const [importLoading, setImportLoading] = useState(false);
  const openConfirmModal = useConfirmModal();

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (currentOfferRow?.id) {
        if (e.ctrlKey && e.key === "-") {
          e.preventDefault();
          setCurrentOfferRow(null);
        }
        return;
      }
      if (!showControls) return;
      if (e.repeat) return;
      if (e.ctrlKey && e.key === "+") {
        // console.log("add offer row");
        e.preventDefault();
        addHandler();
      }
    };

    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [addHandler, currentOfferRow?.id, setCurrentOfferRow, showControls]);

  const setFocusHandler = useCallback((id: string) => {
    if (currentOfferRow?.id !== id) {
      setCurrentOfferRow(offerRows.find(row => row.id === id) ?? null);
    }
  }, [currentOfferRow?.id, offerRows, setCurrentOfferRow]);
  
  const importHandler = async () => {
    setImportLoading(true);
    const result = await importOfferRows();
    if (result) {
      const newRows = result.newItems.map((item) => ({ ...createOfferRow(summary?.grossProfitDivisor, summary?.grossProfit2Divisor), ...item }));
      setOfferRows((prev) => [...prev, ...newRows]);
    }
    setImportLoading(false);
  };

  const confirmDeleteHandler = useCallback(async (event: React.MouseEvent, id: string) => {
    const isConfirm = event.shiftKey ? true : await openConfirmModal("Oletko varma, että haluat poistaa tuoterivin?");
    if (isConfirm) {
      deleteHandler(id);
    }
  }, [openConfirmModal, deleteHandler]);

  const onSwatchbookChange = useCallback((id: string, value: number) => {
    setOfferRows(offerRows => {
      const newOfferRows = [...offerRows];
      const index = newOfferRows.findIndex(row => row.id === id);
      if (index > -1) {
        newOfferRows[index].swatchbook = value;
      }
      return newOfferRows;
    });
  }, [setOfferRows]);

  const offerRowsRef = useRef<RefHandle<IOfferRow>[]>([]);

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const offerRows = offerRowsRef.current;
      const newRows: IOfferRow[] = [];
      for (let i = 0; i < offerRows.length; i++) {
        const row = offerRows[i];
        if (row) {
          const rowData = await row.getData();
          if (rowData) {
            newRows.push(rowData);
          }
        }
      }
      return newRows.length > 0 ? [...newRows] : null;
    },
  }), []);

  return (
    <Table style={{marginTop: "1rem"}}>
      <thead>
        <tr>
          <th style={{width: "20px"}}  title="Ei tulosteta">ET</th>
          <th style={{width: "20px"}}  title="Lihavointi">L</th>
          <th style={{maxWidth: "80px"}}  title="Tehdas">teh</th>
          <th>tuoteryhmä</th>
          {showControls 
            ? <th style={{width: "125px", cursor: "pointer"}} title={"Tuoteryhmän tarkenne\nSelite ylikirjoitus: "+(overwriteDescription?"Päällä":"Pois päältä")} onClick={()=>setOverwriteDescription(!overwriteDescription)}>TR2 {overwriteDescription ? <FontAwesomeIcon icon={faLink} /> : <FontAwesomeIcon icon={faUnlink} />}</th>
            : <th style={{width: "125px"}}  title="Tuoteryhmän tarkenne">TR2</th>
          }
          <th>selite</th>
          <th style={{width: "100px"}}>kpl</th>
          <th style={{width: "100px"}}>jm</th>
          <th style={{width: "100px"}}>nettom²</th>
          <th style={{width: "100px"}}>brm²</th>
          <th style={{width: "80px"}}>m³</th>
          <th style={{width: "100px"}}>määrä</th>
          <th style={{width: "80px"}}>yks</th>
          <th style={{width: "100px"}}>myyntihinta</th>
          <th style={{width: "100px"}}>rivihinta</th>
          <th style={{width: "100px"}}>€/m³</th>
          <th style={{width: "100px", textAlign: "right"}}>toiminnot</th>
        </tr>
      </thead>
      <tbody>        
        <InputContextProvider initDone onAutoUpdate={updateHandler}>
          {offerRows.map((row, index) => (
            <OfferProduct key={row.id} row={row} onDelete={(event) => confirmDeleteHandler(event, row.id)} selected={currentOfferRow?.id === row.id} onClick={() => setFocusHandler(row.id)} onCopy={() => copyHandler(row)} onRemoveFocus={() => setCurrentOfferRow(null)} onSwatchbookChange={(value) => onSwatchbookChange(row.id, value)} striped={(index + 1) % 2 === 1} ref={rowHandle => offerRowsRef.current[index] = rowHandle!} showControls={showControls} />
          ))}
        </InputContextProvider>
        {showControls && (
          <tr>
            <td colSpan={18}>
              <div style={{ display: "flex", gap: "1rem" }}>
                <Button onClick={addHandler} title="Pikanäppäin Ctrl +">Uusi tuoterivi</Button>
                <Button onClick={copyFromOfferHandler}>Kopioi tarjoukselta</Button>
                <Button onClick={importHandler} loading={importLoading}>Import</Button>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const useHandlers = (
  rows: IOfferRow[],
  setOfferRows: React.Dispatch<React.SetStateAction<IOfferRow[]>>,
  setCurrentOfferRow: React.Dispatch<React.SetStateAction<IOfferRow | null>>,
  updateConditionalTerms: () => Promise<void>,
  summary: IOfferCalculationSummary | null,
  setSummary: React.Dispatch<React.SetStateAction<IOfferCalculationSummary>>,
  installations: IInstallations,
  setInstallations: React.Dispatch<React.SetStateAction<IInstallations>>,
  offerKilometers: IOfferCalculationKilometer[],
  overwriteDescription: boolean,
  showControls: boolean,
) => {
  const { productGroups } = useProductGroupOptions();
  const { slabTypes } = useSlabTypeOptions();
  const { factories } = useFactories();
  const { getProductType } = useProductTypeService();

  const addHandler = useCallback(() => {
    const newRow = createOfferRow(summary?.grossProfitDivisor, summary?.grossProfit2Divisor);
    if (rows.length > 0) {
      // get all that have a value in offerKilometer.freightKilometers
      const filteredList = offerKilometers.filter(offerKilometer => offerKilometer.freightKilometers);
      // if there is only 1 with freightKilometers then we use factory from it 
      newRow.factory = filteredList.length === 1 ? filteredList[0].factory : undefined;
    }
    setOfferRows((rows) => [...rows, newRow]);
    setCurrentOfferRow(newRow);
  }, [summary?.grossProfitDivisor, summary?.grossProfit2Divisor, offerKilometers, rows.length, setOfferRows, setCurrentOfferRow]);

  const { calculateOfferRow, calculateOfferSummaryRows, calculateOfferInstallations } = useOfferService();

  const deleteHandler = useCallback(
    async (id: string) => {
      const offerRows = rows.filter((i) => i.id !== id)
      setOfferRows(offerRows);
      const installationRows = installations.installationRows ?? [];
      const newInstallations = await calculateOfferInstallations({...installations, installationRows: installationRows.filter((i) => i.offerRowId != null && i.offerRowId !== id)});
      setInstallations({...newInstallations});
      updateConditionalTerms();
      const newSummary = await calculateOfferSummaryRows(offerRows, newInstallations.installationDeclaredPrice);
      setSummary((summary) => ({ ...summary, ...newSummary }));
    },
    [calculateOfferInstallations, calculateOfferSummaryRows, installations, rows, setInstallations, setOfferRows, setSummary, updateConditionalTerms]
  );

  const updateInstallationRows = useCallback(async (row: IOfferRow) => {
    const installationRows = installations.installationRows ?? [];
    const installationIndex = installationRows.findIndex((r) => r.offerRowId != null && r.offerRowId === row.id);
    const productGroup = productGroups?.find((p) => p.id === row.productGroupId);

    let newInstallations: IInstallations | undefined = undefined;
    if (productGroup && productGroup.installationCost && row.count) {
      if (installationIndex > -1) {
        installationRows[installationIndex] = {
          ...installationRows[installationIndex],
          productGroup: productGroup,
          quantity: row.count,
        };
        newInstallations = await calculateOfferInstallations({ ...installations });
      } else {
        newInstallations = await calculateOfferInstallations({
          ...installations,
          installationRows: [
            ...installationRows,
            {
              id: uuid(),
              offerRowId: row.id,
              productGroup: productGroup,
              cost: productGroup.installationCost,
              quantity: row.count,
              totalCost: "0",
            },
          ],
        });
      }
    } else {
      //remove if no productGroup && productGroup.installationCost && currentRow.count
      newInstallations = await calculateOfferInstallations({
        ...installations,
        installationRows: installationRows.filter((i) => i.offerRowId !== row.id),
      });
    }
    setInstallations(newInstallations);
    return newInstallations;
  }, [calculateOfferInstallations, installations, productGroups, setInstallations]);

  const updateHandler = useCallback(
    async (inputName: string, value: TInputValue, action: string, _: any) => {
      if (action === EInputUpdateAction.OFFER_ROW) {
        const data = _ as IOfferRow;

        if (inputName === EInputs.productGroupId) {
          const selectedProductGroup = productGroups?.find((p) => p.id === value);
          data.productGroupName = selectedProductGroup?.name ?? "";
        }

        let productName = "";
        if (inputName === EInputs.productTypeId) {
          const selectedProductType = value ? await getProductType({ id: value as string }) : undefined;
          //const selectedProductType = productTypes?.find((p) => p.id === value);
          productName = selectedProductType?.name || "";
          data.productTypeId = selectedProductType?.id ?? "0";
          data.productTypeName = productName;
          if(overwriteDescription) data.description = productName;
          if (selectedProductType?.unit) data.unit = selectedProductType.unit;
          if (selectedProductType?.price) data.unitPrice = selectedProductType.price;
        }

        if (inputName === EInputs.factory) {
          const selectedFactory = factories?.find((f) => f.factoryId === (value as EFactory));
          const factoryFee = selectedFactory?.factoryFee;
          if (factoryFee) {
            data.offerRowCalculationWork.factoryFee = factoryFee;
            data.offerRowCalculationWork.hourlyRate = factoryFee;
          }
        }

        const index = rows.findIndex((r) => r.id === data.id);
        if (index > -1) {
          rows[index] = {
            ...data,
            [inputName]: formatInputValue(value),
          };
        }

        if (productName && data.productGroupName === HOLLOWCORE_PRODUCT_GROUP_NAME && data.offerRowCalculationHollowcore) {
          const selectedSlabType = slabTypes?.find((slabType) => slabType.typeName === productName);
          if (selectedSlabType) {
            rows[index].offerRowCalculationHollowcore.slabTypeId = selectedSlabType?.id ?? "";
            rows[index].offerRowCalculationHollowcore.squareWeight = selectedSlabType?.netWeight ?? "0";
            rows[index].offerRowCalculationHollowcore.pricePerSquare = selectedSlabType?.squarePrice ?? "0";
          }
        }

        rows[index] = await calculateOfferRow(rows[index]);
        if (productName && data.productGroupName === HOLLOWCORE_PRODUCT_GROUP_NAME && data.offerRowCalculationHollowcore) {
          rows[index].unitPrice = rows[index].offerRowCalculationHollowcore?.totalPricePerSquare ?? "0";
        }
        setOfferRows(rows);
        if (inputName !== EInputs.hidden) {
          setCurrentOfferRow(rows[index]);
        }

        if (inputName === EInputs.factory || inputName === EInputs.productGroupId || inputName === EInputs.productTypeId) {
          updateConditionalTerms();
        }
        
        let installationDeclaredPrice = installations.installationDeclaredPrice;
        if (inputName === EInputs.productGroupId || inputName === EInputs.count) {
          const currentRow = rows[index];
          const newInstallations = await updateInstallationRows(currentRow);
          installationDeclaredPrice = newInstallations.installationDeclaredPrice;
        }

        const newSummary = await calculateOfferSummaryRows(rows, installationDeclaredPrice);
        setSummary((summary) => {
          //if (summary.calculatedAmount === newSummary.calculatedAmount) {
            // console.log("calculated amount did not change, do not overwrite");
            newSummary.offerPrice = summary.offerPrice;
            newSummary.offerPriceVAT = summary.offerPriceVAT;
            newSummary.offerPriceInstalled = summary.offerPriceInstalled;
            newSummary.offerPriceInstalledVAT = summary.offerPriceInstalledVAT;
          //}
          return { ...summary, ...newSummary };
        });
      }
      return Promise.resolve(true);
    },
    [rows, calculateOfferRow, setOfferRows, setCurrentOfferRow, installations.installationDeclaredPrice, calculateOfferSummaryRows, setSummary, productGroups, getProductType, overwriteDescription, factories, slabTypes, updateConditionalTerms, updateInstallationRows]
  );

  const copyHandler = useCallback(async (row: IOfferRow) => {
    const id = uuid();
    const newRow = { ...row, id };
    setOfferRows((rows) => [...rows, newRow]);
    setCurrentOfferRow(newRow);
    const newInstallations = await updateInstallationRows(newRow);
    const newSummary = await calculateOfferSummaryRows([...rows, newRow], newInstallations.installationDeclaredPrice);
    setSummary((summary) => ({ ...summary, ...newSummary }));
  }, [setOfferRows, setCurrentOfferRow, calculateOfferSummaryRows, rows, setSummary, updateInstallationRows]);

  const openCopyModal = useOfferRowCopyModal();

  const copyFromOfferHandler = useCallback(async () => {
    const row = await openCopyModal();
    if (row) {
      copyHandler(row);
    }
  }, [openCopyModal, copyHandler]);

  return { addHandler, deleteHandler, updateHandler, copyHandler, copyFromOfferHandler };
};

export default forwardRef(OfferProducts);
