import { ReactNode, createContext, useContext } from "react";
import IOfferRow, { EFactory } from "../../../shared/IOfferRow";
import { IOfferCalculationKilometer } from "./OfferCalculationTabKilometers/OfferCalculationTabKilometer";
import { IOfferCalculationSummary } from "./OfferCalculationSummary/OfferCalculationSummary";
import { IInstallations } from "./OfferCalculationTabInstallation/OfferCalculationTabInstallations";

interface IOfferCalculationTabsContext {
  offerKilometers: IOfferCalculationKilometer[];
  setOfferKilometers: React.Dispatch<React.SetStateAction<IOfferCalculationKilometer[]>>;
  openGoogleMapsHandler: (factory: EFactory) => void;
  summary: IOfferCalculationSummary;
  setSummary: React.Dispatch<React.SetStateAction<IOfferCalculationSummary>>;
  installations: IInstallations;
  setInstallations: React.Dispatch<React.SetStateAction<IInstallations>>;
  offerRows: IOfferRow[];
  setOfferRows: React.Dispatch<React.SetStateAction<IOfferRow[]>>;
}

const OfferCalculationTabsContext = createContext<IOfferCalculationTabsContext>(
  {
    offerKilometers: [],
    setOfferKilometers: () => {},
    openGoogleMapsHandler: () => {},
    summary: {} as IOfferCalculationSummary,
    setSummary: () => {},
    installations: {} as IInstallations,
    setInstallations: () => {},
    offerRows: [],
    setOfferRows: () => {},
  }
);

export const useOfferCalculationTabsContext = () =>
  useContext(OfferCalculationTabsContext);

const OfferCalculationTabsContextProvider: React.FC<{
  value: IOfferCalculationTabsContext;
  children: ReactNode;
}> = ({ value, children }) => (
  <OfferCalculationTabsContext.Provider value={value}>
    {children}
  </OfferCalculationTabsContext.Provider>
);

export default OfferCalculationTabsContextProvider;
