import React, { CSSProperties, ReactNode } from 'react';

interface IProps {
  children: ReactNode;
  style?: CSSProperties;
}

const IconBackground: React.FC<IProps> = ({ children, style }) => {
  return (
    <span style={{ ...style, background: "#FFF", padding: ".125rem" }}>{children}</span>
  )
}

export default IconBackground;