import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import { useUserService } from "../../services/users-service";
import { OrganizationType } from "../../shared/IOrganization";
import IUser from "../../shared/IUser";
import {
  EModalId,
  EModalSize,
  useModalContext,
} from "../ui/Modal/modal-context";
import UserEditModal from "./UserEditModal";

export const useUserEditModal = (type: OrganizationType, organizationId: string, user?: IUser) => {
  const { addModal, closeModal, updateModal } = useModalContext();

  const id = useMemo(() => user?.id ?? "add", [user?.id]);
  const isEdit = useMemo(() => id !== "add", [id]);

  const mutateHandler = useCallback(() => updateModal(EModalId.USER_EDIT, { error: null }), [updateModal]);
  const errorHandler = useCallback((error: ErrorResponse) => updateModal(EModalId.USER_EDIT, { error: error.messages.join(", ") }), [updateModal]);

  const closeModalHandler = useCallback(() => closeModal(EModalId.USER_EDIT), [closeModal]);
  const { saveOrUpdate } = useSaveOrUpdate(id, isEdit, organizationId, mutateHandler, closeModalHandler, errorHandler);

  const handler = (): Promise<string | null> => {
    let title = "";
    if (type === "CUSTOMER") {
      title = isEdit ? "Muokkaa asiakkaan yhteyshenkilöä" : "Lisää asiakkaan yhteyshenkilö";
    } else if (type === "ENGINEER") {
      title = isEdit ? "Muokkaa suunnittelijan yhteyshenkilöä" : "Lisää suunnittelijan yhteyshenkilö";
    }
    return new Promise((resolve) => {
      addModal({
        id: EModalId.USER_EDIT,
        isOpen: true,
        size: EModalSize.SMALL,
        title,
        backdropNotClose: true,
        content: (
          <UserEditModal
            onAccept={async (data) => {
              if (user?.id) {
                data.id = user.id;
              }
              data.organizationId = organizationId;
              try {
                const user = await saveOrUpdate(data);
                // console.log(user);
                resolve(user?.id);
              } catch (e) {
                console.error(e);
              }
            }}
            onCancel={() => {
              updateModal(EModalId.USER_EDIT, { loading: false, error: null });
              closeModalHandler();
              resolve(null);
            }}
            user={user}
          />
        ),
      });
    });
  };

  return handler;
};

const useSaveOrUpdate = (id: string, isEdit: boolean, organizationId: string, onMutate: () => void, onSuccess: () => void, onError: (error: ErrorResponse) => void) => {
  const { updateUser, saveUser } = useUserService();

  const mutationFn = (data: IUser) => {
    return isEdit ? updateUser(id, data) : saveUser(data);
  };

  const {
    mutateAsync: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IUser, ErrorResponse, IUser>({
    mutationFn,
    onMutate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.users, organizationId] });
      onSuccess();
    },
    onError,
  });

  return { saveOrUpdate, isPending, isError, error };
};
