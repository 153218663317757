import React from 'react';
import classes from './square.module.scss';

const SquareFI: React.FC = () => {
  return (
    <div className={classes.square}>
      <span className={classes.textFi}>FI</span>
    </div>
  );
};

export default SquareFI;