import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { queryClient } from "../../App";
import { ErrorResponse } from "../../custom-fetch";
import { Route } from "../../routes";
import { QueryKey } from "../../services/query-keys";
import ErrorsAlert, { combineErrors } from "../ErrorsAlert/ErrorsAlert";
import FormButtons from "../ui/FormButtons/FormButtons";
import { ECommonValue, EInputType, IInputField } from "../ui/Input/Input";
import { useInputs } from "../ui/Input/useInputs";
import { useInsulationService } from "../../services/insulations-service";
import IInsulation from "../../shared/IInsulation";

interface IProps {
  id: string;
  insulation?: IInsulation;
}

enum EInputs {
  unitName = "unitName",
  cubicPrice = "cubicPrice",
  archived = "archived"
}

const InsulationEdit: React.FC<IProps> = ({ id, insulation }) => {
  const isEdit = id !== "add";
  
  const navigate = useNavigate();

  const redirectHandler = () => {
    return navigate(Route.insulations);
  };

  const { saveOrUpdate, error, isError, isPending } = useSaveOrUpdate(id, isEdit, redirectHandler);
  const { deleteMutate, isDeleting, isDeletingError, deletingError } = useDelete(id, redirectHandler);
  const { createInput, submit } = useInsulationInputs(insulation);

  const submitHandler = async () => {
    const data = await submit();
    if (data) {
      saveOrUpdate(data);
    }
  };

  const errorMessages = combineErrors({ isError, error }, { isError: isDeletingError, error: deletingError });

  return (
    <>
      {createInput(EInputs.unitName)}
      {createInput(EInputs.cubicPrice)}
      {/*createInput(EInputs.archived)*/}
      {errorMessages.length > 0 && <ErrorsAlert errors={errorMessages} />}
      <FormButtons
        onSubmit={submitHandler}
        isLoading={isPending}
        onDelete={isEdit ? deleteMutate : undefined}
        isDeleting={isDeleting}
        deleteConfirmMessage="Haluatko varmasti poistaa eristeen?"
      />
    </>
  );
};

const useInsulationInputs = (data?: IInsulation) => {
  const [inputs, setInputs] = useState<IInputField>({
    [EInputs.unitName]: {
      type: EInputType.text,
      label: "Tunnus",
      value: "",
      validation: {
        required: true,
      },
      autoFocus: true
    },
    [EInputs.cubicPrice]: {
      type: EInputType.number,
      label: "Kuutiohinta",
      value: "",
      validation: {
        required: true,
      },
    },
    [EInputs.archived]: {
      type: EInputType.checkbox,
      label: "Arkistoitu",
      options: [{ value: ECommonValue.YES }],
      value: [],
    },
  });

  const { createInput, submit } = useInputs({ data, inputs, setInputs });

  return { createInput, submit };
};

const useSaveOrUpdate = (id: string, isEdit: boolean, redirectHandler: () => void) => {
  const { updateInsulation, saveInsulation } = useInsulationService();

  const mutationFn = (data: IInsulation) => {
    return isEdit ? updateInsulation(id, data) : saveInsulation(data);
  };

  const {
    mutate: saveOrUpdate,
    isPending,
    isError,
    error,
  } = useMutation<IInsulation, ErrorResponse, IInsulation>({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: [QueryKey.insulations, id] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.insulations] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.insulationOptions] });
      redirectHandler();
    },
  });

  return { saveOrUpdate, isPending, isError, error };
};

const useDelete = (id: string, redirectHandler: () => void) => {
  const { deleteInsulation } = useInsulationService();

  const {
    mutate: deleteMutate,
    isPending: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation<boolean, ErrorResponse>({
    mutationFn: () => deleteInsulation(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.insulations],
        refetchType: "none",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.insulationOptions],
        refetchType: "none",
      });
      redirectHandler();
    },
  });

  return { deleteMutate, isDeleting, isDeletingError, deletingError };
};

export default InsulationEdit;
