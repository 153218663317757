import { useElementOptions } from '../../hooks/useElementOptions';
import { EFactory } from '../../shared/IOfferRow';
import IWaybillDTO from '../../shared/IWaybillDTO';
import Table from '../ui/Table/Table';
import classes from './WaybillPrint.module.scss';
import WaybillPrintHeader from './WaybillPrintHeader';

interface IProps {
  waybill: IWaybillDTO;
}
const WaybillPrintElements: React.FC<IProps> = ({ waybill }) => {
  const { elements } = useElementOptions(waybill.projectId, waybill.factory as EFactory);

  return (
    <div className={classes.container}>
        <WaybillPrintHeader waybill={waybill} logoSfs={true}/>
        <Table className={classes.printTable}>
        <thead>
          <tr>
            <th>Paikka</th>
            <th>Tyyppi</th>
            <th>Tunnus</th>
            <th>Lisä tunnus</th>
            <th>Ton</th>
            <th>Pituus</th>
            <th>Leveys</th>
            <th>Kuljetus korkeus</th>
            <th>Peti</th>
          </tr>
        </thead>
        <tbody>
          {waybill.waybillRows && waybill.waybillRows.map((row) => {
            const element = elements?.find((e) => e.id === row.elementId);
            return (<tr key={row.id}>
              <td>{row.slot} {row.order}</td>
              <td>{element?.typeName}</td>
              <td>{element?.name}</td>
              <td>{"TODO"}</td>
              <td>{element?.weightTons}</td>
              <td>{element?.length}</td>
              <td>{element?.width}</td>
              <td>{"TODO"}</td>
              <td>{element?.productionLineNumber}/{element?.position}</td>
            </tr>
            )
          })}
        </tbody>
        </Table>
    </div>
  )
}

export default WaybillPrintElements;