import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IWaybill from '../shared/IWaybill';
import { IWaybillsSearch } from '../components/Waybills/WaybillsSearch';

interface IFetchWaybills extends ISignal {
  search?: IWaybillsSearch | null;
}

interface IGetWaybill extends ISignal {
  id: string;
}

export const useWaybillsService = () => {
  const customFetch = useCustomFetch();

  const fetchWaybills = useCallback(async ({ signal, search }: IFetchWaybills) => {
    const [waybills] = await customFetch<IWaybill[]>(`/api/waybills/list`, {
      signal,
      method: "POST",
      body: JSON.stringify(search),
    });
    return waybills;
  }, [customFetch]);
  
  const getWaybill = useCallback(async ({ signal, id }: IGetWaybill) => {
    const [waybill] = await customFetch<IWaybill>("/api/waybills/get/" + id, { signal });
    return waybill;
  }, [customFetch]);

  const saveWaybill = useCallback(async (data: IWaybill) => {
    const [waybill] = await customFetch<IWaybill>("/api/waybills/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return waybill;
  }, [customFetch]);
  
  const updateWaybill = useCallback(async (id: string, data: IWaybill) => {
    const [waybill] = await customFetch<IWaybill>("/api/waybills/update/" + id, {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return waybill;
  }, [customFetch]);
  
  const deleteWaybill = useCallback(async (id: string) => {
    await customFetch<IWaybill>("/api/waybills/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);
  
  return { fetchWaybills, getWaybill, saveWaybill, updateWaybill, deleteWaybill };
}