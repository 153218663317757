import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { ErrorResponse } from "../../custom-fetch";
import { QueryKey } from "../../services/query-keys";
import { IWaybillsSearch } from "./WaybillsSearch";
import { storage } from "../../utils/localstorage-utils";
import { useWaybillsService } from "../../services/waybills-service";
import IWaybill from "../../shared/IWaybill";

export const useWaybillsSearch = (projectId?: string) => {
  const searchJson = useMemo(() => storage.getWaybillsSearch(), []);
  const initialSearch = useMemo(() => searchJson ? JSON.parse(searchJson ?? "") : null, [searchJson]);

  const [search, setSearch] = useState<IWaybillsSearch | null>({...initialSearch, projectId: projectId ?? initialSearch?.projectId } ?? { projectId });
  const { fetchWaybills } = useWaybillsService();

  const {
    data: waybills,
    isPending,
    isRefetching,
    isError,
    error,
    refetch,
  } = useQuery<IWaybill[], ErrorResponse>({
    queryKey: [QueryKey.waybills],
    queryFn: ({ signal }) => fetchWaybills({ signal, search }),
    staleTime: 5000,
  });

  return {
    waybills,
    isLoading: isPending || isRefetching,
    isError,
    error,
    search,
    setSearch,
    refetch,
  };
};
