import { Navigate } from 'react-router-dom';

import imgGoogle from '../assets/google-logo.png';
import imgMicrosoft from '../assets/microsoft-logo.png';
import ErrorsAlert from '../components/ErrorsAlert/ErrorsAlert';
import Logo from '../components/Logo/Logo';
import ImageButton from '../components/ui/Button/ImageButton';
import Container from '../components/ui/Container/Container';
import Spinner from '../components/ui/Spinner/Spinner';
import { useAuthContext } from '../context/auth-context';

const AuthPage: React.FC = () => {
  const { loginWithGoogle, loginWithMicrosoft, isLoggingIn, isAuth, errors } = useAuthContext();
  
  
  if (isAuth) {
    const params = new URLSearchParams(window.location.search);
    const redirect = params.get('continue');
    return <Navigate to={redirect ? redirect : '/'} replace />;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Container
        shadow
        style={{
          maxWidth: "30rem",
          width: "30rem",
          marginTop: "10rem",
          borderRadius: ".5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <Logo size="medium" />
        <h1>Kirjaudu sisään</h1>
        {errors && <ErrorsAlert errors={errors} />}
        {isLoggingIn ? (
          <Spinner />
        ) : (
          <>
            <ImageButton
              outlined
              onClick={loginWithGoogle}
              img={imgGoogle}
              imgAlt="Google"
            >
              Google kirjautuminen
            </ImageButton>
            <ImageButton
              outlined
              onClick={loginWithMicrosoft}
              img={imgMicrosoft}
              imgAlt="Microsoft"
            >
              Microsoft kirjautuminen
            </ImageButton>
          </>
        )}
      </Container>
    </div>
  );
};

export default AuthPage;
