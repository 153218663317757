import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IWeeklyProductionRow from '../shared/IWeeklyProductionRow';

export interface IFetchWeeklyProductionRowData {
  productionWeekEquals?: string;
  productionWeekGreater?: string;
  deliveryWeek?: string;
  factory?: string;
  productTypeId?: string;
}

interface IFetchWeeklyProductionRow extends ISignal {
  projectId: string;
  jsonBody?: IFetchWeeklyProductionRowData;
}

interface IGetWeeklyProductionRow extends ISignal {
  id: string;
}

export const useWeeklyProductionRowsService = () => {
  const customFetch = useCustomFetch();

  const fetchWeeklyProductionRows = useCallback(async ({ signal, projectId, jsonBody }: IFetchWeeklyProductionRow) => {
    let url = "/api/weeklyProductionRows/list";
    if (projectId) url += `/${projectId}`;
    const [rows] = await customFetch<IWeeklyProductionRow[]>(url, { signal, method: "POST", body: JSON.stringify(jsonBody) });
    return rows;
  }, [customFetch]);
  
  const getWeeklyProductionRow = useCallback(async ({ signal, id }: IGetWeeklyProductionRow) => {
    const [row] = await customFetch<IWeeklyProductionRow>("/api/weeklyProductionRows/get/" + id, { signal });
    return row;
  }, [customFetch]);

  const saveWeeklyProductionRow = useCallback(async (projectId: string, data: IWeeklyProductionRow) => {
    const [row] = await customFetch<IWeeklyProductionRow>("/api/weeklyProductionRows/add/" + projectId, {
      method: "POST",
      body: JSON.stringify(data),
    });
    return row;
  }, [customFetch]);
  
  const updateWeeklyProductionRows = useCallback(async (data: IWeeklyProductionRow[]) => {
    const [row] = await customFetch<IWeeklyProductionRow[]>("/api/weeklyProductionRows/update", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return row;
  }, [customFetch]);
  
  const deleteWeeklyProductionRow = useCallback(async (id: string) => {
    await customFetch<IWeeklyProductionRow>("/api/weeklyProductionRows/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  const copyWeeklyProductionRow = useCallback(async (data: IWeeklyProductionRow) => {
    const [row] = await customFetch<IWeeklyProductionRow>("/api/weeklyProductionRows/copy", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return row;
  }, [customFetch]);

  const fetchWeeklyProductionRowsByWeek = useCallback(async ({ signal, projectId, jsonBody }: IFetchWeeklyProductionRow) => {
    let url = "/api/weeklyProductionRows/list-by-week";
    if (projectId) url += `/${projectId}`;
    const [rows] = await customFetch<IWeeklyProductionRow[]>(url, { signal, method: "POST", body: JSON.stringify(jsonBody) });
    return rows;
  }, [customFetch]);

  const fetchWeeklyProductionRowsByProject = useCallback(async ({ signal, projectId, jsonBody }: IFetchWeeklyProductionRow) => {
    let url = "/api/weeklyProductionRows/list-by-project";
    if (projectId) url += `/${projectId}`;
    const [rows] = await customFetch<IWeeklyProductionRow[]>(url, { signal, method: "POST", body: JSON.stringify(jsonBody) });
    return rows;
  }, [customFetch]);

  return { fetchWeeklyProductionRows, getWeeklyProductionRow, saveWeeklyProductionRow, updateWeeklyProductionRows, deleteWeeklyProductionRow, copyWeeklyProductionRow, fetchWeeklyProductionRowsByWeek, fetchWeeklyProductionRowsByProject };
}
