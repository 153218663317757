import MeshImport from "../components/Meshes/MeshImport";

const MeshImportPage: React.FC = () => {
  return (
    <>
      <h1 style={{ margin: "1rem" }}>Verkot Import</h1>
      <MeshImport />
    </>
  );
};

export default MeshImportPage;
