import { useCallback } from 'react';
import { ISignal, useCustomFetch } from "../custom-fetch";
import IAdditionalInvoice from '../shared/IAdditionalInvoice';

interface IFetchAdditionalInvoice extends ISignal {
  projectId: string;
}

interface IGetAdditionalInvoice extends ISignal {
  id: string;
}

export const useAdditionalInvoicesService = () => {
  const customFetch = useCustomFetch();

  const fetchAdditionalInvoices = useCallback(async ({ signal, projectId }: IFetchAdditionalInvoice) => {
    let url = "/api/additionalInvoices/list";
    if (projectId) url += `/${projectId}`;
    const [rows] = await customFetch<IAdditionalInvoice[]>(url, { signal });
    return rows;
  }, [customFetch]);
  
  const getAdditionalInvoice = useCallback(async ({ signal, id }: IGetAdditionalInvoice) => {
    const [row] = await customFetch<IAdditionalInvoice>("/api/additionalInvoices/get/" + id, { signal });
    return row;
  }, [customFetch]);

  const saveAdditionalInvoice = useCallback(async (data: IAdditionalInvoice) => {
    const [row] = await customFetch<IAdditionalInvoice>("/api/additionalInvoices/add", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return row;
  }, [customFetch]);
  
  const updateAdditionalInvoices = useCallback(async (data: IAdditionalInvoice[]) => {
    const [row] = await customFetch<IAdditionalInvoice[]>("/api/additionalInvoices/update", {
      method: "PUT",
      body: JSON.stringify(data),
    });
    return row;
  }, [customFetch]);
  
  const deleteAdditionalInvoice = useCallback(async (id: string) => {
    await customFetch<IAdditionalInvoice>("/api/additionalInvoices/delete/" + id, {
      method: "DELETE"
    });
    return true;
  }, [customFetch]);

  return { fetchAdditionalInvoices, getAdditionalInvoice, saveAdditionalInvoice, updateAdditionalInvoices, deleteAdditionalInvoice };
}
