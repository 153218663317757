import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QueryKey } from "../services/query-keys";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import { ErrorResponse } from "../custom-fetch";
import Spinner from "../components/ui/Spinner/Spinner";
import Container from "../components/ui/Container/Container";
import { useMeshService } from "../services/mesh-service";
import IMesh from "../shared/IMesh";
import MeshEdit from "../components/Meshes/MeshEdit";

type Params = {
  id: string;
};

const MeshPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getMesh } = useMeshService();
  
  const {
    data: mesh,
    isLoading,
    isError,
    error,
  } = useQuery<IMesh, ErrorResponse>({
    queryKey: [QueryKey.meshes, id],
    queryFn: ({ signal }) => getMesh({ signal, id: id! }),
    enabled: id !== 'add',
  });

  return (
    <>
      <h1 style={{ margin: "1rem" }}>Verkko</h1>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <MeshEdit id={id!} mesh={mesh} />
        )}
      </Container>
    </>
  );
};

export default MeshPage;
