import IOfferRow from "../../../../shared/IOfferRow";
import Table from "../../../ui/Table/Table";

interface IProps {
  offerRow: IOfferRow;
}

const WorkTotalTable: React.FC<IProps> = ({ offerRow }) => {
  return (
    <Table removeGap>
      <thead>
        <tr>
          <th></th>
          <th>kg /m³</th>
          <th>kg /m²</th>
          <th style={{paddingLeft: "50px"}}>Punokset Kg/Peti</th>
          <th>Punoksia kpl</th>
          {/* <th>Verkkoja kg/m³</th>
          <th>kg/m²</th>
          <th>PunoksiaKg/Peti</th>
          <th>Punoksia kpl</th> */}
          {/* <th>kok kpl</th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Teräs</td>
          <td>{offerRow.offerRowCalculationTotal.steelCubicMeterWeight}</td>
          <td>{offerRow.offerRowCalculationTotal.steelSquareMeterWeight}</td>
          <td style={{paddingLeft: "50px"}}>{offerRow.offerRowCalculationSteelTendon.weight}</td>
          <td>{offerRow.offerRowCalculationSteelTendon.tendonCount}</td>
        </tr>
        <tr>
          <td>Verkot</td>
          <td>{offerRow.offerRowCalculationTotal.meshCubicMeterWeight}</td>
          <td colSpan={3}>{offerRow.offerRowCalculationTotal.meshSquareMeterWeight}</td>
        </tr>
        
        {/* <td>{offerRow.count}</td> */}
    </tbody>
    </Table >
  );
};

export default WorkTotalTable;
