import { useQuery } from "@tanstack/react-query";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useFactoryService } from "../services/factory-service";
import IFactory from "../shared/IFactory";

export const useFactories = () => {
  const { fetchFactories } = useFactoryService();

  const {
    data: factories,
    isPending,
    isError,
  } = useQuery<IFactory[], ErrorResponse>({
    queryKey: [QueryKey.factories],
    queryFn: fetchFactories,
    staleTime: Infinity,
  });

  return { isPending, isError, factories };
};
