import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { IOption } from "../components/ui/Input/Input";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useProjectsService } from "../services/projects-service";
import IProject from "../shared/IProject";

export const useProjectOptions = () => {
  const { fetchProjects } = useProjectsService();

  const {
    data: projects,
    isPending,
    isError,
  } = useQuery<IProject[], ErrorResponse>({
    queryKey: [QueryKey.projectOptions],
    queryFn: fetchProjects,
    staleTime: 5000,
  });

  const options = useMemo(
    () =>
      isError
        ? []
        : projects?.map(
            (project) => ({ value: project.id, label: project.name } as IOption)
          ),
    [projects, isError]
  );

  return { options, loading: isPending, projects };
};
