import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Offer from "../components/Offers/Offer/Offer";
import Container from "../components/ui/Container/Container";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { useConfirmBeforeUnload } from "../hooks/useConfirmBeforeUnload";
import { useOfferService } from "../services/offers-service";
import { QueryKey } from "../services/query-keys";
import IOffer from "../shared/IOffer";

type Params = {
  id: string;
};

const OfferPage: React.FC = () => {
  useConfirmBeforeUnload();
  
  const [searchParams] = useSearchParams();
  const customerId = useMemo(() => searchParams.get("customerId"), [searchParams]);
  const { id } = useParams<Params>();
  const { getOffer } = useOfferService();
  
  const isEdit = useMemo(() => id !== "add", [id]);

  const {
    data: offer,
    isLoading,
    isError,
    error,
  } = useQuery<IOffer, ErrorResponse>({
    queryKey: [QueryKey.offers, id],
    queryFn: ({ signal }) => getOffer({ signal, id: id! }),
    enabled: isEdit,
  });

  return (
    <>
      <Container>
        {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : null}
      </Container>
      {!isError && !isLoading && (
        <Offer
          id={id!}
          offer={offer}
          customerId={customerId}
        />
      )}
    </>
  );
};

export default OfferPage;
