import "./index.scss";

import { configureStore } from "@reduxjs/toolkit";
import ReactDOM from "react-dom/client";
import App from "./App";
import IAppState from "./shared/store/IAppState";

import { Provider } from "react-redux";
import authReducer from "./store/reducers/authReducer";
import offerReducer from "./store/reducers/offerReducer.ts";

const store = configureStore<IAppState>({
  reducer: {
    auth: authReducer,
    offer: offerReducer,
  },
});

export const dispatch = store.dispatch;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
