import Container from "../components/ui/Container/Container";
import PageHeading from "../components/ui/PageHeading/PageHeading";

const ReportsPage: React.FC = () => {
  return (
    <>
      <PageHeading>Raportit</PageHeading>
      <Container>
        <iframe
          title="Looker Studio -raportti"
          width="1200"
          height="900"
          src="https://lookerstudio.google.com/embed/reporting/546a0e5b-88d4-4a00-9d49-a3fac14cffd4/page/p_r23hlopyjd"
          style={{ border: 0 }}
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      </Container>
    </>
  );
};

export default ReportsPage;
