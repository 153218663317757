import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ErrorsAlert from "../components/ErrorsAlert/ErrorsAlert";
import Spinner from "../components/ui/Spinner/Spinner";
import { ErrorResponse } from "../custom-fetch";
import { QueryKey } from "../services/query-keys";
import { useProductionLinesService } from "../services/productionLines-service";
import IProductionLine from "../shared/IProductionLine";
import ProductionLinePrint from "../components/ProductionLines/ProductionLinePrint/ProductionLinePrint";

type Params = {
  id: string;
};

const ProductionLinePrintPage: React.FC = () => {
  const { id } = useParams<Params>();
  const { getProductionLine } = useProductionLinesService();
  
  const {
    data: productionLine,
    isLoading,
    isError,
    error,
  } = useQuery<IProductionLine, ErrorResponse>({
    queryKey: [QueryKey.productionLines, id],
    queryFn: ({ signal }) => getProductionLine({ signal, id: id! }),
  });
  return (
    <>
      {isError ? (
          <ErrorsAlert errors={error.messages} />
        ) : isLoading ? (
          <Spinner />
        ) : productionLine && (
          <ProductionLinePrint productionLine={productionLine} />
        )}
    </>
  )
}
export default ProductionLinePrintPage;