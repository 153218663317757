type PrintOrientation = 'portrait' | 'landscape';
export const printWithOrientation = (orientation: PrintOrientation = 'portrait') => {
  const printStyles = `
    @media print {
      @page {
        size: ${orientation};
      }
    }
  `;

  const styleSheet = document.createElement('style');
  styleSheet.type = 'text/css';
  styleSheet.innerHTML = printStyles;
  document.head.appendChild(styleSheet);

  window.print();

  document.head.removeChild(styleSheet);
};