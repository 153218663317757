import { useEffect } from "react";
//import { useConfirmModal } from "../components/ui/Modal/useConfirmModal";

export const useConfirmBeforeUnload = () => {
  useEffect(() => {
    const beforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, []);
};

/* browser alert always takes priority so this is not possible
export const useConfirmBeforeUnload = () => {
  const openConfirmModal = useConfirmModal();
  useEffect(() => {
    const beforeUnload = (event: BeforeUnloadEvent) => {
      openConfirmModal("Are you sure you want to leave?")
        .then((confirmed) => {
          if (confirmed) {
            window.removeEventListener("beforeunload", beforeUnload);
          }
        })
        .catch((error) => {
            console.log(error)
        });
      event.preventDefault();
    };

    window.addEventListener("beforeunload", beforeUnload);
    
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
    
  }, [openConfirmModal]);
};
*/