import { useNavigate } from "react-router-dom";
import Table from "../ui/Table/Table";
import { Route } from "../../routes";
import IInsulation from "../../shared/IInsulation";
import { useState } from "react";
import { useInsulationService } from "../../services/insulations-service";
import Button, { EButtonSize } from "../ui/Button/Button";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { queryClient } from "../../App";
import { QueryKey } from "../../services/query-keys";

interface IProps {
  insulations: IInsulation[];
  showUpdateArchived?: boolean
}

const InsulationsTable: React.FC<IProps> = ({ insulations, showUpdateArchived }) => {
  const navigate = useNavigate();
  const { archivedUpdateInsulation } = useInsulationService();
  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const archivedUpdateHandler = async (id: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setUpdatingIds((prevIds) => [...prevIds, id]);
    try {
      const updated = await archivedUpdateInsulation(id);
      queryClient.setQueryData([QueryKey.insulations], (insulations: IInsulation[]) => 
        insulations.map((item) => 
          item.id === id ? { ...item, archived: updated.archived } : item
        )
      );
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setUpdatingIds((prevIds) => prevIds.filter((rowId) => rowId !== id));
    }
  };

  const openHandler = (id: string) => {
    navigate(Route.insulation(id));
  };

  return (
    <Table hover>
      <thead>
        <tr>
          <th>Tunnus</th>
          <th>Kuutiohinta</th>
          <th>Päivitetty</th>
          <th>Arkistoitu</th>
        </tr>
      </thead>
      <tbody>
        {insulations.map((insulation) => (
          <tr
            key={insulation.id}
            onClick={() => openHandler(insulation.id)}
            style={{
              color: insulation.archived ? "gray" : "inherit",
              fontStyle: insulation.archived ? "italic" : "inherit",
            }}
          >
            <td>{insulation.unitName}</td>
            <td>{insulation.cubicPrice} €</td>
            <td>{insulation.updatedString}</td>
            {showUpdateArchived ?
            <td><Button size={EButtonSize.X_SMALL} loading={updatingIds.includes(insulation.id)} onClick={(event)=>archivedUpdateHandler(insulation.id, event)} icon={insulation.archived ? faEyeSlash : faEye}></Button></td>
            :
            <td>{insulation.archived && "arkistoitu"}</td>
            }
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default InsulationsTable;
