import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Menu.module.scss';
import { IMenuItem } from '../useMenuItems';

interface IProps {
  menuItems: IMenuItem[];
}

const Menu: React.FC<IProps> = ({ menuItems }) => {
  
  const menuItemClickHandler = (menuItem: IMenuItem): void => {
    const { link, onClick } = menuItem;
    if (link) {
      window.location.href = link;
    } else if(onClick) {
      onClick();
    }
  };

  const createSubMenu = (menuItems?: IMenuItem[]) => {
    if (!menuItems || !menuItems.length) return null;
    let chunks: IMenuItem[][] = [];
    const oArray = [...menuItems];
    while (oArray.length) {
      chunks = [...chunks, [...oArray.splice(0, 4)]];
    }

    return (
      <div className={classes.SubMenu}>
        {chunks.map((chunk, i) => (
          <div key={i}>{chunk.map((menuItem) => renderMenuItem(menuItem, true))}</div>
        ))}
      </div>
    );
  };

  const renderMenuItem = (menuItem: IMenuItem, isSubMenuItem = false) => {
    const { text, menuItems, styles, to } = menuItem;
    const className = isSubMenuItem ? classes.SubMenuItem : classes.MenuItem;
    const Tag = isSubMenuItem ? "div" : "li";
    const activeClassName = isSubMenuItem ? classes.SubMenuItemActive : classes.MenuItemActive;
    const subMenu = createSubMenu(menuItems);

    if (to) {
      return (
        <Tag key={text}>
          <NavLink
            to={to}
            className={(nav) => nav.isActive ? [activeClassName, className].join(' ') : className}
            style={styles}
            onClick={(e) => e.stopPropagation()}
          >
            {text}
            {subMenu}
          </NavLink>
        </Tag>
      );
    } else {
      return (
        <Tag key={text} className={className} onClick={() => menuItemClickHandler(menuItem)} style={styles}>
          {text}
          {subMenu}
        </Tag>
      );
    }
  };

  return (
    <>
      <nav style={{ display: "flex", flexGrow: 1 }}>
        <ul className={classes.MainMenu}>{menuItems.map((menuItem) => renderMenuItem(menuItem))}</ul>
      </nav>
    </>
  );
};

export default Menu;
